import React, {
	useState,
	useRef,
	useEffect,
	useCallback,
	createContext,
} from "react";
import PlayIcon from "../images/play-circlebio.svg";
import PauseIcon from "@mui/icons-material/PauseCircle";
import { Grid, IconButton, Slider, Typography } from "@mui/material";
import { TrustedHTML } from "trusted-types/lib";
import { typeOf } from "react-is";

const CustomAudioPlayer = ({
	audioUrl,
	musicId,
	displayDirectly,
	activeMenu,
	isInHome,
	toShowPlay
}: any) => {
	audioUrl = audioUrl.split('?')[0]
	const audioRef = useRef(document.createElement("audio"));	
	const [isPlaying, setIsPlaying] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	
	const pauseAudioPlayer = (musicId:any) => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");
	
		audioPlayers.forEach((audioPlayer) => {
			
	
			const audioElement = audioPlayer as HTMLAudioElement;
			if (audioPlayer.id == musicId) {
			//	audioElement.play();
			}else{
				//audioPlayer.pause();
				audioElement.pause();
			}
		});
	};
	const handlePlay = useCallback(() => {
		
			pauseAudioPlayer(musicId);	
		}, [musicId]);
	
	  useEffect(() => {
		setIsPlaying(toShowPlay);
		// Update the audio duration once the audio reference is loaded
	
			const duration = audioRef?.current?.duration;	
			setDuration(duration);
		
		return () => {
			setIsPlaying(false);
			setCurrentTime(0);
		};
	},  [toShowPlay, audioUrl]);

	useEffect(() => {		
	
		audioRef.current.src = audioUrl;	
	}, [audioUrl])

	return (
		<Grid
			container={true}
			className={musicId ? "verticallyCenter audioPlayer" : 'verticallyCenter'}
            id={musicId ? "audioPlayerFor-" + musicId : 'bioMusic'}
			xs={12}
			> 
			
			{activeMenu && activeMenu === "Recording" ? (
				<audio id={musicId} src={audioUrl} ref={audioRef} crossOrigin="anonymous" controls controlsList="nodownload noremoteplayback noplaybackrate" onPlay={handlePlay}/>

			) : (
				<audio id={musicId} ref={audioRef} crossOrigin="anonymous" controls onPlay={handlePlay} controlsList="nodownload noremoteplayback noplaybackrate" >
				<source src={audioUrl} type="audio/mpeg" />
			</audio>
			
			
			)}
			<div style={{display:'none'}}>
			test audio
			<audio ref={audioRef}  crossOrigin="anonymous" controls  controlsList="nodownload noremoteplayback noplaybackrate">
				<source src={audioUrl} type="audio/mpeg" />
			</audio>
			</div>
		</Grid>		
	);
};

export default CustomAudioPlayer;

import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Stack,
	Typography,
} from "@mui/material";
import Trash from "../../images/delete-border-delete.svg";
import Location from "../../images/location.svg";
import Calender from "../../images/calendar.svg";
import Dollar from "../../images/dollar-circle.svg";
import Edit from "../../images/editborder-edit.svg";
import GuitarG from "../../icons/Color/SVG/Color_Electric Guitar.svg";
import AccordionG from "../../icons/Color/SVG/Color_Accordian.svg";
import BanjoG from "../../icons/Color/SVG/Color_Banjo.svg";
import BassGuitarG from "../../icons/Color/SVG/Color_Bass Guitar.svg";
import DrumsG from "../../icons/Color/SVG/Color_Drums.svg";
import FluteG from "../../icons/Color/SVG/Color_Flute.svg";
import KeyboardG from "../../icons/Color/SVG/Color_Keyboard.svg";
import MicrophoneG from "../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Xylophone_Marimba.svg";
import PanpipeG from "../../icons/Color/SVG/Color_Bagpipes.svg";
import PianoG from "../../icons/Color/SVG/Color_Piano.svg";
import SaxophoneG from "../../icons/Color/SVG/Color_Sousaphone.svg";
import TrumpetG from "../../icons/Color/SVG/Color_Trumpet.svg";
import ViolinG from "../../icons/Color/SVG/Color_Violin.svg";
import { useEffect, useState } from "react";
import NewGig from "../Gigs/NewGig";
import GigPost from "../EventAndGigPost/GigPost";
import CloseIcon from "../../images/close.svg";
import PostGig from "./PostGig";

const GigView = ({
	gig,
	setGig,
	handleDelete,
	isEdit,
	editPost,
	postType,
}: any) => {
	const token = localStorage.getItem("sessionId");
	const [openEditGigPopup, setOpenEditGigPopup] = useState(false);
	const [gigDetails, setGigDetails] = useState<any>(gig);
	const [openDeleteGigPopup, setOpenDeleteGigPopup] = useState(false);

	useEffect(() => {
		setGigDetails(gig);
	},[gig]);

	
	const handleSuccess = () => {
		setOpenEditGigPopup(false);
		//props.onSuccess();
	};
	const handleOnClose = () => {
		setOpenEditGigPopup(false);
	};

	const handleCloseDeletePopup = () => {
		setOpenDeleteGigPopup(false);
	};
	const instrumentArr = gigDetails.masterInstruments?.map(
		(instrument: any) => instrument.instrumentName
	);
	const getIcons = (instrument: string) => {
		switch (instrument) {
			case "Accordion":
				return (
					<img
						src={AccordionG}
						style={{ height: "24px", width: "24px" }}
						alt="Accordion"
					/>
				);
			case "Banjo":
				return (
					<img
						src={BanjoG}
						style={{ height: "24px", width: "24px" }}
						alt="Banjo"
					/>
				);
			case "Bass Guitar":
				return (
					<img
						src={BassGuitarG}
						style={{ height: "24px", width: "24px" }}
						alt="BassGuitar"
					/>
				);
			case "Drums":
				return (
					<img
						src={DrumsG}
						style={{ height: "24px", width: "24px" }}
						alt="Drums"
					/>
				);
			case "Flute":
				return (
					<img
						src={FluteG}
						style={{ height: "24px", width: "24px" }}
						alt="Flute"
					/>
				);
			case "Guitar":
				return (
					<img
						src={GuitarG}
						style={{ height: "24px", width: "24px" }}
						alt="Guitar"
					/>
				);
			case "Keyboard":
				return (
					<img
						src={KeyboardG}
						style={{ height: "24px", width: "24px" }}
						alt="Keyboard"
					/>
				);
			case "Microphone":
				return (
					<img
						src={MicrophoneG}
						style={{ height: "24px", width: "24px" }}
						alt="Microphone"
					/>
				);
			case "Panpipe":
				return (
					<img
						src={PanpipeG}
						style={{ height: "24px", width: "24px" }}
						alt="Panpipe"
					/>
				);
			case "Piano":
				return (
					<img
						src={PianoG}
						style={{ height: "24px", width: "24px" }}
						alt="Piano"
					/>
				);
			case "Saxophone":
				return (
					<img
						src={SaxophoneG}
						style={{ height: "24px", width: "24px" }}
						alt="Saxophone"
					/>
				);
			case "Trumpet":
				return (
					<img
						src={TrumpetG}
						style={{ height: "24px", width: "24px" }}
						alt="Trumpet"
					/>
				);
			case "Violin":
				return (
					<img
						src={ViolinG}
						style={{ height: "24px", width: "24px" }}
						alt="Violin"
					/>
				);
			default:
				break;
		}
	};
	// setInstrument(instrumentArr);
	const String = `${gigDetails.venue} ${gigDetails.cityCountry}`;
	const gigVenue = String.replace(/#/g, "");
	const startDateTimeString: string = gigDetails.startDateTime;
	const startDate: Date = new Date(startDateTimeString);
	const durationInMinutes: number = gigDetails.duration;

	const hours: number = Math.floor(durationInMinutes / 60);
	const minutes: number = durationInMinutes % 60;
	const formattedDuration: string = `${hours} Hr ${minutes} Min`;

	const options: Intl.DateTimeFormatOptions = {
		month: "short",
		day: "numeric",
		year: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	};

	const formattedDate: string = startDate.toLocaleDateString("en-US", options);

	// const fetchGigDetails = () => {
	// 	// setLoading(true);
	// 	fetch(`${process.env.REACT_APP_BASE_URL}/api/get-event/details/`, {
	// 		method: "POST",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			Authorization: `Bearer ${token}`,
	// 		},
	// 		body: JSON.stringify({
	// 			id: gigDetails.id,
	// 		}),
	// 	})
	// 		.then((res) => {
	// 			if (res.status !== 200) {
	// 			} else {
	// 				return res.json();
	// 			}
	// 		})
	// 		.then(async (data) => {
	// 			setGigDetails(data);
	// 			setGig(data);
	// 		})
	// 		.catch((err) => {
	// 			//   setLoading(false);
	// 			// setLoading(false);
	// 		});
	// };

	// useEffect(() => {
	// 	if (editPost && postType !== "TEXT")  {
	// 		fetchGigDetails();
	// 	}
	// }, []);


	return (
		<>
			<Grid container={true} xs={12} spacing={1}>
				<Grid item={true} xs={12}>
					<Grid container={true} xs={12}>
						<Grid item={true} xs={6}>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "24px",
									fontWeight: 500,
									lineHeight: "30px",
									textAlign: "left",
								}}
							>
								Gig
							</Typography>
						</Grid>
						<Grid container xs={6} justifyContent={"end"}>
							{isEdit && (
								<Button
									onClick={() => setOpenEditGigPopup(true)}
									sx={{
										border: "1px",
									}}
								>
									<img
										src={Edit}
										alt="edit"
										style={{ width: "100%", height: "100%" }}
									/>
								</Button>
							)}
							<Button
								onClick={() => setOpenDeleteGigPopup(true)}
								sx={{
									border: "1px",
								}}
							>
								<img
									src={Trash}
									alt="Trash"
									style={{ width: "100%", height: "100%" }}
								/>
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item={true} xs={12}>
					<img
						src={
							gigDetails.bannerUrl !== null &&
							gigDetails.bannerUrl !== "" &&
							gigDetails.bannerUrl !== undefined
								? gigDetails.bannerUrl.includes("https://")
									? gigDetails.bannerUrl
									: `${process.env.REACT_APP_PICTURE_URL}${gigDetails.bannerUrl}`
								: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/gig-banner.png`
						}
						style={{ borderRadius: "12px", width: "100%" }}
					/>
				</Grid>
				<Grid item={true} xs={12}>
					<Grid container xs={12} spacing={2}>
						<Grid item={true} xs={4}>
							<img
								src={
									gigDetails.thumbnailUrl !== null &&
									gigDetails.thumbnailUrl !== "" &&
									gigDetails.thumbnailUrl !== undefined
										? gigDetails.thumbnailUrl.includes("https://")
											? gigDetails.thumbnailUrl
											: `${process.env.REACT_APP_PICTURE_URL}${gigDetails.thumbnailUrl}`
										: `${process.env.REACT_APP_PICTURE_URL}/defaultimages/gig-thumbnail.svg`
								}
								style={{ borderRadius: "12px", width: "100%" }}
							/>
						</Grid>
						<Grid item={true} xs={8}>
							<Stack sx={{ width: "100%" }} spacing={0.5}>
								<Typography
									sx={{
										fontFamily: "Lexend",
										fontSize: "18px",
										fontWeight: 600,
										lineHeight: "23px",
										textAlign: "left",
										color: "#000",
										overflow: "hidden",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
									}}
								>
									{gigDetails.title?.substring(0, 20)}
								</Typography>

								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<img
										src={Location}
										alt="location-icon"
										style={{ alignSelf: "flex-start" }}
									/>
									<span
										style={{
											fontFamily: "Lexend",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "20px",
											letterSpacing: "0em",
											textAlign: "left",
											color: "#5B5B65",
											justifySelf: "center",
											marginLeft: "5px",
										}}
									>
										{gigVenue}
									</span>
								</Typography>
								<Typography
									style={{
										fontFamily: "Lexend",
										fontSize: "14px",
										fontWeight: 500,
										lineHeight: "20px",
										letterSpacing: "0em",
										textAlign: "left",
										color: "#5B5B65",
										justifySelf: "center",
										overflow: "hidden",
										whiteSpace: "nowrap",
										textOverflow: "ellipsis",
									}}
								>
									{gigDetails.requirements &&
									gigDetails.requirements !== "undefined"
										? gigDetails.requirements.length > 30
											? `${gigDetails.requirements.substring(0, 30)}...`
											: gigDetails.requirements
										: ""}
								</Typography>

								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<img src={Calender} alt="calender-icon" />
									<span
										style={{
											fontFamily: "Lexend",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "20px",
											letterSpacing: "0em",
											textAlign: "left",
											color: "#5B5B65",
											justifySelf: "center",
											marginLeft: "5px",
										}}
									>{`${formattedDate} | ${formattedDuration} `}</span>
								</Typography>
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<img src={Dollar} alt="dollar-icon" />
									<span
										style={{
											fontFamily: "Lexend",
											fontSize: "14px",
											fontWeight: 500,
											lineHeight: "20px",
											letterSpacing: "0em",
											textAlign: "left",
											color: "#5B5B65",
											justifySelf: "center",
											marginLeft: "5px",
										}}
									>
										{gigDetails.cost !== null ? gigDetails.cost : "Free"}
									</span>
								</Typography>
								<Typography
									sx={{
										fontFamily: "Lexend",
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "16px !important",
										lineHeight: "20px",
										color: "#5B5B65",
									}}
								>
									<Stack direction="row" spacing={1}>
										{instrumentArr?.slice(0, 1).map((name: string) => (
											<Chip key={name} avatar={getIcons(name)} label={name} />
										))}
										{instrumentArr?.length > 1 && (
											<Chip label={`+${instrumentArr.length - 1}`} />
										)}
									</Stack>
								</Typography>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{openDeleteGigPopup && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteGigPopup}
					onClose={() => handleCloseDeletePopup()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeletePopup()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{
									marginLeft: "90%",
									position: "absolute",
									top: "8px",
								}}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete "{gig.title}"</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={() => handleCloseDeletePopup()}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => handleDelete()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{openEditGigPopup && (
				<PostGig
					gig={gig}
					open={openEditGigPopup}
					handleGigClose={handleOnClose}
					gigType={"modifyGig"}
					setGig={setGig}
				/>
			)}
		</>
	);
};

export default GigView;

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { CardMedia, Typography } from "@mui/material";
import Box from "@mui/material/Box";
// import POSTImg from "../../assets/images/image1.jpg";
import POSTImg from "../../images/image1.jpg";
import DefaultProfilePicture from "../../images/User square 198.png";
import { useNavigate } from "react-router-dom";
import {
	addMinutes,
	format,
	isBefore,
	differenceInDays,
	differenceInMinutes,
} from "date-fns";
import CustomAudioPlayer from "../../pages/CustomAudioPlayer";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CommentReplyDialog from "./CommentReplyDialog";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";

export const CommentReaction = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [approvedTags, setApprovedTags] = useState<any[]>(
		JSON.parse(localStorage.getItem("approvedTags") || "[]")
	);
	const [showApprove, setShowApprove] = useState(false);
	//console.log(props.notification.userPrivacySettingsDTO?.tagPermission + " props.notification.userPrivacySettingsDTO?.tagPermission")

	const handleViewPostClick = (
		postId: any,
		nickname: any,
		notifactionType: string
	) => {
		if (props.notification?.notificationType === "CommentOnPost") {
			if (!props.notification?.commentDeleted) {
				setOpenCommentReplyDialog(true);
			} else {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Comment has been deleted by user");
			}
		} else {
			navigate(`/singlePostView?postId=${postId}&nickName=${nickname}`);
		}
	};

	const [isNotificationRead, setIsNotificationRead] = useState(
		props.notification?.readStatus || false
	);

	const handleCardClick = () => {
		setIsNotificationRead(true);
	};

	const [openCommentReplyDialog, setOpenCommentReplyDialog] = useState(false);

	const closeCommentReplyDialog = () => {
		setOpenCommentReplyDialog(false);
	};

	useEffect(() => {
		if (props.notification?.readStatus) {
			setIsNotificationRead(true);
		}
	}, [props.notification?.readStatus]);

	const formatString = (notificationType: any): string => {
		if (notificationType === "likeOnPost") {
			return (
				" liked your post - " +
				format(
					new Date(props.notification?.postLikeTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "commentOnPost") {
			return (
				" commented on your post - " +
				format(
					new Date(props.notification?.postCommentedTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "TagInPost") {
			return (
				" tagged you in post - " +
				format(
					new Date(props.notification?.postLikeTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "TagInComment") {
			return (
				" tagged you in comment - " +
				format(
					new Date(props.notification?.postLikeTime),
					"MM/dd/yyyy hh:mm aaa"
				)
			);
		} else if (notificationType === "reactionOnComment") {
			return " liked your comment on ";
		} else if (notificationType === "commentOnComment") {
			return " has commented on your comment on ";
		}

		return "";
	};

	const viewUserProfile = () => {
		const encodedNickName = encodeURIComponent(props.notification.nickname);

		navigate(`/socialHome/` + encodedNickName);
	};

	const getUserName = () => {
		// console.log(
		// 	"test name : ",
		// 	props.notification.name,
		// 	props.notification.nickname
		// );
		const displayName =
			props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is not visible. Instead just show nickname" ||
			(props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is visible only to my connections. Other see nickname" &&
				!props.notification.connected)
				? props.notification.nickname
				: props.notification.name;

		return <span style={{ color: "#000" }}>{displayName} </span>;
	};

	const taggedid = () => {
		return props.notification.tagRecordId;
	};

	const getCreatorName = () => {
		const displayName =
			props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is not visible. Instead just show nickname" ||
			(props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is visible only to my connections. Other see nickname" &&
				!props.notification.connected)
				? props.notification.postCreatorName
				: props.notification.postCreatorName;

		return <span style={{ color: "#000" }}>{displayName} </span>;
	};

	const getPostCreatorName = () => {
		const creatorName =
			props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is not visible. Instead just show nickname" ||
			(props.notification.userPrivacySettingsDTO?.identityPermission ===
				"Name is visible only to my connections. Other see nickname" &&
				!props.notification.connected)
				? props.notification.nickname
				: props.notification.postCreatorName;

		return <span style={{ color: "#000" }}>{creatorName}</span>;
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleApprovedTagClick = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/approveTagRecords`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: props.notification.tagRecordId,
				isApproved: true,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				const updatedApprovedTags = [
					...approvedTags,
					props.notification.tagRecordId,
					data.id,
				];
				setApprovedTags(updatedApprovedTags);
				localStorage.setItem(
					"approvedTags",
					JSON.stringify(updatedApprovedTags)
				);
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	useEffect(() => {
		const storedApprovedTags = JSON.parse(
			localStorage.getItem("approvedTags") || "[]"
		);
		setApprovedTags(storedApprovedTags);
		setShowApprove(!props.notification?.approvedTag && props.notification?.userPrivacySettingsDTO?.tagPermission !== "No one can Tag me");

	}, [approvedTags, props.notification?.approvedTag, props.notification.userPrivacySettingsDTO.tagPermission]);

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Grid container xs={12}>
				<Grid item xs={12}>
					<Card
						elevation={0}
						sx={{
							borderRadius: "12px",
							background: !isNotificationRead ? "#fff" : "#F9F9FA",
							border: !isNotificationRead ? "none" : "1px solid #E2E2E2",
							boxShadow: !isNotificationRead
								? "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
								: "none",
							m: 1,
						}}
					>
						<CardHeader
							action={
								<>
									{props.notification?.notificationType === "TagInPost" && (
										<>
											<Button
												variant="contained"
												sx={{
													marginRight: "4px",
												}}
												onClick={() =>
													handleViewPostClick(
														props.notification?.postId,
														props.notification?.nickname,
														props.notification?.notificationType
													)
												}
											>
												View Post
											</Button>
											{!approvedTags.includes(props.notification.tagRecordId) &&
												showApprove && (
													<Button
														variant="contained"
														color="info"
														onClick={handleApprovedTagClick}
														sx={{
															border: "1.5px solid #FF702A",
															backgroundColor: "#fff",
															color: "#FF702A",
															"&:hover": {
																color: "#fff",
															},
														}}
													>
														Approve Tag
													</Button>
												)}
										</>
									)}
									{props.notification?.notificationType !== "TagInPost" && (
										<Button
											variant="contained"
											onClick={() =>
												handleViewPostClick(
													props.notification?.postId,
													props.notification?.nickname,
													props.notification?.notificationType
												)
											}
										>
											{props.notification?.notificationType === "CommentOnPost"
												? "Reply"
												: "View Post"}
										</Button>
									)}

									{props.notification?.notificationType === "TagInComment" && (
										<>
											{/* <Button
												variant="contained"
												sx={{
													marginRight: "4px",
												}}
												onClick={() =>
													handleViewPostClick(
														props.notification?.postId,
														props.notification?.nickname,
														props.notification?.notificationType
													)
												}
											>
												View Post
											</Button> */}
											{!approvedTags.includes(
												props.notification.tagRecordId
											) && (
												<Button
													variant="contained"
													color="info"
													onClick={handleApprovedTagClick}
													sx={{
														border: "1.5px solid #FF702A",
														backgroundColor: "#fff",
														color: "#FF702A",
														"&:hover": {
															color: "#fff",
														},
													}}
												>
													Approve Tag
												</Button>
											)}
										</>
									)}
								</>
							}
							title={
								<>
									<Grid
										container
										xs={12}
										onClick={() => {
											props.onNotificationClick(props.notification, true);
											handleCardClick();
										}}
									>
										<Grid item xs={3}>
											{/* <div className="invitation-card-title-wrapper"> */}
											<Typography
												sx={{
													backgroundColor: "#DFEBFF",
													borderRadius: "8px",
													textAlign: "center",
													display: "inline-block",
													padding: "0 10px",
													color: "#000",
													fontSize: "1rem",
													fontWeight: "500",
												}}
												variant="subtitle2"
											>
												Post Reaction
											</Typography>

											{/* </div> */}
										</Grid>
										<Grid item xs={9}>
											{(props.notificationType === "likeOnPost" ||
												props.notificationType === "commentOnPost") && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
													}}
												>
													{getUserName()}

													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>
												</Typography>
											)}
											{props.notificationType === "reactionOnComment" && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
													}}
												>
													{getUserName()}
													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>
													{getPostCreatorName()}'s{" "}
													<span style={{ color: "#5B5B65", marginLeft: "2px" }}>
														post -{" "}
														{format(
															new Date(props.notification?.commentLikeTime),
															"MM/dd//yyyy hh:mm aaa"
														)}
													</span>
												</Typography>
											)}
											{props.notificationType === "commentOnComment" && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
													}}
												>
													{getUserName()}
													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>
													{getPostCreatorName()}'s{" "}
													<span style={{ color: "#5B5B65", marginLeft: "2px" }}>
														post -{" "}
														{format(
															new Date(props.notification?.postCommentedTime),
															"MM/dd//yyyy hh:mm aaa"
														)}
													</span>
												</Typography>
											)}

											{props.notificationType === "TagInPost" && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
													}}
												>
													{getCreatorName()}
													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>
												</Typography>
											)}

											{props.notificationType === "TagInComment" && (
												<Typography
													style={{
														fontSize: "1rem",
														fontWeight: "500",
														fontFamily: "Lexend",
													}}
												>
													{getCreatorName()}
													<span style={{ color: "#5B5B65" }}>
														{formatString(props.notificationType)}
													</span>
												</Typography>
											)}
										</Grid>
									</Grid>
								</>
							}
						/>
						<CardContent>
							<Grid
								container
								spacing={2}
								onClick={() => {
									props.onNotificationClick(props.notification, true);
									handleCardClick();
								}}
							>
								<Grid item xs={2.5}>
									<CardMedia
										component="img"
										height="194"
										image={
											props.notification?.profilePictureUrl != null &&
											props.notification?.profilePictureUrl != ""
												? process.env.REACT_APP_PICTURE_URL +
												  props.notification?.profilePictureUrl
												: DefaultProfilePicture
										}
										sx={{
											width: "80px",
											height: "80px",
											borderRadius: "50%",
											cursor: "pointer",
										}}
										onClick={viewUserProfile}
										alt="Paella dish"
									/>
								</Grid>
								<Grid item xs={9.5}>
									{props.notificationType === "likeOnPost" &&
									!props.notification.postUrl ? (
										<Grid
											item
											xs={12}
											sx={{
												maxWidth: "50px",
												wordWrap: "break-word",
												backgroundColor: "#F8F8F8",
												padding: "10px",
												borderRadius: "12px",
											}}
										>
											<Typography
												sx={{
													color: "#5B5B65",
													fontSize: "1rem",
													fontWeight: "400",
													overflow: "hidden",
													display: "-webkit-box",
													WebkitBoxOrient: "vertical",
													WebkitLineClamp: 2,
													whiteSpace: "normal",
												}}
												variant="body1"
											>
												{props?.notification?.postDescription}
											</Typography>
										</Grid>
									) : props.notificationType === "commentOnPost" &&
									  !props.notification.postUrl ? (
										<Grid container xs={12} spacing={1}>
											<Grid item xs={12}>
												<Typography
													sx={{
														border: "1px solid #C5C5C5",
														color: "#5B5B65",
														fontSize: "1rem",
														fontWeight: "400",
														borderRadius: "12px",
														backgroundColor: "#F8F8F8",
														padding: "6px",
														overflow: "hidden",
														display: "-webkit-box",
														WebkitBoxOrient: "vertical",
														WebkitLineClamp: 2,
														whiteSpace: "normal",
													}}
												>
													{props.notification?.postDescription}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography
													sx={{
														border: "1px solid #C5C5C5",
														borderRadius: "12px",
														color: "#5B5B65",
														padding: "6px",
														fontSize: "1rem",
														fontWeight: "400",
														overflow: "hidden",
														display: "-webkit-box",
														WebkitBoxOrient: "vertical",
														WebkitLineClamp: 2,
														whiteSpace: "normal",
													}}
												>
													{props.notification?.commentMessage}
												</Typography>
											</Grid>
										</Grid>
									) : props.notificationType !== "commentOnComment" ? (
										<Grid
											container
											spacing={2}
											sx={{
												border: props.notification?.commentMessage
													? "1px solid #C2C2C3"
													: "none",
												borderRadius: "12px",
											}}
										>
											<>
												<Grid container gap={1}>
													<>
														<Grid
															item
															xs={5.5}
															sx={{
																backgroundColor: "#F8F8F8",
																// border: "1px solid #D9E7FF",
																borderTopLeftRadius: "12px",
																borderBottomLeftRadius: "12px",
																padding: "10px",
																maxWidth: "285px",
																wordWrap: "break-word",
															}}
														>
															<Typography
																sx={{
																	color: "#5B5B65",
																	fontSize: "1rem",
																	fontWeight: "400",
																	overflow: "hidden",
																	display: "-webkit-box",
																	WebkitBoxOrient: "vertical",
																	WebkitLineClamp: 2,
																	whiteSpace: "normal",
																}}
																variant="body1"
															>
																{props.notification?.postDescription}
															</Typography>

															{props.notification?.postType === "PHOTO" ? (
																<img
																	src={
																		process.env.REACT_APP_PICTURE_URL +
																		props.notification?.postUrl
																	}
																	alt="Post Image"
																	style={{
																		borderRadius: "12px",
																		marginTop: "10px",
																		maxWidth: "100%",
																		width: "100px",
																		height: "100px",
																		// aspectRatio: "4/3",
																		objectFit: "cover",
																	}}
																/>
															) : props.notification.postUrl ? (
																<audio
																	id={props.notification?.postUrl}
																	crossOrigin="anonymous"
																	controls
																	onPlay={() =>
																		props.onItemClick(
																			props.notification?.postUrl
																		)
																	}
																	controlsList="nodownload noremoteplayback noplaybackrate"
																>
																	<source
																		src={
																			props.notification?.postUrl.includes(
																				"https://"
																			)
																				? props.notification?.postUrl
																				: `${process.env.REACT_APP_PICTURE_URL}` +
																				  props.notification?.postUrl
																		}
																		type="audio/mpeg"
																	></source>
																</audio>
															) : (
																""
															)}
														</Grid>
														<Grid item xs={5.5} p={1}>
															{props.notification?.commentMessage && (
																<Typography
																	sx={{
																		color: "#5B5B65",
																		backgroundColor: "#F8F8F8",
																		borderRadius: "12px",
																		fontSize: "1rem",
																		fontWeight: "400",
																		overflow: "hidden",
																		display: "-webkit-box",
																		WebkitBoxOrient: "vertical",
																		WebkitLineClamp: 3,
																		whiteSpace: "normal",
																		padding: "6px",
																	}}
																	variant="body1"
																	p={1}
																>
																	{props.notification?.commentMessage}
																</Typography>
															)}
															{/* </Box> */}
														</Grid>
													</>
													{/* )} */}
												</Grid>
											</>
										</Grid>
									) : (
										<Grid container xs={12} spacing={1}>
											<Grid item xs={12}>
												<Typography
													sx={{
														border: "1px solid #C5C5C5",
														color: "#5B5B65",
														fontSize: "1rem",
														fontWeight: "400",
														borderRadius: "12px",
														backgroundColor: "#F8F8F8",
														padding: "6px",
														overflow: "hidden",
														display: "-webkit-box",
														WebkitBoxOrient: "vertical",
														WebkitLineClamp: 2,
														whiteSpace: "normal",
													}}
												>
													{props.notification?.commentMessage}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Typography
													sx={{
														border: "1px solid #C5C5C5",
														borderRadius: "12px",
														color: "#5B5B65",
														padding: "6px",
														fontSize: "1rem",
														fontWeight: "400",
														overflow: "hidden",
														display: "-webkit-box",
														WebkitBoxOrient: "vertical",
														WebkitLineClamp: 2,
														whiteSpace: "normal",
													}}
												>
													{props.notification?.commentOnComment}
												</Typography>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				<CommentReplyDialog
					open={openCommentReplyDialog}
					notification={props.notification}
					closeCommentReplyDialog={closeCommentReplyDialog}
				/>
			</Grid>
		</>
	);
};

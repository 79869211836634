import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import {
	LocalAudioTrack,
	LocalVideoTrack,
	Participant,
	RemoteAudioTrack,
	RemoteVideoTrack,
} from "twilio-video";

import AvatarIcon from "../../icons/AvatarIcon";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PinIcon from "./PinIcon/PinIcon";
import ScreenShareIcon from "../../icons/ScreenShareIcon";
import useIsTrackSwitchedOff from "../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import usePublications from "../../hooks/usePublications/usePublications";
import useTrack from "../../hooks/useTrack/useTrack";
import useParticipantIsReconnecting from "../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting";
import { useAppState } from "../../state";
import { Typography, Slider, IconButton } from "@mui/material";
import latencyHigh from "../../images/latency-high.svg";
import latencyMed from "../../images/latency-med.svg";
import latencyLow from "../../images/latency-low.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import soundIcon from "../../images/volume-high.svg";
import Unmute from "../../images/microphone-2.svg";
import Mute from "../../images/microphone-slash.svg";
import { Constants } from "../../services/constants";
import { useUser } from "../../context/UserContext";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
// import WebsocketMetricsClient from "../../services/WebsocketMetricsClient";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
const borderWidth = 2;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			isolation: "isolate",
			position: "relative",
			display: "flex",
			alignItems: "center",
			height: "100%",
			overflow: "hidden",
			marginBottom: "0.5em",
			"& video": {
				objectFit: "cover !important",
			},
			borderRadius: "4px",
			border: `2px solid rgb(245, 248, 255)`,
			// paddingTop: `calc(${(9 / 16) * 100}% - 5px)`,
			background: "black",
			[theme.breakpoints.down("sm")]: {
				height: theme.sidebarMobileHeight,
				width: `${(theme.sidebarMobileHeight * 16) / 9}px`,
				marginRight: "8px",
				marginBottom: "0",
				fontSize: "0.75rem",
				paddingTop: `${theme.sidebarMobileHeight - 2}px`,
			},
		},
		innerContainer: {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
		},
		volumeSlider: {
			display: "flex",
			height: "100%",
			flexDirection: "column",
			background: "rgba(0, 0, 0, 0.35)",
			borderRadius: "6px",
			padding: "10px 0 0 0",
			visibility: "hidden",
		},
		showVolumeSlider: {
			display: "flex !important",
		},
		localVolumeSlider: {
			display: "flex",
			height: "100%",
			flexDirection: "column",
			background: "rgba(0, 0, 0, 0.35)",
			borderRadius: "6px",
			padding: "10px 0 0 0",
			visibility: "hidden",
		},
		infoContainer: {
			position: "absolute",
			zIndex: 2,
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
			height: "100%",
			width: "100%",
			background: "transparent",
			top: 0,
			"&:hover": {
				" & $volumeSlider": {
					visibility: "visible",
				},
				" & $localVolumeSlider": {
					visibility: "visible",
				},
			},
		},
		latencyContainer: { marginRight: "10px" },
		avatarContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			background: "black",
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: 1,
			[theme.breakpoints.down("sm")]: {
				"& svg": {
					transform: "scale(0.7)",
				},
			},
		},
		reconnectingContainer: {
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			background: "rgba(40, 42, 43, 0.75)",
			zIndex: 1,
		},
		screenShareIcon: {
			position: "absolute",
			right: 0,
			bottom: "0.6em",
		},
		screenShareIconContainer: {
			background: "rgba(0, 0, 0, 0.5)",
			padding: "0.18em 0.3em",
			marginRight: "0.3em",
			display: "flex",
			"& path": {
				fill: "white",
			},
		},
		identity: {
			// background: "rgba(0, 0, 0, 0.5)",
			color: "white",
			// padding: "0.18em 0.3em 0.18em 0",
			margin: 0,
			// display: "flex",
			alignItems: "center",
			// borderRadius: "6px",
			// position: "absolute",
			bottom: 0,
			// left: "7%",
		},
		userNameSpan: {
			padding: "0.18em 0.3em 0.18em 0",
			background: "rgba(0, 0, 0, 0.5)",
			borderRadius: "6px",
		},
		sliderContainer: {
			// width: "15%",
			display: "flex",
			justifyContent: "flex-end",
			position: "absolute",
			flexDirection: "column",
			bottom: 0,
			left: "10px",
			height: "100%",
		},
		infoContainerDiv: {
			width: "85%",
			position: "absolute",
			bottom: 0,
			// left: "5%"
			// right: 0
		},
		infoContainerDiv5: {
			marginLeft: "43px",
		},
		infoContainerDiv15: {
			left: "15%",
		},
		typography: {
			color: "white",
			[theme.breakpoints.down("sm")]: {
				fontSize: "0.75rem",
			},
			// padding: "0 5px",
		},
		hideParticipant: {
			display: "none",
		},
		cursorPointer: {
			cursor: "pointer",
		},
		galleryView: {
			border: `${theme.participantBorderWidth}px solid #888888`,
			borderRadius: "8px",
			[theme.breakpoints.down("sm")]: {
				position: "relative",
				width: "100%",
				height: "100%",
				padding: "0",
				fontSize: "0.75rem",
				margin: "0",
				"& video": {
					objectFit: "cover !important",
				},
			},
		},
		dominantSpeaker: {
			border: `solid ${borderWidth}px #7BEAA5`,
		},
	})
);

interface ParticipantInfoProps {
	participant: Participant;
	children: React.ReactNode;
	onClick?: () => void;
	isSelected?: boolean;
	isLocalParticipant?: boolean;
	hideParticipant?: boolean;
	isDominantSpeaker?: boolean;
}

export default function ParticipantInfo({
	participant,
	onClick,
	isSelected,
	children,
	isLocalParticipant,
	hideParticipant,
	isDominantSpeaker,
}: ParticipantInfoProps) {
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const userSession = localStorage.getItem("userSession") ?? "";
	const userSessions = JSON.parse(userSession);

	const participantDetails = userSessions.attendees.find(
		(name: any) => name.email === participant.identity
	);

	const user = useUser();
	const publications = usePublications(participant);

	const audioPublication = publications.find((p: any) => p.kind === "audio");
	const videoPublication = publications.find(
		(p: any) => !p.trackName.includes("screen") && p.kind === "video"
	);

	const isVideoEnabled = Boolean(videoPublication);
	const isScreenShareEnabled = publications.find((p: any) =>
		p.trackName.includes("screen")
	);

	const videoTrack = useTrack(videoPublication);
	const isVideoSwitchedOff = useIsTrackSwitchedOff(
		videoTrack as LocalVideoTrack | RemoteVideoTrack
	);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const audioTrack = useTrack(audioPublication) as
		| LocalAudioTrack
		| RemoteAudioTrack
		| undefined;
	const isParticipantReconnecting = useParticipantIsReconnecting(participant);

	const { isGalleryViewActive } = useAppState();

	const classes = useStyles();
	let participantDetailList = JSON.parse(
		localStorage.getItem("participantVolumeDetails") ?? ""
	);
	const audioDetails = participantDetailList.find(
		(p: any) => p.email === participant.identity
	);

	const [volume, setVolume] = useState<number | number[] | null>(0);
	const [showVolumeBar, setShowVolumeBar] = useState(false);
	const [prevVolume, setPrevVolume] = useState<number | number[] | null>(50);
	const [isAudioMute, setIsAudioMute] = useState(false);
	const [latencyIcon, setLatencyIcon] = useState<any>(latencyHigh);
	const [emoji, setEmoji] = useState<any | null>(null);

	useEffect(() => {
		setVolume(audioDetails.audio.master.audio_level);
		WebsocketClientUSerStatus.on("audioControl", onMessageReceived);
		WebsocketClientUSerStatus.on("latency", onLatencyChange);
		WebsocketClientUSerStatus.on("emojiChanged", onEmojiChange);
		return () => {
			WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
			WebsocketClientUSerStatus.off("latency", onLatencyChange);
			WebsocketClientUSerStatus.off("emojiChanged", onEmojiChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const mixerValue = (value: any, participant: any) => {
		// console.log( value);
		setVolume(value);
		if (value !== 0) {
			setPrevVolume(value);
		}
		let requestMessage = {
			action: "audio_control",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			created_for: participant.identity,
			audio: {
				updated_for: "master",
				master: {
					audio_status: value === 0 ? "mute" : "unmute",
					audio_level: prevVolume ?? value,
				},
				channel_1: {
					audio_status: value === 0 ? "mute" : "unmute",
					audio_level: audioDetails.audio.channel_1.audio_level,
				},
				channel_2: {
					audio_status: value === 0 ? "mute" : "unmute",
					audio_level: audioDetails.audio.channel_2.audio_level,
				},
				mic: {
					audio_status: value === 0 ? "mute" : "unmute",
					audio_level: audioDetails.audio.mic.audio_level,
				},
			},
		};
		WebsocketClientUSerStatus.handleAudioControl(requestMessage);
	};

	const participantAudioControl = () => {
		let audioStatus = "unmute";
		// let audioLevel = 50;
		if (!isAudioMute) {
			audioStatus = "mute";
			// audioLevel = 0;
		}
		let requestMessage = {
			action: "audio_control",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			created_for: participant.identity,
			audio_status: audioStatus,
			audio_level: prevVolume ?? 50,
			audio: {
				updated_for: "master",
				master: {
					audio_status: audioStatus,
					audio_level: audioStatus === "unmute" ? prevVolume ?? 50 : 0,
				},
				channel_1: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_1.audio_level,
				},
				channel_2: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_2.audio_level,
				},
				mic: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.mic.audio_level,
				},
			},
		};
		WebsocketClientUSerStatus.handleAudioControl(requestMessage);
	};

	const handleSliderVolumeChange = (value: any) => {
		console.log( value);
		setVolume(value);
		if (value !== 0) {
			setPrevVolume(value);
		}
	};

	const onMessageReceived = (payload: any) => {
		if (payload.metadata.created_by === participant.identity) {
			setShowVolumeBar(true);
			setTimeout(() => {
				setShowVolumeBar(false);
			}, 3000);
			if (payload.metadata.audio.master.audio_status === "mute") {
				setIsAudioMute(true);
				setVolume(0);
			} else {
				setIsAudioMute(false);
				//console.log( payload.metadata.audio.master.audio_level);
				setVolume(payload.metadata.audio.master.audio_level);
				setPrevVolume(payload.metadata.audio.master.audio_level);
			}
		}
		if (payload.metadata.audio.master.audio_status === "mute") {
			participantDetailList = [
				...participantDetailList.map((participant: any) => {
					if (
						participant.email !== undefined &&
						participant.email === payload.metadata.created_by
					) {
						return {
							...participant,
							audio: {
								...participant.audio,
								master: {
									...participant.audio.master,
									audio_status: "mute",
									audio_level: 0,
								},
								channel_1: {
									...participant.audio.channel_1,
									audio_status: "mute",
									audio_level: payload.metadata.audio.channel_1.audio_level,
								},
								channel_2: {
									...participant.audio.channel_2,
									audio_status: "mute",
									audio_level: payload.metadata.audio.channel_2.audio_level,
								},
								mic: {
									...participant.audio.mic,
									audio_status: "mute",
									audio_level: payload.metadata.audio.mic.audio_level,
								},
							},
						};
					} else {
						return participant;
					}
				}),
			];
		} else {
			participantDetailList = [
				...participantDetailList.map((participant: any) => {
					if (
						participant.email !== undefined &&
						participant.email === payload.metadata.created_by
					) {
						return {
							...participant,
							audio: {
								...participant.audio,
								master: {
									...participant.audio.master,
									audio_status: "unmute",
									audio_level: payload.metadata.audio.master.audio_level,
								},
								channel_1: {
									...participant.audio.channel_1,
									audio_status: "unmute",
									audio_level: payload.metadata.audio.channel_1.audio_level,
								},
								channel_2: {
									...participant.audio.channel_2,
									audio_status: "unmute",
									audio_level: payload.metadata.audio.channel_2.audio_level,
								},
								mic: {
									...participant.audio.mic,
									audio_status: "unmute",
									audio_level: payload.metadata.audio.mic.audio_level,
								},
							},
						};
					} else {
						return participant;
					}
				}),
			];
		}
		localStorage.setItem(
			"participantVolumeDetails",
			JSON.stringify(participantDetailList)
		);
	};

	const onLatencyChange = (payload: any) => {
		if (payload.metadata.created_by === participant.identity) {
			if (payload.metadata.latency <= 45) {
				setLatencyIcon(latencyLow);
			} else if (
				payload.metadata.latency > 45 &&
				payload.metadata.latency <= 60
			) {
				setLatencyIcon(latencyMed);
			} else {
				setLatencyIcon(latencyHigh);
			}
		}
	};

	const onEmojiChange = (payload: any) => {
		if (participant.identity === payload.identity) {
			let emoji = Constants.emojis.find(
				(emojiObj) => emojiObj.key === payload.key
			);
			setEmoji(emoji);
			setTimeout(() => {
				setEmoji(null);
			}, 30000);
		}
	};

	const dragBlockHandler = (e: any) => e.stopPropagation();

	const formatNameWithMaxLength = (
		name: string | undefined,
		maxChars: number
	): string => {
		return name && name.length > maxChars
			? name.substring(0, maxChars) + "..."
			: name || "";
	};

	const getParticipantDisplayName = (participantDetails:any, user:any) => {
		const firstName = formatNameWithMaxLength(participantDetails?.firstName, 17);
		const lastName = formatNameWithMaxLength(participantDetails?.lastName, 17);
		const nickname = `@${formatNameWithMaxLength(participantDetails?.nickname, 17)}`;
	  
		if (participantDetails?.userProfileId === user?.userProfileId) {
		  return `${firstName} ${lastName}`;
		}
	  
		switch (participantDetails?.userPrivacySettingsDTO?.identityPermission) {
		  case "Name is not visible. Instead just show nickname":
			return nickname;
	  
		  case "Name is visible to all":
			return `${firstName} ${lastName}`;
	  
		  case "Name is visible only to my connections. Other see nickname":
			return participantDetails?.connected ? `${firstName} ${lastName}` : nickname;
	  
		  default:
			return `${firstName} ${lastName}`;
		}
	  };	  

	return (
		<div
			className={clsx(classes.container, {
				[classes.hideParticipant]: hideParticipant,
				[classes.cursorPointer]: Boolean(onClick),
				[classes.dominantSpeaker]: isDominantSpeaker,
				[classes.galleryView]: isGalleryViewActive,
			})}
			onClick={onClick}
			data-cy-participant={participant.identity}
		>
			<div className={classes.infoContainer}>
				{emoji && <div className="emoji">{emoji.emoji}</div>}

				<div className={classes.sliderContainer}>
					<div
						className={clsx({
							[classes.localVolumeSlider]: isLocalParticipant,
							[classes.volumeSlider]: !isLocalParticipant,
							[classes.showVolumeSlider]: showVolumeBar,
						})}
					>
						<div
							className="slider"
							onClick={dragBlockHandler}
							onMouseDown={dragBlockHandler}
						>
							<Slider
								sx={{ color: "#1F7A10" }}
								aria-label="Volume"
								orientation="vertical"
								onChangeCommitted={(event, value) =>
									mixerValue(value, participant)
								}
								onChange={(event, value) => handleSliderVolumeChange(value)}
								min={0}
								max={100}
								step={10}
								value={volume ?? 0}
							/>
						</div>
						{/* <div style={{ marginTop: "5px", padding: "5px" }}>
						<img src={soundIcon} alt="sound icon" />
					</div> */}
					</div>
					<div className={classes.latencyContainer}>
						<img src={latencyIcon} alt="voice-square" />
						{/* {isScreenShareEnabled && (
							<span className={classes.screenShareIconContainer}>
								<ScreenShareIcon />
							</span>
						)} */}
					</div>
				</div>

				{/* <div className={isGalleryViewActive ? classes.infoContainerDiv5 : classes.infoContainerDiv15 }> */}
				<div
					className={clsx(classes.infoContainerDiv, {
						[classes.infoContainerDiv5]: !isScreenShareEnabled,
						[classes.infoContainerDiv15]: isScreenShareEnabled,
					})}
				>
					{!isLocalParticipant && (
						<div className={classes.identity}>
							<span className={classes.userNameSpan}>
								<IconButton onClick={participantAudioControl}>
									{isAudioMute ? (
										<img
											style={{ height: "25px", width: "25px" }}
											src={Mute}
											alt="mic"
										/>
									) : (
										<img
											style={{ height: "25px", width: "25px" }}
											src={Unmute}
											alt="mic"
										/>
									)}
								</IconButton>
								<Typography
									variant="body1"
									className={classes.typography}
									component="span"
								>
									{getParticipantDisplayName(participantDetails, user)}
								</Typography>
							</span>
						</div>
					)}

					<div className={classes.screenShareIcon}>
						{isScreenShareEnabled && (
							<span className={classes.screenShareIconContainer}>
								<ScreenShareIcon />
							</span>
						)}
					</div>
				</div>

				{/* <div>{isSelected && <PinIcon />}</div> */}
			</div>
			<div className={classes.innerContainer}>
				{(!isVideoEnabled || isVideoSwitchedOff) && (
					<div className={classes.avatarContainer}>
						<AvatarIcon />
					</div>
				)}
				{isParticipantReconnecting && (
					<div className={classes.reconnectingContainer}>
						<Typography variant="body1" className={classes.typography}>
							Reconnecting...
						</Typography>
					</div>
				)}
				{children}
			</div>
		</div>
	);
}

import { memo, useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import useChatContext from "../hooks/useChatContext/useChatContext";
import usePeopleContext from "../hooks/usePeopleContext/usePeopleContext";
import { useFullscreenModeContext } from "../context/FullscreenModeContext";
import { GALLERY_VIEW_ASPECT_RATIO } from "../constants";

const DefaultCoordinates = {
	x: null,
	y: null,
	_height: null,
	_width: 280,
};
const DraggableResizableContainer = (props: any) => {
	const { x, y, _height, _width } = JSON.parse(
		localStorage.getItem("videoCoordinates") ??
			JSON.stringify(DefaultCoordinates)
	);
	//initial size of the container
	const [width, setWidth] = useState(_width);
	const [height, setHeight] = useState(
		_height ?? `${Math.floor(width * GALLERY_VIEW_ASPECT_RATIO)}px`
	);
	//initial position on screen
	const [positionX, setPositionX] = useState(x);
	const [positionY, setPositionY] = useState(y);
	const [prevX, setPrevX] = useState(x);
	const [prevY, setPrevY] = useState(y);

	const { isChatWindowOpen } = useChatContext();
	const { isPeopleWindowOpen } = usePeopleContext();
	const { isFullscreenMode } = useFullscreenModeContext();

	useEffect(() => {
		if (
			localStorage.getItem("videoCoordinates") === null ||
			isChatWindowOpen ||
			isPeopleWindowOpen
		) {
			const _x =
				document.getElementById("participantContainer")?.clientWidth! - _width;
			const _y =
				document.getElementById("participantContainer")?.clientHeight! -
				Math.floor(_width * GALLERY_VIEW_ASPECT_RATIO);

			setPositionX(_x);
			setPositionY(_y);
			localStorage.setItem(
				"videoCoordinates",
				JSON.stringify({
					x: _x,
					y: _y,
					_height: _height,
					_width: _width,
				})
			);
			if (localStorage.getItem("videoCoordinates") === null) {
				localStorage.setItem(
					"videoCoordinates",
					JSON.stringify({
						x:
							document.getElementById("participantContainer")?.clientWidth! -
							285,
						y:
							document.getElementById("participantContainer")?.clientHeight! -
							160,
						_height: _height,
						_width: _width,
					})
				);
			}
		} else {
			let currWidth = document.getElementById("participantContainer")?.clientWidth! - _width;
			let currHeight =  document.getElementById("participantContainer")?.clientHeight! -Math.floor(_width * GALLERY_VIEW_ASPECT_RATIO);
			const _x =
				prevX < currWidth ? prevX ?? currWidth : currWidth
			const _y =
				prevY < currHeight ? prevY ?? currHeight : currHeight
				

			setPositionX(_x);
			setPositionY(_y);
			localStorage.setItem(
				"videoCoordinates",
				JSON.stringify({
					x: _x,
					y: _y,
					_height: _height,
					_width: _width,
				})
			);
		}
	}, [isChatWindowOpen, isPeopleWindowOpen]);

	useEffect(() => {
		const _x =
			document.getElementById("participantContainer")?.clientWidth! - _width;
		const _y =
			document.getElementById("participantContainer")?.clientHeight! -
			Math.floor(_width * GALLERY_VIEW_ASPECT_RATIO);

		setPositionX(_x);
		setPositionY(_y);
		localStorage.setItem(
			"videoCoordinates",
			JSON.stringify({
				x: _x,
				y: _y,
				_height: _height,
				_width: _width,
			})
		);
	}, [isFullscreenMode]);

	const handleDragStop = (e: any, d: any) => {
		setPositionX(d.x);
		setPositionY(d.y);
		setPrevX(d.x);
		setPrevY(d.y);
		localStorage.setItem(
			"videoCoordinates",
			JSON.stringify({
				x: d.x,
				y: d.y,
				_height: _height,
				_width: _width,
			})
		);
	};

	const handleResizeStop = (
		e: any,
		direction: any,
		ref: any,
		delta: any,
		position: any
	) => {
		setWidth(ref.clientWidth);
		setHeight(ref.clientHeight);
		setPositionX(position.x);
		setPositionY(position.y);
		localStorage.setItem(
			"videoCoordinates",
			JSON.stringify({
				x: position.x,
				y: position.y,
				_height: ref.clientHeight,
				_width: ref.clientWidth,
			})
		);
	};

	return (
		<Rnd
			size={{ width: width, height: height }}
			position={{ x: positionX, y: positionY }}
			onDragStop={handleDragStop}
			onResizeStop={handleResizeStop}
			minWidth={"250px"}
			style={{
				border: "1px solid black",
				backgroundColor: "black",
				zIndex: 5,
				borderRadius: "8px",
			}}
			bounds="#participantContainer"
			lockAspectRatio={true}
		>
			{props.children}
		</Rnd>
	);
};

export default memo(DraggableResizableContainer);

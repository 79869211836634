import { useState, useEffect } from "react";
import {
	Grid,
    TextField,
    InputAdornment,
    Typography,
    CircularProgress,
    Box
} from "@mui/material";
import { createStyles,  makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import i18n from "../../l10n/strings.json";
import UserCardShort from "./UserCardShort";
import UserCardDetailed from "./UserCardDetailed";
import { musicianCardInterface } from "../../types/types";
import InfiniteScroll from "react-infinite-scroll-component";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        shadowedBox:{
            // padding: "1% 2%", 
            borderRadius: "15px",
            //boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
        },
		activeMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#ffffff",
            background: "#0273E6"
		},
		musicMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#000 !important",
            // background: "#0273E6"
		},
        instrumentStack: {
            margin: "5% 0",
            "& div": {
                padding: "1%",
                margin: "1% 0",
                backgroudColor: "#FAFAFA",
                borderRadius: "15px"
            }
        },
        spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		}
	})
);


const Invitations = () => {
    const user = useUser();
    const classes = useStyles();
    const token = localStorage.getItem("sessionId");
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
	const [searchString, setSearchString] = useState("");
	const [itemPerPage, setItemPerPage] = useState(4);
    const [invitations, setInvitations] = useState<Array<musicianCardInterface>>( [] );
    const [hasMore, setHasMore] = useState(true);

	useEffect(() => {
		getInvitations();
	}, []);

    const getInvitations = () => {
        try {
            invitations.length === 0 && setLoading(true);
            // setInvitations([]);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/invitations/${user.userProfileId}?searchString=${searchString}&page=${pageNumber}&size=${itemPerPage}&invitationType=received`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                // setInvitations(data);
                if(data.length > 0){
                    setInvitations([...invitations, ...data]);
                    var nextPageNumber = pageNumber+1;
                    setPageNumber(nextPageNumber);
                }
                if(data.length < itemPerPage || data.length === 0){
                    setHasMore(false);
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };
    
    const resetInvitations = () => {
        try {
            setLoading(true);
            setInvitations([]);
			setPageNumber(0);
            setHasMore(true);
            fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/invitations/${user.userProfileId}?searchString=${searchString}&page=0&size=${itemPerPage}&invitationType=received`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if(data.length > 0){
                    setInvitations(data);
                    var nextPageNumber = pageNumber+1;
                    setPageNumber(nextPageNumber);
                }
                if(data.length < itemPerPage || data.length === 0){
                    setHasMore(false);
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };
    const pauseAudioPlayer = (musicId:any) => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");
	    if(audioPlayers){
        audioPlayers.forEach((audioPlayer) => {		
			const audioElement = audioPlayer as HTMLAudioElement;
             if (audioPlayer.id == musicId) {
			// //	audioElement.play();
		     }else{
			// 	//audioPlayer.pause();
				audioElement.pause();
			 }
		});
        }		
	};
	return (
		<>
            {
                loading ? 
                <Grid container item justifyContent="center" >
                    <CircularProgress /> {/* The loading spinner */}
                </Grid>
                :
                <Grid xs={12} >
                    <Grid container xs={12} >
                        
                        {/* User Cards */}
                        {
                            (invitations.length > 0) ?
                            <Grid xs={12} item >
                                <InfiniteScroll
                                    dataLength={invitations.length}
                                    next={getInvitations}
                                    hasMore={hasMore}
                                    loader={
                                        <Box 
                                            className={classes.spinner}
                                        >
                                            <CircularProgress />
                                        </Box>
                                    }
                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                        }
                                >
                                    {
                                        invitations.map(user => {
                                            return (
                                                <Grid item={true} xs={12} mb={2} ml={0.8} mr={1} mt={1} sx={{zIndex: 99}}>
                                                    <UserCardDetailed user={user} refreshList={resetInvitations} hideCardAfterAction={true} onItemClick={pauseAudioPlayer}/>
                                                </Grid>
                                            )
                                        })
                                    }
                                </InfiniteScroll>
                            </Grid>
                            :
                            <Grid xs={12} container item justifyContent="center">
                                No Invitations Received yet.
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
		</>
	);
};

export default Invitations;

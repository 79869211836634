import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	Typography,
	Box,
	Autocomplete,
	Grid,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import { makeStyles } from "@mui/styles";
import { useUser } from "../context/UserContext";
import ErrorAlert from "../components/Error/ErrorAlert";
import ErrorAlertDisplay from "../components/Error/ErrorAlertDisplay";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white !important",
		},
		'& .MuiAutocomplete-clearIndicator': {
			'&:hover': {
				backgroundColor: '#7A7985 !important'
			},
			backgroundColor: '#7A7985 !important',
			height: '18px',
			width: '18px'
		}
	},
}));
const genres = [
	{ title: "Americana" },
	{ title: "Avant-garde" },
	{ title: "Baroque" },
	{ title: "Big Band" },
	{ title: "Bluegrass" },
	{ title: "Blues" },
	{ title: "Bollywood" },
	{ title: "Broadway/Showtunes" },
	{ title: "Chamber-Music" },
	{ title: "Classic Rock" },
	{ title: "Classical" },
	{ title: "Contemporary Classical" },
	{ title: "Country" },
	{ title: "Disco" },
	{ title: "Drumline" },
	{ title: "EDM" },
	{ title: "Electronic/Dance" },
	{ title: "Folk" },
	{ title: "Funk" },
	{ title: "Gospel" },
	{ title: "Hip-hop/Rap" },
	{ title: "House" },
	{ title: "Indie/Alternative" },
	{ title: "Jazz" },
	{ title: "K-Pop" },
	{ title: "Latin" },
	{ title: "Metal" },
	{ title: "Minimalism" },
	{ title: "New Age" },
	{ title: "Opera" },
	{ title: "Orchestral" },
	{ title: "Pop" },
	{ title: "Punk" },
	{ title: "R&B" },
	{ title: "Ragtime" },
	{ title: "Reggae" },
	{ title: "Reggaeton" },
	{ title: "Religious" },
	{ title: "Rock" },
	{ title: "Romantic" },
	{ title: "Salsa" },
	{ title: "Ska" },
	{ title: "Soul" },
	{ title: "Soundtrack" },
	{ title: "Swing" },
	{ title: "Symphonic" },
	{ title: "Techno" },
	{ title: "Wind Ensemble" },
];

const EditGenres = (props: any) => {
	const classes = useStyles();
	const [genresValue, setGenresValue] = useState(props.genresValue);
	const user = useUser();
	const token = localStorage.getItem("sessionId");

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [originalGenres, setOriginalGenres] = useState(props.genresValue);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	useEffect(() => {
		setOriginalGenres(genresValue);
	}, [props.genresValue]);
	const handleClose = () => {
		setGenresValue(originalGenres);
		props.handleClose(false);
	};

	const ArrayOfGenres = Array.prototype.map
		.call(genresValue, function (item) {
			return item.title;
		})
		.join(",");

	const handleUpdateGenres = (event: any) => {
		setIsLoading(true)
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-genres`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				user: {
					id: user.id,
				},
				genres: ArrayOfGenres,
			}),
		})
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					props.setGenresValue(genresValue);
					props.handleClose(false);
					setIsLoading(false)
					return res.json();
				}
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
				setIsLoading(false)
			});
	};
	return (
		<>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				open={props.genresOpen}
			>
				<ErrorAlertDisplay
					alertMsg={alertMsg}
					alertStatus={alertStatus}
					showAlert={showAlert}
					handleAlertClose={handleAlertClose}
				/>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography variant="h6">Edit Genres</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={4}>
							<FormHelperText>{i18n.socialProfile.genres}</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<Autocomplete
								multiple
								id="Genres"
								classes={classes}
								className="social-textField"
								value={genresValue}
								onChange={(event, newValue) => {
									setGenresValue(newValue as any);
								}}
								options={genres}
								getOptionLabel={(option) => option.title}
								isOptionEqualToValue={(option, value) =>
									option.title === value.title
								}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField {...params}
									// placeholder="Genres"
									/>
								)}
							/>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							flexWrap: "wrap",
							pt: 1,
							flexDirection: "row-reverse",
						}}
					>
						<Button
							sx={{ borderRadius: "17px" }}
							size="small"
							variant="outlined"
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button
							size="small"
							variant="contained"
							sx={{ borderRadius: "17px", background: "#FF702A", px: 2 }}
							onClick={handleUpdateGenres}
						>
							{isLoading ? 'Saving..' : 'Save'}
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditGenres;

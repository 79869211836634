import { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	Typography,
	Box,
	Autocomplete,
	Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useUser } from "../../context/UserContext";
import i18n from "../../l10n/strings.json";

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "black !important",
		},
		"& .MuiAutocomplete-clearIndicator": {
			"&:hover": {
				backgroundColor: "#7A7985 !important",
			},
			backgroundColor: "#7A7985 !important",
			height: "18px",
			width: "18px",
		},
	},
}));

const defaultTypes = [
	"Software Bug",
	"New Feature Request",
	"General",
	"Account Help",
	"Feedback",
	"Connectivity Issue",
	"Order Questions"
];

const CreateTicket = (props: any) => {
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const [message, setMesage] = useState("");
	const [messageType, setMessageType] = useState(props.messageType);
	const [dirtyMessage, setDirtyMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisable, setIsDisable] = useState(false); // Added state for disabling the button

  

	const handleClose = () => {
		setMesage("");
		setMessageType("");
		props.handleDialogClose();
	};

	const isEnable = () => {
		if (
			messageType?.length > 0 &&
			message?.length > 0 &&
			message?.length < 512
		) {
			return false;
		} else {
			return true;
		}
	};

	const handleCreateTicket = () => {
		if (user && user.firstName && user.lastName && user.email) {
			setIsLoading(true);
			setIsDisable(true);   // Disable the button before making the API request

			const reqBody = {
				ticket: {
					requester: {
						name: `${user.firstName} ${user.lastName}`,
						email: user.email,
					},
					subject: messageType,
					comment: {
						body: message,
					},
				},
			};
			fetch(`${process.env.REACT_APP_BASE_URL}/api/public/create-ticket`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(reqBody),
			})
				.then((res) => {
					if (res.status >= 400) {
						props.setShowAlert(true);
						props.setAlertStatus(false);
						props.setAlertMsg(i18n.contactUs.errorMsg);
						setIsLoading(false);
						props.handleDialogClose();
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data) {
						props.setShowAlert(true);
						props.setAlertStatus(true);
						props.setAlertMsg(i18n.contactUs.successMsg);
						setIsLoading(false);
						props.handleDialogClose();
						setMesage("");
						setMessageType("");
					}
				})
					
					.finally(() => {
						setIsLoading(false);
						setIsDisable(false); // Re-enable the button after the request is completed
						props.handleDialogClose();
					
				});
				
				
		} else {
			props.setShowAlert(true);
			props.setAlertStatus(false);
			props.setAlertMsg(i18n.contactUs.errorMsg);
			props.handleDialogClose();
		}
	};

	return (
		<>
			<Dialog
				fullWidth
				maxWidth="sm"
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
					p: 3,
				}}
				open={props.createTicketDialog}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography sx={{ fontSize: "1.5rem", fontWeight: "500" }}>
						{props.type === "support"
							? i18n.contactUs.subTitle
							: i18n.home.sendFeedback}
					</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<>
							<Grid xs={4}>
								<FormHelperText>
									{i18n.contactUs.createTicketDialog.msgType}
								</FormHelperText>
							</Grid>
							<Grid xs={12} sx={{ m: 0.5 }}>
								<Autocomplete
									id="messageType"
									classes={classes}
									className="social-textField"
									value={messageType}
									onChange={(event, newValue) => {
										setMessageType(newValue as any);
									}}
									options={defaultTypes}
									getOptionLabel={(option) => option}
									filterSelectedOptions
									renderInput={(params) => <TextField {...params} />}
								/>
							</Grid>
						</>
						<Grid xs={4}>
							<FormHelperText>
								{i18n.contactUs.createTicketDialog.msg}
							</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<TextField
								id="message"
								multiline
								rows={3}
								className="social-textField"
								value={message}
								onChange={(e: any) => setMesage(e.target.value)}
								onFocus={() => setDirtyMessage(true)}
								error={dirtyMessage && message?.length > 512}
								variant="outlined"
								required
							/>
							{dirtyMessage && (
								<FormHelperText
									sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
								>
									{message?.length > 512
										? "Maximum 512 characters allowed"
										: ""}
								</FormHelperText>
							)}
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							flexWrap: "wrap",
							pt: 1,
							flexDirection: "row-reverse",
						}}
					>
						<Button
							sx={{ borderRadius: "17px" }}
							size="small"
							variant="outlined"
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button
							size="small"
							variant="contained"
							sx={{ borderRadius: "17px", background: "#FF702A", px: 2 }}
							onClick={handleCreateTicket}
							disabled={isEnable() || isDisable}   // Disable the button based on both condi
						>
							{isLoading ? "Sending.." : "Send"}
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CreateTicket;

import { useEffect, useRef, useState, KeyboardEvent } from "react";
import {
	AppBar,
	Toolbar,
	InputBase,
	Box,
	IconButton,
	Badge,
	Typography,
	Tabs,
	Tab,
	Menu,
	MenuItem,
	Container,
	Tooltip,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Avatar,
	Divider,
} from "@mui/material";
import { tabsClasses } from "@mui/material/Tabs";
import SearchIcon from "@mui/icons-material/Search";
import Cancel from "@mui/icons-material/Cancel";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { styled, useTheme } from "@mui/material/styles";
import Logo from "../images/LuteFish_Type_RevWhite.png";
import Notification from "../images/notification-bing.svg";
import NotificationsF from '../icons/notification-bing-filled.svg';
import Messages from "../images/message-text2.svg";
import MessagesF from '../icons/message-text-filled.svg';
import ArrowDown from "../images/arrow-down.svg";
import settingsIcon from "../images/setting.svg";
import signout from "../images/signout.svg";
import Profile from "../images/user-octagon-outline.svg";
import Music from "../images/music.svg";
import Card from "../images/cards.svg";
import Calendar from "../images/calendar-2.svg";
import CalendarActive from "../images/calendar1.svg";
import MusicianOutlined from "../images/profile-2user-outlined.svg";
import MusicianActive from "../images/profile-2user-bold.svg";
import MenuIcon from "@mui/icons-material/Menu";
import "../components/commonComponents/common.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useWakeLock } from "react-screen-wake-lock";
import { useUser } from "../context/UserContext";
// import WebsocketClient from "../services/WebsocketClientUSerStatus";
import useParticipantsContext from "../hooks/useParticipantsContext/useParticipantsContext";
import ActiveMusic from "../images/active-music.svg";
import Close from "../images/close.svg";
import { clearLocalstorage, signOut, stopSessionRecording } from "../utils";
import useVideoContext from "../hooks/useVideoContext/useVideoContext";
import ActiveHome from "../images/homeHome.svg";
import { useFullscreenModeContext } from "../context/FullscreenModeContext";
import WebsocketClientUSerStatus from "../services/WebsocketClientUSerStatus";
import HomeR from "../images/home-r.svg";
import MusicR from "../images/music-r.svg";
import CardR from "../images/cards-r.svg";
import CalendarR from "../images/calendar-2r.svg";
import ProfileUserR from "../images/profile-2user-r.svg";
import HomeRF from "../images/home-rf.svg";
import MusicRF from "../images/music-rf.svg";
import CardRF from "../images/cards-rf.svg";
import CalendarRF from "../images/calendar-2rf.svg";
import ProfileUserRF from "../images/profile-2user-rf.svg";
import { useMusiciansFilter } from "./Musicians/MusicProvider/useMusicianFilter";
import Chart from "../images/chart-squareChart.svg";
import TotalUser from "../images/profile-2user.svg";
import Violations from "../images/security-user.svg";
import AdminSettings from "../icons/setting-white.svg";
import EditSocialSetting from "../images/user-octagon edit.svg";
import helpAndSupportIcon from "../icons/question help.svg";
import { useEventsFilter } from "./Events/useEventsFilter";
import MusicPlaylist from "../images/music-playlist.svg";
import DeviceIcon from '../images/Admin/deviceIcon.svg';

import { bindActionCreators } from "redux";
import { actionCreators, AppState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { Client } from "@twilio/conversations";
import { useReload } from "../context/ReloadContext";
import useAdminAuthorized from "../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
// import WebsocketMetricsClient from "../services/WebsocketMetricsClient";


const Search = styled("div")(({ theme }: any) => ({
	display: "flex",
	flexDirection: "row",
	position: "relative",
	borderRadius: "17px",
	backgroundColor: "#EDF5FF",
	marginRight: theme.spacing(2),
	marginLeft: 0,
	width: "100%",

	height: "36px",
	marginTop: "4px",
}));

const SearchIconWrapper = styled("div")(({ theme }: any) => ({
	padding: theme.spacing(0, 2),
	paddingTop: "7px",
	color: "#888888",
}));

const StyledInputBase = styled(InputBase)(({ theme }: any) => ({
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(1)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		// [theme.breakpoints.up('md')]: {
		// 	width: '30ch',
		// },
	},
}));

const TabMenuRespnsiveForAdmin = [
	{
		label: "Dashboard",
		id: "admin-dashboard",
		icon: Chart,
		disabled: false,
		activeIcon: Chart,
	},
	{
		label: "Manage User",
		id: "users-management",
		icon: TotalUser,
		disabled: false,
		activeIcon: TotalUser,
	},
	{
		label: "Moderation",
		id: "moderation-management",
		icon: Violations,
		disabled: false,
		activeIcon: Violations,
	},
];

const TabMenuForAdmin = [
	{
		label: "Dashboard",
		id: "admin-dashboard",
		icon: Chart,
		disabled: false,
		activeIcon: Chart,
	},
	{
		label: "Manage User",
		id: "users-management",
		icon: TotalUser,
		disabled: false,
		activeIcon: TotalUser,
	},
	{
		label: "Device Inventory",
		id: "device-inventory",
		icon: DeviceIcon,
		disabled: false,
		activeIcon: DeviceIcon,
	},
	{
		label: "Moderation",
		id: "moderation-management",
		icon: Violations,
		disabled: false,
		activeIcon: Violations,
	},
	{
		label: "Settings",
		id: "admin-settings",
		icon: AdminSettings,
		disabled: false,
		activeIcon: AdminSettings,
	},
];
const TabMenuForResponsive = [
	{
		label: "Home",
		id: "home",
		icon: HomeR,
		disabled: false,
		activeIcon: HomeRF,
	},
	{
		label: "Jam Session",
		id: "jamsession",
		icon: MusicR,
		disabled: false,
		activeIcon: MusicRF,
	},
	{
		label: "Musicians",
		id: "musicians",
		icon: ProfileUserR,
		disabled: false,
		activeIcon: ProfileUserRF,
	},
	{
		label: "Gigs",
		id: "gigs",
		icon: CardR,
		disabled: false,
		activeIcon: CardRF,
	},
	{
		label: "Events",
		id: "events",
		icon: CalendarR,
		disabled: false,
		activeIcon: CalendarRF,
	},
];

const TabsMenu = [
	{
		label: "Home",
		id: "home",
		icon: ActiveHome,
		disabled: false,
		activeIcon: ActiveHome,
	},
	{
		label: "Jam Session",
		id: "jamsession",
		icon: Music,
		disabled: false,
		activeIcon: ActiveMusic,
	},
	{
		label: "Musicians",
		id: "musicians",
		icon: MusicianOutlined,
		disabled: false,
		activeIcon: MusicianActive,
	},
	{
		label: "Gigs",
		id: "gigs",
		icon: Card,
		disabled: false,
		activeIcon: Card,
	},
	{
		label: "Events",
		id: "events",
		icon: Calendar,
		disabled: false,
		activeIcon: CalendarActive,
	},
];

const SettingsMenu = [
	{
		label: "View Social Profile",
		id: "socialHome",
		icon: Profile,
	},
	{
		label: "Edit Social Profile",
		id: "socialSettings",
		icon: EditSocialSetting,
	},
	{
		label: "My Music Files",
		id: "myMusicFiles",
		icon: MusicPlaylist,
	},
	{
		label: "Account Settings",
		id: "settings",
		icon: settingsIcon,
	},
	// Removed as we've Jam session for the another drop
	// {
	// 	label: "Shop for Jam Box",
	// 	id: "shopDevice",
	// 	icon: Shopping,
	// },
	{
		label: "Help & Support",
		id: "helpandsupport",
		icon: helpAndSupportIcon,
	},
	{
		label: "Sign Out",
		id: "signout",
		icon: signout,
	},
];

const AppBarC = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
		useState<null | HTMLElement>(null);
	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [selectedTab, setSelectedTab] = useState("home");
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const { musicianFilter, setMusicianFilter, resetMusicianFilter } =
		useMusiciansFilter();
	const { release } = useWakeLock();
	const user = useUser();
	const authorities =
		user.authorities &&
		user.authorities.map((authority: { name: string }) => authority.name);
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const { galleryViewParticipants } = useParticipantsContext();
	const { room } = useVideoContext();
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	// const [tabColor, setTabColor] = useState(false);
	const [showSessionLeaveMsg, setShowSessionLeaveMsg] = useState(false);
	const [notificationCount, setNotificationCount] = useState<any>(0);
	const [unreadMessagesCount, setUnreadMessagesCount] = useState<any>(0);
	const redirctionPath = useRef("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [previousSearchString, setPreviousSearchString] = useState("");
	const isMenuTabOpen = Boolean(anchorElNav);
	const isMenuOpen = Boolean(anchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
	const menuId = "primary-search-account-menu";
	const mobileMenuId = "primary-search-account-menu-mobile";
	const [globalSearchString, setGlobalSearchString] = useState<string>("");
	const [displayFlobalSearchDropdown, setDisplayFlobalSearchDropdown] = useState<boolean>(false);
	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

	const dispatch = useDispatch();
	const { logout } = bindActionCreators(actionCreators, dispatch);
	const [userProfileView, setUserProfileView] = useState("");
	const [inputValue, setInputValue] = useState("");
	const [displaySearchIcon, setDisplaySearchIcon] = useState(true);

	const conversations = useSelector((state: AppState) => state.convos);

	const [selectedOption, setSelectedOption] = useState("musicians");

	const token = localStorage.getItem("sessionId");
	const { reloadJamSession, toggleReload } = useReload();
	const {
		eventsFilter,
		setEventsFilter,
		gigsFilter,
		setGigsFilter,
		resetEventsFilter,
		resetGigFilter,
		setIsNavigatedFromGlobalSearch,
		setViewMoreEvents,
		setEventActiveMenu,
		setViewMoreGigs,
		setGigActiveMenu
	} = useEventsFilter();

	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	useEffect(() => {
		setUserProfileView(
			user.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${user.profilePhotoUrl}`
				: ""
		);
	}, [user]);

	window.onload = function () {
		if (user.activated === false) {
			navigate("/login");
		}
		const local: any = localStorage.getItem("wsConnection");
		const statusData = JSON.parse(local);
		if (statusData?.connected) {
			WebsocketClientUSerStatus.ReCreateConnectionUSerStatus(user.email);
		}
	};

	useEffect(() => {
		if (location.pathname.includes("home/admin/settings") || location.pathname.includes("users-management")) {
			setSelectedTab("users-management");
		}
		else if (location.pathname.includes("settings")) {
			setSelectedTab("admin-settings");
		} else {
			const pathName = window.location.hash.substring(2);
			//setSelectedTab(pathName);
			if (
				pathName === "users-management/device" ||
				pathName === "users-management/waitlistedusers" ||
				pathName === "users-management/waitlistedusers" ||
				pathName === "users-management/registereduser"
			) {
				setSelectedTab("users-management");
			}
			if (pathName === "moderation-management/users") {
				setSelectedTab("moderation-management");
			}
		}
		// if (location.pathname.includes("session")) {
		if (location.pathname === "/jamsession" || location.pathname === "/session") {
			setSelectedTab("jamsession");
		}
		if (location.pathname.split("/").slice(-1).join() === "musicians") {
			setSelectedTab("musicians");
		}
		if (location.pathname.split("/")[1] === "events") {
			setSelectedTab("events");
		}

		if(isAdmin && (location.pathname === "/home" || location.pathname === "/jamsession" || location.pathname === "/musicians" || location.pathname === "/gigs" || location.pathname === "/events") ){
			setSelectedTab("admin-dashboard");
			navigate("/admin-dashboard");
		}

		if (window.location.hash.substring(2) === "session") {
			// 	if (selectedTab === "jamsession") {
			// 		setTabColor(true);
			// 	} else {
			// 		setTabColor(false);
			// 	}
			// } else {
			// 	setTabColor(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, window.location.hash, selectedTab]);

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};
	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};
	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleCloseNavMenu = (tabId: any) => {
		if (location.pathname === "/session") {
			redirctionPath.current = `/${tabId}`;
			setShowSessionLeaveMsg(true);
		} else {
			navigate(`/${tabId}`);
		}
		setAnchorElNav(null);
	};
	const handleLogoClick = () => {
		if (location.pathname === "/session") {
			redirctionPath.current = `/home`;
			setShowSessionLeaveMsg(true);
		} else {
			navigate(`/home`);
		}
		setAnchorElNav(null);
	};
	const handleChange: any = (
		event: React.SyntheticEvent<Element, Event>,
		tabId: string
	) => {
		resetMusicianFilter();
		resetEventsFilter();
		resetGigFilter();
		setIsNavigatedFromGlobalSearch(false);
		setInputValue("");
		setDisplaySearchIcon(true);
		setIsNotificationsOpen(false);
		if (location.pathname === "/session") {
			redirctionPath.current = `/${tabId}`;
			setShowSessionLeaveMsg(true);
		} else {
			setSelectedTab(tabId);
			navigate(`/${tabId}`);
		}
		setViewMoreEvents(false)
		setEventActiveMenu("allEvents")
		setViewMoreGigs(false)
		setGigActiveMenu("allGigs")

	};

	const handleNavigation = async (
		settingsId: string,
		isActiveDevice: boolean
	) => {
		if (location.pathname === "/session") {
			if (settingsId === "signout") {
				logout();

				// unregister service workers
				const registrations = await navigator.serviceWorker.getRegistrations();
				for (let registration of registrations) {
					registration.unregister();
				}

				redirctionPath.current = "/login";
			}
			// Hiding for Social-Launch
			// else if (settingsId === "settings" && isActiveDevice) {
			// 	redirctionPath.current = "/home/settings/deviceSetup/device";
			// } else if (settingsId === "settings" && !isActiveDevice) {
			// 	redirctionPath.current = "/home/settings/deviceSetup/add";
			// } else if (settingsId === "socialSettings" && !isActiveDevice) {
			// 	redirctionPath.current = "/home/settings/deviceSetup/add";
			// }
			setShowSessionLeaveMsg(true);
		} else {
			if (settingsId === "signout") {
				(async () => {
					signOut(user.email);
					await new Promise(resolve => setTimeout(resolve, 1000)); // Delay for logout
					logout();
					// Unregister service workers
					const registrations = await navigator.serviceWorker.getRegistrations();
					await Promise.all(registrations.map(registration => registration.unregister()));

					await new Promise(resolve => setTimeout(resolve, 500)); // Delay for navigate
					navigate("/login");
				})()
			} else if (settingsId === "settings" && isActiveDevice) {
				// navigate("/home/settings/deviceSetup/device");
				navigate("/home/settings/account/account");
			} else if (settingsId === "settings" && !isActiveDevice) {
				// navigate("/home/settings/deviceSetup/add");
				navigate("/home/settings/account/account");
			} else if (settingsId === "socialSettings" && !isActiveDevice) {
				// navigate("/home/settings/deviceSetup/add");
				navigate("/home/settings/socialProfileSetting/socialProfileSetting");
			} else if (settingsId === "socialSettings" && isActiveDevice) {
				// navigate("/home/settings/deviceSetup/add");
				navigate("/home/settings/socialProfileSetting/socialProfileSetting");
			} else if (settingsId === "socialHome" && isActiveDevice) {
				// navigate("/home/settings/deviceSetup/device");
				const encodedNickName = encodeURIComponent(user.nickname);
				navigate("/socialHome/" + encodedNickName);
			} else if (settingsId === "socialHome") {
				const encodedNickName = encodeURIComponent(user.nickname);
				navigate("/socialHome/" + encodedNickName);
			} else if (settingsId === "shopDevice") {
				navigate("/shop");
			} else if (settingsId === "helpandsupport") {
				navigate("/helpandsupport");
			} else if (settingsId === "myMusicFiles") {
				navigate("/mymusic");
			}
		}
	};

	const handleCloseUserMenu = (itemId: string) => {
		setAnchorEl(null);
		const token = localStorage.getItem("sessionId");
		fetch(`${process.env.REACT_APP_BASE_URL}/api/active-user-device`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					localStorage.setItem("activeDevice", data);
					handleNavigation(itemId, true);
				}
			})
			.catch((err) => {
				handleNavigation(itemId, false);
			});
	};

	const leaveSession = () => {
		release();
		let message = {
			action: "session_stop",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			unsubscribe:
				galleryViewParticipants && galleryViewParticipants.length === 0
					? true
					: false,
		};

		// WebsocketMetricsClient.disconnectWebsocketConnection();

		stopSessionRecording(twilioSessionId, user);
		WebsocketClientUSerStatus.handleLeaveSession(message);
		setShowSessionLeaveMsg(false);
		// room!.disconnect();
		if (redirctionPath.current === "/login") {
			localStorage.removeItem("sessionId");
			localStorage.removeItem("user");
			localStorage.removeItem("conversationToken");
			localStorage.removeItem("activeDevice");
			localStorage.removeItem("wsConnection");
			localStorage.removeItem("notification");
		}
		room?.disconnect();
		clearLocalstorage();
		navigate(redirctionPath.current);
	};

	const renderMenu = (
		<Menu
			sx={{
				mt: "45px",
				p: 0,
				marginTop: "0px",
				"& .MuiDivider-root": {
					my: "0px",
				},
				"& .MuiButtonBase-root": {
					my: "0px",
				},
				"& .MuiMenu-list": {
					py: "0px",
				},
				"& .MuiPaper-root": {
					mt: "56px",
					borderColor: "#888888",
					borderRadius: "6px",
				},
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			{SettingsMenu.filter((menu) => {
				if (
					isAdmin &&
					(menu.id === "settings" || menu.id === "signout")
				) {
					return true;
				} else if (user.authorities?.[0].name === "ROLE_USER") {
					return true;
				}
				return false;
			}).map((menu) => [
				<MenuItem
					key={menu.label}
					onClick={() => handleCloseUserMenu(menu.id)}
					sx={{
						height: "56px",
						py: 0,
					}}
				>
					<IconButton
						size="medium"
						aria-label="show 4 new mails"
						color="inherit"
					>
						<Badge color="error">
							<img
								src={menu.icon}
								alt={menu.label}
								style={{ height: "24px", width: "24px" }}
							/>
						</Badge>
					</IconButton>
					<p style={{ fontSize: "1.125rem" }}>{menu.label}</p>
				</MenuItem>,
				<Divider sx={{ color: "#D7D7D7", marginTop: "0px" }} />,
			])}
		</Menu>
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const renderMobileMenu = (
		<Menu
			sx={{ mt: "45px" }}
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem>
				<IconButton
					size="large"
					aria-label="show 4 new mails"
					color="inherit"
					disabled
				>
					<Badge color="error">
						<MailIcon />
					</Badge>
				</IconButton>
				<p>Messages</p>
			</MenuItem>
			<MenuItem>
				<IconButton
					size="large"
					aria-label="show 17 new notifications"
					color="inherit"
				>
					<Badge color="error">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<p>Notifications</p>
			</MenuItem>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>Profile</p>
			</MenuItem>
		</Menu>
	);

	const renderTabsMenu = (
		<Menu
			id="menu-appbar"
			anchorEl={anchorElNav}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			open={isMenuTabOpen}
			onClose={handleCloseNavMenu}
			sx={{ mt: "45px" }}
		>
			{
				authorities && isAdmin
					? // <>
					  // 	{
					  TabMenuRespnsiveForAdmin.map((page) => (
							<MenuItem
								key={page.id}
								onClick={() => handleCloseNavMenu(page.id)}
							>
								<IconButton size="large" color="inherit">
									<Badge color="error">
										{/* <img src={page.icon} alt={page.label} /> */}
										{selectedTab === page.id ? (
												<img src={page.activeIcon} alt={page.label} />
											) : (
												<img src={page.icon} alt={page.label} />
										)}
									</Badge>
								</IconButton>
								<Typography textAlign="center">{page.label}</Typography>
							</MenuItem>
					  ))
					: // 	}
					  // </>
					  // <>
					  // 	{
					  TabMenuForResponsive.map((page) => (
							<MenuItem
								key={page.id}
								onClick={() => handleCloseNavMenu(page.id)}
							>
								<IconButton size="large" color="inherit">
									<Badge color="error">
										{/* <img src={page.icon} alt={page.label} /> */}
										{selectedTab === page.id ? (
											<img src={page.activeIcon} alt={page.label} />
										) : (
											<img src={page.icon} alt={page.label} />
										)}
									</Badge>
								</IconButton>
								<Typography textAlign="center">{page.label}</Typography>
							</MenuItem>
					  ))
				// }
				// </>
			}
		</Menu>
	);
	// () => navigate("/messages")
	const handleMessageClick = () => {
		if (location.pathname === "/session") {
			redirctionPath.current = "/messages";
			setShowSessionLeaveMsg(true);
		} else {
			// if (isNotificationsOpen) {
			// 	if (location.pathname === "/session") {
			// 		redirctionPath.current = "/messages";
			// 	}
			// 	setIsNotificationsOpen(false);
			// 	navigate(-1);
			// } else {
			// 	setIsNotificationsOpen(true);
			navigate("/messages");
			// }
		}
	};

	const handleNotificationClick = () => {
		if (location.pathname === "/session") {
			redirctionPath.current = "/notifications";
			setShowSessionLeaveMsg(true);
		} else {
			if (isNotificationsOpen) {
				if (location.pathname === "/session") {
					redirctionPath.current = "/notifications";
				}
				setIsNotificationsOpen(false);
				navigate(-1);
			} else {
				setIsNotificationsOpen(true);
				navigate("/notifications");
			}
		}
	};

	const getUnreadMessagesCount = () => {
		if (conversations) {
			var unreadCount = 0;
			// Iterate through messages and count unread ones
			// conversations.forEach(async (convo:any) => {
			// 	// let count = await convo.getUnreadMessagesCount()
			// 	// if (message.state !== 'read') {
			// 	//   unreadCount++;
			// 	// }
			//   });
			setUnreadMessagesCount(unreadCount);

			const token = localStorage.getItem("conversationToken");
			if (token) {
				const client = new Client(token as string);
				client.on("initialized", async () => {
					const conv = client.user;
				});
			}
		}
	};

	useEffect(() => {
		const handleWindowClose = () => {
			WebsocketClientUSerStatus.handleUserSignOut(user.email);
		};
		window.addEventListener("beforeunload", handleWindowClose);
		return () => {
			window.removeEventListener("beforeunload", handleWindowClose);
		};
	}, [user.email]);

	useEffect(() => {
		const count: any = localStorage.getItem("notification");
		const localCountData = JSON.parse(count);
		setNotificationCount(localCountData?.count);
		const onMessageReceived = (responseMsg: any) => {
			localStorage.setItem(
				"notification",
				JSON.stringify({
					url: `/public/userStatus/${user.email}`,
					email: user.email,
					count: responseMsg.unreadNotificationCount,
					connected: true,
				})
			);
			setNotificationCount(
				responseMsg.unreadNotificationCount <= 0
					? 0
					: responseMsg.unreadNotificationCount
			);
			if (responseMsg.unreadNotificationCount > 0) {
				fetchAllNotification(toggleReload);
			}
		};
		WebsocketClientUSerStatus.on("notification", onMessageReceived);

		getUnreadMessagesCount();

		return () => {
			WebsocketClientUSerStatus.off("notification", onMessageReceived);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(()=>{
		const local: any = localStorage.getItem("wsConnection");
		const statusData = JSON.parse(local);
		if (statusData?.connected) {
		  WebsocketClientUSerStatus.ReCreateConnectionUSerStatus(user.email);
		}
	  },[])

	const fetchAllNotification = (reloadCallback:any) => {
		try {
		  fetch(
			`${process.env.REACT_APP_BASE_URL}/api/getAllnotifications?page=0&size=${notificationCount}`,
			{
			  method: "POST",
			  headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			  },
			  body: JSON.stringify({
				id:user.id
			}),
			}
		  )
			.then((res) => {
			  if (res.status > 400) {
				throw new Error("api_error");
			  } else {
				return res.json();
			  }
			})
			.then((data) => {
			  if (data.length === 0) {
				// setHasMore(false);
			  } else {
				const hasInvitation = data.some((notification:any) => notification.notificationType === "Invitation");
				if (hasInvitation) {
				  reloadCallback();
				}
			  }
			})
			.catch((err) => {
			})
			.finally(() => {
			});
		} catch (error) {
		}
	  };

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const searchGlobal = () => {
		if (globalSearchString !== "") {
			setPreviousSearchString(globalSearchString);
			setMusicianFilter({
				...musicianFilter,
				isGlobalSearch: true,
				firstName: globalSearchString,
				lastName: globalSearchString,
				nickName: globalSearchString,
			});
			var activeScreen: string | undefined = window.location.href
				.split("/")
				.pop();
			// alert(activeScreen);
			if (activeScreen !== "search") {
				navigate("/musicians/search");
			}
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const clearGlobalSearch = () => {
		setGlobalSearchString("");
		setPreviousSearchString("");
		resetMusicianFilter();
		navigate(redirctionPath.current);
	};

	useEffect(() => {
		setGlobalSearchString(musicianFilter.firstName);
	}, [musicianFilter]);

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);

		return () => {
		  document.removeEventListener("click", handleClickOutside);
		};
	  }, []);

	  const handleClickOutside = (e: any) => {
		const isInputClick = e.target.id === "globalInput";
		if(!isInputClick) {
			setDisplayFlobalSearchDropdown(false);
		}
	  };

	const handleGlobalSearch = (searchFrom: string) => {
		if (inputValue) {
			setDisplaySearchIcon((isEnabled) => !isEnabled);
			if (searchFrom === "musicians") {
				setMusicianFilter({
					...musicianFilter,
					isGlobalSearch: true,
					firstName: inputValue,
					lastName: inputValue,
					nickName: inputValue,
				});
				navigate("/musicians/search");
			} else if (searchFrom === "events") {
				setEventsFilter({
					...eventsFilter,
					title: inputValue,
				});
				// navigate("/events", {
				// 	state: { globalSearch: true },
				// });
				navigate("/events");
				setIsNavigatedFromGlobalSearch(true);
			} else if (searchFrom === "gigs") {
				setGigsFilter({
					...gigsFilter,
					title: inputValue,
				});
				// navigate("/gigs", {
				// 	state: { globalSearch: true },
				// });
				navigate("/gigs");
				setIsNavigatedFromGlobalSearch(true);
			}
		}
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const options = ["musicians", "events", "gigs"];
		let newIndex = options.indexOf(selectedOption);

		switch (e.key) {
			case "ArrowDown":
				newIndex = (newIndex + 1) % options.length;
				setSelectedOption(options[newIndex]);
				break;
			case "ArrowUp":
				newIndex = (newIndex - 1 + options.length) % options.length;
				setSelectedOption(options[newIndex]);
				break;
			case "Enter":
				handleGlobalSearch(selectedOption);
				break;
			default:
				break;
		}
	};

	const handleClearSearch = () => {
		setInputValue("");
		setDisplaySearchIcon((isEnabled) => !isEnabled);
		resetGigFilter();
		resetEventsFilter();
		resetMusicianFilter();
		setIsNavigatedFromGlobalSearch(false);
		if (location.pathname.split("/").slice(-1).join() !== "home") {
			navigate(-1);
		}
	};

	return (
		<>
			{!(isFullscreenMode || isTabHidden) && (
				<AppBar
					position="sticky"
					sx={{
						background: theme.gradient.tabBar,

					}}
					id="app-header"
				>
					<Container
						sx={{
							height: "72px",
							py: 1,

						}}
						className="appBarCont"
					>
						<Toolbar disableGutters>
							{authorities && isAdmin ? (
								<>
									<Box
										sx={{
											display: { xs: "flex", md: "flex", xl: "flex" },
											height: "46px", width: "110px"
										}}
									>
										<img
											src={Logo}
											alt="logo"
											style={{ cursor: "pointer"}}
											onClick={() => navigate("/admin-dashboard")}
										/>
									</Box>
									<Box
										sx={{
											flexGrow: 1,
											display: { xs: "none", md: "none", xl: "none" },
											maxWidth: "15%",
										}}
									>
										<IconButton
											size="large"
											aria-label="account of current user"
											aria-controls="menu-appbar"
											aria-haspopup="true"
											onClick={handleOpenNavMenu}
											color="inherit"
										>
											<MenuIcon />
										</IconButton>
									</Box>
									<Box
										sx={{
											flexGrow: 1,
											display: { md: "flex", xl: "flex" },
											mt: 1,
											width: { md: "72%" },
											justifyContent: "center",

										}}
										className="appTabs"
									>
										<Tabs
											value={selectedTab}
											onChange={handleChange}
											indicatorColor="secondary"
											// variant="scrollable"
											// scrollButtons="auto"
											aria-label="Tabs"
											className="appBarTabs"
											sx={{
												[`& .${tabsClasses.scrollButtons}`]: {
													color: "white",
													"&.Mui-disabled": { opacity: 0.3 },
												},
												"& .Mui-selected": {
													background: theme.gradient.gradient1,
													borderRadius: "18px",
													textDecoration: "none",
													minHeight: "36px",
												},
												"& .MuiTabs-indicator": {
													display: "none",
												},
												marginLeft: { xl: "17%", md: "5%" },

											}}
										>
											{TabMenuForAdmin.map((tabMenu) => (
												<Tab
													key={tabMenu.id}
													icon={
														selectedTab === tabMenu.id ? (
															<img
																src={tabMenu.activeIcon}
																alt={tabMenu.label}
															/>
														) : (
															<img src={tabMenu.icon} alt={tabMenu.label} />
														)
													}
													iconPosition="start"
													sx={{ minHeight: "36px", borderRadius: "18px" }}
													className="iconButton"
													label={
														<Typography
															sx={{
																textTransform: "capitalize",
																color: "#FFFFFF",
															}}
														>
															{tabMenu.label}
														</Typography>
													}
													value={tabMenu.id}
													disabled={tabMenu.disabled}
													disableFocusRipple
													disableRipple
												/>
											))}
										</Tabs>
									</Box>
									<Box
										sx={{
											display: { xs: "none", md: "flex" },
											flexGrow: 0,
											ml: "auto",
										}}
										className="rightButton"
									>
										{authorities && !isAdmin && (
											<IconButton
												onClick={handleMessageClick}
												size="large"
												aria-label="show 4 new mails"
												color="inherit"
												disabled={user.userAccountStatus !== "ACTIVE"}
												className="appBarIconBtn"
											>
												<Badge badgeContent={unreadMessagesCount} color="error">
													<img src={Messages} alt="Messages" />
												</Badge>
											</IconButton>
										)}
										{authorities && !isAdmin && (
											<IconButton
												onClick={handleNotificationClick}
												size="large"
												aria-label="show 17 new notifications"
												color="inherit"
												className="appBarIconBtn"
											>
												<Badge badgeContent={notificationCount} color="error">
													<img src={Notification} alt="Notification" />
												</Badge>
											</IconButton>
										)}
										<Tooltip title="Open settings">
											<IconButton
												size="large"
												edge="end"
												aria-label="account of current user"
												aria-haspopup="true"
												aria-controls={menuId}
												onClick={handleProfileMenuOpen}
												color="inherit"
												className="appBarIconBtn"
											>
												<Avatar
													alt={user.firstName}
													src={userProfileView}
													sx={{
														width: 32,
														height: 32,
														bgcolor:
															user.profilePhoto === null ? "darkorange" : "",
														marginRight: "5px",
													}}
												/>
												{/* <img src={AvatarImg} alt="Profile pic" />&nbsp; */}

												<Typography
													className="typography-field-custom-font-size hideInTabPro"
													sx={{
														textOverflow: "ellipsis",
														overflow: "hidden",
														marginRight: "5px",
														display: { xl: "flex" },
													}}
													color="white"
												>
													{`${
														user.firstName && user.firstName?.length > 9
															? user.firstName?.substring(0, 9) + "..."
															: user.firstName
													}`}
												</Typography>

												<img src={ArrowDown} alt="Down Arrow 941" />
											</IconButton>
										</Tooltip>
									</Box>
									<Box
										sx={{
											display: { xs: "none" },
											flexGrow: 0,
											ml: "auto",
										}}
									>
										<IconButton
											size="large"
											aria-label="show more"
											aria-controls={mobileMenuId}
											aria-haspopup="true"
											onClick={handleMobileMenuOpen}
											color="inherit"
										>
											<MoreIcon />
										</IconButton>
									</Box>
								</>
							) : (
								<>
									{/* <Box
										sx={{
											display: { xs: "none", md: "flex", xl: "flex" },
											mr: 1,
										}}
										className="logoSearchBtn"
									>
										<img src={Logo} alt="logo" />
										<Search sx={{ cursor: "pointer" }}>
											<StyledInputBase
												placeholder="Search Artists"
												inputProps={{
													"aria-label": "search",
													color: "#888888",
												}}
												inputRef={globalSearchRef}
												onChange={(e: any) =>
													setGlobalSearchString(e.target.value)
												}
												onKeyDown={handleKeyPress}
												value={globalSearchString}
											/>
											<SearchIconWrapper>
												{
													// isFilterActive ? <Cancel onClick={clearGlobalSearch} /> : <SearchIcon onClick={searchGlobal} />
													globalSearchString != "" &&
													globalSearchString === previousSearchString ? (
														<Cancel onClick={clearGlobalSearch} />
													) : (
														<SearchIcon onClick={searchGlobal} />
													)
												}
											</SearchIconWrapper>
										</Search>
									</Box> */}

									{/* <Box
										sx={{
											display: { xs: "none", md: "none", xl: "flex" },
											height: "30px", width: "90px"
										}}
									>
										<img
											src={Logo}
											alt="logo"
											style={{ cursor: "pointer",height: "30px", width: "90px"}}
											onClick={handleLogoClick}
										/>
									</Box> */}
									<Box
										sx={{
											display: { xs: "flex", md: "flex" },
											height: "46px", width: "110px"
										}}
									>
										<img
											src={Logo}
											alt="logo"
											style={{ cursor: "pointer"}}
											onClick={handleLogoClick}
										/>
									</Box>

									<form
										style={{ position: "relative" }}
										onSubmit={(e) => {
											e.preventDefault();
										}}
									>
										<input
											id="globalInput"
											value={inputValue}
											onChange={(e) => {
												setInputValue(e.target.value);
												setDisplaySearchIcon(true);
												setDisplayFlobalSearchDropdown(true);
											}}
											type="text"
											placeholder="Search Artist, Events, Gigs"
											autoComplete="off"
											onKeyDown={handleKeyDown}
										/>
										{displaySearchIcon ? (
											<SearchIcon
												onClick={() => handleGlobalSearch("musicians")}
												sx={{
													position: "absolute",
													right: "10px",
													top: "50%",
													transform: "translateY(-50%)",
													cursor: "pointer",
												}}
											/>
										) : (
											<Cancel
												onClick={handleClearSearch}
												sx={{
													position: "absolute",
													right: "10px",
													top: "50%",
													transform: "translateY(-50%)",
													cursor: "pointer",
												}}
											/>
										)}

										<div
											style={{
												display:
													displaySearchIcon && displayFlobalSearchDropdown ? "block" : "none",
												position: "absolute",
												top: "2.25rem",
												left: "0.67rem",
												backgroundColor: "#fff",
												width: "15.437rem",
												height: "auto",
												border: "0.0625rem solid #C2C2C2",
												borderRadius: "0 0 0.5rem 0.5rem",
												padding: "13px 0",
											}}
										>
											<div
												onClick={() => handleGlobalSearch("musicians")}
												className="input-options"
												style={{
													marginBottom: "10px",
													backgroundColor:
														selectedOption === "musicians" ? "#F0F0F0" : "#fff",
												}}
											>
												<span className="options-width">{inputValue}</span>
												<span className="category-global">...in Musicians</span>
											</div>
											<div
												onClick={() => handleGlobalSearch("events")}
												className="input-options"
												style={{
													marginBottom: "10px",
													backgroundColor:
														selectedOption === "events" ? "#F0F0F0" : "#fff",
												}}
											>
												<span className="options-width">{inputValue}</span>
												<span className="category-global">...in Events</span>
											</div>
											<div
												onClick={() => handleGlobalSearch("gigs")}
												className="input-options"
												style={{
													backgroundColor:
														selectedOption === "gigs" ? "#F0F0F0" : "#fff",
												}}
											>
												<span className="options-width">{inputValue}</span>
												<span className="category-global">...in Gigs</span>
											</div>
										</div>
									</form>

									{/* <div
										style={{
											display:
												inputValue && displaySearchIcon ? "block" : "none",
											position: "absolute",
											top: "50px",
											left: "12%",
											backgroundColor: "#fff",
											width: "247px",
											height: "144px",
											border: "1px solid #C2C2C2",
											borderRadius: "0 0 8px 8px",
										}}
									>
										<div
											onClick={() => handleGlobalSearch("musicians")}
											className="input-options"
										>
											<span className="options-width">{inputValue}</span>
											<span>...in Musicians</span>
										</div>
										<div
											onClick={() => handleGlobalSearch("events")}
											className="input-options"
										>
											<span className="options-width">{inputValue}</span>
											<span>...in Events</span>
										</div>
										<div
											onClick={() => handleGlobalSearch("gigs")}
											className="input-options"
										>
											<span className="options-width">{inputValue}</span>
											<span>...in Gigs</span>
										</div>
									</div> */}


									<Box
										sx={{
											flexGrow: 1,
											display: { md: "flex", xl: "flex" },
											mt: 1,
											width: { md: "55%", xl: "48%" },
											paddingLeft: { xl: "10px" },
										}}
										className="appTabs"
									>
										<Tabs
											value={selectedTab}
											onChange={handleChange}
											indicatorColor="secondary"
											// variant="scrollable"
											// scrollButtons="auto"
											aria-label="Tabs"
											className="appBarTabs"
											sx={{
												[`& .${tabsClasses.scrollButtons}`]: {
													color: "white",
													"&.Mui-disabled": { opacity: 0.3 },
												},
												"& .Mui-selected": {
													background: theme.gradient.gradient1,
													borderRadius: "18px",
													textDecoration: "none",
													minHeight: "36px",
												},
												"& .MuiTabs-indicator": {
													display: "none",
												},

												margin: { md: "0 auto" },
											}}
										>
											{TabsMenu.map((tabMenu) => (
												<Tab
													key={tabMenu.id}
													icon={
														selectedTab === tabMenu.id ? (
															<img
																src={tabMenu.activeIcon}
																alt={tabMenu.label}
															/>
														) : (
															<img src={tabMenu.icon} alt={tabMenu.label} />
														)
													}
													iconPosition="start"
													sx={{
														minHeight: "36px",
														borderRadius: "18px",
														fontSize: "1rem",
														maxHeight: "36px",
													}}
													className="iconButton"
													label={
														<Typography
															sx={{
																textTransform: "capitalize",
																color: "#FFFFFF",
																fontSize: "1rem",
															}}
														>
															{tabMenu.label}
														</Typography>
													}
													value={tabMenu.id}
													disabled={tabMenu.disabled}
													disableFocusRipple
													disableRipple
												/>
											))}
										</Tabs>
									</Box>

									<Box
										sx={{
											display: { md: "flex" },
											flexGrow: 0,
											ml: "auto",
										}}
										className="rightButton"
									>
										{location.pathname === '/messages' ?
										<IconButton
											onClick={handleMessageClick}
											size="large"
											aria-label="show 4 new mails"
											color="inherit"
											disabled={user.userAccountStatus !== "ACTIVE"}
											className="appBarIconBtn"
										>
											<Badge badgeContent={unreadMessagesCount} color="error">
												<img src={MessagesF} alt="Messages" />
											</Badge>
										</IconButton>
										:
										<IconButton
											onClick={handleMessageClick}
											size="large"
											aria-label="show 4 new mails"
											color="inherit"
											disabled={user.userAccountStatus !== "ACTIVE"}
											className="appBarIconBtn"
										>
											<Badge badgeContent={unreadMessagesCount} color="error">
												<img src={Messages} alt="Messages" />
											</Badge>
										</IconButton>
										}

										{location.pathname === '/notifications' ?
										<IconButton
										onClick={handleNotificationClick}
										size="large"
										aria-label="show 17 new notifications"
										color="inherit"
										className="appBarIconBtn"
									>
										<Badge badgeContent={notificationCount} color="error">
											<img src={NotificationsF} alt="Notification" />
										</Badge>
									</IconButton>
										:
										<IconButton
											onClick={handleNotificationClick}
											size="large"
											aria-label="show 17 new notifications"
											color="inherit"
											className="appBarIconBtn"
										>
											<Badge badgeContent={notificationCount} color="error">
												<img src={Notification} alt="Notification" />
											</Badge>
										</IconButton>
										}


										<Tooltip title="Open settings">
											<IconButton
												size="large"
												edge="end"
												aria-label="account of current user"
												aria-haspopup="true"
												aria-controls={menuId}
												onClick={handleProfileMenuOpen}
												color="inherit"
												className="appBarIconBtn"
												sx={{marginRight:'0px'}}
											>
												<Avatar
													alt={user.firstName}
													src={userProfileView}
													sx={{
														width: 32,
														height: 32,
														bgcolor:
															user.profilePhoto === null ? "darkorange" : "",
														marginRight: "5px",
													}}
												/>
												{/* <img src={AvatarImg} alt="Profile pic" />&nbsp; */}

												<Typography
													className="typography-field-custom-font-size hideInTabPro"
													sx={{
														textOverflow: "ellipsis",
														overflow: "hidden",
														marginRight: "5px",
														display: { xl: "flex" },
													}}
													color="white"
												>
													{`${
														user.firstName && user.firstName?.length > 9
															? user.firstName?.substring(0, 9) + "..."
															: user.firstName
													}`}
												</Typography>

												<img src={ArrowDown} alt="Down Arrow 1177" />
											</IconButton>
										</Tooltip>
									</Box>

									<Box
										sx={{
											display: { xs: "none", sm: "none", md: "none" },
											flexGrow: 0,
											ml: "auto",
										}}
									>
										<IconButton
											size="large"
											aria-label="show more"
											aria-controls={mobileMenuId}
											aria-haspopup="true"
											onClick={handleMobileMenuOpen}
											color="inherit"
										>
											<MoreIcon />
										</IconButton>
									</Box>
								</>
							)}
						</Toolbar>
					</Container>
				</AppBar>
			)}

			{/*renderMobileMenu */}
			{renderMenu}
			{/* {renderTabsMenu} */}
			{showSessionLeaveMsg && (
				<Dialog
					fullWidth
					maxWidth="xs"
					open={showSessionLeaveMsg}
					onClose={() => setShowSessionLeaveMsg(false)}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => setShowSessionLeaveMsg(false)}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={Close} alt="close" />
							</IconButton>
						</Box>
						<Typography>Leave Session</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							You are about to leave an active session.Do you want to continue?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => setShowSessionLeaveMsg(false)}
						>
							Cancel
						</Button>
						<Button variant="contained" onClick={leaveSession}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default AppBarC;


import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
	Grid,
    TextField,
    InputAdornment,
    CircularProgress,
    Box
} from "@mui/material";
import { createStyles,  makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import i18n from "../../l10n/strings.json";
import UserCardShort from "./UserCardShort";

import SearchIcon from '@mui/icons-material/Search';
import Cancel from "@mui/icons-material/Cancel";
import CenterCircularProgress from "../CenterCircularProgress";
import { musicianCardInterface } from "../../types/types";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        shadowedBox:{
            // padding: "1% 2%", 
            borderRadius: "15px",
            //boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
        },
		activeMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#ffffff",
            background: "#0273E6"
		},
		musicMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#000 !important",
            // background: "#0273E6"
		},
        instrumentStack: {
            margin: "5% 0",
            "& div": {
                padding: "1%",
                margin: "1% 0",
                backgroudColor: "#FAFAFA",
                borderRadius: "15px"
            }
        },
        spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		}
	})
);

type RouteParams = {
	userId: string;
};

const Mutuals = () => {
    const classes = useStyles();
    const param = useParams<RouteParams>();
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
	const [searchString, setSearchString] = useState("");
	const [itemPerPage, setItemPerPage] = useState(10);
    const [myConnections, setMyConnections] = useState<Array<musicianCardInterface>>( [] );
    const [hasMore, setHasMore] = useState(true);
    const [previousSearchString, setPreviousSearchString] = useState("");
    const searchRef = useRef("");

	useEffect(() => {
		getMyMutualConnections();
	}, []);

    const getMyMutualConnections = () => {
        myConnections.length === 0 && setLoading(true);
		// setMyConnections({recentJoiners: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
		try {
            // setMyConnections([]);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/mutual-connections?searchString=${searchString}&page=${pageNumber}&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						requestFrom: {id: user.userProfileId },
						requestTo: {id: param.userId},
					}),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                // setMyConnections(data);
                if(data.length > 0){
                    setMyConnections([...myConnections, ...data]);
                    var nextPageNumber = pageNumber+1;
                    setPageNumber(nextPageNumber);
                    setPreviousSearchString(searchString);
                }
                if(pageNumber == 0 && data.length < itemPerPage || data.length === 0){
                    setHasMore(false);
                }
            })
            .catch((err) => {
                setHasMore(false);
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };
    
    const resetMyMutualConnections = () => {
        setHasMore(true);
        setPageNumber(0);
        setLoading(true);
		// setMyConnections({recentJoiners: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
		try {
            setMyConnections([]);
            //@ts-ignore
            let searchStringForService = searchRef.current?.value;
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/mutual-connections?searchString=${searchStringForService}&page=0&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						requestFrom: {id: user.userProfileId },
						requestTo: {id: param.userId},
					}),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if(data.length > 0){
                    setMyConnections(data);
                    // var nextPageNumber = pageNumber+1;
                    setPageNumber(1);
                    setPreviousSearchString(searchStringForService);
                }
                if(data.length < itemPerPage || data.length === 0){
                    // setHasMore(false);
                }
            })
            .catch((err) => {
                setHasMore(false);
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };

    const handleCancel = () => {
        // Reset the search input and cancel the search
        setSearchString('');
        setPreviousSearchString('');
        //@ts-ignore
        searchRef.current.value = '';
        resetMyMutualConnections();
    };
    
    const handleSearch = () => {
        if(searchString != ""){
            // Reset the search input and cancel the search
            setPreviousSearchString(searchString);
            resetMyMutualConnections();
        }
    };

    // useEffect(() => {
    //     resetMyMutualConnections();
    // }, [searchString]);

	return (
		<>  
            {loading ? 
                <Grid container item justifyContent="center" sx={{ p: "16px" }}>
                    <CircularProgress /> {/* The loading spinner */}
                </Grid>
                :   
                <Grid container xs={12} >
                        {
                            (myConnections && myConnections.length > 0) ?

                                <Grid xs={12} container={true} className={classes.shadowedBox} p={"1% 2%"}>
                                        <Grid xs={4} item={true} sx={{mb:2}}>
                                            <TextField
                                                hiddenLabel
                                                id="filled-hidden-label-small"
                                                variant="outlined"
                                                placeholder="Search your connections"
                                                size="small"
                                                InputProps={{
                                                    endAdornment: (
                                                    <InputAdornment position="start" sx={{cursor: "pointer"}}>
                                                        {/* <img src={Search} alt="Search" /> */}
                                                        {(searchString != "" && searchString === previousSearchString ) ? <Cancel onClick={handleCancel} /> : <SearchIcon onClick={handleSearch} />}
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                inputRef={searchRef}
                                                onChange={(e) => setSearchString(e.target.value)}
                                                value={searchString}
                                            />
                                        </Grid>
                                        {/* <Grid item={true} xs={9} className="right-align">
                                            <Link href="#">View All</Link>
                                            </Grid>
                                            {/* </Grid> */}

                                
                                    <Grid item xs={12} >
                                    {/* User Cards */}
                                    <InfiniteScroll
                                        style={{width: "100%"}}
                                        dataLength={myConnections.length}
                                        next={getMyMutualConnections}
                                        hasMore={hasMore}
                                        loader={
                                            <Box 
                                                className={classes.spinner}
                                            >
                                                <CircularProgress />
                                            </Box>
                                        }
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                                <b>Yay! You have seen it all</b>
                                            </p>
                                        }
                                    >
                                        <Grid xs={12} container>
                                                {
                                                    myConnections.length > 0 && myConnections.map(user => {
                                                        return (
                                                            <UserCardShort user={user} refreshList={resetMyMutualConnections} />
                                                        )
                                                    })
                                                }
                                        </Grid>
                                    </InfiniteScroll>
                                </Grid>
                            </Grid>

                            :
                            <Grid container item justifyContent="center">
                                No connections available yet.
                            </Grid>
                        }
                    {/* </Grid> */}
                </Grid>
            }
		</>
	);
};

export default Mutuals;

import { useEffect, useState } from "react";
import { Box, Column, DisplayPill, Grid, Text } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import {
	MessageStatus,
	ReduxMessage,
} from "../../../store/reducers/messageListReducer";
import SendingIcon from "../icons/Sending";
import DeliveredIcon from "../icons/Delivered";
import ReadIcon from "../icons/Read";
import FailedIcon from "../icons/Failed";
import BellMuted from "../icons/BellMuted";

import { NOTIFICATION_LEVEL } from "../constants";
import { SetSidType, SetUnreadMessagesType } from "../../../types";
import { getMessageStatus } from "../api";

import { getLastMessageTime } from "../utils/timestampUtils";

import { ReduxConversation } from "../../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../../store/reducers/participantsReducer";
// import { Avatar } from "@mui/material";
import { Avatar } from "@twilio-paste/core";
import { useUser } from "../../../context/UserContext";

interface SingleConvoProps {
	convoId: string;
	setSid: SetSidType;
	currentConvoSid: string;
	lastMessage: string;
	myMessage: ReduxMessage | false;
	unreadMessagesCount: number;
	convo: ReduxConversation;
	updateUnreadMessages: SetUnreadMessagesType;
	onClick: () => void;
	participants: ReduxParticipant[];
	messages: ReduxMessage[];
	typingInfo: string[];
	use24hTimeFormat: boolean;
}

const measureWidth = (text: string): number => {
	const canvas = document.createElement("canvas");
	const context = canvas.getContext("2d");
	if (!context) {
		return 0;
	}
	context.font = "bold 14px Inter";
	return context.measureText(text).width;
};

function calculateUnreadMessagesWidth(count: number) {
	if (count === 0 || !count) {
		return 0;
	}
	return measureWidth(count.toString()) + 32;
}

function truncateMiddle(text: string, countWidth: number) {
	const width = measureWidth(text);
	if (width > 288 - countWidth) {
		const textLength = text.length;
		const avgLetterSize = width / textLength;
		const nrOfLetters = (288 - countWidth) / avgLetterSize;
		const delEachSide = (textLength - nrOfLetters + 1) / 2;
		const endLeft = Math.floor(textLength / 2 - delEachSide);
		const startRight = Math.ceil(textLength / 2 + delEachSide);
		return text.substr(0, endLeft) + "..." + text.substr(startRight);
	}
	return text;
}

const ConversationView: React.FC<SingleConvoProps> = (
	props: SingleConvoProps
) => {
	const {
		convo,
		convoId,
		myMessage,
		lastMessage,
		unreadMessagesCount,
		use24hTimeFormat,
	} = props;
	const [backgroundColor, setBackgroundColor] = useState();
	const title =
		convo.sid != null
			? truncateMiddle(
					convo.friendlyName ?? convo.sid,
					calculateUnreadMessagesWidth(unreadMessagesCount)
			  )
			: convo.friendlyName;
	const theme = useTheme();
	const textColor =
		unreadMessagesCount > 0
			? theme.textColors.colorText
			: theme.textColors.colorTextIcon;
	const muted = convo.notificationLevel === NOTIFICATION_LEVEL.MUTED;
	const token = localStorage.getItem("sessionId");

	const [lastMsgStatus, setLastMsgStatus] = useState("");
	const [userDetails, setUserDetails] = useState<any>({});
	const time = getLastMessageTime(props.messages, use24hTimeFormat);
	const selfUser = useUser();

	useEffect(() => {
		if (props.currentConvoSid === convo.sid) {
			setBackgroundColor(theme.backgroundColors.colorBackgroundStrong);
			return;
		}
		setBackgroundColor(theme.backgroundColors.colorBackgroundRowStriped);
	}, [props.currentConvoSid, convo.sid]);

	useEffect(() => {
		if (myMessage && !props.typingInfo.length) {
			getMessageStatus(myMessage, props.participants).then((statuses: any) => {
				if (statuses[MessageStatus.Read]) {
					setLastMsgStatus(MessageStatus.Read);
					return;
				}
				if (statuses[MessageStatus.Delivered]) {
					setLastMsgStatus(MessageStatus.Delivered);
					return;
				}
				if (statuses[MessageStatus.Failed]) {
					setLastMsgStatus(MessageStatus.Failed);
					return;
				}
				if (statuses[MessageStatus.Sending]) {
					setLastMsgStatus(MessageStatus.Sending);
					return;
				}
			});
		}
	}, [convo, myMessage, lastMessage, props.participants, props.typingInfo]);

	const getPrivacyName = (data: any) => {
		if (selfUser?.userProfileId === data?.id) {
			return data?.user?.firstName + " " + data?.user?.lastName;
		} else if (
			data?.userPrivacySettingsDTO?.identityPermission ===
			"Name is not visible. Instead just show nickname"
		) {
			return data?.nickname;
		} else if (
			data?.userPrivacySettingsDTO?.identityPermission ===
			"Name is visible to all"
		) {
			return data?.user?.firstName + " " + data?.user?.lastName;
		} else if (
			data?.userPrivacySettingsDTO?.identityPermission ===
			"Name is visible only to my connections. Other see nickname"
		) {
			return data?.isConnected === true
				? data?.user?.firstName + " " + data?.user?.lastName
				: data?.nickname;
		} else {
			return data?.user?.firstName + " " + data?.user?.lastName;
		}
	};

	const fetchUserDetails = () => {
		if (!convo.uniqueName || !convo.uniqueName.includes("@")) return;
		try {
			// fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connections/my-connection/${user.id}?searchString=${userSearchString}&page=0&size=1000000`, {
			let userEmail = convo.uniqueName
				.replace(selfUser.email, "")
				.replace("-", "")
				.replace("GIG@@@", "");
			// fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles?email=${userEmail}`, {
			//   method: "POST",
			//   headers: {
			//     "Content-Type": "application/json",
			//     Authorization: `Bearer ${token}`,
			//   },
			// })
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					email: userEmail,
				}),
			})
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data !== null) {
						const updatedData = { ...data, name: getPrivacyName(data) };
						setUserDetails(updatedData);
					} else {
						setUserDetails({});
					}
				})
				.catch((err) => {
					setUserDetails([]);
				});
		} catch (error) {}
	};

	const getTitle = (friendlyName: string) => {
		// let title = props.convo.friendlyName ? (props.convo.friendlyName as string).replace((selfUser.firstName+' '+selfUser.lastName),'').replace(',','') : '';
		let title = props.convo.friendlyName
			? (props.convo.friendlyName as string)
					.replace(selfUser.firstName + " " + selfUser.lastName, "")
					.replace(",", "")
			: "";
		return title;
	};

	useEffect(() => {
		// alert('showw')
		fetchUserDetails();
	}, []);

	return convoId != null && convoId != "" ? (
		<Box
			style={{
				paddingTop: 14,
				paddingBottom: 14,
				paddingLeft: 16,
				paddingRight: 16,
				cursor: "pointer",
				backgroundColor:
					convo.sid === props.currentConvoSid ? backgroundColor : "",
				borderBottomStyle: "solid",
				borderBottomWidth: 1,
				borderBottomColor: theme.borderColors.colorBorderWeak,
			}}
			id={convoId}
			className="name"
			onMouseOver={() => {
				if (convo.sid === props.currentConvoSid) {
					return;
				}
				setBackgroundColor(theme.backgroundColors.colorBackgroundStrong);
			}}
			onMouseOut={() => {
				if (convo.sid === props.currentConvoSid) {
					return;
				}
				setBackgroundColor(theme.backgroundColors.colorBackgroundRowStriped);
			}}
			onClick={props.onClick}
		>
			<Box
				// display="flex"
				style={{
					backgroundColor:
						convo.sid === props.currentConvoSid ? backgroundColor : "",
				}}
			>
				{props.convo.uniqueName?.includes("GIG@@@") && (
					<Grid marginBottom="space20">
						<DisplayPill>Gig Enquiry</DisplayPill>
					</Grid>
				)}
				<Grid>
					<Column span={2}>
						{userDetails?.profilePhotoUrl ? (
							<Avatar
								size="sizeIcon70"
								//name={(userDetails?.user.firstName + ' ' + userDetails?.user.lastName) as string}
								name={userDetails?.name as string}
								src={`${
									process.env.REACT_APP_PICTURE_URL +
									userDetails?.profilePhotoUrl
								}`}
							/>
						) : (
							<Avatar size="sizeIcon70" name={getTitle(title as string)} />
						)}
					</Column>
					<Column span={10}>
						<Box display="flex">
							<Box
								style={{
									width: 288,
									fontWeight: theme.fontWeights.fontWeightSemibold,
									fontSize: 14,
									color: muted
										? theme.textColors.colorTextInverseWeaker
										: theme.textColors.colorText,
								}}
							>
								{muted ? <BellMuted /> : null}
								<span
									style={{ verticalAlign: "top", paddingLeft: muted ? 4 : 0 }}
								>
									{/* Messages left side name to update for nickname, response from backend for privacy dto and connected status is remaining. */}
									{/* {(userDetails && userDetails.user) ? userDetails.user.firstName + ' ' + userDetails.user.lastName : getTitle(title as string)} */}
									{userDetails && userDetails.user
										? userDetails.name
										: getTitle(title as string)}
									{/* {getTitle(title as string)} */}
								</span>
							</Box>
							{unreadMessagesCount > 0 && (
								<Box paddingLeft="space30">
									<Box
										backgroundColor="colorBackgroundBrandStronger"
										color="colorTextInverse"
										fontFamily="fontFamilyText"
										fontWeight="fontWeightBold"
										// fontSize="fontSize30"
										lineHeight="lineHeight30"
										paddingLeft="space30"
										paddingRight="space30"
										style={{ borderRadius: 12, opacity: muted ? 0.2 : 1 }}
									>
										{unreadMessagesCount}
									</Box>
								</Box>
							)}
						</Box>
						<Box
							style={{
								paddingTop: 4,
								color: textColor,
								fontWeight: theme.fontWeights.fontWeightNormal,
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
							}}
						>
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
								paddingLeft="space30"
							>
								{!props.typingInfo.length && lastMsgStatus ? (
									<Box
										display="flex"
										paddingRight={"space20"}
										alignItems="center"
									>
										{lastMsgStatus === MessageStatus.Sending &&
											props.myMessage && <SendingIcon />}
										{lastMsgStatus === MessageStatus.Delivered &&
											props.myMessage && <DeliveredIcon />}
										{lastMsgStatus === MessageStatus.Failed &&
											props.myMessage && <FailedIcon color="#D61F1F" />}
										{lastMsgStatus === MessageStatus.Read &&
											props.myMessage && <ReadIcon />}
									</Box>
								) : null}
								<Box
									style={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{lastMsgStatus === MessageStatus.Failed ? (
										<Text
											as="span"
											color="colorTextError"
											fontWeight={"fontWeightSemibold"}
										>
											{"Message failed to send"}
										</Text>
									) : (
										<Text as="span" color="colorTextWeak">
											{lastMessage}
										</Text>
									)}
								</Box>
							</Box>
							<Box style={{ whiteSpace: "nowrap", paddingLeft: 4 }}>
								<Text
									as="span"
									color={"colorTextWeak"}
									// fontSize="fontSize20"
									fontWeight={"fontWeightSemibold"}
								>
									{time}
								</Text>
							</Box>
						</Box>
					</Column>
				</Grid>
			</Box>
		</Box>
	) : (
		<Box
			style={{
				paddingTop: 14,
				paddingBottom: 14,
				paddingLeft: 16,
				paddingRight: 16,
				cursor: "pointer",
				// backgroundColor: backgroundColor,
			}}
			id={undefined}
			className="name"
			onMouseOver={() => {
				// if (convo.sid === props.currentConvoSid) {
				//   return;
				// }
				setBackgroundColor(theme.backgroundColors.colorBackgroundStrong);
			}}
			onMouseOut={() => {
				// if (convo.sid === props.currentConvoSid) {
				//   return;
				// }
				setBackgroundColor(theme.backgroundColors.colorBackgroundRowStriped);
			}}
			onClick={props.onClick}
		>
			<Box
				style={
					{
						// backgroundColor: backgroundColor,
					}
				}
			>
				<Box display="flex">
					<Box
						style={{
							width: 288,
							fontWeight: theme.fontWeights.fontWeightSemibold,
							fontSize: 14,
							color: muted
								? theme.textColors.colorTextInverseWeaker
								: theme.textColors.colorText,
						}}
					>
						{muted ? <BellMuted /> : null}
						<span style={{ verticalAlign: "top", paddingLeft: muted ? 4 : 0 }}>
							{convo.sid != null ? title : convo.friendlyName}
						</span>
					</Box>
					{unreadMessagesCount > 0 && (
						<Box paddingLeft="space30">
							<Box
								backgroundColor="colorBackgroundBrandStronger"
								color="colorTextInverse"
								fontFamily="fontFamilyText"
								fontWeight="fontWeightBold"
								// fontSize="fontSize30"
								lineHeight="lineHeight30"
								paddingLeft="space30"
								paddingRight="space30"
								style={{ borderRadius: 12, opacity: muted ? 0.2 : 1 }}
							>
								{unreadMessagesCount}
							</Box>
						</Box>
					)}
				</Box>
				<Box
					style={{
						paddingTop: 4,
						color: textColor,
						fontWeight: theme.fontWeights.fontWeightNormal,
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Box
						style={{
							display: "flex",
							flexDirection: "row",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{!props.typingInfo.length && lastMsgStatus ? (
							<Box display="flex" paddingRight={"space20"} alignItems="center">
								{lastMsgStatus === MessageStatus.Sending && props.myMessage && (
									<SendingIcon />
								)}
								{lastMsgStatus === MessageStatus.Delivered &&
									props.myMessage && <DeliveredIcon />}
								{lastMsgStatus === MessageStatus.Failed && props.myMessage && (
									<FailedIcon color="#D61F1F" />
								)}
								{lastMsgStatus === MessageStatus.Read && props.myMessage && (
									<ReadIcon />
								)}
							</Box>
						) : null}
						<Box
							style={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{lastMsgStatus === MessageStatus.Failed ? (
								<Text
									as="span"
									color="colorTextError"
									fontWeight={"fontWeightSemibold"}
								>
									{"Message failed to send"}
								</Text>
							) : (
								<Text as="span" color="colorTextWeak">
									{lastMessage}
								</Text>
							)}
						</Box>
					</Box>
					<Box style={{ whiteSpace: "nowrap", paddingLeft: 4 }}>
						<Text
							as="span"
							color={"colorTextWeak"}
							// fontSize="fontSize20"
							fontWeight={"fontWeightSemibold"}
						>
							{time}
						</Text>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default ConversationView;

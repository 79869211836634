import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	FormHelperText,
	DialogActions,
	FormControl,
	Select,
	MenuItem,
	Autocomplete,
	Typography,
	Chip,
	Box,
	IconButton,
	Grid,
	FormControlLabel,
	Card,
	CardContent,
} from "@mui/material";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
	addMinutes,
	lightFormat,
	differenceInMinutes,
	min,
	set,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import TimePickerCmp from "./TimePickerCmp";
import StartTimePicker from "./StartTimePicker";
import i18n from "../../l10n/strings.json";
import Close from "../../images/close.svg";
import Profile from "../../images/profile-circle-auto.svg";
// contexts
import { useUser } from "../../context/UserContext";
// services
import { Constants } from "../../services/constants";
import { UserInterface } from "../../types/types";
import { useAlertStatus } from "../../context/AlertStatusContext";

// utils
import { checkIsValidDate } from "../../utils";
import { useTheme , styled } from "@mui/material/styles";
import RightAarrow from "../../images/right arrow.svg";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useActiveSubscription } from "../Subscription/SubscriptionProvider/useUserSubscription";
import User from "../../services/user";
import Switch, { SwitchProps } from "@mui/material/Switch";

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white",
			"&:hover": {
				color: "white",
			},
		},
	},
}));

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0273E6",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const ScheduleSession = (props: any) => {
	const classes = useStyles();
	const theme = useTheme();
	const user = useUser();
	const navigate = useNavigate();
	const durationList = Constants.duration;
	const [hubServersList, setHubServersList] = useState<any>();
	const { isSubscribed } = useActiveSubscription();

	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const [activeUsers, setActiveUsers] = useState<Array<UserInterface> | []>([]);

	const [sessionName, setSessionName] = useState("");
	const [desc, setDesc] = useState("");
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [startTime, setStartTime] = useState<Date | null>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [minDate, setMinDate] = useState<Date | undefined>();
	const [endDate, setEndDate] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [endTime, setEndTime] = useState<Date | null>(() => {
		const now = new Date();
		now.setMinutes(now.getMinutes() + 30);
		return now;
	});

	const [maxTime, setMaxTime] = useState<Date | null>(
		addMinutes(new Date(), 240)
	);
	const [minTime, setMinTime] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [timezone, setTimezone] = useState<any>(null);
	const [showAdvanceView, setShowAdvanceView] = useState(false);
	const [errorDate, setErrorDate] = useState(false);
	const [duration, setDuration] = useState<number>(30);
	const [endsAt, setEndsAt] = useState<Date | null | string>(
		addMinutes(new Date(), 30)
	);
	const [pastSessionOpen, setPastSessionOpen] = useState(false);

	const [chips, setChips] = useState<Array<UserInterface>>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [validSession, setValidSession] = useState(true);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState("");
	const [subscriptionActive, setSubscriptionActive] = useState<Boolean>();
	const ALLOWED_SESSION_FOR_SUBSCRIPTION: number = 5;

	const [isScheduling, setIsScheduling] = useState(false);
	const [userLoading, setUserLoading] = useState(false);
	const [searchString, setSearchString] = useState<string>("");
	const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false);
	const [showHubServerDropdown, setShowHubServerDropdown] = useState(false);

	const [hubServer, setHubServer] = useState("");
	const [showHubServerErrorMsg, setShowHubServerErrorMsg] = useState(false);

	const [subscriptions, setSubscriptions] = useState<any[]>([]);
	const [isHubServerListFetching, setIsHubServerListFetching] = useState(false);
	const [showNotfyMsg, setShowNotfyMsg] = useState(false);
	useEffect(() => {
		User.fetchUserInfo((userInfo: any) => {
			if (userInfo.error) {
				console.error("Failed to fetch user info:", userInfo.error);
			} else {
				setSubscriptions(userInfo.subscriptionInformationDTOs);
			}
		});
	}, []);
	const planSubscription = subscriptions?.find(
		(sub) =>
			sub.type === "Software" &&
			(sub.status.toLowerCase() === "active" ||
				sub.status.toLowerCase() === "paused") &&
			(sub.name.toLowerCase() === "free" ||
				sub.name.toLowerCase() === "standard" ||
				sub.name.toLowerCase() === "premium")
	);
	const subscriptionName = planSubscription
		? planSubscription.name.toLowerCase()
		: null;

	const allowedUser = planSubscription ? planSubscription.allowedUsers : null;

	const getActiveUsers = () => {
		setUserLoading(true);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/jamsection/active-users?searchString=${searchString}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					setUserLoading(false);
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					const arr = data.filter((u: UserInterface) => {
						return u.id !== user.id;
					});
					let filteredList: any = [];
					arr.map((auser: any) => {
						let name = "";
						name = auser?.firstName + " " + auser?.lastName;
						filteredList.push({ ...auser, name });
					});
					setActiveUsers(filteredList);
					setUserLoading(false);
				}
			})
			.catch((err) => {
				setUserLoading(false);
				setActiveUsers([]);
			});
	};
	useEffect(() => {
		if (
			searchString !== "" &&
			searchString.toString() !== "0" &&
			isAutocompleteFocused
		) {
			getActiveUsers();
		} else {
			setUserLoading(false);
			setActiveUsers([]);
		}
		const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		if (!timezone) {
			setTimezone(
				Constants.timezoneList.find((tz) => tz.value === currTimezone) ??
				Constants.timezoneList[0]
			);
		}

		// CHECK ACTIVE SUBSCRIPTION & SET WHETHER USER CAN SCHEDULE A SESSIONS OR NOT
		if (
			user.sessionCount &&
			ALLOWED_SESSION_FOR_SUBSCRIPTION > user.sessionCount &&
			isSubscribed
		) {
			setSubscriptionActive(true);
		} else {
			// REMOVED THE CONDITION FOR THE DEPLOYMENT PURPOSE AS WEBHOOK ARE NOT WORKING
			setSubscriptionActive(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchString]);

	const handleBodyClick = () => {
		setShowNotfyMsg(false)
	}

	useEffect(() => {
		document.body.addEventListener('click', handleBodyClick)

		return () => {
			document.body.removeEventListener('click', handleBodyClick)
		}
	}, []);

	useEffect(() => {
		if (startDate !== null) {
			const _endDate = addMinutes(new Date(startDate), duration);
			if (checkIsValidDate(_endDate)) {
				if (showAdvanceView) {
					setEndDate(_endDate);

					if (endTime !== null) {
						let updatedEndTime = new Date(_endDate);
						const timeDifference = (_endDate.getTime() - endTime.getTime()) / (1000 * 60);

						if (timeDifference > -30) {
							const minutes = updatedEndTime.getMinutes();

							if (minutes !== 0 && minutes !== 30) {
								if (minutes < 30) {
									updatedEndTime = addMinutes(updatedEndTime, 30 - minutes);
								} else {
									updatedEndTime = addMinutes(updatedEndTime, 60 - minutes);
								}
								setEndTime(updatedEndTime);
							} else {
								setEndTime(endTime);
							}

							setEndTime(updatedEndTime);
							setEndDate(updatedEndTime);
						} else {
							const minutes = updatedEndTime.getMinutes();
							if (minutes !== 0 && minutes !== 30) {
								if (minutes < 30) {
									updatedEndTime = addMinutes(updatedEndTime, 30 - minutes); // Add minutes to reach the next 30-minute mark
								} else {
									updatedEndTime = addMinutes(updatedEndTime, 60 - minutes); // Add minutes to reach the next full hour
								}
								setEndTime(updatedEndTime);
							} else {
								setEndTime(endTime);
							}
							setEndTime(updatedEndTime);
							setEndDate(updatedEndTime);

						}
					} else {
						let updatedEndTime = new Date(_endDate);
						const minutes = updatedEndTime.getMinutes();

						if (minutes !== 0 && minutes !== 30) {
							if (minutes < 30) {
								updatedEndTime = addMinutes(updatedEndTime, 30 - minutes);
							} else {
								updatedEndTime = addMinutes(updatedEndTime, 60 - minutes);
							}
							setEndTime(updatedEndTime);
						} else {
							setEndTime(endTime);
						}

						setEndTime(updatedEndTime);
					}
				}

				setEndsAt(
					_endDate.toLocaleTimeString("en-US", {
						day: "numeric",
						year: "numeric",
						month: "numeric",
						hour: "numeric",
						minute: "numeric",
					})
				);
			}
		}
	}, [startDate, duration, showAdvanceView]);

	useEffect(() => {
		let _maxTime = null;
		let _minTime = null;
		if (startDate !== null) {
			if (startDate?.getDate() === endDate?.getDate()) {
				const maxDate = set(startDate, {
					hours: 23,
					minutes: 59,
					seconds: 59,
				});
				_maxTime = min([maxDate, addMinutes(startDate, 240)]);
				_minTime = addMinutes(startDate, 30);
			} else {
				_maxTime = addMinutes(startDate, 240);
				if (endDate !== null) {
					_minTime = set(endDate, { hours: 0, minutes: 0, seconds: 0 });
				}
			}
		}
		setMaxTime(_maxTime);
		setMinTime(_minTime);
	}, [endDate, startDate]);

	useEffect(() => {
		if (timezone) {
			let _startDate = null;
			if (startDate) {
				_startDate = startDate.toLocaleString("en-US", {
					timeZone: timezone.value,
				});
			} else {
				_startDate = new Date().toLocaleString("en-US", {
					timeZone: timezone.value,
				});
			}

			const reminder = 30 - (new Date(_startDate).getMinutes() % 30);
			const date = new Date(_startDate);
			const minutes = date.getMinutes();
			const isOnInterval = minutes % 30 === 0;
			if (chips && chips.length >= 5) {
				if (!isOnInterval) {
					setStartDate(addMinutes(date, 15));
					setStartTime(addMinutes(date, 15));
				} else {
					setStartDate(date);
					setStartTime(date);
				}
			} else {
				if (!isOnInterval && reminder > 0) {
					setStartDate(addMinutes(date, reminder));
					setStartTime(addMinutes(date, reminder));
				} else {
					setStartDate(date);
					setStartTime(date);
				}
			}
			setMinDate(new Date(_startDate));
		}
	}, [timezone, chips]);

	useEffect(() => {
		//This useEffected needed because it synchronosly changes validsession variable
		//which useState does not because it is async.
	}, [validSession]);

	const isInvalid = () => {
		if (
			sessionName === "" ||
			timezone === "" ||
			startDate === null ||
			startTime === null ||
			(showAdvanceView && endDate === null ||
			endTime === null )||
			minTime === null
		) {
			return true;
		}
		else if (
			(chips?.length + 1 >= 6 && hubServer === "") ||
			(chips?.length + 1 >= 6 && showHubServerErrorMsg)
			||
			(chips?.length + 1 >= 6 && !user?.subscriptionInformationDTOs?.some(
				(sub) =>
					sub.name === "Premium" &&
					(sub.status === "ACTIVE" || sub.status === "PAUSED")
			))
		) {
			return true;
		}
		else if (chips.length < 1) {
			return true;
		} else if (showAdvanceView && errorDate) {
			return true;
		} else if (sessionName.length > 64 || desc.length > 250) {
			return true;
		} else if (isScheduling) {
			return true;
		} else {
			return false;
		}
	};
	const handleAutocompleteChange = (
		event: any,
		item: readonly UserInterface[] | null
	) => {
		if (item && item?.length >= 5 && (user?.subscriptionInformationDTOs?.some(
			(sub) =>
				sub.name === "Premium" &&
				(sub.status === "ACTIVE" || sub.status === "PAUSED")
		))) {
			let hubServerArray = [];
			hubServerArray = item.map((item: any) => item?.deviceId?.toLowerCase());
			setShowHubServerDropdown(true);
			getHubServerPrefernce([...hubServerArray, user?.deviceId?.toLowerCase()]);
		} else {
			setShowHubServerDropdown(false);
		}

		if (Array.isArray(item)) {
			const validItems = item.slice(0, allowedUser);

			setChips([...validItems]);
		} else {
			console.error("Invalid item provided to handleAutocompleteChange:", item);
		}
		setActiveUsers([]);
		setSearchString("");
	};

	useEffect(() => {
		if (searchString === "" || searchString === "0") setActiveUsers([]);
	}, [activeUsers, searchString]);

	useEffect(() => {
		if (startDate) {
			const currentDate = new Date().toLocaleString("en-US", {
				timeZone: timezone.value,
			});
			const diffInMinutes = differenceInMinutes(
				startDate,
				new Date(currentDate)
			);

			if (diffInMinutes < 15 && chips.length >= 5) {
				setShowHubServerErrorMsg(true);
				return;
			} else {
				setShowHubServerErrorMsg(false);
			}
		}
	}, [startDate, chips]);

	const handleSubmit = (allowPastSession: boolean = false) => {
		setIsScheduling(true);

		let newArray = null;

		if (showHubServerDropdown && hubServer && hubServersList) {
			newArray = rearrangeArray(hubServer, hubServersList);
		}

		let min = duration;
		// To check whether its a past session or not. If yes then get confirmation.
		if (
			startDate !== null &&
			startDate.getTime() < new Date().getTime() &&
			!allowPastSession
		) {
			let _Date = addMinutes(new Date(startDate), duration);
			const new_time = new Date(_Date);
			const new_time_stamp = new_time.getTime();
			if (new_time_stamp <= new Date().getTime()) {
				setPastSessionOpen(true);
			}
			setPastSessionOpen(true);
			return false;
		} else if (showAdvanceView) {
			if (startDate !== null && endDate !== null) {
				min = differenceInMinutes(endDate, startDate);
			}
		}
		chips.push(user);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/create-sessions`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				sessionName: sessionName.trim(),
				createdBy: user.id,
				dateTime: zonedTimeToUtc(startDate as Date, timezone.value as string),
				duration: min,
				sessionDescription: desc.trim(),
				timezone: timezone.value,
				attendees: chips,
				livestreamStatus: loading,
				hubpreferences: newArray,
			}),
		}).then((res) => {
			if (res.status === 502) {
				navigate("/defaultPage");
			} else if (res.status >= 400) {
				let showAlert = true;
				let alertStatus = false;
				let alertMsg = "Error! Please try again..";
				props.handleClose(showAlert, alertStatus, alertMsg);
			} else {
				// UPDATE THE SESSION COUNT STORED IN USER CONTEXT WHENEVER ANY NEW SESSION IS SCHEDULED

				//User.fetchUserInfo(() => {});
				setIsScheduling(false);
				let showAlert = true;
				let alertStatus = true;
				let alertMsg = `${i18n.jamSession.success}`;
				props.handleClose(showAlert, alertStatus, alertMsg);
			}
		});
	};

	const getMaxDate = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 240);
		}
	};

	const getMinDate = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 30);
		}
	};

	const getMinStartTime = () => {
		return null;
	};

	const handleSessionDialogClose = () => {
		setShowAdvanceView(false);
		setPastSessionOpen(false);
		setSessionName("");
		setDesc("");
		setStartDate(new Date());
		setEndDate(addMinutes(new Date(), 30));
		setEndTime(addMinutes(new Date(), 30));
		setEndsAt(addMinutes(new Date(), 30));
		props.handleClose();
		setLoading(false);
	};

	useEffect(() => {
		setVisible(
			loading
				? i18n.jamSession.dialog.liveStreamingStatusTrue
				: i18n.jamSession.dialog.liveStreamingStatusFalse
		);
	}, [loading]);

	const handlePopupCloseClick = () => {
		setPastSessionOpen(false);
		setIsScheduling(false);
	};

	function handlePopupSubmitClick(this: any) {
		handleSubmit(true);
	}

	const filterOptions = (options: any, { inputValue }: any) => {
		const input = inputValue.trim();
		return options.filter((option: any) =>
			option.name.toLowerCase().includes(input.toLowerCase())
		);
	};

	// Debounce function
	const debounce = (func: Function, delay: number) => {
		let timeoutId: NodeJS.Timeout;
		return (...args: any[]) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => func(...args), delay);
		};
	};

	const debouncedSetSearchString = debounce((value: string) => {
		setSearchString(value);
	}, 300);

	const handleSearchStringChange = (event: any, value: string) => {
		if (event && event.target && event.target.value !== searchString) {
			debouncedSetSearchString(event.target.value);
		}
	};

	const handleFocus = () => {
		setIsAutocompleteFocused(true);
	};

	const handleBlur = () => {
		setIsAutocompleteFocused(false);
	};

	useEffect(() => {
		if (!isAutocompleteFocused) {
			setSearchString("");
		}
	}, [isAutocompleteFocused]);

	const getHubServerPrefernce = (prefArr: any) => {
		setIsHubServerListFetching(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/gethubpreference`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(prefArr),
		})
			.then((response) => {
				if (response.status >= 400) {
					console.log("Failed to fetch API");
					setLoading(false);
				} else {
					return response.json();
				}
			})
			.then((result) => {
				if (
					hubServersList &&
					hubServersList?.length > 0 &&
					result[0]?.regionName !== hubServersList[0]?.regionName
				) {
					updateAlertStatus(true, true, "The hub server preference has changed");
					setHubServer("");
				}
				setHubServersList(result);
				if (result && result[0] && result[0].regionName) {
					setHubServer(result[0].regionName);
				}
				setIsHubServerListFetching(false);
			})
			.catch((error) => {
				console.error("Error fetching user data:", error);
				setIsHubServerListFetching(false);
			});
	};

	const [selectedRegions, setSelectedRegions] = useState<number[]>([]);

	const handleSelectedChange = (event: any) => {
		const selectedValues = event.target.value;
		setSelectedRegions(selectedValues);
	};

	const rearrangeArray = (selectedRegionName: any, hubServersList: any) => {
		// Find the index of the selected region
		const selectedIndex = hubServersList.findIndex(
			(server: any) => server.regionName === selectedRegionName
		);

		if (selectedIndex === -1) return [...hubServersList]; // Return a copy of the original array if selected region is not found

		// Extract the selected region
		const [selectedServer] = hubServersList.slice(
			selectedIndex,
			selectedIndex + 1
		);

		// Create a new array with the selected server at the beginning and the rest of the items
		const remainingServers = [
			...hubServersList.slice(0, selectedIndex),
			...hubServersList.slice(selectedIndex + 1),
		];

		return [selectedServer, ...remainingServers];
	};

	return (
		<>
			<Dialog
				sx={{
					border: "1px solid #888888",
					color: "customColor.gray4",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "8px",
					//background: `linear-gradient(0deg, #888888, #888888),linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
				}}
				fullWidth
				maxWidth="lg"
				onClose={(event, reason) => {
					if (reason && reason === "backdropClick") return;
					props.handleClose();
				}}
				open={props.open && subscriptionActive}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={props.handleClose}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%", position: "absolute", top: "10px" }}
						>
							<img src={Close} alt="close" />
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							variant="h2"
							sx={{
								color: "customColor.black",
							}}
						>
							{i18n.jamSession.dialog.dialogTitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid
						container={true}
						columns={{ xs: 12, sm: 12, md: 12 }}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<FormControl sx={{ width: "100%" }}>
							<Grid item={true} xs={12}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<FormHelperText sx={{ ml: 0 }}>
										<Typography variant="body4" color="customColor.label">
											{i18n.jamSession.dialog.title}
											<span style={{ color: "red" }}>*</span>
										</Typography>
									</FormHelperText>
								</Box>
								<TextField
									sx={{
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
											backgroundColor: "customColor.gray1",
										},
									}}
									fullWidth
									variant="outlined"
									value={sessionName}
									onChange={(event) => {
										const inputValue = event.target.value;
										if (inputValue.length <= 64) {
										  setSessionName(inputValue.trimStart());
										}
									  }}
									  error={sessionName.length >= 64} 
									  helperText={sessionName.length >= 64 ? "Enter maximum 64 characters" : ""} 
								></TextField>
								<FormHelperText
									sx={{
										textAlign: "right",
									}}
								>
									<Typography variant="body2" color="error.main">
										{sessionName?.length > 64
											? "Enter maximum 64 characters"
											: ""}
									</Typography>
								</FormHelperText>
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText sx={{ ml: 0 }}>
									<Typography variant="body4" color="customColor.label">
										{i18n.jamSession.dialog.description}
									</Typography>
								</FormHelperText>
								<TextField
									sx={{
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
										},
										backgroundColor: "customColor.gray1",
									}}
									multiline
									rows={1}
									fullWidth
									variant="outlined"
									value={desc}
									onChange={(event) => {
										const inputValue = event.target.value;
										if (inputValue.length <= 250) {
										  setDesc(inputValue.trimStart());
										}
									  }}
									  error={desc.length >= 250} 
									  helperText={desc.length >= 250 ? "Enter maximum 250 characters" : ""} 
								></TextField>
								<FormHelperText
									sx={{
										textAlign: "right",
									}}
								>
									<Typography variant="body2" color="error.main">
										{desc?.length > 250 ? "Enter maximum 250 characters" : ""}
									</Typography>
								</FormHelperText>
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText sx={{ ml: 0 }}>
									<Typography variant="body4" color="customColor.label">
										{i18n.jamSession.dialog.attendees}
										<span style={{ color: "red" }}>*</span>
									</Typography>
								</FormHelperText>
								<Autocomplete
									multiple
									className="session-form"
									classes={classes}
									options={activeUsers.filter(
										(user) => !chips.some((chip) => chip.id === user.id)
									)}
									// getOptionLabel={(option) =>
									// 	`${option.firstName} ${option.lastName}`
									// }
									//to change acc to nickname privacy
									getOptionLabel={(option) => `${option?.name}`}
									sx={{
										backgroundColor: "customColor.gray1",
									}}
									filterOptions={filterOptions}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									loading={userLoading}
									noOptionsText={
										searchString !== "" && activeUsers.length === 0
											? "No attendees present."
											: ""
									}
									onFocus={handleFocus}
									onBlur={handleBlur}
									//onChange={handleAutocompleteChange}

									onChange={(event, value) => {
										// Restrict to 5 attendees
										if (allowedUser - 1) {
											if (value.length > allowedUser - 1) {
												updateAlertStatus(
													true,
													false,
													"You can only select up to " +
													allowedUser +
													" attendees."
												);
												value.pop();
												return;
											}
										}

										handleAutocompleteChange(event, value);
									}}
									onInputChange={handleSearchStringChange}
									renderTags={(values, getTagProps) =>
										chips.map((chip, index) => {
											const { key, className, ...tagProps } = getTagProps({
												index: index,
											});
											return (
												<Chip
													key={key}
													avatar={<img src={Profile} alt="profile" />}
													className={`${className} label-chip`}
													// label={`${chip.firstName} ${chip.lastName}`}
													//change for nickname privacy
													label={`${chip?.name}`}
													sx={{
														backgroundColor: "customColor.blue",
														fontStyle: theme.typography.subtitle2.fontStyle,
														fontSize: theme.typography.subtitle2.fontSize,
														lineHeight: theme.typography.subtitle2.lineHeight,
														fontWeight: theme.typography.subtitle2.fontWeight,
														color: "secondary.main",
													}}
													{...tagProps}
												/>
											);
										})
									}
									renderInput={(params) => <TextField {...params} />}
									// renderInput={(params) => (
									// 	<TextField
									// 	  {...params}
									// 	  InputProps={{
									// 		...params.InputProps,
									// 		endAdornment: (
									// 		  <>
									// 			{userLoading && <CircularProgress/>}
									// 		  </>
									// 		),
									// 	  }}
									// 	/>
									//   )}
									open={activeUsers.length > 0}
								/>
							</Grid>

							{!showAdvanceView ? (
								// <div style={{ display: "flex", flexDirection: "row" }}>
								<Grid container columnSpacing={1}>
									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.startDate}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												openTo="day"
												views={["year", "month", "day"]}
												inputFormat="MM/dd/yyyy"
												// minDate={minDate}
												value={startDate}
												onChange={(newValue: Date | null) => {
													if (newValue === null) {
														setStartDate(null);
													} else if (checkIsValidDate(newValue)) {
														if (startTime) {
															newValue.setHours(startTime.getHours());
															newValue.setMinutes(startTime.getMinutes());
															newValue.setSeconds(startTime.getSeconds());
															newValue.setMilliseconds(startTime.getMilliseconds());
														} else {
															newValue.setHours(0);
															newValue.setMinutes(0);
															newValue.setSeconds(0);
															newValue.setMilliseconds(0);
														}
														setStartDate(newValue);
													} else {
														setStartDate(null);
													}
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															"&.MuiTextField-root": {
																backgroundColor: "customColor.gray1",
																width: "100%",
															},
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight:
																	theme.typography.subtitle1.fontWeight,
															},
														}}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.startTime}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<LocalizationProvider
											sx={{ width: "100%" }}
											dateAdapter={AdapterDateFns}
										>
											<TimePicker
												value={startTime}
												onChange={(newValue: Date | null) => {
													setStartTime(newValue)
													if (newValue !== null && startDate) {
														if (checkIsValidDate(newValue)) {
															// Update startDate with the selected time
															const updatedDate = new Date(startDate);
															updatedDate.setHours(newValue.getHours());
															updatedDate.setMinutes(newValue.getMinutes());
															updatedDate.setSeconds(newValue.getSeconds());
															updatedDate.setMilliseconds(newValue.getMilliseconds());
															setStartDate(updatedDate);
														}
													}
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															"&.MuiTextField-root": {
																backgroundColor: "customColor.gray1",
																width: "100%",
															},
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight: theme.typography.subtitle1.fontWeight,
															},
														}}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.duration}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<Select
											labelId="demo-simple-select-label"
											value={duration}
											onChange={(event: any) => {
												setDuration(event.target.value);
											}}
											sx={{
												backgroundColor: "customColor.gray1",
												color: "customColor.black",
												width: "100%",
											}}
										>
											{durationList.map((duration) => (
												<MenuItem key={duration.value} value={duration.value}>
													{duration.label}
												</MenuItem>
											))}
										</Select>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.timeZone}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<Autocomplete
											options={Constants.timezoneList}
											value={timezone}
											defaultValue={Constants.timezoneList[0]}
											onChange={(event: any, newValue: any) => {
												setTimezone(newValue);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													sx={{
														input: {
															color: "customColor.black",
															fontSize: theme.typography.subtitle1.fontSize,
															fontWeight: theme.typography.subtitle1.fontWeight,
														},
														"&.MuiTextField-root": {
															backgroundColor: "customColor.gray1",
														},
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							) : (
								<>
									<Grid container columnSpacing={1}>
										<Grid item={true} xs={4}>
											<Grid>
												<FormHelperText sx={{ ml: 0 }}>
													<Typography variant="body4" color="customColor.label">
														{i18n.jamSession.dialog.startDate}
														<span style={{ color: "red" }}>*</span>
													</Typography>
												</FormHelperText>
											</Grid>
											<Grid>
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<DatePicker
														openTo="day"
														views={["year", "month", "day"]}
														inputFormat="MM/dd/yyyy"
														// minDate={minDate as Date}
														value={startDate}
														onChange={(newValue: Date | null) => {
															if (checkIsValidDate(newValue)) {
																if (startTime && newValue) {
																	newValue.setHours(startTime.getHours());
																	newValue.setMinutes(startTime.getMinutes());
																	newValue.setSeconds(startTime.getSeconds());
																	newValue.setMilliseconds(startTime.getMilliseconds());
																}
																setStartDate(newValue);
																setErrorDate(false);
															}else{
																setErrorDate(true);
															}
														}}
														renderInput={(params) => (
															<TextField
																{...params}
																sx={{
																	input: {
																		color: "customColor.black",
																		fontSize:
																			theme.typography.subtitle1.fontSize,
																		fontWeight:
																			theme.typography.subtitle1.fontWeight,
																	},
																	"&.MuiTextField-root": {
																		backgroundColor: "customColor.gray1",
																		width: "100%",
																	},
																}}
															/>
														)}
													/>
												</LocalizationProvider>
											</Grid>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.startTime}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePicker
													value={startTime}
													onChange={(newValue: Date | null) => {
														setStartTime(newValue)
														if (newValue !== null && startDate) {
															if (checkIsValidDate(newValue)) {
																// Update startDate with the selected time
																const updatedDate = new Date(startDate);
																updatedDate.setHours(newValue.getHours());
																updatedDate.setMinutes(newValue.getMinutes());
																updatedDate.setSeconds(newValue.getSeconds());
																updatedDate.setMilliseconds(newValue.getMilliseconds());
																setStartDate(updatedDate);
															}
														}
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																	width: "100%",
																},
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight: theme.typography.subtitle1.fontWeight,
																},
															}}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.timeZone}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<Autocomplete
												options={Constants.timezoneList}
												value={timezone}
												defaultValue={Constants.timezoneList[0]}
												onChange={(event: any, newValue: any) => {
													setTimezone(newValue);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight:
																	theme.typography.subtitle1.fontWeight,
															},
															"&.MuiTextField-root": {
																backgroundColor: "customColor.gray1",
															},
														}}
													/>
												)}
											/>
										</Grid>
									</Grid>

									<Grid container columnSpacing={1}>
										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.endDate}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													inputFormat="MM/dd/yyyy"
													maxDate={getMaxDate()}
													minDate={getMinDate()}
													value={endDate}
													onChange={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setEndDate(newValue);
														} else if (newValue === null) {
															setEndDate(newValue);
														}
													}}
													onError={(reason, value) => {
														if (reason) {
															setErrorDate(true);
														} else {
															setErrorDate(false);
														}
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																	width: "100%",
																},
															}}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.endTime}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePicker
													value={endTime}
													onChange={(newValue) => {
														setEndTime(newValue)
														if (newValue !== null && endDate) {
															if (checkIsValidDate(newValue)) {
																// Update startDate with the selected time
																const updatedDate = new Date(endDate);
																updatedDate.setHours(newValue.getHours());
																updatedDate.setMinutes(newValue.getMinutes());
																updatedDate.setSeconds(newValue.getSeconds());
																updatedDate.setMilliseconds(newValue.getMilliseconds());
																setEndDate(updatedDate);
																setErrorDate(false);
															} else {
																setErrorDate(true);	
															}
														}
													}}
													renderInput={(params) => <TextField {...params} />}
													minTime={minTime}
													maxTime={maxTime}
												/>

											</LocalizationProvider>
										</Grid>

										{showAdvanceView ? (
											""
										) : (
											<Grid item={true} xs={4}>
												<FormHelperText sx={{ ml: 0 }}>
													<Typography variant="body4" color="customColor.label">
														{i18n.jamSession.dialog.timeZone}
														<span style={{ color: "red" }}>*</span>
													</Typography>
												</FormHelperText>
												<Autocomplete
													options={Constants.timezoneList}
													value={timezone}
													defaultValue={Constants.timezoneList[0]}
													onChange={(event: any, newValue: any) => {
														setTimezone(newValue);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																},
															}}
														/>
													)}
												/>
											</Grid>
										)}
									</Grid>
								</>
							)}
						</FormControl>
						<Grid xs={12} sx={{ marginTop: "10px", marginLeft: "11px" }}>
							<FormControlLabel
								control={
									<IOSSwitch
										checked={loading}
										onChange={() => setLoading(!loading)}
									/>
								}
								label={
									<span style={{ fontSize: "1rem", color: "#5C5C5C" }}>
										{visible}
									</span>
								}
							/>
						</Grid>

						<Grid container xs={12} md={12} lg={12}>
							<Grid item xs={9}>
								<Typography
									sx={{
										marginTop: "15px",
										color: "customColor.gray5",
									}}
									variant="subtitle1"
								>
									{!showAdvanceView
										? `Ends at ${endsAt}`
										: endDate !== null
											? `Ends at ${lightFormat(
												endDate,
												"MM/dd/yyyy - hh:mm aaa"
											)}`
											: ""}
								</Typography>
								<IconButton
									aria-label="close dialogbox"
									onClick={() => setShowAdvanceView(!showAdvanceView)}
									onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
										event.preventDefault()
									}
									edge="end"
									sx={{ marginTop: "5px" }}
								>
									<Typography
										sx={{ color: "customColor.link" }}
										variant="subtitle1"
									>
										{showAdvanceView ? "Set Duration" : "Set End Date"}
									</Typography>
								</IconButton>
							</Grid>

							<Grid item xs={3}>
								{showHubServerDropdown && (
									<>
										{showNotfyMsg && (
											<Card
												sx={{
													width: "350px",
													position: "absolute",
													top: "50%",
													right: "1%",
													zIndex: "1000",
												}}
												onMouseLeave={() => setShowNotfyMsg(false)}
											>
												<CardContent>
													<p style={{ fontSize: "16px" }}>
														Based on our calculations these server locations
														should be best for this group.
														<span
															onClick={() =>
																window.open(
																	"https://lutefish.com/serverlocations",
																	"_blank"
																)
															}
															style={{ color: "#0A6BFE", cursor: "pointer" }}
														>
															{" "}
															Click here
														</span>{" "}
														to learn more.
													</p>
												</CardContent>
											</Card>
										)}
										<Grid item={true} xs={12}>
											<FormHelperText>
												<Typography variant="body4" color="customColor.label">
													Select Server Location
												</Typography>
												<span
													onMouseEnter={() => setShowNotfyMsg(true)}
													style={{ color: "red" }}
												>
													{" "} ?
												</span>
											</FormHelperText>
											<Select
												labelId="demo-simple-select-label"
												value={hubServer}
												onChange={(event: any) => {
													setHubServer(event.target.value);
												}}
												sx={{
													backgroundColor: "customColor.gray1",
													color: "customColor.black",
													width: "100%",
												}}
												renderValue={(value: string) => value}
											>
												{isHubServerListFetching ? (
													<MenuItem>Loading...</MenuItem>
												) : (
													hubServersList &&
													hubServersList.map((server: any, index: number) => (
														<MenuItem key={server?.id} value={server?.regionName}>
															{index + 1}. {server?.regionName}
														</MenuItem>
													))
												)}
											</Select>
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						xs={12}
						// sm={8}
						// md={3}
						columnSpacing={1}
						direction={"row"}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							padding: "0 16px 16px 0",
						}}
					>
						<Grid item xs={9}>
							{showHubServerErrorMsg && (
								<Typography sx={{ color: "#FD4B24" }} variant="body1">
									Setting up a room for a large group can take a little time .
									Please schedule your session to start at least 15 minutes from
									now.
								</Typography>
							)}
						</Grid>
						<Grid item xs={3}>
							<Grid
								container
								xs={12}
								columnSpacing={1}
								direction={"row"}
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									padding: "0 16px 16px 0",
								}}
							>
								<Grid item>
									<Button
										sx={{
											color: "customColor.orange",
											borderRadius: "24px",
											borderColor: "customColor.orange",
											"&:hover": {
												borderColor: "customColor.orange",
											},
										}}
										size="large"
										variant="outlined"
										onClick={handleSessionDialogClose}
									>
										<Typography variant="h6">{i18n.dialog.cancel}</Typography>
									</Button>
								</Grid>

								<Grid item>
									<Button
										sx={{
											borderRadius: "24px",
											backgroundColor: "customColor.orange",
											color: "secondary",
											"&:hover": {
												backgroundColor: "customColor.orange",
												color: "secondary",
											},
											"& .MuiButton-endIcon": {
												mr: 0,
											},
										}}
										size="large"
										variant="contained"
										type="submit"
										disabled={isInvalid()}
										onClick={() => handleSubmit()}
										endIcon={<img src={RightAarrow} alt="Right Arrow" />}
										className="submit-button"
									>
										<Typography variant="h6">
											{isScheduling
												? i18n.jamSession.dialog.schedulingBtn
												: i18n.jamSession.dialog.scheduleBtn}
										</Typography>
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			<Dialog
				sx={{
					border: "1px solid #888888",
					color: "customColor.gray4",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "8px",
					// background: `linear-gradient(0deg, #888888, #888888),linear-gradient(0deg, #FFFFFF, #FFFFFF)`
				}}
				// fullWidth
				maxWidth="md"
				onClose={(event, reason) => {
					if (reason && reason === "backdropClick") return;
					props.handleClose();
				}}
				open={subscriptionActive === false}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={props.handleClose}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%", position: "absolute", top: "10px" }}
						>
							<img src={Close} alt="close" />
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							variant="h2"
							sx={{
								color: "customColor.black",
							}}
						>
							{i18n.jamSession.dialog.subscriptionExpiredTitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent className="center-align">
					<Typography variant="h5">
						{i18n.jamSession.dialog.subscriptionExpiredDesc}
					</Typography>
				</DialogContent>
				<DialogActions className="center-align">
					<Button
						sx={{
							color: "customColor.orange",
							borderRadius: "24px",
							borderColor: "customColor.orange",
							"&:hover": {
								borderColor: "customColor.orange",
							},
						}}
						size="large"
						variant="outlined"
						onClick={() => {
							navigate("/plans");
						}}
					>
						<Typography variant="h6">{i18n.dialog.viewPlans}</Typography>
					</Button>
				</DialogActions>
			</Dialog>

			{validSession ? (
				<div>
					<Dialog
						open={pastSessionOpen}
						onClose={() => {
							return false;
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						fullWidth
						maxWidth="sm"
					>
						<DialogTitle id="alert-dialog-title">
							{/* <Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={handlePopupCloseClick}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%" }}
							>
								<img src={Close} alt="close" />
							</IconButton>
						</Box> */}
							{/* {"Use Google's location service?"} */}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{i18n.jamSession.dialog.confirmPastSession}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								className="cancel-button"
								variant="outlined"
								onClick={handlePopupCloseClick}
							>
								{i18n.jamSession.dialog.confirmPastSessionBtnDecline}
							</Button>
							<Button
								className="submit-button"
								variant="contained"
								onClick={handlePopupSubmitClick}
								autoFocus
							>
								{i18n.jamSession.dialog.confirmPastSessionBtnAllow}
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			) : (
				<div>
					<Dialog
						open={pastSessionOpen}
						onClose={() => {
							return false;
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						fullWidth
						maxWidth="sm"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Cannot Schedule Session
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								className="cancel-button"
								variant="outlined"
								onClick={handlePopupCloseClick}
							>
								Cancel
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			)}
		</>
	);
};
export default ScheduleSession;

import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material";
import StatCard from "./CardComponent/StatCard";
import { useEffect, useState } from "react";
import ArrowDownIcon from "./ArrowDown";
import ErrorAlert from "../Error/ErrorAlert";
import TotalUser from "../../images/profile-2usertotal-users.svg";
import Violations from "../../images/security-userViolations.svg";
import PaidUsers from "../../images/profile-tickpaid-users.svg";
import InactiveUsers from "./CardComponent/InactiveUsers";
import LineCharts from "./Chart/LineChart";
import Barchart from "./Chart/StackedBarChart";

const AdminDashboard = () => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedYear, setSelectedYear] = useState<number>(
		new Date().getFullYear()
	);
	const [totalUsers, setTotalUsers] = useState<number>(0);
	const [totalViolations, setTotalViolations] = useState<number>(0);
	const [totalPaidUser, setTotalPaidUsers] = useState<number>(0);
	const [datanewusers, setDatanewusers] = useState<DataItem[]>([]);
	const [monthlyactive, setMontlyActive] = useState<DataItem[]>([]);
	const [monthlyPaid, setMonthlyPaid] = useState<DataItem[]>([]);

	const currentYear: number = new Date().getFullYear();
	const years: number[] = Array.from(
		{ length: currentYear - 2022 },
		(_, index) => 2023 + index
	);

	const token = localStorage.getItem("sessionId");
  

	useEffect(() => {
		getTotalUsers();
		getTotalReportedUser();
		getMonthlyNewUsers();
		getMonthlyActiveUsers();
		getTotalPaidUsers();
		getMonthlyPaidUsers();
	}, [selectedYear]);

	interface DataItem {
		month: string;
		totalUsers: any;
	}

	useEffect(() => {
	}, [datanewusers, monthlyactive]);

	const getTotalUsers = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/total-users`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setTotalUsers(result);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getTotalPaidUsers = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/total-paid-users`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setTotalPaidUsers(result);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getTotalReportedUser = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/total-violations`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setTotalViolations(result);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getMonthlyNewUsers = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/monthly-new-users/${selectedYear}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				let transformedData: Array<DataItem> = Object.keys(result).map(
					(key: any) => ({
						month: key,
						totalUsers: result[key],
					})
				);

				setDatanewusers(transformedData);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getMonthlyActiveUsers = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/monthly-active-users/${selectedYear}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				let transformedData: Array<DataItem> = Object.keys(result).map(
					(key: any) => ({
						month: key,
						totalUsers: result[key],
					})
				);

				setMontlyActive(transformedData);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getMonthlyPaidUsers = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/monthly-paid-users/${selectedYear}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				let transformedData: Array<DataItem> = Object.keys(result).map(
					(key: any) => ({
						month: key,
						totalUsers: result[key],
					})
				);

				setMonthlyPaid(transformedData);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleYearSelect = (year: number) => {
		setSelectedYear(year);
		handleClose();
	};

	return (
		<>
			<Grid
				className="center-align mainPanel"
				spacing={{ xs: 1 }}
				sx={{ marginTop: "20px", margin:"auto" }}
			>
				<Grid
					container={true}
					className="column mainPanel"
					sx={{ marginBottom: "5vh"}}
					rowSpacing={{ xs: 1 }}
				>
					<Grid item={true}>
						<Grid
							container={true}
							columns={{ xs: 12 }}
							direction="row"
						>
							<Grid item={true} xs={3} sx={{paddingTop: "30px", paddingBottom:"20px"}}>
								<Typography
									sx={{
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "36px",
										lineHeight: "49px",
										color: "#000000",
										marginBottom: "10px"
									}}
								>
									Summary
								</Typography>
							</Grid>
							<Grid
								container={true}
								sx={{ justifyContent: "space-between" }}
								
							>
								<Grid item >
									<StatCard
										title="Total Users"
										value={totalUsers}
										icon={TotalUser}
										isDuration={false}
									/>
								</Grid>
								<Grid item >
									<InactiveUsers />
								</Grid>
								<Grid item >
									<StatCard
										title="Total Paid Users"
										value={totalPaidUser}
										icon={PaidUsers}
										isDuration={false}
									/>
								</Grid>
								<Grid item >
									<StatCard
										title="Total Violations"
										value={totalViolations}
										icon={Violations}
										isDuration={false}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								sx={{
									display: "flex",
									justifyContent: "space-between",
									width: "100%",
								}}
							>
								<Grid item={true} xs={3} sx={{ marginTop: "4vh" }}>
									<Typography
										sx={{
											fontStyle: "normal",
											fontWeight: "500",
											fontSize: "36px",
											lineHeight: "49px",
											color: "#000000",
											marginBottom: "10px"
										}}
									>
										Trending Data
									</Typography>
								</Grid>
								<Grid item sx={{ alignSelf: "center" }}>
									<Button
										variant="outlined"
										endIcon={<ArrowDownIcon />}
										onClick={handleClick}
										sx={{ width: "100px", paddingLeft: "5px" }}
									>
										{selectedYear}
									</Button>
									<Menu
										anchorEl={anchorEl}
										open={Boolean(anchorEl)}
										onClose={handleClose}
									>
										{years.map((year) => (
											<MenuItem
												key={year}
												onClick={() => handleYearSelect(year)}
											>
												{year}
											</MenuItem>
										))}
									</Menu>
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								<Grid item={true} xs={6}>
									<Card style={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}>
										<CardContent>
											<Typography
												variant="h5"
												sx={{
													fontFamily: "Lexend",
													lineHeight: "30px",
													marginBottom: "15px",
													fontSize: "24px",
													color: "#5b5b65",
												}}
											>
												Monthly New Users
											</Typography>
											<LineCharts data={datanewusers} color="#ff702a" />
										</CardContent>
									</Card>
								</Grid>
								<Grid item={true} xs={6}>
									<Card style={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}>
										<CardContent>
											<Typography
												variant="h5"
												sx={{
													fontFamily: "Lexend",
													lineHeight: "30px",
													marginBottom: "15px",
													color: "#5b5b65",
													fontSize: "24px",
												}}
											>
												Monthly Active Users
											</Typography>
											<LineCharts data={monthlyactive} color="#0273e6" />
										</CardContent>
									</Card>
								</Grid>
							</Grid>
							<Grid container spacing={3} mt={1}>
								<Grid item={true} xs={6}>
									<Card style={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}>
										<CardContent>
											<Typography
												variant="h5"
												sx={{
													fontFamily: "Lexend",
													lineHeight: "30px",
													marginBottom: "15px",
													color: "#5b5b65",
													fontSize: "24px",
												}}
											>
												Monthly Paid User
											</Typography>
											<LineCharts data={monthlyPaid} color="#69D9E0" />
										</CardContent>
									</Card>
								</Grid>
								<Grid item={true} xs={6}>
									<Card style={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}>
										<CardContent>
											<Typography
												sx={{
													fontFamily: "Lexend",
													fontWeight: 500,
													fontSize: "24px",
													lineHeight: "30px",
													marginBottom: "15px",
													color: "#5b5b65",
												}}
											>
												Subscription Trends
											</Typography>
											<Barchart selectedYear={selectedYear} />
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default AdminDashboard;

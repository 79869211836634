import { Card, CardContent, CardHeader } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const PostSkeleton = () => {
    return (
        <>
            <Card sx={{ minWidth: 300, flexGrow: 1, boxShadow: '0px 1px 14px 0px rgba(217, 231, 255, 0.77)', mb: 3, borderRadius: '12px', px: 2, py: 2 }}>
                <CardHeader
                    avatar={
                        <Skeleton animation="wave" variant="circular" width={50} height={50} />
                    }
                    action={
                        null
                    }
                    title={
                        <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                        />
                    }
                    subheader={
                        <Skeleton animation="wave" height={10} width="40%" />
                    }
                />
                <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                <CardContent>
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                </CardContent>
            </Card>
        </>
    )
}

export default PostSkeleton
import { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
import User from "../services/user";
// types
import type { ReactNode } from "react";
import type { UserInterface } from "../types/types";

const _storedUser = User.getDefaultUser();
const UserContext = createContext<UserInterface>(_storedUser);

export const useUser = () => useContext(UserContext);

type Props = {
	children: ReactNode;
};

export const UserProvider = (props: Props) => {
	const [user, setUser] = useState<UserInterface>(_storedUser);

	useEffect(() => {
		const handleUserChanged = (newUser: UserInterface) => {
			setUser(newUser);
		};

		User.on("userChanged", handleUserChanged);

		return () => {
			User.on("userChanged", handleUserChanged);
		};
	}, []);

	return (
		<UserContext.Provider value={user}>{props.children}</UserContext.Provider>
	);
};

UserProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

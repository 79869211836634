import { useEffect, useState } from 'react';

export default function useHeight() {
  const [height, setHeight] = useState(window.innerHeight - 75);

	useEffect(() => {
		const onResize = () => {
			setHeight(window.innerHeight - 75);
		};

		window.addEventListener("resize", onResize);
		return () => {
			window.removeEventListener("resize", onResize);
		};
	});

  return height + 'px';
}

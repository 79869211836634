import React, { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    TextField,
    Grid,
    Typography,
    Card,
    CardHeader,
    CardContent
} from "@mui/material";
import LeftPanel from "./LeftPanel";
import i18n from "../l10n/strings.json";
import RightAarrow from "../images/right arrow.svg";
import { useGradient2Styles } from "../theme";
import { title } from "process";

function ForgotPassword() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [cardContainerHeight, setCardContainerheight] = useState<number | undefined>(0);
    const gradient = useGradient2Styles();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const element = document.getElementById("right-pannel-header");
        const rect = element?.getBoundingClientRect();
        setCardContainerheight(rect?.height);

    }, []);

    const handleSubmit = () => {
        setIsLoading(true);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/account/reset-password/init`,
            {
                method: "POST",
                headers: { "Content-Type": "application/json" },
								body: JSON.stringify({ email: email }),
						}
        )
        .then((res) => {
            if (res.status === 200) {
                setIsLoading(false);
              return res;
            } else {
              return res.json(); // or res.blob() or res.formData() based on the expected response type
            }
          })
            .then((data) => {
                if (data.status === 200) {
                    navigate("/accountRegistered", { state: { email: email } });
                }
                else {
                    navigate("/emailNotExistError", { state: { errorKey: data.errorKey, email: email }});
                }
            });
    };

    return (
        <Grid
            container={true}
            columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }} 
        >
            <Grid xs={6} sm={6} md={4} lg={4} xl={4}>
                <LeftPanel />
            </Grid>
            <Grid xs={6} sm={6} md={4} lg={4} xl={4}  className="alignCenterTabLogin">
                <Grid
                    container={true}
                    className="column"
                >
                    <Grid item={true}
                        id="right-pannel-header"
                    >
                        <Grid
                            container={true}
                            className="column"
                            rowSpacing={{ xs: -1, sm: 0, md: 10, xl: 15, xxl: 20 }}
                        >
                            <Grid item={true} xs={12} className="right-align">
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Typography
                                        mr={2}
                                        mt={1}
                                        sx={{
                                            fontFamily: "Lexend",
                                            fontWeight: "500",
                                            fontSize: "1rem",
                                            lineHeight: "20px",
                                            color: "#5D5D65",
                                        }}
                                    >
                                        {i18n.signUp.notMemberMsg}
                                    </Typography>
                                    <Button
                                        component={Link}
                                        to="/signUpEmail"
                                        sx={{
                                            fontWeight: "900",
                                            fontSize: "1rem",
                                            marginRight: "5vw",
                                            backgroundColor: "#ffffff",
                                            borderRadius: "18px",
                                            color: "#0273E6",
                                            border: "1px solid #0273E6",
                                            height: "36px"
                                        }}
                                    >
                                        {i18n.signUp.title}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true}
                        xs={12}
                        className="center-align"
                    >
                        <Card
                            component="section"
                            sx={{
                                position: "relative",
                                top: `calc(50% - ${cardContainerHeight}px)`,
                                boxShadow: "none",
                                paddingBottom: 0,
                                width: "80%",
                                marginTop: "150px",
                            }}

                        >
                            <CardContent className="center-align" sx={{ py: 1, px: 3 }}>
                                <FormControl sx={{ width: "80%", py: 1, px: 2 }}>
                                    <Grid
                                        container={true}
                                        columns={{ xs: 12, sm: 12, md: 12 }}
                                        rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                                    >
                                        <Typography variant="h2" sx={{ color: "customColor.black" }}>{i18n.login.forgotPasswordTitle}</Typography>
                                        <Grid item={true} xs={12} className="center-align">
                                            <Box
                                                sx={{
                                                    display: "inline-flex",
                                                }}
                                            >
                                                <Typography variant="h6"
                                                    sx={{
                                                        textAlign: "start",
                                                        color: "customColor.text"
                                                    }} >
                                                    {i18n.login.forgetPasswordInsturctions}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12}>
                                            <Grid container>
                                                <Grid item={true} xs={12}>
                                                    <Typography variant="subtitle1" sx={{ color: "customColor.label" }}>
                                                        {i18n.login.registeredEmailFieldName}
                                                    </Typography>
                                                    <TextField
                                                        type="email"
                                                        fullWidth
                                                        inputProps={{
                                                            style: {
                                                                borderColor: "customColor.gray2",
                                                                background: "customColor.gray1",
                                                                color: "customColr.black",
                                                            },
                                                        }}
                                                        // error={error || (dirty && !validator.isEmail(email))}
                                                        sx={{ paddingBottom: { xs: 1, sm: 2, md: 3 }, width: "80%" }}
                                                        value={email}
                                                        onChange={(
                                                            event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                                                        ) => setEmail(event.target.value)}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} style={{
                                                    paddingLeft: 0
                                                }}>
                                                    {/* Button styled */}
                                                    <Button
                                                        className={`submit-button ${gradient.gradient3}`}
                                                        sx={{
                                                            background: isLoading || !email
            ? "rgba(128, 128, 128, 0.5)" 
            : "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
                                                            borderRadius: "24px", width: "80%",
                                                            height: "48px",
                                                            fontWeight: "500",
                                                            lineHeight: "22.5px",
                                                        }}
                                                        size="large"
                                                        onClick={handleSubmit}
                                                        disabled={isLoading || !email}
                                                        fullWidth
                                                        variant="contained"
                                                        endIcon={<img src={RightAarrow} alt="Right Arrow" />}
                                                    >
                                                    <Typography>{isLoading ? "Submitting" : i18n.login.submitButton}</Typography>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
export default ForgotPassword;

import React, { useState } from 'react';
import CloseIcon from "../images/close.svg";
import { useNavigate } from 'react-router-dom';


const SocialProfBar: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();


  const handleClose = () => {
    setIsVisible(false);
  };

  const handleUpdateProfile = () => {
    navigate("/home/settings/socialProfileSetting/socialProfileSetting")
};
    

  return (
    <>
      {isVisible && (
        <div style={{ height: '50px', backgroundColor: '#FFF8D6', padding: '9px', color: '#EB2A00', display: 'flex', alignItems: 'center',paddingTop: '15px' }}>
          <div style={{ marginRight: 'auto', marginTop: '5px', fontSize:'medium', fontWeight:'600', marginLeft:'50px' }}>
            <span>Your Social Profile is incomplete</span>
            <p style={{ color: '#877E4C', marginTop: '5px', fontSize:'medium', fontWeight:'500' }}>Please update your Genre, Instrument so you will be searchable by all these parameters</p>
          </div>
          <button onClick={handleUpdateProfile} style={{ backgroundColor: 'white', color: '#FF702A', border: '1px solid #FF702A', padding: '6px 20px', borderRadius: '20px', fontSize: '16px', marginRight: '60px', cursor:'pointer' }}>
            Update Profile
          </button>
          <img src={CloseIcon} alt="close" onClick={handleClose} style={{ cursor: 'pointer' }} />   
        </div>
      )}
    </>
  );
};

export default SocialProfBar;

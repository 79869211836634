import React, { ChangeEvent, useEffect, useState } from "react";
import {
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import {
	Box,
	Button,
	FormControl,
	IconButton,
	InputAdornment,
	Grid,
	Card,
	CardContent,
	Typography,
	OutlinedInput
} from "@mui/material";
import LeftPanel from "./LeftPanel";
import CheckIcon from "../images/check-fill.svg";
import CloseIcon from "../images/close-line.svg";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";
import ErrorMark from "../images/danger.svg";
import i18n from "../l10n/strings.json";
import RightAarrow from "../images/right arrow.svg";

const UPPERCASEREGEX = /.*[A-Z].*/;
const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;

const ResetPassword = () => {
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();
	const term = searchParams.get("key");
	//const isEdgeBrowser = window.navigator.userAgent.indexOf("Edg") !== -1;
	const [password, setPassword] = useState("");
	const [repassword, setRepassword] = useState("");
	const [focusRenewPassword, setFocusRenewPassword] = useState(false);
	const [focusNewPassword, setFocusNewPassword] = useState(false);
	const [message, setMessage] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
	const [emailId, setEmailId] = useState("");
	const [showRenewPassword, setShowRenewPassword] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showPassRequirement, setShowPassRequirements] = useState(true);
	const [cardContainerHeight, setCardContainerheight] = useState<number | undefined>(0);

	useEffect(() => {
		const element = document.getElementById("right-pannel-header");
		const rect = element?.getBoundingClientRect();
		setCardContainerheight(rect?.height);
	}, []);


	const isEnable = () => {
		if (
			password.length >= 8 && password.length <= 16 &&
			repassword.length >= 8 && repassword.length <= 16 &&
			password === repassword && UPPERCASEREGEX.test(password) &&
			NUMBEREREGEX.test(password) && SPECIALCHAREREGEX.test(password)) {
			return false;
		} else {
			return true;
		}
	};

	useEffect(() => {
		const getEmailByResetKey = () => {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/account/reset-password/getemail`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ key: term }),
				}
			)
				.then((response) => {
					return response.json();
				})
				.then((result) => {
					if (result) {
						setEmailId(result.email);
					} else {
						setEmailId("User not found");
					}
				});
		};
		getEmailByResetKey();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSubmit = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/account/reset-password/finish`,
			{
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					key: term,
					newPassword: password,
				}),
			}
		)
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				if (result.status === "OK") {
					navigate("/login", { state: { success: true } });
				} else {
					setMessage(result.message);
				}
			});
	};

	const handleSetReNewPassword = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFocusRenewPassword(true);
		setRepassword(event.target.value);
	};

	const handleSetNewPassword = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFocusNewPassword(true);
		setPassword(event.target.value);
	};

	return (
		<Grid
			container={true}
			columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}  style={{ minHeight: "100vh", overflow: 'auto' }}
		>
			<Grid xs={6} sm={6} md={4} lg={4} xl={4}>
				<LeftPanel />
			</Grid>
			<Grid xs={6} sm={6} md={4} lg={4} xl={4}  className="alignCenterTabLogin">
				<Grid
					container={true}
					className="column"
				>
					<Grid item={true}
						id="right-pannel-header"
					>
						<Grid
							container={true}
							className="column"
							rowSpacing={{ xs: -1, sm: 0, md: 5, xl: 15, xxl: 20 }}
						>
							<Grid item={true} xs={12} className="right-align">
							<Box display="flex" justifyContent="flex-end" mt={2}>
					<Typography
						mr={2}
						mt={1}
						sx={{
							fontWeight: "500",
							fontSize: "1rem",
							lineHeight: "20px",
							color: "#5D5D65",
						}}
					>
						{i18n.signUp.notMemberMsg}
					</Typography>
					<Button
						component={Link}
						to="/signUpEmail"
						sx={{
							
							fontWeight: "900",
							fontSize: "1rem",
							marginRight: "5vw",
							backgroundColor: "#ffffff",
							borderRadius: "18px",
							color: "#0273E6",
							border: "1px solid #0273E6",
							height: "36px"
						}}
					>
						{i18n.signUp.title}
					</Button>
				</Box>
							</Grid>
							<Grid item={true} xs={12} className="center-align" >
								{
									message ? (
										<Box
											sx={{
												display: "inline-flex",
												alignContent: "center",
												alignItems: "flex-start",
												textAlign: "center",
												color: "#3EB92A",
											}}
										>
											<Box sx={{ marginRight: "10px" }}>
												<img src={ErrorMark} alt="success-mark" height="20px" />
											</Box>
											<Typography className="typography-field-custom-font-size">
												{message}
											</Typography>
										</Box>
									) : <Box></Box>
								}
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item={true}
						xs={12}
						className="center-align"
					>
						<Card
							component="section"
							sx={{
								position: "relative",
								top: `calc(50% - ${cardContainerHeight}px)`,
								boxShadow: "none",
								width: "100%",
								marginTop: "50px"
							}}
						>
							<CardContent
								className="center-align"
								sx={{ padding: 0 }}
							>
								<FormControl sx={{ width: "80%", padding: 0 }}>
									<Grid
										container={true}
										columns={{ xs: 12, sm: 12, md: 12 }}
										rowSpacing={{ xs: 1, sm: 1, md: 2, lg: 6, xl: 8 }}
									>
										<Grid item={true}>
											<Typography className="screen-title-custom-size" sx={{ paddingBottom: "10px" }}>{i18n.signUp.resetPassword}</Typography>
											<Grid
												container={true}
												columns={{ xs: 12, sm: 12, md: 12 }}
											>

												<Grid item={true} xs={"auto"}>
													<Typography className="typography-field-custom-font-size"
														sx={{
															color: "#707081",
															fontWeight: 500,
															marginRight: "0.2rem"
														}} >
														{i18n.login.passwordForAcoountMsg}
													</Typography>
												</Grid>
												<Grid item={true} xs={"auto"}>
													<Typography className="typography-field-custom-font-size"
														sx={{
															color: "#00000",
															fontWeight: 800,
														}} >
														{emailId}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item={true} className="center-align">
											<Grid container
												columns={{ xs: 12, sm: 12, md: 12 }}
												columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 5, xl: 8 }}
											>
												<Grid item xs={showPassRequirement ? 8 : 12} sm={showPassRequirement ? 8 : 12} md={showPassRequirement ? 8 : 12} lg={showPassRequirement ? 6 : 12} xl={showPassRequirement ? 6 : 12}>
													<Grid container
														rowSpacing={2}
													>
														<Grid item={true} xs={12}>
															<Grid
																sx={{
																	display: "flex",
																	justifyContent: "space-between",
																}}>
																<Typography className="typography-field-custom-font-size">
																	{i18n.login.newPasswordFieldName}
																</Typography>
															</Grid>
															<Grid item={true} xs={12}>
																<OutlinedInput
																	id="outlined-adornment-password"
																	type={showPassword ? "text" : "password"}
																	sx={{ background: "#F8F8F8", width: "100%", border: "1px", borderRadius: "6px"}}
																	onChange={handleSetNewPassword}
																	onCopy={(e) => e.preventDefault()}
																	onPaste={(e) => e.preventDefault()}
																	onCut={(e) => e.preventDefault()}
																	error={focusNewPassword && (password.length < 8 || password.length > 16 || !UPPERCASEREGEX.test(password) ||
																		!NUMBEREREGEX.test(password) || !SPECIALCHAREREGEX.test(password))}
																	value={password}
																	endAdornment={
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="toggle password visibility"
																				onClick={() => setShowPassword((show) => !show)}
																				onMouseDown={(
																					event: React.MouseEvent<HTMLButtonElement>
																				) => event.preventDefault()}
																				edge="end"
																			>
																				{showPassword ? (
																					<img src={VisibleOn} alt="eye" />
																				) : (
																					<img src={VisibleOff} alt="Eye Slash" />
																				)}
																			</IconButton>
																		</InputAdornment>
																	}
																/>
															</Grid>
															<Grid item={true} xs={12}>
																{focusNewPassword && (password.length < 8 || password.length > 16) && (
																	<Typography className="typography-field-custom-font-size" sx={{
																		color: "#FD4B24",
																		textAlign: "right",
																	}}>
																		{i18n.login.newPasswordHelperMsg}
																	</Typography>
																)}
															</Grid>
														</Grid>
														<Grid item={true} xs={12} sx={{marginTop:"20px"}}>
															<Grid
																sx={{
																	display: "flex",
																	justifyContent: "space-between",
																}}>
																<Typography className="typography-field-custom-font-size">
																	{i18n.login.retypeNewPasswordFieldName}
																</Typography>
															</Grid>
															<Grid item={true} xs={12}>
																<OutlinedInput
																	id="outlined-adornment-password"
																	type={showRenewPassword ? "text" : "password"}
																	sx={{ background: "#F8F8F8", width: "100%", border: "1px", borderRadius: "6px"}}
																	onChange={handleSetReNewPassword}
																	onCopy={(e) => e.preventDefault()}
																	onPaste={(e) => e.preventDefault()}
																	onCut={(e) => e.preventDefault()}
																	error={
																		focusRenewPassword && (repassword.length < 8 || password !== repassword)
																	}
																	value={repassword}
																	endAdornment={
																		<InputAdornment position="end">
																			<IconButton
																				aria-label="toggle password visibility"
																				onClick={() => setShowRenewPassword((show) => !show)}
																				onMouseDown={(
																					event: React.MouseEvent<HTMLButtonElement>
																				) => event.preventDefault()}
																				edge="end"
																			>
																				{showRenewPassword ? (
																					<img src={VisibleOn} alt="eye" />
																				) : (
																					<img src={VisibleOff} alt="Eye Slash" />
																				)}
																			</IconButton>
																		</InputAdornment>
																	}
																/>
															</Grid>
															<Grid item={true} xs={12}>
																{focusRenewPassword && (
																	<Typography className="typography-field-custom-font-size right-align"
																		sx={{
																			color: "#FD4B24",
																			textAlign: "right",
																		}}
																	>
																		{!repassword
																			? i18n.login.reenterPasswordMsg
																			: password !== repassword
																				? i18n.login.passwordDoesNotMatchMsg
																				: ""}
																	</Typography>
																)}
															</Grid>
														</Grid>
														<Grid item={true} xs={12} style={{
															paddingLeft: 0,
															marginTop: "20px"
														}}>
															<Button
																className="submit-button button-field-custom-font-size"
																sx={{borderRadius: "24px",
																	background:
																		"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
																	height: "48px",
																	color: "#FFFFFF",
																	fontSize: "1.125rem",
																	fontWeight: "500",
																	lineHeight: "22.5px",
																	fontFamily: "Lexend",}}
																fullWidth
																size="large"
																variant="contained"
																disabled={isEnable()}
																onClick={onSubmit}
																endIcon={<img src={RightAarrow} alt="Right Arrow" />}
															>
																{i18n.login.submitButton}
															</Button>
														</Grid>
													</Grid>
												</Grid>
												{showPassRequirement && (
													<Grid item xs={4} sm={4} md={4} lg={6} xl={6}>
														<Typography className="password-guideline typography-field-custom-font-size">
															{i18n.dialog.passwordRequirments}
															<Typography className="password-validate  typography-field-custom-font-size">
																{password.length >= 8 ? (
																	<img src={CheckIcon} alt="Check" />
																) : (
																	<img src={CloseIcon} alt="Close" />
																)}
																{i18n.dialog.eightCharacter}
															</Typography>
															<Typography className="password-validate typography-field-custom-font-size">
																{UPPERCASEREGEX.test(password) ? (
																	<img src={CheckIcon} alt="Check" />
																) : (
																	<img src={CloseIcon} alt="Close" />
																)}
																{i18n.dialog.capital}
															</Typography>
															<Typography className="password-validate typography-field-custom-font-size">
																{NUMBEREREGEX.test(password) ? (
																	<img src={CheckIcon} alt="Check" />
																) : (
																	<img src={CloseIcon} alt="Close" />
																)}
																{i18n.dialog.number}
															</Typography>
															<Typography className="password-validate typography-field-custom-font-size">
																{SPECIALCHAREREGEX.test(password) ? (
																	<img src={CheckIcon} alt="Check" />
																) : (
																	<img src={CloseIcon} alt="Close" />
																)}
																{i18n.dialog.character}
															</Typography>
															<Typography className=" typography-field-custom-font-size" style={{ marginLeft: "25px" }}>{i18n.dialog.specialCharSet}</Typography>
														</Typography>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Grid>
								</FormControl>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid >
		</Grid >
	);
};
export default ResetPassword;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
	Grid,
	IconButton,
	InputAdornment,
	OutlinedInput,
	FormHelperText,
	Card,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import CopyIcon from "../images/copy.svg";
import { useUser } from "../context/UserContext";
import { fontSize } from "styled-system";
import useSnackbarAlert from "../hooks/useSnackbarAlert/useSnackbarAlert";
import LiveStreamingIcon from "../icons/LIveStreamingIcon";

const SessionInformation = (props: any) => {
	const _userSession = localStorage.getItem("userSession") ?? "";
	const userSession = JSON.parse(_userSession);
	const navigate = useNavigate();
	const user = useUser();
	const [isCopied, setIsCopied] = useState(false);
	const { SnackbarAlert, handleAlertOpen } = useSnackbarAlert();

	const url =
		`${process.env.REACT_APP_BASE_URL_FRONTEND}/live?id=` +
		props.twilioSessionId;

	async function copyTextToClipboard() {
		if ("clipboard" in navigator) {
			return await navigator.clipboard.writeText(url);
		} else {
			return document.execCommand("copy", true, url);
		}
	}

	// onClick handler function for the copy button
	const handleCopyClick = () => {
		// Asynchronously call copyTextToClipboard
		copyTextToClipboard()
			.then(() => {
				// If successful, update the isCopied state value
				setIsCopied(true);
				handleAlertOpen("Live Streaming Link Copied", true);
				setTimeout(() => {
					setIsCopied(false);
				}, 10000);
			})
			.catch((err) => {
			});
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			open={props.sessionInformationDialogBoxOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
					pb: "40px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={props.closeSessionInformationDialogBox}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.dialog.sessionInformationDialogBoxTitle}
					</Typography>
				</Box>
			</DialogTitle>

			{/* Jam Session Title */}
			<DialogContent
				sx={{
					marginLeft: "3px",
					marginRight: "3px",
				}}
			>
				<Box
					sx={{
						width: "504px",
						height: "84px",
						top: "101px",
						left: "45px",
						border: "0px 0px 1px 0px",
						borderBottom: "1px solid #D7D7D7",
						borderRadius: "0",
					}}
				>
					<Grid
						container
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "left",
							alignContent: "left",
							top: "8px",
							height: "18px",
							width: "135px",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Lexend",
								fontSize: "16px",
								fontWeight: "400",
								lineHeight: "20px",
								letterSpacing: "0em",
								textAlign: "left",
							}}
						>
							{"Jam Session Title"}
						</Typography>
					</Grid>

					<Grid
						container
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "left",
							alignContent: "left",
							width: "470px",
							height: "22px",
							marginTop: "13px",
							marginLeft: "13px",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Lexend",
								fontSize: "16px",
								fontWeight: "600",
								lineHeight: "20px",
								letterSpacing: " 0em",
							}}
						>
							{userSession.sessionName}
						</Typography>
					</Grid>
				</Box>
			</DialogContent>

			{/* Live Streaming Link To Listen */}
			{props.isBroadcastingStarted ? (
				<Box
					sx={{
						width: "550px",
						height: "92px",
						top: " 228px",
						marginLeft: "10px",
					}}
				>
					<DialogActions>
						<Grid
							container
							style={{
								display: "flex",
								flexDirection: "row",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "16px",
									fontWeight: "400",
									lineHeight: "20px",
									letterSpacing: "0em",
									textAlign: "left",
									marginBottom: "10px",
								}}
							>
								{i18n.dialog.sessionInformationLiveStreamingLinkTitle}{" "}
							</Typography>
							<span>
								<LiveStreamingIcon />{" "}
							</span>

							<OutlinedInput
								type={"text"}
								sx={{
									width: "504px",
									background:
										"linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #D7D7D7, #D7D7D7)",
									height: "40px",
									borderRadius: "6px",
									border: "1px solid",
								}}
								disabled
								defaultValue={props.isBroadcastingStarted ? url : ""}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleCopyClick}
											edge="end"
											disabled={!props.isBroadcastingStarted}
										>
											<img src={CopyIcon} alt="Eye Slash" />
										</IconButton>
									</InputAdornment>
								}
							/>
						</Grid>
					</DialogActions>
				</Box>
			) : (
				<Grid sx={{ pb: "150px" }}></Grid>
			)}

			{props.isBroadcastingStarted && (
				<DialogContent
					sx={{
						marginLeft: "3px",
						marginRight: "3px",
						pb: "100px",
						pt: "0",
					}}
				>
					<Grid
						container
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "left",
							alignContent: "left",
						}}
					>
						<Typography style={{ fontSize: "14px" }}>
							{i18n.dialog.sessionInformationLiveStreamingLinkFooter}{" "}
						</Typography>
					</Grid>
				</DialogContent>
			)}
			<SnackbarAlert />
		</Dialog>
	);
};
export default SessionInformation;

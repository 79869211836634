import { StackedBarChart } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
	Bar,
	BarChart,
	Legend,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip,
	CartesianGrid,
} from "recharts";
import ErrorAlert from "../../Error/ErrorAlert";

interface Barchartprops {
	selectedYear: number;
}

const Barchart: React.FC<Barchartprops> = ({ selectedYear }) => {
	const [data1, setData1] = useState<any[]>([]);

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const token = localStorage.getItem("sessionId");

	useEffect(() => {}, [data1]);
	useEffect(() => {
		getSubscriptionTrends();
	}, [selectedYear]);

	const getSubscriptionTrends = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/subscription-trend/${selectedYear}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setData1(result);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getFillColor = (index: any) => {
		const colors = ["#716EFC", "#4d4c4f", "#259a12", "#9747ff", "#0273E6"]; // Define your colors
		return colors[index % colors.length];
	};

	return (
		<ResponsiveContainer width="105%" height={400}>
			<BarChart
				data={Object.keys(data1).map((month: any) => ({
					month: month.toUpperCase(),
					...data1[month],
				}))}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}				
			>
				<CartesianGrid horizontal={true} vertical={false} />
				<XAxis dataKey="month" tickLine={true} />
				<YAxis axisLine={false} tickLine={false} allowDecimals={false} />
				<Tooltip />
				{Object.keys(data1).length > 0 &&
					Object.keys(data1[Object.keys(data1)[0] as any]).map(
						(key: string, index: number) =>
							// Check if the key is not "Audio Box V1.2"
							key !== "Audio Box V1.2" ? (
								<Bar
									key={key}
									dataKey={key}
									stackId={String.fromCharCode(97 + index)}
									fill={getFillColor(index)}
								/>
							) : null // If the key is "Audio Box V1.2", skip rendering by returning null
					)}

				<Legend verticalAlign="top" align="right" style={{marginTop:"-15px"}}/>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default Barchart;

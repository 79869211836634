
import { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	IconButton,
	Box,
	Typography,
	Grid
} from "@mui/material";
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import { display } from "@mui/system";


const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;



const EditName = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();

	const [firstname, setFirstName] = useState(props.firstName);
	const [lastname, setLastName] = useState(props.lastName);
	const [dirty, setDirty] = useState(false);

	const isInvalid = firstname === "" || lastname === "" ||
		(firstname === props.firstName && lastname === props.lastName) ||
		NUMBEREREGEX.test(firstname) || SPECIALCHAREREGEX.test(firstname) ||
		NUMBEREREGEX.test(lastname) || SPECIALCHAREREGEX.test(lastname);

	const saveData = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-Name`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				// id: user.id,
				id: props.userIdByAdmin ? props.userIdByAdmin : user.id,
				firstName: firstname,
				lastName: lastname,
			}),
		})
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				props.handleClose(true, "nameSuccess");
				if (result !== null) {
				}
			});
	};

	const handleClose = () => {
		setFirstName(props.firstName);
		setLastName(props.lastName);
		props.handleClose(false);
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			open={props.nameOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(
							event: React.MouseEvent<HTMLButtonElement>
						) => event.preventDefault()}
						edge="end"
						sx={{ marginLeft: "90%" }}>
						<img
							src={CloseIcon}
							alt="close"
						/>
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.settings.accountSetup.editNameDialog}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: "5px 20px",
				}}
			>
				<Grid container
					columnSpacing={2}
				>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.firstName}
						</FormHelperText>

						<TextField id="first-name"
							fullWidth
							sx={{ marginBottom: "10px" }}
							inputProps={{
								style:
									{ background: "#F8F8F8" }
							}}
							value={firstname}
							onChange={(e) => setFirstName(e.target.value.trim())}
							onFocus={() => setDirty(true)}
							error={dirty && (firstname?.length > 50 || NUMBEREREGEX.test(firstname) || SPECIALCHAREREGEX.test(firstname))}
							variant="outlined"
						/>
						{dirty && (firstname.length > 50 || NUMBEREREGEX.test(firstname) || SPECIALCHAREREGEX.test(firstname)) ?
							(<FormHelperText sx={{
								color: "#FD4B24",
								textAlign: "right"
							}}>
								{i18n.dialog.validFirstNameMsg}
							</FormHelperText>
							)
							: ""
						}
					</Grid>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								// fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.lastName}
						</FormHelperText>
						<TextField id="last-name"
							fullWidth
							sx={{ marginBottom: "10px" }}
							inputProps={{
								style: { background: "#F8F8F8" }
							}}
							value={lastname}
							onChange={(e) => setLastName(e.target.value.trim())}
							onFocus={() => setDirty(true)}
							error={dirty && (lastname?.length > 50 || NUMBEREREGEX.test(lastname) || SPECIALCHAREREGEX.test(lastname))}
							variant="outlined"
						/>
						{dirty && (lastname.length > 50 || NUMBEREREGEX.test(lastname) || SPECIALCHAREREGEX.test(lastname)) ?
							(<FormHelperText sx={{
								color: "#FD4B24",
								textAlign: "right"
							}}>
								{i18n.dialog.validLastNameMsg}
							</FormHelperText>
							)
							: ""
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container
					columnSpacing={1}
					style={{
						display: "flex", flexDirection: "row", justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ width: "120px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							{i18n.dialog.cancel}
						</Button>
					</Grid>

					<Grid item={true}>
						<Button
							className="submit-button"
							size="large"
							variant="contained"
							fullWidth
							sx={{ width: "120px" }}
							// width: "135px"
							disabled={isInvalid}
							onClick={saveData}
						>
							{i18n.dialog.save}
						</Button>

					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
};
export default EditName;

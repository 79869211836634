import React, {useEffect, useState} from 'react';
import { useOnlineStatus } from '../../hooks/useOnlineStatus/useOnlineStatus';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';

// Using the correct way to create styles with Theme type
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    overflow: 'hidden',
    fontSize: '10px',
    padding: '2px',
    transition: 'background-color 0.3s ease',
  },
  offline: {
    background: '#ef4f5e',
    color: '#fff',
  },
  online: {
    background: '#3558fa',
    color: '#fff',
  },
}));

let timer: NodeJS.Timeout | null = null;

export default function NetworkStatus() {
  const [showBanner, setShowBanner] = useState(false);
  const [wasOnline, setWasOnline] = useState(true);
  const isOnline = useOnlineStatus(); 
  const classes = useStyles();

  useEffect(() => {
    // Show the banner when going offline
    if (!isOnline && wasOnline) {
      setShowBanner(true);
      setWasOnline(false);
    } else if (isOnline && !wasOnline) {
      // Show the banner when coming back online, then hide after 5 seconds
      setShowBanner(true);
      setWasOnline(true);
      timer = setTimeout(() => {
        setShowBanner(false); 
      }, 5000);
    }
  }, [isOnline, wasOnline]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [])

  if (!showBanner) return (<div></div>);

  return (
    <div className={clsx(classes.container, isOnline ? classes.online : classes.offline)}>
      {isOnline ? '✅ You are connected to the internet. 🎉' : '❌  You are currently offline. Please check your internet connection. 🥲'}
    </div>
  );
}

import { createContext, useContext, useRef, useState } from "react";
import { format, utcToZonedTime } from "date-fns-tz";

const AdminContext = createContext<any>(null);

export const UserDetailsProvider = ({ children }: any) => {
	const [adminSetUserId, setAdminSetUserId] = useState({});
	const token = localStorage.getItem("sessionId");
	const [isEditing, setIsEditing] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>();
	const [rowsData, setRowsData] = useState<any[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [pageNumber, setPageNumber] = useState(0);
	const adminSearchRef = useRef(null);
	const [userRole, setUserRole] = useState("");
	const [searchUserName, setSearchUserName] = useState("");
	const [selectedUserEmails, setSelectedUserEmails] = useState<any>([]);
	const [selectedUser, setSelectedUser] = useState<any>([]);
	const [orderByField, setOrderByField] = useState("");
	const [selectedButton, setSelectedButton] = useState("active");
	const [loading, setLoading] = useState(false);
	const [adminScreenShow, setadminScreenShow] = useState(false);
	const [adminEmail, setAdminEmail] = useState<any>([]);
	const [selectedUsername, setSelectedUsername] = useState<any>("");
	const [isDownloadComplete, setIsDownloadComplete] = useState<Boolean>(true);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [page, setPage] = useState(0);
	const [downloadData, setDownloadData] = useState<any[]>([]);


	const getAllAdminUsers = async (searchString = "", activeStatus = "active", itemPerPage=20) => {
		setRowsData([]);
		setLoading(true);
		try {
			setHasMore(true);
			setPageNumber(0);
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/admin/get-all-admin-users?searchString=${searchString}&
				orderBy=${orderByField}&byUserRole=${userRole}&adminUserType=${activeStatus}&page=${page}&size=${rowsPerPage}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (response.status > 400) {
				throw new Error("api_error");
			}
			const data = await response.json();

			if (data.length === 0) {
				setHasMore(false);
				setRowsData([]);
				return [];
			} else {
				const updatedRowsData = data.map((item: any) => ({
					id: item.id,
					userProfileId: item.userProfileId,
					name: item.firstName,
					email: item.email,
					adminRoleType: item.authorities[0]?.name,
					allUserCount:item.allUserCount,
					createdDate: (() => {
						const parsedDateUTC = utcToZonedTime(
							new Date(item.createdDate),
							"UTC"
						);
						return format(parsedDateUTC, "yyyy-MM-dd");
					})(),
				}));
				setRowsData(updatedRowsData);
				return updatedRowsData;
			}
		} catch (error) {
			setHasMore(false);
        	setRowsData([]);
        	return [];
		} finally {
			setLoading(false);
		}
	};

		const getNextUsers = async (searchString = "", activeStatus = "active", itemPerPage=20) => {
			try {
				setHasMore(true);
				setPageNumber(0);
				const response = await fetch(
					`${process.env.REACT_APP_BASE_URL}/api/admin/get-all-admin-users?searchString=${searchString}&
					orderBy=${orderByField}&byUserRole=${userRole}&adminUserType=${activeStatus}&page=${pageNumber}&size=${itemPerPage}`,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);
				if (response.status > 400) {
					throw new Error("api_error");
				}
				const data = await response.json();
	
				if (data.length === 0) {
					setHasMore(false);
					return [];
				} else {
					const updatedRowsData = data.map((item: any) => ({
						id: item.id,
						userProfileId: item.userProfileId,
						name: item.firstName,
						email: item.email,
						adminRoleType: item.authorities[0]?.name,
						allUserCount:item.allUserCount,
						createdDate: (() => {
							const parsedDateUTC = utcToZonedTime(
								new Date(item.createdDate),
								"UTC"
							);
							return format(parsedDateUTC, "yyyy-MM-dd");
						})(),
					}));
					setDownloadData(updatedRowsData);
					return updatedRowsData;
				}
			} catch (error) {
				setHasMore(false);
				return [];
			} finally {
				setLoading(false);
			}
		};

	const convertToCSV = (data: any) => {
		if (!data.length) return "";

		// Define the new headers
		const headers = ["SrNo", "Name", "Email", "AdminRoleType", "CreatedDate"];

		// Convert data to only include the necessary fields
		const modifiedData = data.map(
			({ id, name, email, adminRoleType, createdDate }: any) => ({
				SrNo: id,
				Name: name,
				Email: email,
				AdminRoleType: adminRoleType,
				CreatedDate: createdDate,
			})
		);

		// Generate CSV rows
		const csvRows = [headers.join(",")]; // Add the headers as the first row
		for (const row of modifiedData) {
			const values = headers.map((header) => {
				const escaped = ("" + row[header]).replace(/"/g, '\\"');
				return `"${escaped}"`;
			});
			csvRows.push(values.join(","));
		}

		return csvRows.join("\n");
	};

	const handleExport = (selectedRows: any) => {
		if (selectedRows.length > 0) {
			const csvData = convertToCSV(selectedRows);
			const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
			const url = URL.createObjectURL(blob);

			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "data.csv");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			setIsDownloadComplete(true);
			URL.revokeObjectURL(url);
		} else {
			return;
		}
	};

	return (
		<AdminContext.Provider
			value={{
				adminSetUserId,
				setAdminSetUserId,
				getAllAdminUsers,
				rowsData,
				setRowsData,
				isEditing,
				setIsEditing,
				selectedRow,
				setSelectedRow,
				getNextUsers,
				hasMore,
				adminSearchRef,
				userRole,
				setUserRole,
				searchUserName,
				setSearchUserName,
				selectedUserEmails,
				setSelectedUserEmails,
				selectedUser,
				setSelectedUser,
				orderByField,
				setOrderByField,
				selectedButton,
				setSelectedButton,
				loading,
				handleExport,
				adminScreenShow, 
				setadminScreenShow,
				adminEmail,
				setAdminEmail,
				selectedUsername,
				setSelectedUsername,
				isDownloadComplete,
				setIsDownloadComplete,
				setRowsPerPage,
				rowsPerPage,
				page,
				setPage
			}}
		>
			{children}
		</AdminContext.Provider>
	);
};

export const useAdminContext = () => {
	return useContext(AdminContext);
};

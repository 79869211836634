import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, GlobalStyles, Grid, TextField, Typography } from "@mui/material"
import Sessions from "../jam-session/Sessions"
import HelpAndTips from "../../pages/HelpAndTips"
import i18n from "../../l10n/strings.json";
import { useEffect, useState } from "react";
import { useGradient2Styles } from "../../theme";
import RightAarrow from "../../images/right arrow.svg";
import { Box} from "@mui/system";
import { is } from "date-fns/locale";
import { set } from "date-fns";
import validator from "validator";
import { Form } from "react-router-dom";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { useUser } from "../../context/UserContext";

import MyActivitiesSidePanel from "../Social/myActivitiesSidePanel";


const LeftPanel = ({ activeDevice }: any) => {

	const user = useUser();
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");

	const setAlertOnEditSession = (
		_showAlert: boolean,
		_alertStatus: boolean,
		_alertMsg: string
	) => {
		setShowAlert(_showAlert);
		setAlertStatus(_alertStatus);
		setAlertMsg(_alertMsg);
	};


    return(
        <>
        <Grid item sx={{ width: 'auto' }}>
								<Sessions
									refresh={true}
									source="home"
									reqStartdate={new Date()}
									filter={"agenda"}
									setAlertOnEditSession={setAlertOnEditSession}
									activeDevice={activeDevice}
								/>
							</Grid>
							<MyActivitiesSidePanel />
							{/* <Grid item sx={{ width: 'auto' }}>
								<HelpAndTips
									title={i18n.home.instructions.appFeatureUpdatesTitle}
									text={i18n.home.instructions.appFeatureUpdatesDesc}
								/>
							</Grid>
							<Grid item sx={{ width: 'auto' }}>
								<HelpAndTips
									title={i18n.home.instructions.appFunctionalityTitle}
									text={i18n.home.instructions.appFunctionalityDesc}
								/>
							</Grid> */}
        </>
    )
}

export default LeftPanel

import { memo, useEffect, useState } from "react";
import { Grid, Slider, Typography, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import AvatarIcon from "../../icons/AvatarIcon";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { useUser } from "../../context/UserContext";
import Stack from "@mui/material/Stack";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Guitar from "../../images/Vector.svg";
import Drums from "../../images/drums.svg";
import InstrumentImg from "../../images/Instrument placeholderMixer.svg";
import Mic from "../../images/microphone7.svg";
import MixerSliderEdit from "./MixerSliderEdit";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import "./mixer.css";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
// import WebsocketMetricsClient from "../../services/WebsocketMetricsClient";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outerContainer: {
			display: "flex",
			alignItems: "center",
			position: "relative",
			flexDirection: "column",
			margin: "5px 0",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: 1,
			[theme.breakpoints.down("sm")]: {
				"& svg": {
					transform: "scale(0.7)",
				},
			},
		},
		avatarContainer: {},
		sliderContainer: {
			display: "flex",
			justifyContent: "space-around",
			flexDirection: "column",
			bottom: 0,
			zIndex: 2,
			height: "100%",
			width: "100%",
		},
		volumeSlider: {
			display: "flex",
			height: "70%",
			flexDirection: "column",
			alignItems: "center",
			borderRadius: "6px",
			padding: "10px 0 0 0",
			"& .MuiSlider-thumb": {
				height: "30px",
				width: "30px",
				background: "#ff702a",
				border: "2px solid #8EBAFB",
				boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			},
			"& .MuiSlider-mark": {
				width: "15px",
				backgroundColor: "#000000",
			},
			"& .MuiSlider-markLabel": {
				color: "#888888",
			},
		},
	})
);

const MixerSlider = (props: any) => {
	const user = useUser();
	const classes = useStyles();
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const userSession = localStorage.getItem("userSession") ?? "";
	const sessionID = localStorage.getItem("sessionId");

	let participantDetailList = JSON.parse(
		localStorage.getItem("participantVolumeDetails") ?? ""
	);
	let userDetails = JSON.parse(localStorage.getItem("user") ?? "");
	const audioDetails: any = participantDetailList.find(
		(p: any) => p.email === props.participant.identity
	);
	const [userCheckedMap, setUserCheckedMap] = useState(() => {
        return JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");
    });

	const [checked, setChecked] = useState(() => {
		const userId = props.participant.identity; // Assuming this is a unique identifier for the user
		return userCheckedMap[userId] ?? false; // Default to false if not found
	  });

	const [channel1Volume, setChannel1Volume] = useState<number | number[]>(
		audioDetails.audio.channel_1.audio_level ?? 50
	);
	const [channel2Volume, setChannel2Volume] = useState<number | number[]>(
		audioDetails.audio.channel_2.audio_level ?? 50
	);
	const [micVolume, setMicVolume] = useState<number | number[]>(
		audioDetails.audio.mic.audio_level ?? 50
	);
	const [updatedFor, setUpdatedFor] = useState("");
	const [editSlider, setEditSlider] = useState(false);
	const [channel1VolumeText, setChannel1VolumeText] = useState("");
	const [channel2VolumeText, setChannel2VolumeText] = useState("");
	const [micVolumeText, setMicVolumeText] = useState("");
	const [mixerVolume, setMixerVolume] = useState<number | number[]>(
		audioDetails.audio.master.audio_level ?? 50
	);
	const [decibelValue, setDecibelValue] = useState<number>(0);

	const userSessions = JSON.parse(userSession);

	// if (WebsocketMetricsClient.websocketClient == undefined) {
	// 	// @question(Pratiti): the userID here should be the deviceID for the user. Didn't see that in a cookie and when I tried
	// 	// to mimic what I'd seen in other places with those `fetch` calls it didn't work. Help, please!
	// 	WebsocketMetricsClient.createMetricsClientConnection({
	// 		userID: userDetails?.deviceId, // how to get device id?
	// 		sessionID: userSessions?.twilioSessionId,
	// 	});
	// }

	const handleDialogClose = (success: boolean, editMode: string) => {
		setEditSlider(false);
	};

	const participantDetails = userSessions.attendees.find(
		(name: any) => name.email === props.participant.identity
	);

	const isParticipantInUserSessions = (
		participantIdentity: string,
		userSessions: any
	) => {
		return userSessions?.attendees.some(
			(attendee: any) => participantIdentity === attendee.email
		);
	};
	useEffect(() => {
		const userId = props.participant.identity;
		let storedMap = userCheckedMap || JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");
		if (!(userId in storedMap)) {
			storedMap[userId] = false;
		}
		setChecked(storedMap[userId]);
		setUserCheckedMap(storedMap);
		localStorage.setItem("userCheckedMap", JSON.stringify(storedMap));
	}, [props.participant.identity]); 
	

	useEffect(() => {
		const onAudioDetailsMessageReceived = (payload: any) => {
			if (payload.metadata.created_by === props.participant.identity) {
				let db;
				if (payload.metadata.decibel_level >= 30) {
					db = 30;
				} else if (payload.metadata.decibel_level <= -60) {
					db = -60;
				} else {
					db = payload.metadata.decibel_level;
				}
				let percentageValue = (db + 60) / 90;
				setDecibelValue(percentageValue * 100);
			}
			// if (
			// 	props.participant.deviceId === payload.metadata.created_by
			// ) {
			// 	let db;
			// 	if (payload.metadata.decibel_level >= 30) {
			// 		db = 30;
			// 	} else if (payload.metadata.decibel_level <= -60) {
			// 		db = -60;
			// 	} else {
			// 		db = payload.metadata.decibel_level;
			// 	}
			// 	let percentageValue = (db + 60) / 90;
			// 	const perValue = Math.abs(percentageValue * 100);
			// 	// console.log("Decible value: ", perValue);
			// 	setDecibelValue(perValue);
			// }
		};

		WebsocketClientUSerStatus.on("audioControl", onMessageReceived);
		WebsocketClientUSerStatus.on("audioDetails", onAudioDetailsMessageReceived);

		return () => {
			WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
			WebsocketClientUSerStatus.off("audioDetails", onAudioDetailsMessageReceived);
		};
	}, []);

	const handleMixerVolume = (updated_for: String) => {
		// setMixerVolume(value);

		let audioStatus = mixerVolume === 0 ? "mute" : "unmute";
		let requestMessage = {
			action: "audio_control",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			created_for: props.participant.identity,
			audio: {
				updated_for: updated_for,
				master: {
					audio_status: audioStatus,
					audio_level: mixerVolume,
				},
				channel_1: {
					audio_status:
						audioStatus === "mute"
							? "mute"
							: channel1Volume === 0
							? "mute"
							: "unmute",
					audio_level: channel1Volume,
				},
				channel_2: {
					audio_status:
						audioStatus === "mute"
							? "mute"
							: channel2Volume === 0
							? "mute"
							: "unmute",
					audio_level: channel2Volume,
				},
				mic: {
					audio_status:
						audioStatus === "mute"
							? "mute"
							: micVolume === 0
							? "mute"
							: "unmute",
					audio_level: micVolume,
				},
			},
		};

		WebsocketClientUSerStatus.handleMixerAudioControl(requestMessage);
	};


	const handleClick = () => {
		const newChecked = !checked; 
		setChecked(newChecked);
		
		const userId = props.participant.identity;
	
		const storedMap = JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");
	
		const updatedMap = { ...storedMap, [userId]: newChecked };
		
		localStorage.setItem("userCheckedMap", JSON.stringify(updatedMap));
	};
	
	const onMessageReceived = (payload: any) => {
		if (payload.metadata.created_by === props.participant.identity) {
			// if (payload.metadata.audio.master.audio_status === "mute") {
			// 	setMixerVolume(0);
			// 	setChannel1Volume(payload.metadata.audio.channel_1.audio_level);
			// 	setChannel2Volume(payload.metadata.audio.channel_2.audio_level);
			// 	setMicVolume(payload.metadata.audio.mic.audio_level);
			// } else {
			// 	setMixerVolume(payload.metadata.audio.master.audio_level);
			// 	setChannel1Volume(payload.metadata.audio.channel_1.audio_level);
			// 	setChannel2Volume(payload.metadata.audio.channel_2.audio_level);
			// 	setMicVolume(payload.metadata.audio.mic.audio_level);
			// }

			if (payload.metadata.audio.updated_for === "master") {
				if (payload.metadata.audio.master.audio_status === "mute") {
					setMixerVolume(0);
				} else {
					setMixerVolume(payload.metadata.audio.master.audio_level);
				}
			} else if (payload.metadata.audio.updated_for === "channel_1") {
				if (payload.metadata.audio.channel_1.audio_status === "mute") {
					setChannel1Volume(0);
				} else {
					setChannel1Volume(payload.metadata.audio.channel_1.audio_level);
				}
			} else if (payload.metadata.audio.updated_for === "channel_2") {
				if (payload.metadata.audio.channel_2.audio_status === "mute") {
					setChannel2Volume(0);
				} else {
					setChannel2Volume(payload.metadata.audio.channel_2.audio_level);
				}
			} else if (payload.metadata.audio.updated_for === "mic") {
				if (payload.metadata.audio.mic.audio_status === "mute") {
					setMicVolume(0);
				} else {
					setMicVolume(payload.metadata.audio.mic.audio_level);
				}
			}
		}
		saveChannelLevels();
	};

	const handleChannel1 = (event: Event, newValue: number | number[]) => {
		setChannel1Volume(newValue);
		setUpdatedFor("channel_1");
	};
	const handleChannel2 = (event: Event, newValue: number | number[]) => {
		setChannel2Volume(newValue);
		setUpdatedFor("channel_2");
	};
	const handleMic = (event: Event, newValue: number | number[]) => {
		setMicVolume(newValue);
		setUpdatedFor("mic");
	};
	const handleSliderChange = (event: Event, newValue: number | number[]) => {
		setMixerVolume(newValue);
		setUpdatedFor("master");
	};

	const saveChannelLevels = () => {
		participantDetailList = [
			...participantDetailList.map((participant: any) => {
				if (
					participant.email !== undefined &&
					participant.email === user.email
				) {
					return {
						...participant,
						audio: {
							...participant.audio,
							master: {
								...participant.audio.master,
								audio_status: "unmute",
								audio_level: mixerVolume,
							},
							channel_1: {
								...participant.audio.channel_1,
								audio_status: "unmute",
								audio_level: channel1Volume,
							},
							channel_2: {
								...participant.audio.channel_2,
								audio_status: "unmute",
								audio_level: channel2Volume,
							},
							mic: {
								...participant.audio.mic,
								audio_status: "unmute",
								audio_level: micVolume,
							},
						},
					};
				} else {
					return participant;
				}
			}),
		];
		localStorage.setItem(
			"participantVolumeDetails",
			JSON.stringify(participantDetailList)
		);
	};

	// useEffect(() => {
	// 	handleMixerVolume()
	// }, [channel1Volume, channel2Volume, micVolume, mixerVolume])

	const getBackgroundGradient = () => {
		if (decibelValue) {
			if (decibelValue < 50) {
				return `linear-gradient(180deg, grey ${
					100 - decibelValue
				}%, #20E100 ${decibelValue}%)`;
			} else if (decibelValue >= 50 && decibelValue < 80) {
				return `linear-gradient(180deg, grey ${100 - decibelValue}%, #FFB800 ${
					decibelValue - 50
				}%, #20E100 50%)`;
			} else {
				if (decibelValue === 100) {
					return `linear-gradient(180deg, #FF2E00 ${
						decibelValue - 90
					}%, #FFB800 30%, #20E100 50%)`;
				} else {
					return `linear-gradient(180deg, grey ${
						100 - decibelValue
					}%, #FF2E00 ${decibelValue - 90}%, #FFB800 30%, #20E100 50%)`;
				}
			}
		} else {
			return "grey !important";
		}
	};

	const getIcon = (iconName: any) => {
		switch (iconName) {
			case "Drum":
				return <img src={Drums} alt={iconName} />;
			case "Guitar":
				return <img src={Guitar} alt={iconName} />;
			case "Mic":
				return <img src={Mic} alt={iconName} />;
			default:
				return "";
		}
	};

	const formatNameWithMaxLength = (
		name: string | undefined,
		maxChars: number
	): string => {
		return name && name.length > maxChars
			? name.substring(0, maxChars) + "..."
			: name || "";
	};

	const getParticipantDisplayName = (participantDetails:any, user:any) => {
		const firstName = formatNameWithMaxLength(participantDetails?.firstName, 17);
		const lastName = formatNameWithMaxLength(participantDetails?.lastName, 17);
		const nickname = `@${formatNameWithMaxLength(participantDetails?.nickname, 17)}`;
	  
		if (participantDetails?.userProfileId === user?.userProfileId) {
		  return `${firstName} ${lastName}`;
		}
	  
		switch (participantDetails?.userPrivacySettingsDTO?.identityPermission) {
		  case "Name is not visible. Instead just show nickname":
			return nickname;
	  
		  case "Name is visible to all":
			return `${firstName} ${lastName}`;
	  
		  case "Name is visible only to my connections. Other see nickname":
			return participantDetails?.connected ? `${firstName} ${lastName}` : nickname;
	  
		  default:
			return `${firstName} ${lastName}`;
		}
	  };
	  

	return (
		<>
			<Grid sx={{ height: "100%" }}>
				<Box sx={{ height: "25%", textAlign: "center" }}>
					<Grid container direction={"column"} sx={{ py: 1 }}>
						<Grid item>
							<AvatarIcon />
						</Grid>

						<Grid item>
							<Typography variant="subtitle1" sx={{ color: "white" }}>
								{getParticipantDisplayName(participantDetails, user)}
							</Typography>
						</Grid>
					</Grid>
				</Box>

				<Box sx={{ height: "75%" }}>
					<Grid
						container
						sx={{ height: "100%", position: "relative", display: "flex" }}
					>
						{checked ? (
							<Grid
								container
								className="secondarySliderContainer"
								xs={12}
								md={8}
								sx={{ backgroundColor: "#06233E", height: "80%", marginTop: 1 }}
							>
								<Grid
									xs={4}
									container
									rowGap={1}
									direction={"column"}
									sx={{ alignItems: "center" }}
								>
									<Grid
										item
										className="secondarySlider"
										sx={{
											"& .MuiSlider-thumb": {
												height: "20px",
												width: "20px",
												background: `linear-gradient(143.33deg, #8E99A7 -4.78%, #434D59 63.57%)`,
												//border: "2px solid #8EBAFB",
												boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											},
											"& .MuiSlider-mark": {
												width: "15px",
												backgroundColor: "#000000",
											},
											"& .MuiSlider-rail": {
												width: "5px",
												background: "#4D5EB9",
											},
											"& .MuiSlider-markLabel": {
												color: "#888888",
											},
											"& .MuiSlider-track": {
												width: "5px", // Fixed width for track
												border: "none",
												backgroundColor: "transparent",
											},
										}}
									>
										<Slider
											size="small"
											aria-label="Volume"
											orientation="vertical"
											valueLabelDisplay="auto"
											defaultValue={50}
											value={channel1Volume}
											// onChange={(event, value) => setChannel1Volume(value)}
											onChange={handleChannel1}
											onChangeCommitted={() => {
												handleMixerVolume("channel_1");
											}}
										/>
									</Grid>
									<Grid item sx={{ pt: 1 }} className="mixerImg">
										<img
											src={InstrumentImg}
											alt="Guitar"
											style={{ height: "18px" }}
										/>
									</Grid>
									<Grid item sx={{ pt: 1, textAlign: "center" }}>
										<Tooltip title={channel1VolumeText}>
											<Typography
												className="secondaryText"
												variant="caption"
												sx={{
													color: "white",
													textOverflow: "ellipsis",
													display: "block",
													overflow: "hidden",
												}}
											>
												{channel1VolumeText ? channel1VolumeText : "CH1"}
											</Typography>
										</Tooltip>
									</Grid>
								</Grid>

								<Grid
									xs={4}
									container
									rowGap={1}
									direction={"column"}
									sx={{ alignItems: "center" }}
								>
									<Grid
										item
										className="secondarySlider"
										sx={{
											"& .MuiSlider-thumb": {
												height: "20px",
												width: "20px",
												background: `linear-gradient(143.33deg, #8E99A7 -4.78%, #434D59 63.57%)`,
												//border: "2px solid #8EBAFB",
												boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											},
											"& .MuiSlider-mark": {
												width: "15px",
												backgroundColor: "#000000",
											},
											"& .MuiSlider-rail": {
												width: "5px",
												background: "#4D5EB9",
											},
											"& .MuiSlider-markLabel": {
												color: "#888888",
											},
											"& .MuiSlider-track": {
												width: "5px", // Fixed width for track
												border: "none",
												backgroundColor: "transparent",
											},
										}}
									>
										<Slider
											size="small"
											aria-label="Volume"
											orientation="vertical"
											defaultValue={30}
											valueLabelDisplay="auto"
											value={channel2Volume}
											// onChange={(event, value) => setChannel2Volume(value)}
											onChange={handleChannel2}
											onChangeCommitted={() => {
												handleMixerVolume("channel_2");
											}}
										/>
									</Grid>
									<Grid item sx={{ pt: 1 }} className="mixerImg">
										<img
											src={InstrumentImg}
											alt="Drums"
											style={{ height: "18px" }}
										/>
									</Grid>
									<Grid item sx={{ pt: 1, textAlign: "center" }}>
										<Tooltip title={channel2VolumeText}>
											<Typography
												className="secondaryText"
												variant="caption"
												sx={{
													color: "white",
													textOverflow: "ellipsis",
													display: "block",
													overflow: "hidden",
												}}
											>
												{channel2VolumeText ? channel2VolumeText : "CH2"}
											</Typography>
										</Tooltip>
									</Grid>
								</Grid>

								<Grid
									xs={4}
									container
									rowGap={1}
									direction={"column"}
									sx={{ alignItems: "center" }}
								>
									<Grid
										className="secondarySlider"
										item
										sx={{
											"& .MuiSlider-thumb": {
												height: "20px",
												width: "20px",
												background: `linear-gradient(143.33deg, #8E99A7 -4.78%, #434D59 63.57%)`,
												//border: "2px solid #8EBAFB",
												boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											},
											"& .MuiSlider-mark": {
												width: "15px",
												backgroundColor: "#000000",
											},
											"& .MuiSlider-rail": {
												width: "5px",
												background: "#4D5EB9",
											},
											"& .MuiSlider-markLabel": {
												color: "#888888",
											},
											"& .MuiSlider-track": {
												width: "5px", // Fixed width for track
												border: "none",
												backgroundColor: "transparent",
											},
										}}
									>
										<Slider
											size="small"
											aria-label="Volume"
											orientation="vertical"
											valueLabelDisplay="auto"
											defaultValue={37}
											value={micVolume}
											// onChange={(event, value) => setMicVolume(value)}
											onChange={handleMic}
											onChangeCommitted={() => {
												handleMixerVolume("mic");
											}}
										/>
									</Grid>
									<Grid item sx={{ pt: 1 }} className="mixerImg">
										<img src={Mic} alt="Mic" style={{ height: "18px" }} />
									</Grid>
									<Grid item sx={{ pt: 1, textAlign: "center" }}>
										<Tooltip title={micVolumeText}>
											<Typography
												className="secondaryText"
												variant="caption"
												sx={{
													color: "white",
													display: "block",
													textOverflow: "ellipsis",
													overflow: "hidden",
												}}
											>
												{micVolumeText ? micVolumeText : "Mic"}
											</Typography>
										</Tooltip>
									</Grid>
								</Grid>
							</Grid>
						) : (
							""
						)}

						<Grid
							container
							className="primarySliderContainer"
							direction={"column"}
							rowGap={checked ? 1 : 1}
							xs={checked ? 12 : 12}
							md={checked ? 4 : 12}
							sx={{
								height: "95%",
								alignItems: "center",
								pt: 2,
								mt: checked ? 0 : 2,
							}}
						>
							<Grid className="primarySlider">
									<Slider
										sx={{
											"& .MuiSlider-track": {
												width: "10px", // Fixed width for track
												border: "none",
												backgroundColor: "transparent",
											},
											"& .MuiSlider-rail": {
												width: "10px", // Fixed width for rail
												background: getBackgroundGradient(),
												borderRadius: "4px", // Optional: add border radius
												opacity: 1,
											},
											"& .MuiSlider-thumb": {
												height: "30px",
												width: "30px",
												background: "#ff702a",
												//border: "2px solid #8EBAFB",
												boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											},
											"& .MuiSlider-mark": {
												width: "15px",
												backgroundColor: "#000000",
											},
											"& .MuiSlider-markLabel": {
												color: "#888888",
											},
										}}
										aria-label="Volume"
										orientation="vertical"
										// marks={mixerRanges}
										onChangeCommitted={(event: any, value) =>
											handleMixerVolume("master")
										}
										// onChange={(event, value) => setMixerVolume(value)}
										value={mixerVolume}
										onChange={handleSliderChange}
										min={0}
										max={100}
										step={5}
										valueLabelDisplay="auto"
									/>
							</Grid>

							<Grid className="showIconButton">
								<IconButton
									aria-label="show"
									className="showIcon"
									onClick={handleClick}
									
									sx={{
										height: "20px",
										width: "20px",
										backgroundColor: "#CFD9DF",
										ml: checked ? "auto" : 0, // Push to the right when checked
										mt: checked ? 0 : 1, // Push down when unchecked
									}}
								>
									{checked ? (
										<KeyboardArrowLeftIcon />
									) : (
										<KeyboardArrowRightIcon />
									)}
								</IconButton>
							</Grid>

							<Grid>
								<Button
								// edit slider
									variant="text"
									sx={{ color: "#5F98F9" }}
									onClick={() => setEditSlider(true)}
								>
									Edit
								</Button>
							</Grid>
							<MixerSliderEdit
								editSlider={editSlider}
								handleClose={handleDialogClose}
								participantName={
									`${formatNameWithMaxLength(
										participantDetails?.firstName,
										17
									)}` +
									` ${formatNameWithMaxLength(
										participantDetails?.lastName,
										17
									)}`
								}
								// volumeText = {{channel1VolumeText: channel1VolumeText, channel2VolumeText: channel2VolumeText, micVolumeText: micVolumeText}}
								setVolumeText={{
									setChannel1VolumeText: setChannel1VolumeText,
									setChannel2VolumeText: setChannel2VolumeText,
									setMicVolumeText: setMicVolumeText,
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</Grid>
		</>
	);
};
export default memo(MixerSlider);

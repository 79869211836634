import { createContext, useContext, useEffect, useState } from "react";
import React, { useRef } from "react";
import { format, utcToZonedTime } from "date-fns-tz";

export const UserManageFilterContext = createContext<any>(null);

export const DeviceManagementFilterProvider = ({ children }: any) => {
	const token = localStorage.getItem("sessionId");
	const [pageNumber, setPageNumber] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(20);
	const [selectedDeviceIds, setSelectedDeviceIds] = useState<any>([]);

	const [deviceManagementFilter, setDeviceManagementFilter] = useState<any>({
		isGlobalSearch: true,
		userProfileId: null,
		genres: [],
		cities: "",
		firstName: "",
		lastName: "",
		nickName: "",
		instrument: [],
		isOpenForCollaboration: false,
		acceptGigs: false,
		deviceModelType: "",
		activationDate: null,
	});

	const [filterByDevice, setFilterByDevice] = useState<any>({
		activationDate: "",
		deviceModelType: "",
	});

	interface Data {
		deviceId: string;
		deviceStatus: string;
		userName: string;
		subscription: string;
		lastOnline: string;
		actions: string;
	}

	const [isFilterActive, setIsFilterActive] = useState(false);

	const [rowData, setRowData] = useState<Data[]>([]);

	const [deviceStatus1, setDeviceStatus1] = useState("");
	const [subscriptionStatus1, setSubscriptionStatus1] = useState("");
	const [searchDeviceName, setSearchDeviceName] = useState("");
	const [filterById, setFilterById] = useState("");
	const inputRef = useRef(null);
	const [orderByField, setOrderByField] = useState("");

	const resetDeviceManagementFilter: any = () => {
		setDeviceManagementFilter({
			isGlobalSearch: true,
			genres: [],
			cities: "",
			firstName: "",
			lastName: "",
			nickName: "",
			instrument: [],
			isOpenForCollaboration: false,
			acceptGigs: false,
			deviceModelType: "",
			activationDate: null,
		});

		setFilterByDevice({
			activationDate: null,
			deviceModelType: "",
		});
	};

	useEffect(() => {
		if (
			filterByDevice.activationDate ||
			filterByDevice.deviceModelType.length > 0
		) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
	}, [filterByDevice]);

	useEffect(() => {
		if (
			deviceManagementFilter.genres.length > 0 ||
			deviceManagementFilter.cities.length > 0 ||
			deviceManagementFilter.instrument.length > 0 ||
			deviceManagementFilter.firstName.length > 0 ||
			deviceManagementFilter.lastName.length > 0 ||
			deviceManagementFilter.nickName.length > 0 ||
			deviceManagementFilter.isOpenForCollaboration ||
			deviceManagementFilter.acceptGigs ||
			deviceManagementFilter.deviceModelType > 0 ||
			deviceManagementFilter.activationDate > 0
		) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
	}, [deviceManagementFilter]);

	const getAllDevices1 = () => {
		try {
			const nextPage = pageNumber + 1;

			const requestBody = {
				globalSearch: deviceManagementFilter.isGlobalSearch,
				userProfileId: deviceManagementFilter.userProfileId,
				firstName: searchDeviceName,
				lastName: searchDeviceName,
				nickname: searchDeviceName,
				location: deviceManagementFilter.cities,
				genres: deviceManagementFilter.genres,
				instruments: deviceManagementFilter.instrument,
				openForCollab: deviceManagementFilter.isOpenForCollaboration,
				openForGigs: deviceManagementFilter.acceptGigs,
				deviceModelType: deviceManagementFilter.deviceModelType,
				activationDate: deviceManagementFilter.activationDate,
			};
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-devices/device-management/filter-search?deviceStatus=${deviceStatus1}&subscriptionStatus=${subscriptionStatus1}&page=${nextPage}&size=${itemPerPage}&orderBy=${orderByField}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						setHasMore(false);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						const updatedRows = data.map((item: any) => ({
							userId: item.user.id,
							deviceId: item.deviceId,
							deviceStatus: item.status,
							userName: `${item.user.firstName} ${item.user.lastName}`,
							subscription: (() => {
								const subStr = item.subscriptionInformationDTOs.reduce(
									(accumulator: any, subscription: any) => {
										if (subscription.type === "Software") {
											if (subscription.status === "ACTIVE") {
												accumulator.push("Subscriber");
											} else if (subscription.status === "EXPIRED") {
												accumulator.push("Expired");
											} else if (subscription.status === "STOPPED") {
												accumulator.push("Cancelled");
											}
										}
										return accumulator;
									},
									[]
								);

								if (subStr.length === 0) {
									return "Free";
								} else if (subStr.length === 1) {
									return subStr[0];
								} else {
									return subStr.join(", ");
								}
							})(),
							lastOnline: !item.lastOnline ? '-' :`${format(
								new Date(item.lastOnline),
								"yyyy-MM-dd hh:mm a"
							)}`,
							actions: "Edit", // Hardcoded value
							replaceDeviceId: item.replaceDeviceId,
						}));
						setRowData((prevRowData) => [...prevRowData, ...updatedRows]);
						setPageNumber(nextPage);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					// setLoading(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	const filterByDeviceAndModelType = (deviceObj: any) => {
		try {
			const nextPage = pageNumber;

			const deviceStatus = deviceObj?.status ? deviceObj.status : "";

			const requestBody = {
				activationDate: filterByDevice.activationDate,
				deviceModelType: filterByDevice.deviceModelType,
			};
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-devices/device-management/filter-by-device?page=0&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						if (data.length === 0) {
							setHasMore(false);
						} else {
							const updatedRows = data.map((item: any) => ({
								userId: item.user.id,
								deviceId: item.deviceId,
								deviceStatus: item.status,
								userName: `${item.user.firstName} ${item.user.lastName}`,
								subscription: "Active",
								lastOnline: item.lastOnline
									? (() => {
											const parsedDateUTC = utcToZonedTime(
												new Date(item.lastOnline),
												"UTC"
											);
											return format(parsedDateUTC, "yyyy-MM-dd hh:mm a", {
												timeZone: "UTC",
											});
									  })()
									: "-",
								actions: "Edit", // Hardcoded value
								replaceDeviceId: item.replaceDeviceId,
							}));

							setRowData(updatedRows);

							// if (pageNumber === 0) {
							// 	setRowData(updatedRows);
							// } else {
							// 	setRowData((prevRowData) => [...prevRowData, ...updatedRows]);
							// }

							setPageNumber(nextPage + 1);
						}
					}
				})
				.catch((err) => {
				})
				.finally(() => {
					// setLoading(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	const handleFilterReset = () => {
		setRowData([]);
		try {
			setHasMore(true);
			setPageNumber(0);
			const firstName = searchDeviceName || "";
			const lastName = searchDeviceName || "";
			const nickName = searchDeviceName || "";

			const requestBody = {
				globalSearch:
					filterById === "device"
						? false
						: deviceManagementFilter.isGlobalSearch,
				userProfileId: deviceManagementFilter.userProfileId,
				firstName: firstName,
				lastName: filterById !== "device" ? lastName : "",
				nickname: filterById !== "device" ? nickName : "",
				location: deviceManagementFilter.cities,
				genres: deviceManagementFilter.genres,
				instruments: deviceManagementFilter.instrument,
				openForCollab: deviceManagementFilter.isOpenForCollaboration,
				openForGigs: deviceManagementFilter.acceptGigs,
				deviceModelType: deviceManagementFilter.deviceModelType,
				activationDate: deviceManagementFilter.activationDate,
			};
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-devices/device-management/filter-search?deviceStatus=${deviceStatus1}&subscriptionStatus=${subscriptionStatus1}&page=0&size=${itemPerPage}&orderBy=${orderByField}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						setHasMore(false);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
						setRowData([]);
					} else {
						const updatedRows = data.map((item: any) => ({
							userId: item.user.id,
							deviceId: item.deviceId,
							deviceStatus: item.status,
							userName: `${item.user.firstName} ${item.user.lastName}`,
							subscription: (() => {
								const subStr = item.subscriptionInformationDTOs.reduce(
									(accumulator: any, subscription: any) => {
										if (subscription.type === "Software") {
											if (subscription.status === "ACTIVE") {
												accumulator.push("Subscriber");
											} else if (subscription.status === "EXPIRED") {
												accumulator.push("Expired");
											} else if (subscription.status === "STOPPED") {
												accumulator.push("Cancelled");
											}
										}
										return accumulator;
									},
									[]
								);

								if (subStr.length === 0) {
									return "Free";
								} else if (subStr.length === 1) {
									return subStr[0];
								} else {
									return subStr.join(", ");
								}
							})(),
							lastOnline: !item.lastOnline ? '-' :`${format(
								new Date(item.lastOnline),
								"yyyy-MM-dd hh:mm a"
							)}`,
							actions: "Edit", // Hardcoded value
							replaceDeviceId: item.replaceDeviceId,
						}));

						setRowData(updatedRows);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					// setLoading(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	return (
		<UserManageFilterContext.Provider
			value={{
				deviceManagementFilter,
				setDeviceManagementFilter,
				isFilterActive,
				resetDeviceManagementFilter,
				filterByDeviceAndModelType,
				rowData,
				setRowData,
				getAllDevices1,
				hasMore,
				setPageNumber,
				setFilterByDevice,
				filterByDevice,
				handleFilterReset,
				setDeviceStatus1,
				setSubscriptionStatus1,
				deviceStatus1,
				subscriptionStatus1,
				searchDeviceName,
				setSearchDeviceName,
				inputRef,
				filterById,
				setFilterById,
				selectedDeviceIds,
				setSelectedDeviceIds,
				setOrderByField,
				orderByField,
			}}
		>
			{children}
		</UserManageFilterContext.Provider>
	);
};

// Custom hook to consume the context
export function useDeviceManageFilter() {
	return useContext(UserManageFilterContext);
}

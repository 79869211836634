import React, {useState} from "react";
import {
  Button,
  MenuList,
  MenuItem
} from "@mui/material";

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { menuOptions } from "./AdminConstants";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import { useAdminDevciceManagement } from "./useAdminDeviceManagement";
import AdminImportDevicesCsvModal from "./AdminImportDevicesCsvModal";
import { saveTextAsFile } from "../../../utils/file";

interface adminDeviceImportExport{
  userCanPerformActions : boolean
}


export default function AdminDeviceImportExport(props: adminDeviceImportExport) {

  const {userCanPerformActions} = props;

  const token = localStorage.getItem("sessionId");
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { updateAlertStatus } = useAlertStatus();

  const {
    rowData,
    manufacturer
  } = useAdminDevciceManagement();


  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    value: string | any,
    index: number | any
  ) => {
    setSelectedIndex(index);
    processSelection(value)
  };

  const processSelection = async(selectedValue: string) => {
    console.log('Selection', selectedValue)
    if(selectedValue === 'export'){
      await exportToCSV()
    }else if(selectedValue === 'template'){
      exportToCSV(true);
    }else{
      setOpen(false);
      setIsModalOpen(true)    
    }
  }

	const exportToCSV = async(isTemplate = false) => {
    setIsLoading(true);
		try {
      let url = `${process.env.REACT_APP_BASE_URL}/api/devices/csv/export`;

      if(isTemplate){
        url = `${url}?template=true`
      }
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.ok) {
        const blob = await response.blob();
        downLoadCSV(blob, isTemplate);
        setTimeout(() => {
          setIsLoading(false);
          updateAlertStatus(true, true, `Devices has been exported successfully!`);
        }, 1000);
      } else {
        updateAlertStatus(true, false, 'Network Error! Please try again..');
        setOpen(false);
        setIsLoading(false);
      }
    } catch (error) {
      updateAlertStatus(true, false, 'Network Error! Please try again..');
      setOpen(false);
      setIsLoading(false);
    }
	}

  const downLoadCSV = (blob: Blob, isTemplate:boolean = false) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    const filename = `devices_${isTemplate ? 'template_' : ''}${new Date().toISOString()}.csv`;
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    setOpen(false);
    downloadCSVInstructions()
  }

  const downloadCSVInstructions = () => {
    const instructions = `#### Instruction for import devices CSV ### \n\r
    Please make sure, you are adding correct data. deviceId fields value should not be duplicate and already present in device inventory.
    Also, make sure you are using the correct ID of the device manufacturer and not usng their name.  \n\r
    To get the valid IDs of the device manufacturers, please refer the below list of device manufacturers.    \n\r
    For ex - from below list, you can enter the "id" of the respective manufacturers in "deviceManufacturedBy" column.  \n\r
    Manufacturer List - \n ${JSON.stringify(manufacturer, null, 2)}
  `
    saveTextAsFile(instructions, 'device_csv_import_instructions', 'text/plain');
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="import/export menu"
        sx={{boxShadow: 'none'}}
      >
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="import/export"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={!userCanPerformActions}
        > Import/Export
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? 'none' : 'auto'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {menuOptions.map((option, index) => (
                    <MenuItem
                      key={option.title}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, option.value, index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <AdminImportDevicesCsvModal open={isModalOpen} onClose={setIsModalOpen}/> 
    </React.Fragment>
  )
}

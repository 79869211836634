import {
	Box,
	Grid,
	Paper,
	Typography,
	Snackbar,
	Alert,
	AlertTitle,
	TextField,
	Button,
	DialogActions,
	Dialog,
	DialogContentText,
	DialogContent,
	DialogTitle,
	IconButton,
	CircularProgress,
	Card,
	CardContent,
	CardActions,
	Checkbox,
	FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import CloseIcon from "../../../images/close.svg";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import { useLocation, useNavigate } from "react-router-dom";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		promptBtn: {
			width: "100%",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		searchField: {
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				borderRadius: "17px",
			},
		},
		optionButtons: {
			border: "1.5px solid #0273E6 !important",
			borderRadius: "15px !important",
			color: "#0273E6 !important",
			fontStyle: "normal !important",
			fontWeight: "bold !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

const AdminActions = (props: any) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [openCloseDialog, setOpenCloseDialog] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [editImageStatus, setEditImageStatus] = useState(false);
	const [editItemId, setEditItemId] = useState();
	const [deleteItemId, setDeleteItemId] = useState();
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();

	const { state } = location;
	const {
		userId,
		userProfileId,
		email,
		user: userDetails,
		userName,
		userStatus,
	} = state || {};

	const [confirm, setConfirm] = useState(false);
	const isAdmin: boolean = useAdminAuthorized("isAdminUserManagementAdmins");

	const [checkRequired, setCheckRequired] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

	const handleCheckboxChange = (event:any) => {
		setIsChecked(event.target.checked);
		if(checkRequired){
			setCheckRequired(false)
		}
		
  };

	const handleConfirmChange = (event: any) => {
		setConfirm(event.target.checked);
	};
	useEffect(() => {
		console.log("useEffect triggered");
		console.log("Received state values:", {
			email,
			userId,
			userProfileId,
			userName,
		});
	}, []);

	const handleEditDialogClose = () => {
		setOpenEditDialog(false);
	};

	const handleEditDialog = () => {
		setOpenEditDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};

	const adminAction1 = (action: string) => {
		setIsLoading(true);
		try {
			const moderationDTOs = [{ offenderUserProfileId: userProfileId }];
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/action-on-user-account?userAccountStatus=${action}&forType=User`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(moderationDTOs),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("API Error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					const action = data[0].offenderUserProfile.userAccountStatus;
					setSnackbarMessage(`Account ${action.toLowerCase()}d successfully`);
					setSnackbarOpen(true);
					setIsLoading(false);
					handleCloseDeleteDialog();
					navigate('/users-management/');
				})
				.catch((err) => {
					setSnackbarMessage("An error occurred");
					setSnackbarOpen(true);
					setIsLoading(false);
				});
		} catch (error) {
			setSnackbarMessage("An error occurred");
			setSnackbarOpen(true);
			setIsLoading(false);
		}
	};


	return (
		<div>
			{isLoading ? (
				<Grid sx={{ textAlign: "center" }}>
					<CircularProgress />
				</Grid>
			) : (
				<Grid
					container
					xs={12}
					sx={{ marginLeft: "24px", display: "flex" }}
					className="centerPanelwoRight"
				>
					<Grid item={true} xs={9} className="centerPanelWidth">
						<Card
							sx={{
								background: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "12px",
							}}
						>
							<CardContent sx={{ padding: "24px" }}>
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										fontSize: "20px",
									}}
									color="#000000"
								>
									{" "}
									Suspend Account
								</Typography>
								<Box
									sx={{
										marginTop: "20px",
										marginBottom: "78px",
										color: "#5b5b65",
									}}
								>
									<Typography sx={{ color: "#5b5b65", fontSize: "16px" }}>
										Suspending account will result into :
									</Typography>

									<ul
										style={{
											margin: "0",
											paddingInlineStart: "25px",
											color: "#5b5b65",
										}}
									>
										<li>
											Account will be hidden from everywhere. Cannot be searched
										</li>
										<li>Prevent log-in</li>
										<li>Data retained</li>
										<li>Send email confirmation from Kaviyo</li>
									</ul>
								</Box>
								<CardActions
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end",
										marginBottom: "8px",
									}}
								>
									<Button
										className="submit-button button-field-custom-font-size"
										size="large"
										variant="contained"
										sx={{ width: "198px" }}
										onClick={() => setOpenDeleteDialog(true)}
									>
										Suspend Account
									</Button>
								</CardActions>

								<hr />

								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										fontSize: "20px",
										marginTop: "16px",
									}}
									color="#000000"
								>
									{" "}
									Close Account
								</Typography>
								<Box sx={{ marginTop: "20px", marginBottom: "78px" }}>
									<Typography sx={{ color: "#5b5b65", fontSize: "16px" }}>
										Delete & Close account will result into :
									</Typography>

									<ul
										style={{
											margin: "0",
											paddingInlineStart: "25px",
											color: "#5b5b65",
										}}
									>
										<li>
											Account will be hidden from everywhere. Cannot be searched
										</li>
										<li>Prevent log-in</li>
										<li>Delete data after 30 days</li>
										<li>Send email confirmation from Kaviyo</li>
									</ul>
								</Box>

								<CardActions
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end",
										marginBottom: "8px",
									}}
								>
									<Button
										className="submit-button button-field-custom-font-size"
										size="large"
										variant="contained"
										sx={{ width: "198px" }}
										onClick={() => setOpenCloseDialog(true)}
									>
										Close Account
									</Button>
								</CardActions>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			)}
			{openCloseDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openCloseDialog}
					onClose={() => setOpenCloseDialog(false)}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => setOpenCloseDialog(false)}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Close Account</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure you wan to Close the account for below user. This is
							irreversible
						</DialogContentText>

						<Grid container xs={12} sx={{ marginTop: "24px" }}>
							<Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
								<Typography
									sx={{
										fontWeight: 400,
										color: "rgba(122, 121, 133, 1)",
										fontFamily: "Lexend",
										fontSize: "16px !important",
										justifyContent: "center",
										textAlign: "center",
									}}
								>
									User :
								</Typography>
							</Grid>
							<Grid item xs={8} sx={{ display: "flex", alignContent: "start" }}>
								<Typography
									sx={{
										fontWeight: 400,
										color: "rgba(77, 76, 79, 1)",
										fontFamily: "Lexend",
										fontSize: "16px !important",
										justifyContent: "center",
										textAlign: "center",
										paddingLeft: "5px",
									}}
								>
									{email}
								</Typography>
							</Grid>
						</Grid>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							{" "}
							<FormControlLabel
								control={
									<Checkbox																
										name="confirm"
										color="primary"
										checked={isChecked}
										onChange={handleCheckboxChange}
									/>
								}
								label="I confirm to delete and close this account"
								sx={{ mt: 2 }}
							/>{" "}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							sx={{ width: "150px" }}
							onClick={() => setOpenCloseDialog(false)}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							
							sx={{ width: "150px" }}
							disabled={!isAdmin || !isChecked}
							onClick={() => adminAction1("INACTIVE")}
						>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			)}
			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Suspend Account</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure you want to Suspend the account for user below
						</DialogContentText>

						<Grid container xs={12} sx={{ marginTop: "24px" }}>
							<Grid item xs={4} sx={{ display: "flex", justifyContent: "end" }}>
								<Typography
									sx={{
										fontWeight: 400,
										color: "rgba(122, 121, 133, 1)",
										fontFamily: "Lexend",
										fontSize: "16px !important",
										justifyContent: "center",
										textAlign: "center",
									}}
								>
									User :
								</Typography>
							</Grid>
							<Grid item xs={8} sx={{ display: "flex", alignContent: "start" }}>
								<Typography
									sx={{
										fontWeight: 400,
										color: "rgba(77, 76, 79, 1)",
										fontFamily: "Lexend",
										fontSize: "16px !important",
										justifyContent: "center",
										textAlign: "center",
										paddingLeft: "5px",
									}}
								>
									{email}
								</Typography>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							sx={{ width: "150px" }}
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							disabled={!isAdmin || editImageStatus}
							sx={{ width: "150px", color: isAdmin ? "#FFFFFF" : "#757575",
								cursor: isAdmin ? "pointer" : "not-allowed", }}
							onClick={() => adminAction1("SUSPEND")}
						
						>
							Suspend
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default AdminActions;

import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
	Typography,
	Divider,
	Grid,
	FormHelperText,
	TextField,
	Checkbox,
	FormControlLabel,
	Autocomplete,
	Button,
	FormControl,
	Select,
	MenuItem,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../../context/UserContext";
import i18n from "../../../l10n/strings.json";
import InstrumentSkillLevel from "../../Musicians/InstrumentSkills";
import { useMusiciansFilter } from "../../Musicians/MusicProvider/useMusicianFilter";
import ErrorAlert from "../../Error/ErrorAlert";
import { useUserModerationFilter } from "./ModerationManagementProvider/useUserModerationFilter";
import { useContentModerationFilter } from "./ContentModerationManagementProvider/useContentModerationFilter";
import GoogleLocationService from "../../GoogleLocationService";
import { useDebouncedFields } from "../../../hooks/useDebounceHooks/useDebouncedFields";

type RouteParams = {
	menuId: string;
};

interface MyComponentProps {
	menu: string;
}

type ModerationStatus = {
	title: string;
	value: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			padding: "1% 17px",
			borderRadius: "15px",
			//boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		backBtn: {
			width: "100%",
			fontWeight: "500 !important",
			color: "#0273E6 !important",
		},
		root: {
			color: "white",
			"& .MuiAutocomplete-tag": {
				backgroundColor: "blue",
				color: "white",
			},
			"& .MuiSvgIcon-root": {
				color: "white",
			},
		},
	})
);

type Genres = {
	title: string;
};

type Instrument = {
	id: string;
	instrumentName: string;
	icon?: string;
};

const genreFilter: Array<Genres> = [
	{ title: "Americana" },
	{ title: "Avant-garde" },
	{ title: "Baroque" },
	{ title: "Big Band" },
	{ title: "Bluegrass" },
	{ title: "Blues" },
	{ title: "Bollywood" },
	{ title: "Broadway/Showtunes" },
	{ title: "Chamber-Music" },
	{ title: "Classic Rock" },
	{ title: "Classical" },
	{ title: "Contemporary Classical" },
	{ title: "Country" },
	{ title: "Disco" },
	{ title: "Drumline" },
	{ title: "EDM" },
	{ title: "Electronic/Dance" },
	{ title: "Folk" },
	{ title: "Funk" },
	{ title: "Gospel" },
	{ title: "Hip-hop/Rap" },
	{ title: "House" },
	{ title: "Indie/Alternative" },
	{ title: "Jazz" },
	{ title: "K-Pop" },
	{ title: "Latin" },
	{ title: "Metal" },
	{ title: "Minimalism" },
	{ title: "New Age" },
	{ title: "Opera" },
	{ title: "Orchestral" },
	{ title: "Pop" },
	{ title: "Punk" },
	{ title: "R&B" },
	{ title: "Ragtime" },
	{ title: "Reggae" },
	{ title: "Reggaeton" },
	{ title: "Religious" },
	{ title: "Rock" },
	{ title: "Romantic" },
	{ title: "Salsa" },
	{ title: "Ska" },
	{ title: "Soul" },
	{ title: "Soundtrack" },
	{ title: "Swing" },
	{ title: "Symphonic" },
	{ title: "Techno" },
	{ title: "Wind Ensemble" },
];

const ContentModerationFilter = () => {
	const location = useLocation();
	const param = useParams<RouteParams>();
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const {
		contentModerationFilter,
		setContentModerationFilter,
		getAllUsers,
		isFilterActive,
		resetUserModerationFilter,
		rowData,
		setRowData,
		updatePendingCount,
		setUpdatePendingCount,
	} = useContentModerationFilter();

	const [pageTitle, setPageTitle] = useState("");
	const [genres, setGenres] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);

	const [instrumentList, setInstrumentList] = useState<Array<Instrument>>([]);
	const [selectedInstrumentFilter, setSelectedInstrumentsFilter] = useState<
		Array<Instrument> | []
	>([]);
	const [totalViolations, setTotalViolations] = useState<Number>(0);
	const [pendingActions, setPendingActions] = useState<Number>(0);

	const updateSelectedInstruments = (newInstrumentSet: any) => {
		setSelectedInstrumentsFilter(newInstrumentSet);
	};

	const updateInstrumentFilter = (instrument: string, skillLevel: number) => {};

	const [immediateEmail, setImmediateEmail] = useState<String>("");
	const { fields, handleFieldChange } = useDebouncedFields({ email: "" }, 500);
	const [moderationStatus, setModerationStatus] = useState("");
	const dummyEvent = {
		target: {
			value: "",
		},
	};
	const updateSearchFilter = (field: string, value: any) => {
		switch (field) {
			case "genres":
				var selectedGenres: any = [];
				value.map((genre: any) => {
					selectedGenres.push(genre.title);
				});
				setContentModerationFilter({
					...contentModerationFilter,
					isGlobalSearch: false,
					genres: selectedGenres,
				});
				break;
			case "instruments":
				var selectedInstrument: any = [];
				value.map((inst: any) => {
					selectedInstrument.push(inst.instrumentName);
				});
				setContentModerationFilter({
					...contentModerationFilter,
					isGlobalSearch: false,
					instrument: selectedInstrument,
				});
				break;
			case "collaboration":
				setContentModerationFilter({
					...contentModerationFilter,
					isGlobalSearch: false,
					isOpenForCollaboration: value,
				});
				break;
			case "location":
				setContentModerationFilter({
					...contentModerationFilter,
					cities: value,
					isGlobalSearch: false,
				});
				break;
			case "acceptGigs":
				setContentModerationFilter({
					...contentModerationFilter,
					acceptGigs: value,
					isGlobalSearch: false,
				});
				break;

			case "moderationStatus":
				setContentModerationFilter({
					...contentModerationFilter,
					moderationStatus: value !== "" ? value : null,
					isGlobalSearch: true,
				});
				break;
			case "email":
				setContentModerationFilter({
					...contentModerationFilter,
					email: value !== "" ? value : null,
					isGlobalSearch: true,
				});
				break;
			default:
				break;
		}
	};

	const handleEmailChange = (event: any) => {
		const value = event.target.value;
		setImmediateEmail(value);
		handleFieldChange("email")(event);
	};

	
	useEffect(() => {
		updateSearchFilter("email", fields.email !== "" ? fields.email : null);
	}, [fields.email]);

	useEffect(() => {
		updateSearchFilter("moderationStatus", moderationStatus);
	}, [moderationStatus]);

    useEffect(() => {
        // Clear the debounced email state
        setImmediateEmail("");
    }, [location.pathname]);

	const clearFilter = () => {
		handleFieldChange("email")(dummyEvent);
		setImmediateEmail("");
		setModerationStatus("");
		resetUserModerationFilter();
	};

	const moderationStatusOptions: Array<ModerationStatus> = [
		{ title: "Select status", value: "" },
		{ title: "Pending", value: "PENDING" },
		{ title: "Done", value: "DONE" },
	];

	useEffect(() => {
		getInstrumentsList();
		getTotalReportedUser();
		getTotalPendingActions();
	}, []);

	useEffect(() => {
		if (updatePendingCount === true) {
			getTotalPendingActions();
		}
		setUpdatePendingCount(false);
	}, [updatePendingCount === true]);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setInstrumentList(data);
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getTotalReportedUser = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/moderations/count-moderation?moderationStatus=&forType=Content`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setTotalViolations(result);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const getTotalPendingActions = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/moderations/count-moderation?moderationStatus=PENDING&forType=Content`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				setPendingActions(result);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	return (
		<>
			<Grid
				container
				margin={0.5}
				xs={12}
				className={`${classes.shadowedBox}`}
				sx={{ height: "450px" }}
			>
				<Grid item xs={12}>
					<Typography
						variant="h5"
						sx={{
							textAlign: "center",
							fontFamily: "Lexend",
							fontSize: "24px",
							lineHeight: "30px",
							fontWeight: 500,
						}}
					>
						Filters
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Grid container={true}>
						<Grid item md={10} xs={10}>
							<FormHelperText
								className="jamsession-label"
								sx={{
									fontWeight: 400,
									fontSize: "1rem",
									fontFamily: "Lexend",
									lineHeight: "20px",
								}}
							>
								Total Violations
							</FormHelperText>
						</Grid>
						<Grid item md={2} xs={2}>
							<Typography
								variant="h4"
								component="div"
								style={{
									fontFamily: "Lexend",
									fontSize: "24px",
									fontWeight: "500",
									lineHeight: "30px",
									textAlign: "center",
									marginTop: "5px",
								}}
							>
								{`${totalViolations}`}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container={true}>
						<Grid item md={10}>
							<FormHelperText
								className="jamsession-label"
								sx={{
									fontWeight: 400,
									fontSize: "1rem",
									fontFamily: "Lexend",
									lineHeight: "20px",
								}}
							>
								Pending Actions
							</FormHelperText>
						</Grid>
						<Grid item md={2}>
							<Typography
								variant="h4"
								component="div"
								style={{
									fontFamily: "Lexend",
									fontSize: "24px",
									fontWeight: "500",
									lineHeight: "30px",
									textAlign: "center",
									marginTop: "5px",
								}}
							>
								{`${pendingActions}`}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				{/* <Grid item xs={12}>
					<FormHelperText className="jamsession-label" sx={{fontFamily: "Lexend", marginTop: "5px"}}>
						{i18n.musicians.filters.genresTitle}
					</FormHelperText> */}
				{/* <TextField
                        inputProps={{
                            style: {
                                background: "#F8F8F8",

                                fontStyle: "normal",
                                fontSize: "1.125rem",
                                lineHeight: "25px",
                                fontWeight: 900,
                                color: "#000000",
                                // padding:"10px",
                            },
                        }}
                        fullWidth
                        variant="outlined"
                        value={genres}
                        onChange={(event) =>
                            setGenres(event.target.value.trimStart())
                        }
                        error={genres.length > 64}
                        size="small"
                    ></TextField> */}
				{/* <Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags"
						options={genreFilter}
						getOptionLabel={(option) => option.title}
						classes={classes}
						// getOptionDisabled={(option) => !!selectedInstrumentFilter.find(element => element.id === option.id)}
						// defaultValue={}
						value={genreFilter.filter((genre: any) => {
							return contentModerationFilter.genres.includes(genre.title as never);
						})}
						renderInput={(params) => (
							<TextField {...params} placeholder="Genres" />
						)}
						sx={{ width: "100%" }}
						onChange={(event, newGenres) =>
							updateSearchFilter("genres", newGenres)
						}
					/> */}
				{/* <FormHelperText
						sx={{
							fontSize: "0.8125rem",
							color: "#FD4B24",
							textAlign: "right",
						}}
					>
						{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
					</FormHelperText> */}
				{/* </Grid> */}

				{/* <Grid item xs={12}>
					<FormHelperText className="jamsession-label" sx={{fontFamily: "Lexend"}}>
						{i18n.musicians.filters.cityTitle}
					</FormHelperText> */}
				{/* <TextField
						inputProps={{
							style: {
								background: "#F8F8F8",

								fontStyle: "normal",
								fontSize: "1rem",
								lineHeight: "25px",
								fontWeight: 900,
								color: "#000000",
								padding: "15px",
							},
						}}
						fullWidth
						variant="outlined"
						value={contentModerationFilter.cities}
						onChange={(event) =>
							updateSearchFilter("location", event.target.value)
						}
						error={contentModerationFilter.cities.length > 64}
						size="small"
					></TextField> */}

				{/* <GoogleLocationService
								onLocationChange={(locationDesc: string) => {
									if(locationDesc !== ""){
										updateSearchFilter("location", locationDesc);
									}
									// setCity(locationDesc);
								}}
					/>
					<FormHelperText
						sx={{
							fontSize: "0.8125rem",
							color: "#FD4B24",
							textAlign: "right",
						}}
					> */}
				{/* {genres?.length > 64 ? "Enter maximum 64 characters" : ""}
					</FormHelperText>
				</Grid> */}

				{/* <Grid item xs={12}>
					<FormHelperText className="jamsession-label" sx={{fontFamily: "Lexend"}}>
						{i18n.musicians.filters.instrumentsTitle}
					</FormHelperText>
					<Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags1"
						options={instrumentList}
						getOptionLabel={(option) => option.instrumentName}
						classes={classes}
						// getOptionDisabled={(option) => !!selectedInstrumentList.find(element => element.id === option.id)}
						// defaultValue={}
						value={instrumentList.filter((ins: any) => {
							return contentModerationFilter.instrument.includes(
								ins.instrumentName as never
							);
						})}
						renderInput={(params) => <TextField {...params} />}
						sx={{ width: "100%" }}
						onChange={(event, newInstrument) =>
							updateSearchFilter("instruments", newInstrument)
						}
					/>
				</Grid> */}
				{/* Instruments Music Notes */}
				{/* <Grid item xs={12} marginY={1}>
					<Grid item>
						{selectedInstrumentFilter.map((instrument) => {
							return (
								<InstrumentSkillLevel
									instrument={instrument.id}
									skillLevel={0}
									editable={true}
									updateInstrumentFilter={updateInstrumentFilter}
								/>
							);
						})}
					</Grid>
				</Grid> */}
				<Grid item xs={12}>
					<FormHelperText
						className="jamsession-label"
						sx={{ fontFamily: "Lexend" }}
					>
						Moderation Status
					</FormHelperText>
					<Select
						value={moderationStatus}
						onChange={(event) => {
							setModerationStatus(event.target.value);
						}}
						sx={{
							width: "100%",
							backgroundColor: "#f4f4f4",
							color: "#333",
							borderRadius: "4px",
							".MuiOutlinedInput-notchedOutline": {
								borderColor: "#ccc",
							},
							"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
								borderColor: "#1976d2",
							},
							"& .MuiSvgIcon-root": {
								color: "#1976d2",
							},
						}}
					>
						{moderationStatusOptions.map((statusOptions: ModerationStatus) => (
							<MenuItem key={statusOptions.title} value={statusOptions.value}>
								{statusOptions.title}
							</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid item xs={12}>
					<FormHelperText
						className="jamsession-label"
						sx={{ fontFamily: "Lexend" }}
					>
						Email
					</FormHelperText>
					<TextField
						inputProps={{
							style: {
								background: "#F8F8F8",

								fontStyle: "normal",
								fontSize: "1rem",
								lineHeight: "25px",
								fontWeight: 900,
								color: "#000000",
								padding: "15px",
							},
						}}
						fullWidth
						variant="outlined"
						value={immediateEmail}
						onChange={handleEmailChange}
						error={contentModerationFilter?.email?.length > 64}
						size="small"
					></TextField>
				</Grid>

				{/* Hiding Collaboration Content for social-site launch */}
				{/* {
				// user.isCollaborationActive === true ?
						<>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											onChange={(event) =>
												updateSearchFilter("collaboration", event.target.checked)
											}
											sx={{ "&.Mui-checked": { color: "#0273e6", fontSize: "16px" } }}
										/>
									}
									label="Open for Collaboration"
									checked={contentModerationFilter.isOpenForCollaboration}
									sx={{fontSize: "16px"}}
								/>
							</Grid>

							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											onChange={(event) =>
												updateSearchFilter("acceptGigs", event.target.checked)
											}
											sx={{ "&.Mui-checked": { color: "#0273e6" } }}
										/>
									}
									label="Accept Gigs"
									checked={contentModerationFilter.acceptGigs}
									sx={{fontSize: "16px"}}
								/>
							</Grid>
						</>
					// : <></>
				} */}

				{/* {isFilterActive && (
					<>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid container xs={12} justifyContent="center" marginY={2}>
						<Button
							variant="text"
							sx={{ border: "2px solid #0273E6", color: "#0273E6" }}
							size="small"
							onClick={() => clearFilter()}
						>
							<Typography variant="h6">Clear Filter</Typography>
						</Button>
					</Grid>
					</>
				)} */}

				<>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Button
							size="large"
							sx={{
								width: "100%",
								fontSize: "18px !important",
								marginTop: "10px",
							}}
							fullWidth
							variant="outlined"
							onClick={clearFilter}
							disabled={!isFilterActive}
						>
							Reset All
						</Button>
					</Grid>
				</>
			</Grid>
		</>
	);
};

export default ContentModerationFilter;

import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const StartTimePicker = (props: any) => {
	const theme = useTheme();
	return (
		<TimePicker
			value={props.startDate}
			onChange={(newValue: Date | null) => {
				props.setStartDate(newValue);
			}}
			// minTime={props.getMinStartTime()}
			renderInput={(params) => (
				<TextField
					{...params}
					sx={{
						input: {
							color: 'customColor.black', fontSize: theme.typography.subtitle1.fontSize,
							fontWeight: theme.typography.subtitle1.fontWeight,
						},
						"&.MuiTextField-root": {
							backgroundColor: "customColor.gray1",
							width: "100%",
						},
					}}
				/>
			)}
		/>
	);
};

export default StartTimePicker;

import { useState, useEffect } from "react";
import { Participant } from "twilio-video";
import { useAppState } from "../../../state";

export function usePagination(participants: Participant[]) {
	const [currentPage, setCurrentPage] = useState(1); // Pages are 1 indexed
	const itemPerPage = 12;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { maxGalleryViewParticipants } = useAppState();
	let totalPages = 1;
	if (participants.length > 0) {
		totalPages = Math.ceil(
			participants.length / itemPerPage
		);
	}

	const isBeyondLastPage = currentPage > totalPages;

	useEffect(() => {
		if (isBeyondLastPage) {
			setCurrentPage(totalPages);
		}
	}, [isBeyondLastPage, totalPages]);

	let participantsList = participants;

	let paginatedParticipants = participants.slice(
		(currentPage - 1) * itemPerPage,
		currentPage * itemPerPage
	);

	return { participantsList, paginatedParticipants, setCurrentPage, currentPage, totalPages };
}

import React, { useEffect } from "react";

declare global {
	interface Window {
		adsbygoogle: any[];
	}
}

interface AdsComponentProps {
	dataAdSlot: string;
}

const AdsComponent: React.FC<AdsComponentProps> = ({ dataAdSlot }) => {
	useEffect(() => {
		try {
			(window.adsbygoogle = window.adsbygoogle || []).push({});
		} catch (e) {}
	}, []);

	return (
		<>
			<ins
				className="adsbygoogle"
				style={{ display: "block" }}
				data-ad-test="on"
				data-ad-client="ca-pub-3438239046038323"
				data-ad-slot={dataAdSlot}
				data-ad-format="auto"
				data-full-width-responsive="true"
			></ins>
		</>
	);
};

export default AdsComponent;

import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	Collapse,
	Divider,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	Slider,
	TableCell,
	TableRow,
	Typography,
	styled,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandSquare from "../../../images/add-square1.svg";
import MinimizeSquare from "../../../images/minus-square.svg";
import ArrowRight from "../../../images/arrow-right-next.svg";
import People from "../../../images/peoples.svg";
import Clock from "../../../images/Admin/clock.svg";
import ClockNo from "../../../images/Admin/clock-No.svg";
import CardsNo from "../../../images/Admin/card-slash.svg";
import MusicNo from "../../../images/Admin/music-no.svg";
import Cards from "../../../images/Admin/cards.svg";
import Calendar from "../../../images/Admin/calendar.svg";
import Music from "../../../images/Admin/music.svg";
import Online from "../../../images/Admin/online.svg";
import Offline from "../../../images/offlineDevice.svg";
import { useEffect, useState } from "react";
import Shield from "../../../images/Admin/shield-tick.svg";
import ErrorAlertDisplay from "../../Error/ErrorAlertDisplay";
import ErrorAlert from "../../Error/ErrorAlert";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import { useUserModerationFilter } from "./ModerationManagementProvider/useUserModerationFilter";
import useAdminAuthorized from "../../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import CloseIcon from "../../../images/close.svg";

	const UserView = ({ row, isItemSelected, labelId, getAllUsers }: any) => {
	const [viewPost, setViewPost] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const options = ["SUSPEND", "BAN", "TERMINATE"];
	const [loading, setLoading] = useState(false);
	const token = localStorage.getItem("sessionId");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [userAccountStatus, setUserAccountStatus] = useState(
		row.userAccountStatus
	);
	const [profilePhotoUrl, setProfilePhotoUrl] = useState("");
	const [updateUserStatus, setUpdateUserStatus] = useState("");
	const { updateUserPendingCount, setUpdateUserPendingCount } =
		useUserModerationFilter();
	const isModeratorAdmin: boolean = useAdminAuthorized("isModeratorAdmins");
	const [openSuspendDialog, setOpenSuspendDialog] = useState(false);
	const [openReactivateDialog, setOpenReactivateDialog] = useState(false);
	const [openCloseDialog, setOpenCloseDialog] = useState(false);
	const [openReinstateDialog, setOpenReinstateDialog] = useState(false);
	const { updateAlertStatus } = useAlertStatus();
	useEffect(() => {
		setViewPost(false);
	}, [row]);

	useEffect(() => {
		setProfilePhotoUrl(
			row.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${row.profilePhotoUrl}`
				: ""
		);
		setUpdateUserStatus(row.status === "PENDING" ? "IN PROCESS" : row.status);
	}, [row]);
	useEffect(() => {
		setUserAccountStatus(row.userAccountStatus);
	}, [row.userAccountStatus]);

	const navigate = useNavigate();
	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleCloseSuspendDialog = () => {
		setOpenSuspendDialog(false);
	  };

	const handleCloseReactivateDialog = () => {
		setOpenReactivateDialog(false);
	}

	const handleCloseCloseDialog = () => {
		setOpenCloseDialog(false);
	}

	const handleCloseReinstateDialog = () => {
		setOpenReinstateDialog(false);
	}

	const handleActions = (option: any) => {
		try {
			const moderationDTOs = [
				{ offenderUserProfileId: row.offenderUserProfileId },
			];
			setLoading(true);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/action-on-user-account?userAccountStatus=${option}&forType=User`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(moderationDTOs),
				}
			)
				.then((res) => {
					if (res.status !== 200 && res.status !== 201) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						// getAllUsers(null);
						setUpdateUserStatus("DONE");
						setUpdateUserPendingCount(true);
						setAnchorEl(null);
						setUserAccountStatus(option === "REACTIVATE" ? "ACTIVE" : option);
						const displayText:any ={
							SUSPEND: "Account suspended successfully",
							REACTIVATE: "Account activated successfully",
							INACTIVE: "Account closed successfully"
						}
						updateAlertStatus(true, true, displayText[option]);
						if (option === "SUSPEND") {
							handleCloseSuspendDialog();
						}
						else if (option === "REACTIVATE") {
							handleCloseReactivateDialog();
							handleCloseReinstateDialog();
						}
						else if (option === "INACTIVE"){
							handleCloseCloseDialog();
						}
						return res.json();
					}
				})
				.then((data) => {})
				.catch((err) => {})
				.finally(() => {
					setAnchorEl(null);
					setLoading(false);
				});
		} catch (error) {
			setAnchorEl(null);
			setLoading(false);
		}
	};

	const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const viewUserProfile = () => {
		const encodedNickName = encodeURIComponent(row.nickName);
		navigate(`/userProfile/` + encodedNickName);
	};

	const getUserStatus = (userStatus: any) => {
		if (userStatus === null) {
			return row.status;
		} else {
			if (userStatus === "SUSPEND") {
				return "Suspended";
			} else if (userStatus === "BAN") {
				return "Banned";
			} else if (userStatus === "TERMINATE") {
				return "Terminated";
			} else if (userStatus === "ACTIVE") {
				return "Active";
			} else if (userStatus === "INACTIVE") {
				return "Inactive";
			} else {
				return userStatus;
			}
		}
	};

	const getTime = (date: any) => {
		const originalTimestamp = new Date(date);
		const year = originalTimestamp.getFullYear();
		const month = String(originalTimestamp.getMonth() + 1).padStart(2, "0");
		const day = String(originalTimestamp.getDate()).padStart(2, "0");
		const hours = originalTimestamp.getHours();
		const minutes = originalTimestamp.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		const formattedHours = hours % 24 || 12;
		const NewTime = `${year}-${month}-${day} - ${formattedHours}:${String(
			minutes
		).padStart(2, "0")}`;
		return NewTime;
	};
	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<TableRow
				hover
				// onClick={(event) => handleClick(event, row.name)}
				role="checkbox"
				aria-checked={isItemSelected}
				tabIndex={-1}
				key={row.name}
				selected={isItemSelected}
				sx={{
					cursor: "pointer",
					fontSize: "1rem",
					fontWeight: 400,
					fontFamily: "Lexend",
				}}
			>
				<TableCell padding="checkbox">
					{/* <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                /> */}
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setViewPost(!viewPost)}
						sx={{ paddingLeft: "8px !important" }}
					>
						{viewPost ? (
							<img src={MinimizeSquare} />
						) : (
							<img src={ExpandSquare} />
						)}
					</IconButton>
				</TableCell>
				<TableCell
					component="th"
					id={labelId}
					scope="row"
					padding="none"
					sx={{ fontSize: "16px !important", paddingLeft: "8px !important" }}
				>
					{row.name}
				</TableCell>
				<TableCell
					align="left"
					padding="none"
					sx={{ fontSize: "16px !important", paddingLeft: "8px !important" }}
				>
					{getTime(row.reportDate)}
				</TableCell>
				<TableCell
					align="left"
					padding="none"
					sx={{ fontSize: "16px !important", paddingLeft: "8px !important" }}
				>
					{row.count}
				</TableCell>
				<TableCell
					align="left"
					sx={{
						p: "0 !important",
						fontSize: "16px !important",
						paddingLeft: "8px !important",
					}}
				>
					{updateUserStatus}
				</TableCell>

				<TableCell
					align="left"
					sx={{
						p: "0 !important",
						fontSize: "16px !important",
						paddingLeft: "8px !important",
					}}
				>
					<IconButton
						aria-label="more"
						id="long-button"
						aria-controls={open ? "long-menu" : undefined}
						aria-expanded={open ? "true" : undefined}
						aria-haspopup="true"
						onClick={handleClick1}
						sx={{ paddingLeft: "8px !important" }}
						disabled={!isModeratorAdmin}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						id="long-menu"
						MenuListProps={{
							"aria-labelledby": "long-button",
						}}
						sx={{
							"& .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper":
								{
									boxShadow:
										"0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
								},
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						{userAccountStatus === "SUSPEND" ? (
							<MenuItem
							disabled={!isModeratorAdmin || userAccountStatus === "INACTIVE"}
							onClick={() => setOpenReinstateDialog(true)}
							>
								Reinstate
							</MenuItem>
						) : (
							<MenuItem
							disabled={!isModeratorAdmin || userAccountStatus === "INACTIVE"}
							onClick={()=>setOpenSuspendDialog(true)}
							>
								Suspend account
							</MenuItem>
						)}
						{userAccountStatus === "INACTIVE" ? (
							<MenuItem
								disabled={!isModeratorAdmin}
								onClick={() => setOpenReactivateDialog(true)}
							>
								Reactivate account
							</MenuItem>
						) : (
							<MenuItem
								disabled={!isModeratorAdmin}
								onClick={() => setOpenCloseDialog(true)}
							>
								Close account
							</MenuItem>
						)}
						{openSuspendDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openSuspendDialog}
          onClose={() => handleCloseSuspendDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseSuspendDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Suspend Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to suspend this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseSuspendDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleActions("SUSPEND")}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

{openCloseDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openCloseDialog}
          onClose={() => handleCloseCloseDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseCloseDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Close Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to Close this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseCloseDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleActions("INACTIVE")}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

{openReactivateDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openReactivateDialog}
          onClose={() => handleCloseReactivateDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseReactivateDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Reactivate Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to Reactivate this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseReactivateDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleActions("REACTIVATE")}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

{openReinstateDialog && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openReinstateDialog}
          onClose={() => handleCloseReinstateDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseReinstateDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Reinstate Account</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to reinstate this account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseReinstateDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleActions("REACTIVATE")}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
					</Menu>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{
						paddingBottom: 0,
						paddingTop: 0,
						backgroundColor: "#E7E7E7",
						width: "100%",
					}}
					colSpan={7}
				>
					<Collapse in={viewPost} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Grid container columnGap={1}>
								<Grid item xs={8}>
									<Card
										sx={{
											minWidth: 250,
											flexGrow: 1,
											minHeight: "30%",
											mt: 1,
											borderRadius: "12px",
										}}
									>
										<CardContent sx={{ alignItems: "center", gap: 1 }}>
											<Grid
												container
												rowGap={1}
												columns={{ xs: 12, sm: 6, md: 12 }}
											>
												<Grid item xs={2}>
													<Avatar
														alt={row.name}
														src={profilePhotoUrl}
														sx={{ height: "50px", width: "50px" }}
													/>
												</Grid>
												<Grid item xs={10}>
													<Grid container direction={"column"}>
														<Grid item sx={{ height: "20px" }}>
															<Grid container>
																<Grid item xs={5}>
																	<Typography
																		variant="body1"
																		sx={{ fontWeight: 500, fontSize: "16px" }}
																	>
																		{row.name}
																	</Typography>
																</Grid>
																<Grid
																	item
																	xs={6}
																	sx={{ textAlign: "right" }}
																></Grid>
																<Grid item xs={1}>
																	<IconButton
																		aria-label="next"
																		onClick={viewUserProfile}
																	>
																		<img
																			src={ArrowRight}
																			alt="close"
																			height={24}
																			width={24}
																		/>
																	</IconButton>
																</Grid>
															</Grid>
														</Grid>
														<Grid item>
															<Typography
																variant="caption"
																sx={{ color: "#888888", fontSize: "12px" }}
															>
																{row.profileTitle}
															</Typography>
														</Grid>
														{/* <Grid item >
                                                            <Typography variant="caption" sx={{ color: '#888888' }}>
                                                                9hr
                                                            </Typography>
                                                        </Grid> */}
													</Grid>
												</Grid>
												{/* {post.feedText !== 'undefined' ? */}
												<Grid
													item
													xs={12}
													sx={{
														filter:
															row.userStatus === "BAN"
																? userAccountStatus === "BAN"
																	? "blur(1px)"
																	: ""
																: "",
													}}
												>
													<Typography
														sx={{
															color: "#888888",
															fontSize: "1rem",
															fontWeight: 400,
														}}
													>
														{row.profileBio}
													</Typography>
												</Grid>
												<Grid
													item
													xs={12}
													container
													rowGap={1}
													sx={{
														mt: 1,
														filter:
															userAccountStatus === "BAN" ? "blur(1px)" : "",
													}}
												>
													<Grid item container xs={6}>
														<img
															src={People}
															style={{ width: "24px", height: "24px" }}
															alt="Location"
														/>
														&nbsp;
														<Typography
															variant="caption"
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															{row.totalConnections} connections
														</Typography>
													</Grid>
													<Grid item container xs={6}>
														<img
															src={row.online ? Clock : ClockNo}
															style={{ width: "24px", height: "24px" }}
															alt="Location"
														/>
														&nbsp;
														<Typography
															variant="caption"
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															user {row.online ? "Online" : "Offline"}
														</Typography>
													</Grid>
													<Grid item container xs={6}>
														<img
															src={row.openForGigs ? Cards : CardsNo}
															style={{ width: "24px", height: "24px" }}
															alt="Location"
														/>
														&nbsp;
														<Typography
															variant="caption"
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															{row.openForGigs
																? "Open for Gigs"
																: `Not open for Gigs`}
														</Typography>
													</Grid>
													{/* <Grid item xs={6}>
                                                        <Typography variant="caption" sx={{ color: '#5B5B65' }} >
                                                            <img src={Online} style={{ width: '13px', height: '13px' }} alt="Location" />&nbsp;
                                                            Device Online (low latency)
                                                        </Typography>
                                                    </Grid> */}
													<Grid item container xs={6}>
														<img
															src={Calendar}
															style={{ width: "24px", height: "24px" }}
															alt="Location"
														/>
														&nbsp;
														<Typography
															variant="caption"
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															Member since{" "}
															{new Date(
																row.createdDate ? row.createdDate : ""
															).toLocaleString("en-us", {
																month: "short",
																year: "numeric",
															})}
														</Typography>
													</Grid>
													<Grid item container xs={6}>
														<img
															src={row.openForCollab ? Music : MusicNo}
															style={{ width: "24px", height: "24px" }}
															alt="Location"
														/>
														&nbsp;
														<Typography
															variant="caption"
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															{row.openForCollab
																? `Open for Collaboration`
																: `Not open for Collaboration`}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								<Grid item xs={3}>
									<Card
										sx={{
											minWidth: 250,
											flexGrow: 1,
											minHeight: "30%",
											mt: 1,
											borderRadius: "12px",
										}}
									>
										<CardContent sx={{ alignItems: "center", gap: 1 }}>
											<Grid container direction={"column"}>
												<Grid item container>
													<Grid item xs={9}>
														<Typography
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															Total users reporting
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography
															sx={{ color: "black", fontSize: "14px" }}
														>
															: {row.totalUserReporting}
														</Typography>
													</Grid>
												</Grid>
												<Grid item container>
													<Grid item xs={9}>
														<Typography
															sx={{ color: "#5B5B65", fontSize: "14px" }}
														>
															Total Issues count
														</Typography>
													</Grid>
													<Grid item xs={3}>
														<Typography
															sx={{ color: "black", fontSize: "14px" }}
														>
															: {row.totalIssueCount}
														</Typography>
													</Grid>
												</Grid>
												<Divider
													sx={{
														color: "#999999",
														borderBottomWidth: "1px",
														my: 1,
													}}
												/>
												<Grid item>
													{Object.entries(row.violationsType).map(
														([key, value], index: any) => {
															return (
																<Chip
																	key={index}
																	sx={{
																		backgroundColor: "#EDEDED",
																		color: "#5B5B65",
																		mr: 1,
																		mb: 1,
																		fontSize: "1rem",
																		fontWeight: 400,
																		height: "30px",
																	}}
																	label={`${key} (${value})`}
																/>
															);
														}
													)}
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
								{/* <Grid item xs={4}>
                                    <Typography>
                                        <img src={Shield} alt='' />
                                        User is Banned
                                    </Typography>
                                </Grid> */}
								<Grid item xs={12}>
									<Box
										sx={{
											display: "flex",
											gap: 2,
											flexWrap: "wrap",
											pt: 1,
											flexDirection: "row-reverse",
											mx: 2,
											mb: 2,
										}}
									>
										{userAccountStatus === "BAN" ||
										userAccountStatus === "SUSPEND" ||
										userAccountStatus === "TERMINATE" ? (
											<Button
												size="small"
												onClick={() => handleActions("REACTIVATE")}
												variant="contained"
												sx={{
													borderRadius: "17px",
													background: "#259A12",
													px: 2,
												}}
											>
												Reactivate User
											</Button>
										) : (
											""
										)}

										{row.userStatus === "BAN" && (
											<>
												<Typography sx={{ mr: "auto", mt: 1 }}>
													User is Banned
												</Typography>
												<img
													src={Shield}
													alt=""
													style={{
														marginTop: "8px",
														height: "24px",
														width: "24px",
													}}
												/>
											</>
										)}
									</Box>
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

export default UserView;

import { useEffect } from "react";
import { useUser } from "../../context/UserContext";

const useAdminAuthorized = (forAdmin: string): boolean => {
	const user = useUser();
	if (user && user.authorities) {
		const authorities = user.authorities.map(
			(authority: { name: string }) => authority.name
		);
		switch (forAdmin) {
			case "isAllAdmins":
				return authorities.some(
					(role: string) =>
						role === "ROLE_ADMIN" ||
						role === "ROLE_SUPER_ADMIN" ||
						role === "ROLE_ADMIN_CONTENT_MODERATOR" ||
						role === "ROLE_ADMIN_VIEWER"
				);
			case "isModeratorAdmins":
				return authorities.some(
					(role: string) =>
						role === "ROLE_ADMIN" ||
						role === "ROLE_SUPER_ADMIN" ||
						role === "ROLE_ADMIN_CONTENT_MODERATOR"
				);
			case "isModeratorAdminsAndUser":
				return authorities.some(
					(role: string) =>
						role === "ROLE_ADMIN" ||
						role === "ROLE_SUPER_ADMIN" ||
						role === "ROLE_ADMIN_CONTENT_MODERATOR" ||
						role === "ROLE_USER"
				);
			case "isAdminUserManagementAdmins":
				return authorities.some(
					(role: string) => role === "ROLE_ADMIN" || role === "ROLE_SUPER_ADMIN"
				);
			case "isSuperAdmin":
				return authorities.includes("ROLE_SUPER_ADMIN");
			default:
				return false;
		}
	}
	return false;
};

export default useAdminAuthorized;

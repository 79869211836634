import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
	Button,
	FormControl,
	Box,
	Grid,
	Card,
	CardContent,
	Typography,
} from "@mui/material";
import LeftPanel from "./LeftPanel";
import SuccessMark from "../images/tick-circle.svg";
import i18n from "../l10n/strings.json";

type RouterState = {
	email: string;
};

const AccountRegistered = () => {
	const location = useLocation();
	const locationState = location.state as RouterState | undefined;
	const [cardContainerHeight, setCardContainerheight] = useState<number | undefined>(0);

	useEffect(() => {
		const element = document.getElementById("right-pannel-header");
		const rect = element?.getBoundingClientRect();
		setCardContainerheight(rect?.height);
	}, []);

	return (
		<Grid
			container={true}
			columns={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}
		>
			<Grid item={true} xs={6} sm={6} md={4} lg={4} xl={4}>
				<LeftPanel />
			</Grid>
			<Grid item={true} xs={6} sm={6} md={4} lg={4} xl={4}  className="alignCenterTabLogin">
				<Grid container={true}
					className="column"
				>
					<Grid item className="right-align"
						id="right-pannel-header">
						<Grid
							container={true}
							columns={{ xs: 12, sm: 12, md: 12 }}
							className="column right-align"
							rowSpacing={{ xs: 1, sm: 3, md: 5, xl: 10, xxl: 20 }}
						>
							<Grid item={true} xs={12} className="right-align">
							<Box display="flex" justifyContent="flex-end" mt={2}>
					<Typography
						mr={2}
						mt={1}
						sx={{
							fontFamily: "Lexend",
							fontWeight: "500",
							fontSize: "1rem",
							lineHeight: "20px",
							color: "#5D5D65",
						}}
					>
						{i18n.signUp.notMemberMsg}
					</Typography>
					<Button
						component={Link}
						to="/signUpEmail"
						sx={{
							fontWeight: "900",
							fontSize: "1rem",
							marginRight: "5vw",
							backgroundColor: "#ffffff",
							borderRadius: "18px",
							color: "#0273E6",
							border: "1px solid #0273E6",
							height: "36px"
						}}
					>
						{i18n.signUp.title}
					</Button>
				</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						item={true}
						xs={12}
						className="center-align"
					>
						<Card
							component="section"
							sx={{

								position: "relative",
								top: `calc(50% - ${cardContainerHeight}px)`,
								boxShadow: "none",
								paddingBottom: 0,
								marginTop: "150px",
								marginLeft: "100px"
							}}
						>
							<CardContent className="center-align" sx={{ padding: 1 }}>
								<FormControl sx={{ width: "80%", padding: 1 }}>
									<Grid
										container={true}
										rowSpacing={{ xs: 1, sm: 1, md: 2 }}
									>
										<Typography variant="h2" sx={{color: "customColor.black"}}>{i18n.login.accountRegisteredTitle}</Typography>
										<Grid item={true} xs={12}
											sx={{
												display: "inline-flex",
												marginTop: "30px",
											}}
										>
											<Box mr={1}>
												<img src={SuccessMark} alt="error-mark" height="20px" />
											</Box>
											<Typography variant="h6"
												sx={{
													textAlign: "start",
													color: "success.main",
												}} >
												{i18n.login.emailSentSuccessfullyToMailMsg}
											</Typography>
										</Grid>
										<Grid item={true} xs={12}>
											<Typography variant="h6"
												sx={{
													fontWeight: 900,
													color: "customColor.black",
													paddingLeft: "26px"
												}} >
												{locationState?.email}
											</Typography>
										</Grid>
									</Grid>
								</FormControl>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);

}
export default AccountRegistered;

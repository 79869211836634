import { memo, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import {
	Alert,
	AlertTitle,
	Button,
	Grid,
	IconButton,
	Snackbar,
	Typography,
} from "@mui/material";
import { useWakeLock } from "react-screen-wake-lock";
import Room from "../Room/Room";
import MenuBar from "../MenuBar/MenuBar";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";

import useHeight from "../../hooks/useHeight/useHeight";
import useRoomState from "../../hooks/useRoomState/useRoomState";
import { useUser } from "../../context/UserContext";
import { SessionInterface } from "../../types/types";
import { clearLocalstorage, stopSessionRecording } from "../../utils";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useParticipantsContext from "../../hooks/useParticipantsContext/useParticipantsContext";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";
import SessionHoldDialog from "../../dialogBox/SessionHoldDialog";

import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import WebsocketMetricsClient from "../../services/WebsocketMetricsClient";
import i18n from "../../l10n/strings.json";
import DeviceError from "./DeviceError";
import getErrorInfo from "../../types/errorTypes";
import DOMPurify from "dompurify";
import CloseIcon from "../../icons/CloseIcon";
import ErrorMark from "../../images/Alert-filled.svg";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

type RouterState = {
	session: SessionInterface;
	token: string;
	username: string;
	twilioSessionId: string;
};

type ErrorCode = keyof typeof i18n.errorCode;

function VApp() {
	const roomState = useRoomState();
	const navigate = useNavigate();
	const [startTime, setStartTime] = useState();
	const { request } = useWakeLock();
	// Here we would like the height of the main container to be the height of the viewport.
	// On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
	// not the viewport. This looks bad when the mobile browsers location bar is open.
	// We will dynamically set the height with 'window.innerHeight', which means that this
	// will look good on mobile browsers even after the location bar opens or closes.
	const height = useHeight();
	// Session
	const token = localStorage.getItem("sessionId");
	const location = useLocation();
	const user = useUser();
	const locationState = location.state as RouterState | undefined;
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const { galleryViewParticipants } = useParticipantsContext();
	const { room } = useVideoContext();
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	const [isSessionHold, setIsSessionHold] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const wasRoomStateConnectedRef = useRef(false);
	const timeoutRef = useRef<any>(undefined);
	const [deviceError, setDeviceError] = useState(false);
	const [errorCode, setErrorCode] = useState("");
	const [deviceId, setDeviceId] = useState("");
	const [userData, setUserData] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [deviceData, setDeviceData] = useState<any>();
	const [errorMessage, setErrorMessage] = useState("");
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	useEffect(() => {
		getDeviceId();
	}, []);

	useEffect(() => {
		if (deviceId && errorCode === "102") {
			fetch(`${process.env.REACT_APP_BASE_URL}/user/get-user-by-device-id`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify([{ deviceId: deviceId }]),
			})
				.then((response) => {
					if (response.status >= 400) {
						console.log("Failed to fetch API");
						setLoading(false);
					} else {
						return response.json();
					}
				})
				.then((result) => {
					setUserData(result);
					setLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching user data:", error);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, [deviceId, errorCode, token]);

	useEffect(() => {
		let user = localStorage.getItem("user");
		if (user) {
			try {
				const userDetails = JSON.parse(user);
				if (
					(!userDetails.firstName ||
					!userDetails.lastName ||
					!userDetails.location) && !isAdmin
				) {
					navigate("/socialProfileNew", {
						state: {
							email: userDetails.email,
							password: "",
						},
					});
				}
			} catch (e) {
				console.error("Failed to parse user data:", e);
			}
		}
	}, [navigate]);

	const getDeviceId = () => {
		const requestBody = {
			userId: locationState?.session?.createdBy,
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-device/getDevice`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(requestBody),
		})
			.then((res) => {
				if (res.status >= 400) {
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setDeviceData(data);
				}
			});
	};

	const Container = styled("div")({
		display: "grid",
		gridTemplateRows: "1fr auto",
	});

	const Main = styled("main")(({ theme }: { theme: Theme }) => ({
		// overflow: "hidden",
		// paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
		background: "black",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
		},
	}));

	const linkifyMessage = (errorMessage: string) => {
		const contactUsText = "contact us";
		const contactUsLink = `<a href="${process.env.REACT_APP_BASE_URL_FRONTEND}/helpAndSupport" target="_blank" rel="noopener noreferrer" style="color: blue;">contact us</a>`; // Update with your contact link

		if (errorMessage.includes(contactUsText)) {
			return errorMessage.replace(contactUsText, contactUsLink);
		}

		return errorMessage;
	};

	const onErrorReceived = (payLoad: any) => {
		setErrorCode(payLoad?.metadata?.error_code);
		setDeviceId(payLoad?.metadata?.created_by);
		const message = payLoad?.metadata?.error;
		setErrorMessage(linkifyMessage(message));
		setDeviceError(true);
	};

	useEffect(() => {
		WebsocketClientUSerStatus.on("errorCode", onErrorReceived);
		return () => {
			WebsocketClientUSerStatus.off("errorCode", onErrorReceived);
		};
	}, []);

	const errorInfo = errorCode !== "102" ? getErrorInfo(errorCode) : null;

	useEffect(() => {
		let count = 0;
		let isRoomInstantiated = false;
		if (room) {
			count++;
			isRoomInstantiated = true;
		}
		if (isRoomInstantiated && count === 1) {
			if (room?.participants.size > 0) {
				room?.participants.forEach((remoteParticipant: any, key: any) => {
					if (remoteParticipant.identity !== user.email) {
						let participantDeviceStatusMsg = {
							action: "device_status",
							timestamp: new Date().toISOString(),
							session_id: twilioSessionId,
							created_by: user.id,
							created_for: remoteParticipant.identity,
						};
						WebsocketClientUSerStatus.handleAudioControl(
							participantDeviceStatusMsg
						);
					}
				});
			}
		}
	}, [room]);

	useEffect(() => {
		let isMsgReceived = false;
		let ignore = false;

		const onMessageReceived = (payload: any) => {
			let payloadData: any = JSON.parse(payload.body);
			if (timeoutRef) {
				globalThis.clearTimeout(timeoutRef.current);
			}
			switch (payloadData.action) {
				case "start_session":
					WebsocketClientUSerStatus.updateSessionStatus(
						payloadData.metadata.session_status
					);
					if (
						payloadData &&
						payloadData.metadata.session_status === "SESSION_STARTED"
					) {
						if (!isMsgReceived) {
							let participantDetailList = JSON.parse(
								localStorage.getItem("participantVolumeDetails") ?? ""
							);

							const initialVolumeMsg = {
								action: "audio_control",
								timestamp: new Date().toISOString(),
								session_id: payloadData.metadata.session_id,
								created_by: user.id,
								created_for: user.email,
								audio: {
									updated_for: "master",
									master: {
										audio_status: "unmute",
										audio_level: 50,
									},
									channel_1: {
										audio_status: "unmute",
										audio_level: 50,
									},
									channel_2: {
										audio_status: "unmute",
										audio_level: 50,
									},
									mic: {
										audio_status: "unmute",
										audio_level: 50,
									},
								},
							};

							participantDetailList = [
								...participantDetailList.map((participant: any) => {
									if (
										participant.email !== undefined &&
										participant.email === user.email
									) {
										return {
											...participant,
											audio: {
												...participant.audio,
												master: {
													...participant.audio.master,
													audio_status: "unmute",
													audio_level: 50,
												},
												channel_1: {
													...participant.audio.channel_1,
													audio_status: "unmute",
													audio_level: 50,
												},
												channel_2: {
													...participant.audio.channel_2,
													audio_status: "unmute",
													audio_level: 50,
												},
												mic: {
													...participant.audio.mic,
													audio_status: "unmute",
													audio_level: 50,
												},
											},
										};
									} else {
										return participant;
									}
								}),
							];
							localStorage.setItem(
								"participantVolumeDetails",
								JSON.stringify(participantDetailList)
							);
							WebsocketClientUSerStatus.handleAudioControl(initialVolumeMsg);

							// if (isRoomStarted && count === 1) {
							// 	room?.participants.forEach(
							// 		(key: any, remoteParticipant: any) => {
							// 			if (remoteParticipant.identity !== user.email) {
							// 				let participantDeviceStatusMsg = {
							// 					action: "device_status",
							// 					timestamp: new Date().toISOString(),
							// 					session_id: payloadData.metadata.session_id,
							// 					created_by: user.id,
							// 					created_for: remoteParticipant.email,
							// 				};
							// 				WebsocketClientUSerStatus.handleAudioControl(
							// 					participantDeviceStatusMsg
							// 				);
							// 			}
							// 		}
							// 	);
							// }

							isMsgReceived = true;
						}
					} else if (
						payloadData &&
						payloadData.metadata.session_status === "SESSION_HOLD"
					) {
						if (payloadData.metadata.created_by === user.email) {
							setIsSessionHold(true);
						}
					} else if (
						payloadData &&
						payloadData.metadata.session_status === "SESSION_STOPPED"
					) {
						if (payloadData.metadata.created_by === user.email) {
							WebsocketClientUSerStatus.addLog("Session stop in videosession");
							setShowAlert(true);
							setAlertMsg("Device not reachable! Please try again..");
							setAlertStatus(false);
							leaveSession();
						}
					}
					break;
				case "Error":
					// alert("Error !!");
					break;
				default:
					setShowAlert(true);
					setAlertMsg("Device not reachable! Please try again..");
					setAlertStatus(false);
			}
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/sessions/${locationState?.session.id}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((response) => {
				if (response.status >= 400) {
					throw new Error();
				} else {
					if (!ignore) {
						return response.json();
					}
				}
			})
			.then((data) => {
				if (data) {
					ignore = true;
					setStartTime(data.startTime);
					localStorage.setItem("twilioSessionId", data.twilioSessionId);
					WebsocketClientUSerStatus.startSession(data, user);

					// if device is offline and didn't get start session response. Added wait for 10 sec.
					timeoutRef.current = setTimeout(() => {
						// setLoading(false);
						handleStartSessionResponse(false);
					}, 15000);
					request();
				}
			});

		WebsocketClientUSerStatus.on("subcribed", onMessageReceived);

		return () => {
			ignore = true;
			WebsocketClientUSerStatus.off("subcribed", onMessageReceived);
		};
	}, []);

	const handleStartSessionResponse = (status: Boolean) => {
		setShowAlert(true);
		setAlertMsg("Device not reachable! Please try again..");
		setAlertStatus(false);
	};

	const handleWindowClose = async (event: any) => {
		// let response = leaveSession();
		event.preventDefault();
		event.stopPropagation();
		event.returnValue = "";
		return false;
	};

	const leaveSession = () => {
		let message = {
			//action: "participant_stop_session",
			action: "session_stop",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			// created_for: forParticipant,
			created_by: user.id,
			unsubscribe:
				galleryViewParticipants && galleryViewParticipants.length === 0
					? true
					: false,
		};

		// WebsocketMetricsClient.disconnectWebsocketConnection();

		stopSessionRecording(twilioSessionId, user);
		WebsocketClientUSerStatus.handleLeaveSession(message);
		WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
		room?.disconnect();
		clearLocalstorage();
	};

	const handleDialogClose = () => {
		setIsSessionHold(false);
		leaveSession();
		navigate("/jamsession");
	};

	useEffect(() => {
		window.addEventListener("beforeunload", handleWindowClose);
		// window.addEventListener("unload", handleUnload);
		return () => {
			window.removeEventListener("beforeunload", handleWindowClose);
			// window.removeEventListener("unload", handleUnload);
		};
	}, []);

	useEffect(() => {
		if (roomState === "connected") {
			wasRoomStateConnectedRef.current = true;
			localStorage.setItem("roomPrevState", roomState);
		}
		if (wasRoomStateConnectedRef.current && roomState === "disconnected") {
			console.log("connection timeout", roomState);
			// setShowAlert(true);
			// setAlertStatus(false);
			// setAlertMsg(
			// 	"Time-out! Sessions are limited to four hours. Please rejoin the session or schedule a new session to get back to jamming."
			// );
			setErrorCode("408"); // changed this code from 401 to connection error 408
			setErrorMessage(linkifyMessage("This could be due to a connection error or the session exceeding the 4-hour limit. Please reconnect or contact us if the issue persists."))
			setDeviceError(true);
			leaveSession();
			navigate("/jamsession");
		}
		localStorage.setItem("roomCurrentState", roomState);
	}, [roomState]);

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		console.log("errorrrrrr", localStorage.getItem("roomCurrentState"));
	// 		handleErrorAlert();
	// 	}, 10000);
	// }, [localStorage.getItem("roomCurrentState") === "disconnected"]);

	// const handleErrorAlert = () => {
	// 	if (
	// 		localStorage.getItem("roomPrevState") === "connected" &&
	// 		localStorage.getItem("roomCurrentState") === "disconnected"
	// 	) {
	// 		// console.log("errorrrrrr22222", localStorage.getItem("roomCurrentState"));
	// 		// setShowAlert(true);
	// 		// setAlertStatus(false);
	// 		// setAlertMsg("Please join the session again!");
	// 		setErrorCode("401");
	// 		setErrorMessage(
	// 			linkifyMessage("Please try again and contact us if the issue persists.")
	// 		);
	// 		setDeviceError(true);
	// 	}
	// };

	const handleAlertClose = () => {
		console.log("handelAlertCloseeee");
		setShowAlert(false);
		leaveSession();
		navigate("/jamsession");
	};

	const handleClose = () => {

		setDeviceError(false);
		if(errorCode === "501" || errorCode === "401"){
			handleAlertClose();
		}else{
			return ;
		}
	};

	useEffect(() => {
		console.log(alertMsg);
		setAlertMsg("");
	}, []);
	return (
		<>
			<Container
				className=" mainPanelSession"
				id="fullscreen-container"
				style={{
					height: isFullscreenMode
						? `${window.innerHeight}px`
						: isTabHidden
						? `${window.innerHeight - 5}px`
						: height,
					backgroundColor: "#000000",
					overflow: "hidden",
				}}
			>
				{roomState !== "disconnected" && (
					// 	<>Disconnected</>
					// ) : (
					<Main>
						<MenuBar startTime={startTime} />
						<Room />
					</Main>
				)}
				{isSessionHold && (
					<SessionHoldDialog
						isSessionHold={isSessionHold}
						back={handleDialogClose}
					/>
				)}
				{alertMsg !==
				"Time-out! Sessions are limited to four hours. Please rejoin the session or schedule a new session to get back to jamming." ? (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "70px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={30000}
						onClose={handleAlertClose}
					>
						<Alert
							onClose={handleAlertClose}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.5rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",
									fontWeight: "500",
									color: "#000000",
									margin: "auto",
									padding: "5px",
									// justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				) : (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "70px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						onClose={handleAlertClose}
					>
						<Alert
							onClose={handleAlertClose}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px", marginBottom: "60px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.5rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",
									fontWeight: "500",
									color: "#000000",
									margin: "auto",
									padding: "5px",
									// justifyContent: "center !important",
								}}
							>
								{alertMsg}
								<div style={{ textAlign: "center", marginTop: "10px" }}>
									<Button
										variant="contained"
										size="medium"
										onClick={handleAlertClose}
									>
										Ok
									</Button>
								</div>
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
			</Container>
			{/* <DeviceError  /> */}
			<Snackbar
				open={deviceError}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				sx={{
					marginTop: "50px",
					borderRadius: "8px",
					padding: "9px 10px",
					backgroundColor: "#ffffff",
				}}
			>
				<Alert
					icon={false}
					action={
						<IconButton
							size="small"
							color="inherit"
							onClick={handleClose}
							sx={{ marginTop: "-70px" }}
						>
							<CloseIcon />
						</IconButton>
					}
					sx={{ width: "100%", backgroundColor: "#ffffff" }}
				>
					<Grid container spacing={2} alignItems="center">
						<Grid item sx={{ display: "flex" }}>
							<img
								src={ErrorMark}
								alt="Error Mark"
								style={{ height: "24px", width: "24px" }}
							/>
						</Grid>
						<Grid item justifyContent={"center"} alignItems="center">
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontWeight: 500,
									fontSize: "18px",
									lineHeight: "22.5px",
								}}
							>
								{errorCode !== "102"
									? errorInfo?.title
									: "Connection to peer lost unexpectedly!"}
							</Typography>
						</Grid>
					</Grid>
					<Grid container alignItems="center">
						<Grid
							item
							justifyContent={"center"}
							alignItems={"center"}
							sx={{ marginLeft: "2.5rem" }}
						>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "16px",
									fontWeight: 500,
									lineHeight: "20px",
									color: "#7A7985",
								}}
								dangerouslySetInnerHTML={{ __html: errorMessage }}
							></Typography>
						</Grid>
					</Grid>
					<Grid
						display={"flex"}
						justifyContent={"right"}
						sx={{ marginTop: "10px" }}
					>
						<Typography
							sx={{
								fontFamily: "Lexend",
								fontWeight: 400,
								fontSize: "16px",
								lineHeight: "20px",
								color: "#7a7985",
							}}
						>
							Error Code: {errorCode}
						</Typography>
					</Grid>
				</Alert>
			</Snackbar>
		</>
	);
}

export default memo(VApp);


import { useState, ChangeEvent } from "react";
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, Box, Typography, Grid
} from "@mui/material";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CheckIcon from "../images/check-fill.svg";
import CloseIcon from "../images/close-line.svg";
import Close from "../images/close.svg";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";
import { rgba } from "@react-spring/shared";
import { justifyContent } from "styled-system";

const UPPERCASEREGEX = /.*[A-Z].*/;
const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;

const ChangePasswordByAdmin = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	//const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [newpassword, setNewPassword] = useState("");
	const [repassword, setRepassword] = useState("");
 ;
	const [iserror, setError] = useState(false);
	const [message, setMessage] = useState("");
	 

	const isEnabled =
		newpassword.length >= 8 &&
		newpassword.length <= 16 &&
		NUMBEREREGEX.test(newpassword) &&
		UPPERCASEREGEX.test(newpassword) &&
		SPECIALCHAREREGEX.test(newpassword) &&
		repassword.length >= 8 &&
		repassword.length <= 16 &&
		newpassword === repassword;


		const resetPassword = () => {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/account/reset-password/init`,
				{
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ email: props.email }),					 
				}
			)
			.then((response) => {
				if (response.status >= 400) {
					setError(true);
					setMessage(i18n.settings.accountSetup.currentPasswordErrorMsg);
					throw new Error();
				} else {
					props.handleClose(true, "passwordSuccess");
					return response.json();
				}
			})
			.then((result) => {
				setError(false);
				if (result.status === "BAD_REQUEST") {
					setError(true);
					setMessage(i18n.settings.accountSetup.passwordErrorMsg);
				}
				if (result.status === "OK") {
					props.handleClose(true, "passwordSuccess");
				}
			})
			.catch((error) => {
				setError(true);
			});
		};

	 

	const handleClose = () => {
		props.handleClose()
	};

	 
	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px" 
			}}
			fullWidth
			maxWidth="sm"
			open={props.changePasswordOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(
							event: React.MouseEvent<HTMLButtonElement>
						) => event.preventDefault()}
						edge="end"
						sx={{ marginLeft: "90%" }}>
						<img
							src={Close}
							alt="close"
						/>
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						margin: "top",
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "rgba(0, 0, 0, 1)",
							fontSize: "30px !important",
						}}
					>
						Reset Password
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "left",
					}}
				>
					<Typography  
						sx={{
							fontWeight: 400,
							color: "rgba(91, 91, 101, 1)",
						    fontFamily:"Lexend",
							fontSize: "16px !important",
							justifyContent: "left",
							textAlign: "left",
						}}
					>
						This will reset password and trigger an email to the user below to change password
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					marginLeft: "48px",
					marginRight: "48px",
					padding: "20px 0",
				}}
			>
				
			 <Grid container xs={12}>
			 <Grid item xs={4} sx={{display:'flex', justifyContent:'end'}}>
			 <Typography  
						sx={{
							fontWeight: 400,
							color: "rgba(122, 121, 133, 1)",
						    fontFamily:"Lexend",
							fontSize: "16px !important",
							justifyContent: "center",
							textAlign: "center",
						}}
					>
					User :  
				</Typography>
			 </Grid>
			 <Grid item xs={8} sx={{display:'flex', alignContent:'start'}}>

			 <Typography  
						sx={{
							fontWeight: 400,
							color: "rgba(77, 76, 79, 1)",
						    fontFamily:"Lexend",
							fontSize: "16px !important",
							justifyContent: "center",
							textAlign: "center",
							paddingLeft : "5px",
						}}
					>
					{props.email}
				</Typography>
				</Grid>

			 </Grid>
			</DialogContent>
			<DialogActions>
				<Grid container xs={12}>				
					<Grid item={true} xs={6} sx={{display: "flex", justifyContent:'flex-end' }}>
						<Button
							sx={{ width: "135px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={handleClose}
						>
							{i18n.dialog.cancel}
						</Button>
					</Grid>
					<Grid item={true} xs={0.5} ></Grid>
					<Grid item={true} xs={5.5} sx={{justifyContent:'start'}}>
						<Button
							className="submit-button"
							size="large"
							variant="contained"
							sx={{ width: "135px",paddingLeft:"5px" }}
							onClick={resetPassword}
							
						>
							{i18n.dialog.reset}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	)
};
export default ChangePasswordByAdmin;

import { useState, useEffect, useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

const GoogleLocationService = (props: any) => {
	const { placePredictions, getPlacePredictions } = usePlacesService({
		apiKey: process.env.REACT_APP_GOOOGLE_MAPS_API_KEY,
	});

	const timeoutIdRef = useRef<any>(undefined);

	const [location, setLocation] = useState(
		props.defaultValue ? { description: props.defaultValue } : {}
	);

	useEffect(() => {
		if (props.defaultValue !== location.description) {
			setLocation({ description: props.defaultValue });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.defaultValue]);

	const handleAutocompleteChange = (event: any, item: any) => {
		if (item?.description) {
			props.onLocationChange(item.description);
		} else {
			props.onLocationChange("");
		}
	};

	const handleInputChange = (event: any) => {
		if (event) {
			const input = event.target.value;
			clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = setTimeout(() => {
				if (input?.length > 2) {
					getPlacePredictions({input});
				}
			}, 200);
		}
	};

	const cityPredictions = placePredictions.filter((prediction) =>
		prediction.types.includes("locality")
	);

	return (
		<>
			<Autocomplete
				options={cityPredictions}
				freeSolo
				getOptionLabel={(option) =>
					typeof option === "string" ? option : option.description
				}
				onChange={handleAutocompleteChange}
				renderInput={(params) => (
					<TextField autoFocus={false} {...params} error={props.isError} />
				)}
				onInputChange={handleInputChange}
				value={location.description || ""} // Use location.description
				style={{ background: "#F8F8F8", width: "100%" }}
			/>
		</>
	);
};

export default GoogleLocationService;

import { createContext, useContext, useEffect, useRef, useState } from "react";
import { format } from "date-fns";

export const UserModerationFilterContext = createContext<any>(null);

export const UserModerationFilterProvider = ({ children }: any) => {
	const token = localStorage.getItem("sessionId");
	const [updateUserPendingCount, setUpdateUserPendingCount] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(20);

	const [userType, setUserType] = useState("");
	const [violationType, setViolationType] = useState("")
	const [orderByField, setOrderByField] = useState("")

	const [searchUserName, setSearchUserName] = useState("");
	const inputRef = useRef(null);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);

	const [userModerationFilter, setUserModerationFilter] = useState<any>({
		isGlobalSearch: true,
		genres: [],
		cities: "",
		firstName: "",
		lastName: "",
		nickName: "",
		email: null,
		instrument: [],
		moderationStatus: null,
		isOpenForCollaboration: false,
		acceptGigs: false,
	});

	interface Data {
		name: string;
		reportDate: string;
		reportType: string;
		count: string;
		status: string;
		actions: string;
	}

	const [isFilterActive, setIsFilterActive] = useState(false);

	const [rowData, setRowData] = useState<Data[]>([]);

	const resetUserModerationFilter: any = () => {
		setUserModerationFilter({
			isGlobalSearch: true,
			genres: [],
			cities: "",
			firstName: "",
			lastName: "",
			nickName: "",
			email: null,
			instrument: [],
			moderationStatus: null,
			isOpenForCollaboration: false,
			acceptGigs: false,
		});
	};

	useEffect(() => {
		if (
			userModerationFilter.genres.length > 0 ||
			userModerationFilter.cities.length > 0 ||
			userModerationFilter.instrument.length > 0 ||
			userModerationFilter.firstName.length > 0 ||
			userModerationFilter.lastName.length > 0 ||
			userModerationFilter.nickName.length > 0 ||
			userModerationFilter.isOpenForCollaboration ||
			userModerationFilter.acceptGigs ||
			userModerationFilter.email !== null ||
			userModerationFilter.moderationStatus !== null
		) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
	}, [userModerationFilter]);

	// useEffect(() => {
	// 	// getSearchedResult();
	// 	getAllUsers();
	// }, [userManagementFilter]);

	const getAllUsers = (userObj: any) => {
		try {
			const nextPage = pageNumber + 1;
			const requestBody = {
				userId: userObj?.userId ? userObj?.userId : null,
				globalSearch: userModerationFilter.isGlobalSearch,
				firstName: userModerationFilter.firstName,
				lastName: userModerationFilter.lastName,
				nickname: userModerationFilter.nickName,
				location: userModerationFilter.cities,
				genres: userModerationFilter.genres,
				instruments: userModerationFilter.instrument,
				openForCollab: userModerationFilter.isOpenForCollaboration,
				openForGigs: userModerationFilter.acceptGigs,
				moderationStatus: userModerationFilter.moderationStatus,
				email: userModerationFilter.email,
			};

			fetch(`${process.env.REACT_APP_BASE_URL}/api/moderations/search?forType=User&violationType=${violationType}&moderationStatus=&postType=&userAccountStatus=${userType}&orderBy=${orderByField}&page=${page}&size=${rowsPerPage}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(requestBody)
			})
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {

						const updatedRows = data.map((item: any) => ({
							name: `${item.offenderUserProfile.user.firstName} ${item.offenderUserProfile.user.lastName}`, // Use the 'id' property from the API response
							userProfileId: item.offenderUserProfile.id,
							nickName:  item.offenderUserProfile.nickname,
							reportDate: item.reportedAt, // Use the 'status' property from the API response
							reportType: item.reportType, // Combine first and last name
							count: item.count.totalUserReporting, // Hardcoded value
							status: item.status,  // Use the 'activationDate' property from the API response
							actions: "Edit", // Hardcoded value
							totalIssueCount: item.count.totalIssueCount,
							totalUserReporting: item.count.totalUserReporting,
							profilePhotoUrl: item.offenderUserProfile.profilePhotoUrl,
							profileTitle: item.offenderUserProfile.profileTitle,
							profileBio: item.offenderUserProfile.profileBio,
							openForCollab: item.offenderUserProfile.openForCollab,
							openForGigs: item.offenderUserProfile.openForGigs,
							createdDate: item.offenderUserProfile.user.createdDate,
							totalConnections: item.totalConnections,
							online: item.offenderUserProfile.online,
							userStatus: item.userStatus,
							offenderUserProfileId: item.offenderUserProfile.id,
							userAccountStatus: item.offenderUserProfile.userAccountStatus,
							violationsType: item.violationPerCount,
							allUserCount: item.allUserCount,
						}));

						setRowData((prevRowData) => [...prevRowData, ...updatedRows]);
						setPageNumber(nextPage);
					}
				})
				.catch((err) => {
				});
		} catch (error) {
			// setLoading(false);
		}
	};


	const handleUsersReset = (userObj: any) => {
		try {
			setHasMore(true);
			setPageNumber(0);
			setPage(0);
			const firstName = searchUserName || "";
			const lastName = searchUserName || "";
			const nickName = searchUserName || "";

			const requestBody = {
				userId: userObj?.userId ? userObj?.userId : null,
				globalSearch: userModerationFilter.isGlobalSearch,
				firstName: firstName,
				lastName: lastName,
				nickname: nickName,
				location: userModerationFilter.cities,
				genres: userModerationFilter.genres,
				instruments: userModerationFilter.instrument,
				openForCollab: userModerationFilter.isOpenForCollaboration,
				openForGigs: userModerationFilter.acceptGigs,
				email: userModerationFilter.email,
				moderationStatus: userModerationFilter.moderationStatus
			};
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/search?forType=User&violationType=${violationType}&moderationStatus=&postType=&userAccountStatus=${userType}&orderBy=${orderByField}&page=0&size=${rowsPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
						setRowData([]);
					}
					else {
						const updatedRows = data.map((item: any) => ({
							name: `${item.offenderUserProfile.user.firstName} ${item.offenderUserProfile.user.lastName}`, // Use the 'id' property from the API response
							nickName:  item.offenderUserProfile.nickname,
							reportDate: item.reportedAt, // Use the 'status' property from the API response
							reportType: item.reportType, // Combine first and last name
							count: item.count.totalUserReporting, // Hardcoded value
							status: item.status,  // Use the 'activationDate' property from the API response
							actions: "Edit", // Hardcoded value
							totalIssueCount: item.count.totalIssueCount,
							totalUserReporting: item.count.totalUserReporting,
							profilePhotoUrl: item.offenderUserProfile.profilePhotoUrl,
							profileTitle: item.offenderUserProfile.profileTitle,
							profileBio: item.offenderUserProfile.profileBio,
							openForCollab: item.offenderUserProfile.openForCollab,
							openForGigs: item.offenderUserProfile.openForGigs,
							createdDate: item.offenderUserProfile.user.createdDate,
							totalConnections: item.totalConnections,
							online: item.offenderUserProfile.online,
							userStatus: item.userStatus,
							offenderUserProfileId: item.offenderUserProfile.id,
							userAccountStatus: item.offenderUserProfile.userAccountStatus,
							violationsType: item.violationPerCount,
							allUserCount: item.allUserCount,
						}));

						setRowData(updatedRows);

					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					// setLoading(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	return (
		<UserModerationFilterContext.Provider
			value={{
				userModerationFilter,
				setUserModerationFilter,
				isFilterActive,
				resetUserModerationFilter,
				rowData,
				setRowData,
				getAllUsers,
				handleUsersReset,
				hasMore,
				userType,
				setUserType,
				violationType,
				setViolationType,
				searchUserName,
				setSearchUserName,
				inputRef,
				orderByField,
				setOrderByField,
				updateUserPendingCount,
				setUpdateUserPendingCount,
				rowsPerPage,
				setRowsPerPage,
				page,
				setPage,
			}}
		>
			{children}
		</UserModerationFilterContext.Provider>
	);
};

// Custom hook to consume the context
export function useUserModerationFilter() {
	return useContext(UserModerationFilterContext);
}

import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { Card, CardMedia } from '@mui/material';
import CancelIcon from '../../images/Group 4282.svg';

const ViewImage = ({ open, handleClose, ImageUrl, page }: any) => {

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={page === 'cover' ? "lg" : 'sm'}
            sx={{
                border: "1px solid #888888",
                boxShadow: "customColor.dropShadow",
            }}
            fullWidth
        >
            <label
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    cursor: 'pointer',
                    zIndex: 2,
                }}
            >
                <IconButton id="photo-Cancel"
                    aria-label="Edit" size='small' onClick={() => handleClose()} >
                    <img src={CancelIcon} alt="Edit" style={{ height: '24px', width: '24px' }} />
                </IconButton>
            </label>
            <img
                src={ImageUrl}
                id="upload-button"
                alt="Upload"
                style={{
                    objectFit: "fill", width: '100%',
                    height: '100%',
                }}
            />
            {/* <Card sx={{ height: 'auto', position: 'relative', borderRadius: '0px', maxHeight: 600, overflowY:'auto' }}>
                <label
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        cursor: 'pointer',
                        zIndex: 2,
                    }}
                >
                    <IconButton id="photo-Cancel"
                        aria-label="Edit" size='small' onClick={() => handleClose()} >
                        <img src={CancelIcon} alt="Edit" style={{ height: '24px', width: '24px' }} />
                    </IconButton>
                </label>
                <img
                    src={ImageUrl}
                    id="upload-button"
                    alt="Upload"
                    style={{
                        objectFit: "fill", width: '100%',
                        height: '100%',
                    }}
                />
            </Card> */}
        </Dialog>
    );
};

export default ViewImage;

import { Alert, AlertTitle, Button, FormHelperText, Grid, Snackbar, TextField } from '@mui/material'
import { useState } from "react";
import validator from "validator";
import ErrorIcon from "../../../images/danger.svg";
import SuccessIcon from "../../../images/tick-square.svg";

const AssignRole = () => {
    const token = localStorage.getItem("sessionId");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertStatus, setAlertStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");

    const isEnable = () => {
        if (!validator.isEmail(email) || email.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const assignRole = () => {
        if (email) {
            setIsLoading(true);

            const reqBody = {
                email: email,
            };
            fetch(`${process.env.REACT_APP_BASE_URL}/api/assign-role/collab-user`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(reqBody),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data.code === 1060) {
                        setShowAlert(true);
                        setAlertStatus(true);
                        setAlertMsg("Role Assigned Successfully!!");
                        setEmail("");
                    } else if (data.code === 1061) {
                        setShowAlert(true);
                        setAlertStatus(false);
                        setAlertMsg(`Role is already assigned to ${email}`);
                    } else if (data.code === 1062) {
                        setShowAlert(true);
                        setAlertStatus(false);
                        setAlertMsg("Email not exists! Please try again...");
                    } else {
                        setShowAlert(true);
                        setAlertStatus(false);
                        setAlertMsg("Somethins went wrong! Please try again...");
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    return (
        <div>
            {showAlert && (
                <Snackbar
                    sx={{
                        "&.MuiSnackbar-anchorOriginTopCenter": {
                            top: "70px",
                        },
                    }}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={showAlert}
                    autoHideDuration={6000}
                    onClose={handleAlertClose}
                >
                    <Alert
                        onClose={handleAlertClose}
                        icon={
                            alertStatus ? (
                                <img src={SuccessIcon} alt="error-icon" />
                            ) : (
                                <img
                                    src={ErrorIcon}
                                    alt="error-icon"
                                    style={{ marginRight: "10px" }}
                                />
                            )
                        }
                        sx={{
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "6px",
                            fontSize: "1.125rem",
                            fontWeight: "500",
                            color: "black",
                        }}
                    >
                        <AlertTitle
                            sx={{
                                fontSize: "1.125rem",
                                fontWeight: "500",
                                color: "#000000",
                                display: "flex",
                                margin: "auto",
                                padding: "5px",
                                justifyContent: "center !important",
                            }}
                        >
                            {alertMsg}
                        </AlertTitle>
                    </Alert>
                </Snackbar>
            )}
            <Grid item xs={12}>
                <Grid xs={12} pt={1}>
                    <FormHelperText>
                    Enter email to assign collaboration role
                    </FormHelperText>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid sx={{ display: "flex" }}>
                    <Grid xs={6} p={1}>
                        <TextField
                            id="message"
                            className="social-textField"
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                            error={email.length > 0 && !validator.isEmail(email)}
                            variant="outlined"
                            required
                        />
                        {email.length > 0 && !validator.isEmail(email) && (
                            <FormHelperText
                                sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
                            >
                                {!validator.isEmail(email)
                                    ? "Please enter valid email"
                                    : ""}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid xs={4} p={1}>
                        <Button
                            size="small"
                            variant="contained"
                            sx={{ borderRadius: "17px", background: "#FF702A", px: 2 }}
                            onClick={assignRole}
                            disabled={isEnable() ? true : false}
                        >
                            {isLoading ? "Saving.." : "Assign Role"}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    )
}

export default AssignRole
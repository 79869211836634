import { useState, useEffect, useRef } from "react";
import {
	Grid,
    TextField,
    InputAdornment,
    CircularProgress,
    Box
} from "@mui/material";
import { createStyles,  makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import InfiniteScroll from "react-infinite-scroll-component";
// contexts
import { useUser } from "../../context/UserContext";
import i18n from "../../l10n/strings.json";
import UserCardShort from "./UserCardShort";
import UserCardDetailed from "./UserCardDetailed";

import Search from '@mui/icons-material/Search';
import Cancel from "@mui/icons-material/Cancel";
import { musicianCardInterface } from "../../types/types";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        shadowedBox:{
            // padding: "1% 2%",
            borderRadius: "15px",
            //boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
        },
		activeMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#ffffff",
            background: "#0273E6"
		},
		musicMenu: {
			fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#000 !important",
            // background: "#0273E6"
		},
        instrumentStack: {
            margin: "5% 0",
            "& div": {
                padding: "1%",
                margin: "1% 0",
                backgroudColor: "#FAFAFA",
                borderRadius: "15px"
            }
        },
        spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		}
	})
);


const ActiveMusicians = () => {
    const classes = useStyles();
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
	const [searchString, setSearchString] = useState("");
	const [itemPerPage, setItemPerPage] = useState(10);
    const [musicians, setMusicians] = useState<Array<musicianCardInterface>>( [] );
    const [hasMore, setHasMore] = useState(true);
    const [previousSearchString, setPreviousSearchString] = useState("");
    const searchRef = useRef("");

	useEffect(() => {
		getActiveMusicians();
	}, []);

    // useEffect(() => {
	// 	resetActiveMusicians();
	// }, [searchString]);

    const getActiveMusicians = () => {
        musicians.length === 0 && setLoading(true);
		// setMusicians({mostActive: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
		try {
            // setMusicians([]);
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/all-musicians/${user.id}?searchString=${searchString}&page=${pageNumber}&size=${itemPerPage}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if(data.mostActive.length > 0){
                    // setMusicians([...musicians, ...data.mostActive]);
                    setMusicians([...musicians, ...data.mostActive]);
                    var nextPageNumber = pageNumber+1;
                    setPageNumber(nextPageNumber);
                    setPreviousSearchString(searchString);
                }
                if(pageNumber == 0 && data.mostActive.length < itemPerPage){
                    setHasMore(false);
                }
            })
            .catch((err) => {
                setHasMore(false);
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };

    const resetActiveMusicians = () => {
        setLoading(true);
		// setMusicians({mostActive: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
		try {
            setMusicians([]);
            setHasMore(true);
            setPageNumber(0);
            //@ts-ignore
            let searchStringForService = searchRef.current?.value;
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/all-musicians/${user.id}?searchString=${searchStringForService}&page=0&size=${itemPerPage}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if(data.mostActive.length > 0){
                    setMusicians(data.mostActive);
                    var nextPageNumber = pageNumber+1;
                    setPageNumber(1);
                    setPreviousSearchString(searchStringForService);
                }
                if(data.mostActive.length < itemPerPage){
                    // setHasMore(false);
                }
            })
            .catch((err) => {
                setHasMore(false);
            })
            .finally(() => {
                setLoading(false);
            });
		} catch (error) {
			setLoading(false);
		}
    };

    const handleCancel = () => {
        // Reset the search input and cancel the search
        setSearchString('');
        setPreviousSearchString('');
        //@ts-ignore
        searchRef.current.value = '';
        resetActiveMusicians();
        // setIsSearching(false);
    };

    const handleSearch = () => {
        if(searchString != ""){
            // Reset the search input and cancel the search
            setPreviousSearchString(searchString);
            resetActiveMusicians();
            // setIsSearching(false);
        }
    };
    const pauseAudioPlayer = (musicId:any) => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");
	    if(audioPlayers){
        audioPlayers.forEach((audioPlayer) => {		
			const audioElement = audioPlayer as HTMLAudioElement;
             if (audioPlayer.id == musicId) {
			// //	audioElement.play();
		     }else{
			// 	//audioPlayer.pause();
				audioElement.pause();
			 }
		});
        }		
	};

	return (
		<>
            {loading ?
                <Grid container item justifyContent="center" sx={{ p: "16px" }}>
                    <CircularProgress /> {/* The loading spinner */}
                </Grid>
                :
                <Grid xs={12} marginX={1}>
                        {
                            (musicians && musicians.length > 0 || searchString.length > 0) ?

                                <Grid container xs={12} className={classes.shadowedBox}>
                                    {/* <Grid xs={12} padding={1}>
                                        <Grid item={true} xs={3}>
                                            // <Typography variant="h5">Recent Joiners</Typography>
                                            <TextField
                                                hiddenLabel
                                                id="filled-hidden-label-small"
                                                variant="outlined"
                                                placeholder="Search musicians"
                                                size="small"
                                                // sx={{width: "130%"}}
                                                InputProps={{
                                                    endAdornment: (
                                                    <InputAdornment position="start" sx={{cursor: "pointer"}}>
                                                        // <img src={Search} alt="Search" />
                                                        {(searchString != "" && searchString === previousSearchString ) ? <Cancel onClick={handleCancel} /> : <Search onClick={handleSearch} />}
                                                    </InputAdornment>
                                                    ),
                                                }}
                                                value={searchString}
                                                inputRef={searchRef}
                                                onChange={(e) => setSearchString(e.target.value)}
                                                focused={searchString.length > 0 ? true : false}
                                            />
                                        </Grid>
                                        // <Grid item={true} xs={9} className="right-align">
                                        //     <Link href="#">View All</Link>
                                        // </Grid>
                                    </Grid> */}


                                    {/* User Cards */}
                                    <Grid xs={12} item>
                                        <InfiniteScroll
                                            dataLength={musicians.length}
                                            next={getActiveMusicians}
                                            hasMore={hasMore}
                                            loader={
                                                <Box
                                                    className={classes.spinner}
                                                >
                                                    <CircularProgress />
                                                </Box>
                                            }
                                            endMessage={
                                                <p style={{ textAlign: 'center' }}>
                                                    <b>Yay! You have seen it all</b>
                                                </p>
                                                }
                                        >
                                            {
                                                (musicians && musicians.length > 0) ?
                                                musicians.map(user => {
                                                    return (
                                                        <Grid item={true} xs={12}>
                                                            <UserCardDetailed user={user} refreshList={resetActiveMusicians} onItemClick={pauseAudioPlayer}/>
                                                        </Grid>
                                                    )
                                                })
                                                :
                                                <Grid container item xs={12} justifyContent="center">
                                                    No users available yet.
                                                </Grid>
                                            }
                                        </InfiniteScroll>
                                    </Grid>
                                </Grid>

                            :
                            <Grid container item justifyContent="center">
                                No users available yet.
                            </Grid>
                        }
                    {/* </Grid> */}
                </Grid>
            }
		</>
	);
};

export default ActiveMusicians;

import * as React from "react";
import Button from "@mui/material/Button";
import { Theme, styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface ActionDialogProps {
	title: string;
	titleStyle?: React.CSSProperties;
	titleAlignment?: "left" | "center" | "right";
	actionButtons?: React.ReactNode;
	dialogWidth?: string | number;
	open: boolean; 
	handleClose: () => void; 
	children: React.ReactNode;
}

interface BootstrapDialogProps {
	width?: string | number;
}

const BootstrapDialog = styled(Dialog, {
	shouldForwardProp: (prop) => prop !== "width",
})<BootstrapDialogProps>(({ theme, width }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
	"& .MuiDialog-paper": {
		width: width,
	},
}));

export default function ActionDialog({
	title,
	titleStyle,
	titleAlignment = "center",
	actionButtons,
    dialogWidth="500px",
	open,
	handleClose,
	children,
}: ActionDialogProps) {

	

	return (
		<React.Fragment>
			{/* <Button variant="outlined" onClick={handleClickOpen}>
				Open dialog
			</Button> */}
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				maxWidth="md"
				width={dialogWidth}
			>
				<DialogTitle
					sx={{ m: 0, p: 2, textAlign: titleAlignment, ...titleStyle }}
					id="reusable-dialog-title"
				>
					{title}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>{children}</DialogContent>
				<DialogActions sx={{ justifyContent: "center", mb: 2 }}>
					{actionButtons}
				</DialogActions>
			</BootstrapDialog>
		</React.Fragment>
	);
}


import React, { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Box,
	Typography,
	Grid
} from "@mui/material";
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";

const VerifyAccount = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	//const isEdgeBrowser = window.navigator.userAgent.indexOf("Edg") !== -1;
	const [accountPassword, setAccountPassword] = useState("");
	const [showPassword, setShowPassword] = useState(true);
	const [iserror, setError] = useState(false);

	const isEnabled = accountPassword.length >= 8 && accountPassword.length <= 16;

	useEffect(() => {
		if (props.open) {
			setAccountPassword("");
			setError(false);
			setShowPassword(true);
		}
	}, [props.open]);

	const verifyPassword = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/updateMultifactorAuth`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					email: user.email,
					password: accountPassword,
				}),
			}
		)
			.then((response) => {
				if (response.status >= 400) {
					setError(true);
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				setError(false);
				props.handleClose(true);
			})
			.catch(() => {
				setError(true);
			});
	};

	const unblockUser = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests/Unblock`,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					requestFrom: { id: props.toUnblockUser.userProfile.id },
					requestTo: { id: props.toUnblockUser.blockedUser },
					password: accountPassword,
				}),
			}
		)
			.then((response) => {
				if (response.status >= 400) {
					setError(true);
					throw new Error();
				} else {
					props.source === "sideBar" && props.getUserDetails();
					props.handleClose(true);
					setError(false);
					props.source === "blockedUserList" && props.fetchBlockedUsers();
					props.source === "musicianList" && props.refreshList();
				}
			})
			.catch(() => {
				setError(true);
			});
	}

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			onClose={() => props.handleClose(false)}
			open={props.open}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={() => props.handleClose(false)}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{!props.unblock
							? i18n.settings.accountSetup.verifyAccountDialog
							: "Verify Password"}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent>
				{props.unblock && (
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="body1" sx={{ marginBottom: 1 }}>
								To unblock this user confirm your password
							</Typography>
							<Typography
								sx={{ fontSize: "1.125rem", fontWeight: "500", marginBottom: 5 }}
							>
								{props.toUnblockUser.blockedUserName}
							</Typography>
						</Grid>
					</Grid>
				)}
				<Grid container xs={12} columnSpacing={2}>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.dialog.accountPassword}
							<a
								href="/#/forgotPassword"
								style={{
									
									fontStyle: "normal",
									fontSize: "1rem",
									color: "#0A6BFE",
									float: "right",
								}}
							>
								{i18n.dialog.forgotPassword}
							</a>
						</FormHelperText>
						<OutlinedInput
							type={showPassword ? "password" : "text"}
							sx={{ background: "#F8F8F8", marginBottom: "20px" }}
							fullWidth
							error={iserror}
							onChange={(e) => setAccountPassword(e.target.value)}
							onCopy={(e) => e.preventDefault()}
							onPaste={(e) => e.preventDefault()}
							onCut={(e) => e.preventDefault()}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPassword((show) => !show)}
										onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
											event.preventDefault()
										}
										edge="end"
									>
										{!showPassword ? (
											<img src={VisibleOn} alt="eye" />
										) : (
											<img src={VisibleOff} alt="Eye" />
										)}
									</IconButton>
								</InputAdornment>
							}
						/>
						{iserror && (
							<FormHelperText sx={{ color: "#FD4B24", textAlign: "right" }}>
								{i18n.dialog.invalidPassword}
							</FormHelperText>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					columnSpacing={1}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ width: "135px" }}
							className="cancel-button"
							size="large"
							variant="outlined"
							onClick={() => props.handleClose(false)}
						>
							{i18n.dialog.cancel}
						</Button>
					</Grid>
					<Grid item={true}>
						<Button
							sx={{ width: "135px" }}
							className="submit-button"
							size="large"
							variant="contained"
							onClick={!props.unblock ? verifyPassword : unblockUser}
							disabled={!isEnabled}
						>
							{!props.unblock ? i18n.dialog.next : "Unblock"}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
export default VerifyAccount;

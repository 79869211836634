import { useState, useEffect } from "react";
import {
	Button,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Box,
	Grid,
} from "@mui/material";
import Divider from '@mui/material/Divider';
import { useUser } from "../context/UserContext";
import CloseIcon from "../images/close.svg";
import i18n from "../l10n/strings.json";
import StunTest from "../services/StunTest";

const CompatibilityPopup = (props: any) => {
	const user = useUser();
    const [stunProgress, setStunProgress] = useState('null');
    const [stunResult, setStunResult] = useState(false);

    useEffect(() => {
        runSTUNTest();	
	}, []);

	const handleClose = (event: any, reason: string) => {
		if (reason && reason === "backdropClick") {
			return;
		}
		props.onClose();
	}

    const runSTUNTest = async () => {
		setStunResult(false);
		let iceServers = { urls: ['stun:stun.l.google.com:3478']};
		let t = StunTest.start();
		StunTest.on('icegatheringstatechange', (iceGatheringState) => {
			setStunProgress(iceGatheringState);
		})
		StunTest.on('icetestresult', (iceResult) => {
			setStunResult(iceResult);
		})
		
		// StunTest.on('icecandidate', (candidate) => {
		// 	// setStunResult(iceGatheringState);
		// })
    };

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px"
			}}
			fullWidth
			maxWidth="sm"
			onClose={handleClose}
			open={props.open}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={props.onClose}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.settings.compatibility.dialogTitle}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent>
				<Grid
					container
					xs={12}
					// columnSpacing={2}
				>
					<Grid item={true} xs={12}>
						<iframe
							style={{
								border: "none",
								width: "100%",
								height: "100%",
								minHeight: "320px",
								overflow: "hidden !important",
							}}
							src={process.env.REACT_APP_SPEED_TEST_URL}
						/>
					</Grid>
					<Grid item={true} xs={12}>
						<b>{i18n.settings.compatibility.doubleNatTest}</b>
						{stunProgress == "complete" && stunResult ? (
							<span style={{ color: "#259A12" }}>PASS</span>
						) : stunProgress == "complete" && !stunResult ? (
							<span style={{ color: "#F00" }}>FAILED</span>
						) : (
							"PROCESSING"
						)}
					</Grid>
					<Divider sx={{ margin: "2% 0", width: "100%" }} />
					<Grid item={true} xs={12}>
						<Typography component="span">
							<b>Overall Performance: </b>
							Compatibility test results as PASS when Internet upload speed is
							above 10Mbps & Double NAT Test is PASS.
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default CompatibilityPopup;



import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	IconButton,
	Box,
	Typography,
	Grid,
	Chip,
	InputAdornment,
	Tooltip,
	Tabs,
	Tab,
	Autocomplete,
} from "@mui/material";
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Copy from "../images/copy.svg"
import Profile from '../images/profile-circle-auto.svg';
import { useTheme } from "@mui/material/styles";
import ErrorAlert from "../components/Error/ErrorAlert";

const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;

const linkAccessProvided = [
	{
		id: "anyone",
		title: "Anyone with link",
		disabled: false
	},
	{
		id: "network",
		title: "Only in network",
		disabled: false
	},
	{
		id: "specific",
		title: "Specific people in network",
		disabled: false
	},
]
const useStyles = makeStyles((theme: any) => ({

	root: {
		color: "white",
		'& .MuiAutocomplete-tag': {
			backgroundColor: '#0273E6',
			color: 'white',
			'&:hover': {
				backgroundColor: '#0273E6',
				color: 'white',
			}
		},
		'& .css-1x7uvzz-MuiTypography-root': {
			color: '#7A7985'
		},
		'& .MuiAutocomplete-clearIndicator':{
			display:'none'
		  }
	}
}));

// const useStyles = makeStyles((theme: Theme) =>
// 	createStyles({
// 		linkAccessBtn : {
// 			borderRadius: "20px",
// 			textTransform: "none",
// 			color: "#ffffff",
// 			fontSize: "0.9rem",
// 			height: "36px",
// 			// transition: defaultTheme.transitions.create(
// 			// 	["background-color", "box-shadow", "border", "color"],
// 			// 	{
// 			// 		duration: defaultTheme.transitions.duration.short,
// 			// 	}
// 			// ),
// 			"& .MuiChip-label": {
// 				color: "customColor.orange",
// 				fontWeight: 500,

// 				fontSize: "1rem",
// 				lineHeight: "22px"
// 			},
// 			backgroundColor: "#FF702A",
// 			'&:hover': {
// 				border: "1px solid #FF702A",
// 				backgroundColor: "#FFF",
// 				color: "#FF702A"
// 			},
// 		}
// 	})
// );
function CircleRadio(props: RadioProps) {
	return (
		<Radio
			disableRipple
			color="primary"
			checkedIcon={<CheckCircleRoundedIcon />}
			{...props}
		/>
	);
}

const ShareMusic = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const classes = useStyles();
	const theme = useTheme();
	const [firstname, setFirstName] = useState(props.firstName);
	const [lastname, setLastName] = useState(props.lastName);
	const [dirty, setDirty] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [selectedTab, setSelectedTab] = useState('anyone');
	const [noteToPeople, setNoteToPeople] = useState(`${process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl}`);
	const [filteredActiveUsers, setFilteredActiveUsers] = useState<any>([]);
	const [selectedUser, setSelectedUser] = useState<any>([]);
	const [loading, setLoading] = useState(false)
	const [isMusicSharing, setIsMusicSharing] = useState('Share Music')
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [userListLoading, setUserListLoading] = useState(false);
	const [searchString, setSearchString] = useState<any>("");

	const isInvalid = firstname === "" || lastname === "" ||
		(firstname === props.firstName && lastname === props.lastName) ||
		NUMBEREREGEX.test(firstname) || SPECIALCHAREREGEX.test(firstname) ||
		NUMBEREREGEX.test(lastname) || SPECIALCHAREREGEX.test(lastname);

	const saveData = () => {
		if (selectedTab === 'specific') {
			const selectedUsersId = selectedUser && selectedUser.map((user: any) => ({ id: user.id }));
			setIsLoading(true)
			fetch(`${process.env.REACT_APP_BASE_URL}/api/share/selecteduser`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					musicUrl: props.recording.musicUrl,
					selectedUsers: selectedUsersId
				}),
			})
				.then((res) => {
					if (res.status !== 200) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						props.getRecordingList();
						handleClose();
						setIsLoading(false)
						setSelectedUser([])
						setSelectedTab('anyone')
						return res.json();
					}
				})
				.catch((error) => {
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Network Error! Please try again..");
					setIsLoading(false)
				});
		} else if (selectedTab === 'network') {
			setIsLoading(true)
			fetch(`${process.env.REACT_APP_BASE_URL}/api/share/myconnection`, {
				method: "POST",
				headers: {
				"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					musicUrl: props.recording.musicUrl
				}),
			})
				.then((res) => {
					if (res.status !== 200) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						props.getRecordingList();
						handleClose();
						setIsLoading(false)
						return res.json();
					}
				})
				.catch((error) => {
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Network Error! Please try again..");
					setIsLoading(false)
				});
			// fetch(`${process.env.REACT_APP_BASE_URL}/api/share/myconnection`, {
			// 	method: "GET",
			// 	headers: {
			// 		"Content-Type": "application/json",
			// 		Authorization: `Bearer ${token}`,
			// 	},
			// 	body: JSON.stringify({
			// 		musicUrl: props.recording.musicUrl,
			// 	}),
			// })
			// 	.then((res) => {
			// 		if (res.status !== 200) {
			// 			ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
			// 		} else {
			// 			// props.setGenresValue(genresValue);
			// 			handleClose();
			// 			setIsLoading(false)
			// 			return res.json();
			// 		}
			// 	})
			// 	.catch((error) => {
			// 		setShowAlert(true);
			// 		setAlertStatus(false);
			// 		setAlertMsg("Network Error! Please try again..");
			// 		setIsLoading(false)
			// 	});
		} else {
		}
	};

	const handleClose = () => {
		setFirstName(props.firstName);
		setLastName(props.lastName);
		setSelectedUser([]);
		setSelectedTab('anyone')
		if(isMusicSharing === 'No Sharing'){
			props.getRecordingList();
		}
		props.handleClose(false);
	};

	// const copyShareLink = () => {
	// 	navigator.clipboard.writeText(process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl);
	// 	setShowTooltip(true);
	// 	setTimeout(() => {
	// 		setShowTooltip(false);
	// 	}, 2000)
	// }

	const copyShareLink = () => {
		const recordingUrl = props.recording.musicUrl.includes("https://nhrecordings.blob.core.windows.net")
			? props.recording.musicUrl
			: process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl;
	
		navigator.clipboard.writeText(recordingUrl);
		setShowTooltip(true);
		setTimeout(() => {
			setShowTooltip(false);
		}, 2000);
	}

	const getUsersList = () =>{
		setLoading(true)
		setUserListLoading(true)
		fetch(
			// `${process.env.REACT_APP_BASE_URL}/api/active-users`,
			`${process.env.REACT_APP_BASE_URL}/api/getAllTaggedUserProfileList?search=${searchString}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					let filteredList: any = [];
					data.map((auser: any) => {
							let name = ''; // Variable to hold the name value
							if (user?.userProfileId === auser?.id) {
								name = auser?.user?.firstName + " " + auser?.user?.lastName;
							} else if (auser?.userPrivacySettingsDTO?.identityPermission === 'Name is not visible. Instead just show nickname') {
								name = auser?.nickname;
							} else if (auser?.userPrivacySettingsDTO?.identityPermission === 'Name is visible to all') {
								name = auser?.user?.firstName + " " + auser?.user?.lastName;
							} else if (auser?.userPrivacySettingsDTO?.identityPermission === 'Name is visible only to my connections. Other see nickname') {
								name = auser?.isConnected === true
									? auser?.user?.firstName + " " + auser?.user?.lastName
									: auser?.nickname;
							}
							else {
								name = auser?.user?.firstName + " " + auser?.user?.lastName;
							}
							filteredList.push({ ...auser.user, name });
							//   }
						})
					// const sortedOptions = options.sort((a, b) => a.localeCompare(b));
					//setFilteredActiveUsers(filteredList.sort((a: any, b: any) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName)));
					setFilteredActiveUsers(filteredList.sort((a: any, b: any) => (a.name).localeCompare(b.name)));
					setLoading(false)
					setUserListLoading(false)
				} else {
					setLoading(false)
					setUserListLoading(false)
					setFilteredActiveUsers([]);
				}
			})
			.catch((err) => {
				setLoading(false)
				setFilteredActiveUsers([]);
			});
	}

	const getSharedUsersList = () =>{
		setLoading(true)
		fetch(
			// `${process.env.REACT_APP_BASE_URL}/api/active-users`,
			`${process.env.REACT_APP_BASE_URL}/api/get-shared-users`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					musicUrl: props.recording.musicUrl,
				}),
			})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					let filteredList: any = [];
					// data.filter((item: any) =>
					// 	item.user.authorities[0].name === 'ROLE_USER' &&
					// 	((item.userPrivacySettingsDTO.messagePermission === 'Anyone can message me without being in connection') ||
					// 		(item.userPrivacySettingsDTO.messagePermission === 'Only connections can send me message' && item.isConnected === true))
					// )
						data.map((auser: any) => {
							let name = ''; // Variable to hold the name value
							if (user?.userProfileId === auser?.id) {
								name = auser?.user?.firstName + " " + auser?.user?.lastName;
							} else if (auser?.userPrivacySettingsDTO?.identityPermission === 'Name is not visible. Instead just show nickname') {
								name = auser?.nickname;
							} else if (auser?.userPrivacySettingsDTO?.identityPermission === 'Name is visible to all') {
								name = auser?.user?.firstName + " " + auser?.user?.lastName;
							} else if (auser?.userPrivacySettingsDTO?.identityPermission === 'Name is visible only to my connections. Other see nickname') {
								name = auser?.isConnected === true
									? auser?.user?.firstName + " " + auser?.user?.lastName
									: auser?.nickname;
							}
							else {
								name = auser?.user?.firstName + " " + auser?.user?.lastName;
							}
							filteredList.push({ ...auser.user, name });
							//   }
						})
					// const sortedOptions = options.sort((a, b) => a.localeCompare(b));
					//setFilteredActiveUsers(filteredList.sort((a: any, b: any) => (a.firstName + ' ' + a.lastName).localeCompare(b.firstName + ' ' + b.lastName)));
					setSelectedUser(filteredList.sort((a: any, b: any) => (a.name).localeCompare(b.name)));
					setLoading(false)
				} else {
					setLoading(false)
					setSelectedUser([]);
				}
			})
			.catch((err) => {
				setLoading(false)
				setSelectedUser([]);
			});
	}

	useEffect(() => {
		if (props.shareMusicOpen) {
			getSharedUsersList()
			getUsersList()
		}
	}, [props.shareMusicOpen]);

	const handleChange = (tabId: any) => {
		setSelectedTab(tabId)
	}
	const filterOptions = (options: any, { inputValue }: any) => {
		return options.filter((option: any) =>
			(option.name).toLowerCase().includes(inputValue.toLowerCase())
		);
	};

	const handleSharing = (value:any) => {
		setIsMusicSharing(value)
		if(value=== 'No Sharing'){
			setIsLoading(true)
			setSelectedTab('')
			fetch(`${process.env.REACT_APP_BASE_URL}/api/unshare/music`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					musicUrl: props.recording.musicUrl,
				}),
			})
				.then((res) => {
					if (res.status !== 200) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						// props.setGenresValue(genresValue);
						// handleClose();
						setIsLoading(false)
						return res.json();
					}
				})
				.catch((error) => {
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Network Error! Please try again..");
					setIsLoading(false)
				});
			}else{
				setSelectedTab('anyone')
			}
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			open={props.shareMusicOpen}
		>
			<DialogTitle sx={{ m: 0, p: 2 }}>
				{/* {children} */}
				{props.shareMusicOpen ? (
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<img src={CloseIcon} alt="Close Dialog" />
						{/* <CloseIcon /> */}
					</IconButton>
				) : null}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center"
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 600,
							color: "#000000",
							// fontFamily: "Lexend"
						}}
					>
						{i18n.myMusic.dialog.shareMusic}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						padding: "3% 0"
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 400,
							fontSize: "16px !important",
							color: "#000000",
						}}
					>
						<span>{i18n.myMusic.dialog.shareMusicText1}</span>
						<br />
						<span>{i18n.myMusic.dialog.shareMusicText2}</span>
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					// padding: "5px 20px",
				}}
			>
				<Grid container
					columnSpacing={2}
					rowSpacing={2}
					paddingBottom={2}
				>
					<Grid item xs={12}>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							value={isMusicSharing}
							onChange={(event) => handleSharing(event.target.value)}
							name="radio-buttons-group" classes={classes} >
							<FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="No Sharing" control={<CircleRadio />} label="No Sharing" />
							<FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Share Music" control={<CircleRadio />} label="Share Music" />
						</RadioGroup>
					</Grid>
					<Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							{i18n.myMusic.dialog.shareMusicLinkAccessMsg}
						</FormHelperText>

						<Grid container xs={12} sx={{ justifyContent: "space-between" }} >
							<Grid item={true}>
								<Tabs
									value={selectedTab}
									onChange={(event, value) => handleChange(value)}
									aria-label="Plain tabs"
									sx={{
										'& .MuiTabs-indicator': {
											display: 'none'
										},
										borderRadius: 'lg',
										'& .Mui-selected': {
											color: 'white !important',
											minHeight: '30px',
											minWidth: 'min-content',
											'&:active': { backgroundColor: isMusicSharing !== 'Share Music' ? '#D9D9D9 !important' : "#DAECFF !important", 
											// color: isMusicSharing !== 'Share Music' ? '#D9D9D9 !important' : '#0273E6 !important'
										 },
										},
										width: 'fit-content',
										'& .MuiTab-root': {
											minHeight: '30px',
											minWidth: 'min-content',
										},
										// alignSelf:'center',
										// display:'inline'

									}}
								>
									{linkAccessProvided.map((tabMenu) => (
										<Tab
											key={tabMenu.id}
											sx={{
												backgroundColor: selectedTab === tabMenu.id ? '#0273E6' : isMusicSharing !== 'Share Music' ? '#D9D9D9' : 'white',
												border: isMusicSharing !== 'Share Music' ? '2px solid #D9D9D9' : '2px solid #0273E6',
												color: selectedTab === tabMenu.id || isMusicSharing !== 'Share Music' ? 'white' : '#0273E6',
												borderRadius: '20px',
												mr: 1,
												minHeight: '30px',
												maxHeight: '30px',
												fontSize: '16px',
												padding: '0px 5px 0px 5px',
												fontWeight: '400 !important',
												textTransform: 'none',
												fontFamily: 'Lexend',
												'&:active': { background: isMusicSharing !== 'Share Music' ? '#D9D9D9' : "#EEF6FF" },

											}}
											value={tabMenu.id}
											label={tabMenu.title}
											disabled={tabMenu.disabled || isMusicSharing !== 'Share Music'}
										/>
									))}
								</Tabs>
							</Grid>

							{/* {linkAccessProvided.map(accessType => {
							return(
								<>
									<Grid item={true}>
										<Chip className={classes.linkAccessBtn} label={accessType.title} variant="filled" 
										onClick={handleClick}  disabled={accessType.disabled}
										color='info'
										clickable />
									</Grid>
								</>
							)
						})} */}
						</Grid>
						{/* <Chip label="Clickable" variant="outlined" onClick={handleClick} /> */}
					</Grid>
					<Grid item={true} xs={12}>

						{selectedTab === 'specific' ?
							<>
								<FormHelperText
									sx={{
										// fontSize: "1rem",
										color: "#7A7985",
										// ml:'0px !important'
									}}
									id="outlined-weight-helper-text"
								>
									Type names
								</FormHelperText>
								<Autocomplete
									multiple
									id="multiple-limit-tags1"
									classes={classes}
									className="social-textField"
									value={selectedUser}
									onChange={(event, newValue) => {
										setSelectedUser(newValue as any);
									}}
									options={filteredActiveUsers}
									getOptionLabel={(option) => option?.name}
									isOptionEqualToValue={(option, value) => option?.name === value?.name}
									filterSelectedOptions
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									loading={userListLoading}
									renderTags={(values, getTagProps) =>
										selectedUser.map((chip: any, index: any) => {
											const { key, className, ...tagProps } = getTagProps({
												index: index,
											});
											return (
												<Chip
													key={key}
													avatar={<img src={Profile} alt="profile" />}
													className={`${className} label-chip`}
													label={`${chip.name}`}
													sx={{
														backgroundColor: "customColor.blue",
														fontStyle: theme.typography.subtitle2.fontStyle,
														fontSize: theme.typography.subtitle2.fontSize,
														lineHeight: theme.typography.subtitle2.lineHeight,
														fontWeight: theme.typography.subtitle2.fontWeight,
														color: "secondary.main",
														'& .MuiSvgIcon-root': {
															color: 'white',
															'&:hover': {
																color: 'white'
															}
														}
													}}
													{...tagProps}
												/>
											);
										})
									}
									renderInput={(params) => <TextField {...params} label="" />}
									filterOptions={filterOptions}
								/>
								{/* <FormHelperText
									sx={{
										// fontSize: "1rem",
										color: "#7A7985", mt: 2
										// ml:'0px !important'
									}}
									id="outlined-weight-helper-text"
								>
									Note to people
								</FormHelperText>
								<TextField id="share-url"
									className="social-textField"
									fullWidth
									multiline
									rows={2}
									value={noteToPeople}
									onChange={(event) => setNoteToPeople(event.target.value)}
									variant="outlined"
								/> */}
							</>
							: selectedTab === 'network' ?
								<>
									{/* <FormHelperText
										sx={{
											// fontSize: "1rem",
											color: "#7A7985",
											// ml:'0px !important'
										}}
										id="outlined-weight-helper-text"
									>
										{i18n.myMusic.dialog.shareMusicUrlMsg}
									</FormHelperText>
									<TextField id="share-url"
										fullWidth
										sx={{ marginBottom: "10px" }}
										inputProps={{
											style: { background: "#F8F8F8" }
										}}
										value={process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl}
										variant="outlined"
										disabled
										InputProps={{
											endAdornment: (
												<InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={copyShareLink}>
													<Tooltip
														arrow
														PopperProps={{
															disablePortal: true,
														}}
														onClose={() => setShowTooltip(false)}
														open={showTooltip}
														disableFocusListener
														disableHoverListener
														disableTouchListener
														title="Copied!"
													>
														<img src={Copy} />
													</Tooltip>
												</InputAdornment>
											),
											sx: { ...{ background: "rgb(248, 248, 248)" } }
										}}
									/> */}
									<Typography sx={{ color: '#7A7985', fontWeight: 400, fontSize: '0.875rem', ml: 1 }}>
										Only people in your connected network can listen
									</Typography>
								</>
								:
								<>
									<FormHelperText
										sx={{
											// fontSize: "1rem",
											color: "#7A7985",
											ml: 0
										}}
										id="outlined-weight-helper-text"
									>
										{i18n.myMusic.dialog.shareMusicUrlMsg}
									</FormHelperText>
									<TextField id="share-url"
										fullWidth
										sx={{ marginBottom: "10px" }}
										inputProps={{
											style: { background: "#F8F8F8" }
										}}
										// value={process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl}
										value={props.recording.musicUrl.includes("https://nhrecordings.blob.core.windows.net") 
   											 ? props.recording.musicUrl 
   											 : process.env.REACT_APP_PICTURE_URL + props.recording.musicUrl}
										variant="outlined"
										disabled
										InputProps={{
											endAdornment: (
												<InputAdornment position="end" sx={{ cursor: "pointer" }} onClick={copyShareLink}>
													<Tooltip
														arrow
														PopperProps={{
															disablePortal: true,
														}}
														onClose={() => setShowTooltip(false)}
														open={showTooltip}
														disableFocusListener
														disableHoverListener
														disableTouchListener
														title="Copied!"
													>
														<img src={Copy} />
														{/* <Button onClick={handleTooltipOpen}>Click</Button> */}
													</Tooltip>
												</InputAdornment>
											),
											sx: { ...{ background: "rgb(248, 248, 248)" } }
										}}
									/>
								</>
						}

					</Grid>
				</Grid>
			</DialogContent>
			{(selectedTab !== 'anyone' && isMusicSharing !== 'No Sharing') &&
				<DialogActions>
					<Grid container
						columnSpacing={1}
						style={{
							display: "flex", flexDirection: "row", justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								sx={{ color: "#FF702A", width: "120px", fontSize: '1.125rem' }}
								className="cancel-button"
								size="large"
								variant="outlined"
								onClick={handleClose}
							>
								{i18n.dialog.cancel}
							</Button>
						</Grid>

						<Grid item={true}>
							<Button
								className="submit-button"
								size="large"
								variant="contained"
								fullWidth
								sx={{ width: "120px", fontSize: '1.125rem' }}
								// width: "135px"
								disabled={selectedTab === 'specific' && (selectedUser && selectedUser.length === 0)}
								onClick={saveData}
							>
								Done
							</Button>

						</Grid>
					</Grid>
				</DialogActions>
			}
		</Dialog>
	)
};
export default ShareMusic;

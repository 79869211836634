import { createContext, useState, useContext, useEffect } from "react";

type MusicianFilterContextType = {
    musicianCount: {
        totalSentConnectionRequests: number,
        totalConnections: number,
        totalInvitations: number,
        totalRejectedConnectionRequests: number
    },
	resetMusicianCount: any
};

export const MusicianCountContext = createContext<MusicianFilterContextType>(null!);

export const MusicianCountProvider = ({ children }: any) => {
    const [musicianCount, setMusicianCount] = useState({"totalSentConnectionRequests": 0, "totalConnections":0, "totalInvitations":0, "totalReceivedConnectionRequests":0, "totalRejectedConnectionRequests":0 })
    // const [resetMusicianCount, setResetMusicianCount] = useState(false);

    const resetMusicianCount:any = () => {
        // setCount({"totalSentConnectionRequests": 0, "totalConnections":0, "totalInvitations":0, "totalReceivedConnectionRequests":0, "totalRejectedConnectionRequests":0 });

        getCount();
    }

    // useEffect(() => {
    //     if(
    //         musicianFilter.genres.length > 0 || 
    //         musicianFilter.cities.length > 0 || 
    //         musicianFilter.instrument.length > 0 || 
    //         musicianFilter.firstName.length > 0 || 
    //         musicianFilter.lastName.length > 0 || 
    //         musicianFilter.nickName.length > 0 || 
    //         musicianFilter.isOpenForCollaboration || 
    //         musicianFilter.acceptGigs ){
    //         setIsFilterActive(true);
    //     }else{
    //         setIsFilterActive(false);
    //     }
    // }, [musicianFilter])

    useEffect(() => {
        getCount();
    }, [])

    const token = localStorage.getItem("sessionId");
    const getCount = () => {
        try {
            setMusicianCount({"totalSentConnectionRequests": 0, "totalConnections":0, "totalInvitations":0, "totalReceivedConnectionRequests":0, "totalRejectedConnectionRequests":0 });
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/counts`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                // setMusicians(data);
                setMusicianCount({"totalSentConnectionRequests": data.totalSentConnectionRequests , "totalConnections": data.totalConnections, "totalInvitations": data.totalInvitations, "totalReceivedConnectionRequests": data.totalReceivedConnectionRequests, "totalRejectedConnectionRequests": data.totalRejectedConnectionRequests });
            })
            .catch((err) => {
            })
            .finally(() => {
                // setLoading(false);
            });
		} catch (error) {
			// setLoading(false);
		}
    }

	return (
		<MusicianCountContext.Provider
			value={{
				musicianCount,
                resetMusicianCount
			}}
		>
			{children}
		</MusicianCountContext.Provider>
	);
};

// Custom hook to consume the context
export function useMusicianCounts() {
    return useContext(MusicianCountContext);
}
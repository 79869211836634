import { CardContent, Typography } from "@mui/material";
import { ReccommendationEvents } from "../commonComponents/Recommendation/ReccomendationEvents";
import CardHeader from "@mui/material/CardHeader";
import ArrowRight from "../../images/arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const Events = () => {
	const navigate = useNavigate();
	const handleViewAllAction = () => {
		navigate("/events");
	};

	const token = localStorage.getItem("sessionId");
	const [similarEvents, setSimilarEvents] = useState<any[]>([]);
	const [showSkeleton, setShowSkeleton] = useState(true);

	useEffect(() => {
		getSimilarEventsRecommendations();
	}, []);

	const getSimilarEventsRecommendations = (
		page = 0,
		size = 8,
		retryCount = 0
	) => {
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/events/recommendation/similar-artist?eventType=EVENT&page=${page}&size=${size}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					setShowSkeleton(false);
					setSimilarEvents(data);
					if (data.length < 2 && retryCount < 2) {
						// Retry with the same page and size
						getSimilarEventsRecommendations(page, size + 8, retryCount + 1);
					}
				})
				.catch((err) => {
				})
				.finally(() => {});
		} catch (error) {
			setShowSkeleton(false);
		}
	};

	return (
		<>
			{similarEvents.length > 0 && (
				<>
					<CardHeader
						onClick={handleViewAllAction}
						sx={{ cursor: "pointer" }}
						action={
							<img
								src={ArrowRight}
								alt="view-all"
								style={{ marginTop: "10px" }}
							/>
						}
						title={
							<Typography
								sx={{
									color: "#000",
									fontSize: "24px",
									fontWeight: "500",
									fontFamily: "Lexend",
								}}
							>
								Events
							</Typography>
						}
					/>
					<CardContent sx={{ alignItems: "center", gap: 1 }}>
						{/* <Typography variant="h6" sx={{ fontWeight: "bold" }}>
						Events
					</Typography> */}
						{/* <IconButton variant="plain" color="neutral" size="sm" sx={{ ml: 'auto' }}>
                                <Typography variant="body2" sx={{ mt: 0.5, color: '#3F77D7', mr: 3 }}>
                                    View All </Typography>
                            </IconButton> */}
						<ReccommendationEvents
							showSkeleton={showSkeleton}
							similarEvents={similarEvents}
						/>
					</CardContent>
				</>
			)}
		</>
	);
};
export default Events;

import { Box, Paper, Grid, Container, Typography } from "@mui/material";
import LogoWengor from "../../images/LuteFish_Logo_UI_withtagline_RGB.svg";
import i18n from "../../l10n/strings.json";
import "../Social/social.css";
import ProfileDetails from "../commonComponents/profileDetails";
import { useLocation } from "react-router";
import TickCircle from "../../images/Posts/tick-circle.svg";
import { SignUpProvider, useSignUp } from "../../context/SignUpContext";
import ProfileDetailsNew from "./profileDetailsNew";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SocialProfileNew = (props: any) => {
	// const { userData, setUserData } = useSignUp();
	const location = useLocation();
	const navigate = useNavigate();
	useEffect(() => {
		const userDetailsString = localStorage.getItem("user");
		if (userDetailsString) {
			try {
				const userDetails = JSON.parse(userDetailsString);
				if (
					userDetails &&
					(userDetails.firstName || userDetails.lastName || userDetails.location)
				) {
					navigate(`/home`);
				}
			} catch (error) {
				console.error("Error parsing user details:", error);
			}
		}
		}, []);
	return (
		<>
			<SignUpProvider>
				<Grid
					maxWidth="xl"
					sx={{
						position: "relative",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Grid
							container
							direction="column"
							sx={{ display: "flex", justifyContent: "center" }}
						>
							<div className="column sticky-header">
								<div className="row" style={{ padding: "20px 30px 10px 30px" }}>
									<img src={LogoWengor} alt="LuteFish Logo"></img>
								</div>
								<Typography
									variant="h1"
									className="edit-heading-name"
									sx={{
										textAlign: "center",
										mt: "-90px",
										padding: "10px 0px 10px 0px",
										fontWeight: "bold",
										boxShadow: "0px 0px 6px #CCDEFB",
									}}
								>
									{i18n.socialProfile.titleNew}
								</Typography>
							</div>

							<Grid className="signupform" gap={"20px"} mt={"30px"} mb={"30px"}>
								<Typography
									className="signup-confirm-message row"
									sx={{ color: "#3EB92A" }}
								>
									<img
										src={TickCircle}
										height="32px"
										width="32px"
										style={{ marginRight: "10px", marginTop: "-3px" }}
									/>
									{i18n.socialProfile.accountCreated}
								</Typography>
								<Typography
									className="signup-links-span-new"
									sx={{ color: "#5B5B65" }}
								>
									{i18n.socialProfile.almostThere}
								</Typography>
							</Grid>
							<div className="signupform">
								<Grid
									item
									width={"600px"}
									component={Paper}
									sx={{
										scrollbarWidth: "none",
										boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
										position: "relative",
										p: { lg: 2, md: 2 },
									}}
								>
									<Typography className="social-signup-title">
										{i18n.socialProfile.titleCapital}
									</Typography>
									<Typography className="social-signup-span">
										<span style={{ color: "#FD4B24" }}>*</span>
										{i18n.socialProfile.required}
									</Typography>
									<Grid className="signupform">
										<ProfileDetailsNew
											// firstName={location.state.firstName}
											// lastName={location.state.lastName}
											// email={location.state.email}
											// location={location.state.location}
											// fbUserId={location.state.fbUserId}
											// password={location.state.password}
											// type={location.state.type}

											email={location.state?.email}
											fbUserId={location.state?.fbUserId}
											password={location.state?.password}
											type={location.state?.type}
										/>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Box>
				</Grid>
			</SignUpProvider>
		</>
	);
};
export default SocialProfileNew;

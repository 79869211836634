import { Button, Grid, Typography } from "@mui/material";
import Logo from "../images/SignupLogo.svg";
import Guitar from "../images/GuitarLogo.png";
import RightAarrow from "../images/right arrow.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAdminAuthorized from "../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const DefaultPage = () => {

	const navigate = useNavigate();
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if ((!userDetails.firstName || !userDetails.lastName || !userDetails.location) && !isAdmin) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

  const handleTryAgain = () => {
	window.history.back();

	
  };
	
	return (
		<Grid container={true} sx={{ display: "flex" }}>
			<Grid item={true}>
				<img src={Logo} alt="Lutefish" />
			</Grid>
			<Grid
				container={true}
				sx={{ display: "flex", flexDirection: "column", margin: "150px" }}
				xs={12}
			>
				<Grid item={true} xs={4}>
					<img src={Guitar} alt="guitar" />
				</Grid>
				<Grid item={true} xs={8}>
					<Grid container={true} sx={{ display: "flex" }} xs={12}>
						<Grid item={true}>
							<Typography
								sx={{
									fontFamily: " Lexend",
									fontWeight: 500,
									lineHeight: "45px",
									fontSize: "36px",
									color: "#000",
								}}
							>
								Oops, Something went wrong !
							</Typography>
						</Grid>
						<Grid item={true} sx={{marginTop: "50px"}}>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "20px",
									fontWeight: 400,
									lineHeight: "25px",
								}}
							>
								Its our fault and we have noted this. Your data is not saved and
								you need to try again. If the problem persists please write to{" "}
								<a href="mailto:support@wenger.com">support@lutefish.com</a>
							</Typography>
						</Grid>
						<Grid item={true} sx={{marginTop:"150px"}}>
							<Button
								sx={{
									background:
										"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",

									height: "48px",
									color: "#FFFFFF",
									fontSize: "1.125rem",
									fontWeight: "500",
									lineHeight: "22.5px",
									borderRadius: "24px",
								}}
								size="large"
								fullWidth
								variant="contained"
								onClick={()=>handleTryAgain()}
								endIcon={<img src={RightAarrow} alt="Right Arrow" />}
							>
								<Typography
									className="signinWidth"
									sx={{ color: "#FFFFFF", fontSize: "1.125rem" }}
								>
									Try Again
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DefaultPage;

import React, { useEffect, useRef } from "react";
import BackgroundSelectionDialog from "../BackgroundSelectionDialog/BackgroundSelectionDialog";
import ChatWindow from "../ChatWindow/ChatWindow";
import clsx from "clsx";
import { GalleryView } from "../GalleryView/GalleryView";
import { MobileGalleryView } from "../MobileGalleryView/MobileGalleryView";
import MainParticipant from "../MainParticipant/MainParticipant";
import { useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { Participant, Room as IRoom } from "twilio-video";
import { ParticipantAudioTracks } from "../ParticipantAudioTracks/ParticipantAudioTracks";
import ParticipantList from "../ParticipantList/ParticipantList";
import { useAppState } from "../../state";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import usePeopleContext from "../../hooks/usePeopleContext/usePeopleContext";
import useScreenShareParticipant from "../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import PeopleWindow from "../People/PeopleWindow";

const useStyles = makeStyles((theme: Theme) => {
	const totalMobileSidebarHeight = `${
		theme.sidebarMobileHeight +
		theme.sidebarMobilePadding * 2 +
		theme.participantBorderWidth
	}px`;
	return {
		container: {
			position: "relative",
			display: "grid",
			gridTemplateColumns: `1fr 300px`,
			gridTemplateRows: "100%",
			[theme.breakpoints.down("sm")]: {
				gridTemplateColumns: `100%`,
				gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
			},
		},

		rightDrawerOpen: {
			gridTemplateColumns: `1fr 300px 320px`,
		},
	};
});

/**
 * This hook turns on speaker view when screensharing is active, regardless of if the
 * user was already using speaker view or gallery view. Once screensharing has ended, the user's
 * view will return to whatever they were using prior to screenshare starting.
 */

export function useSetSpeakerViewOnScreenShare(
	screenShareParticipant: Participant | undefined,
	room: IRoom | null,
	setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>,
	isGalleryViewActive: boolean
) {
	const isGalleryViewActiveRef = useRef(isGalleryViewActive);
	// Save the user's view setting whenever they change to speaker view or gallery view:
	useEffect(() => {
		isGalleryViewActiveRef.current = isGalleryViewActive;
	}, [isGalleryViewActive]);

	useEffect(() => {
		if (
			screenShareParticipant &&
			screenShareParticipant !== room!.localParticipant
		) {
			// When screensharing starts, save the user's previous view setting (speaker or gallery):
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const prevIsGalleryViewActive = isGalleryViewActiveRef.current;
			// Turn off gallery view so that the user can see the screen that is being shared:
			// setIsGalleryViewActive(false);
			return () => {
				// If the user was using gallery view prior to screensharing, turn gallery view back on
				// once screensharing stops:
				// if (prevIsGalleryViewActive) {
					// if(prevIsGalleryViewActive !== isGalleryViewActive){
						// setIsGalleryViewActive(prevIsGalleryViewActive);
					// }
				// }
			};
		}
	}, [screenShareParticipant, setIsGalleryViewActive, room]);
}

export default function Room() {
	const classes = useStyles();
	const { isChatWindowOpen } = useChatContext();
	const { isPeopleWindowOpen } = usePeopleContext();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { isBackgroundSelectionOpen, room, isSharingScreen } = useVideoContext();
	const { isGalleryViewActive, setIsGalleryViewActive } = useAppState();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const screenShareParticipant = useScreenShareParticipant();

	useEffect(() => {
		if (room) {
		  setIsGalleryViewActive(true);
		}
	  }, [room, setIsGalleryViewActive]);

	// Here we switch to speaker view when a participant starts sharing their screen, but
	// the user is still free to switch back to gallery view.
	useSetSpeakerViewOnScreenShare(
		screenShareParticipant,
		room,
		setIsGalleryViewActive,
		isGalleryViewActive
	);

	return (
		<div
		  className={clsx(classes.container, {
			[classes.rightDrawerOpen]:
			isChatWindowOpen || isBackgroundSelectionOpen || isPeopleWindowOpen,
		  })}
		  style={{
			height: "calc(100% - 64px )",
			backgroundColor: "#000000",
		  }}
		>
		  <ParticipantAudioTracks />
		  {(isGalleryViewActive && !screenShareParticipant) || screenShareParticipant === room.localParticipant ? (
			isMobile ? (
			  <MobileGalleryView />
			) : (
			  <GalleryView />
			)
		  ) : (
			<>
			  <MainParticipant />
			  <ParticipantList />
			</>
		  )}
		  <ChatWindow />
		  <PeopleWindow />
		  <BackgroundSelectionDialog />
		</div>
	  );
}

import { useState, useEffect, useCallback } from "react";

interface FetchOptions {
	method?: string;
	headers?: Record<string, string>;
	body?: string | null;
	fetchOnMount?: boolean;
}

interface UseFetchResult<T> {
	data: T | null;
	loading: boolean;
	error: string | null;
	triggerFetch: (
		fetchUrl: string,
		fetchOptions?: FetchOptions,
		onSuccess?: (message: string) => void,
		onError?: (message: string) => void
	) => void;
}

const useFetch = <T,>(
	initialUrl: string | null = null,
	initialOptions: FetchOptions = {
		method: "GET",
		fetchOnMount: false,
		headers: {},
		body: null,
	}
): UseFetchResult<T> => {
	const [options, setOptions] = useState<FetchOptions>(initialOptions);
	const [url, setUrl] = useState<string | null>(initialUrl);
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(
		initialOptions.fetchOnMount || false
	);
	const [error, setError] = useState<string | null>(null);

	const fetchData = useCallback(
		async (
			fetchUrl: string,
			fetchOptions: FetchOptions,
			onSuccess?: (message: string) => void,
			onError?: (message: string) => void
		) => {
			setLoading(true);
			setError(null);

			try {
				const response = await fetch(fetchUrl, fetchOptions);

				const contentType = response.headers.get("content-type");
				const isJsonResponse =
					contentType && contentType.includes("application/json");

				if (response.status === 200 && isJsonResponse) {
					const result = await response.json();
					if (onSuccess) {
						onSuccess("Success");
					}
					setData(result);
				} else if (response.status === 200 && !isJsonResponse) {
					if (onSuccess) {
						onSuccess("Success");
					}
				} else if (response.status === 500) {
					if (onError) {
						onError("Error");
					}
				} else {
					throw new Error("Network response was not ok");
				}
			} catch (error: any) {
				setError(error.message);
			} finally {
				setLoading(false);
			}
		},
		[]
	);

	useEffect(() => {
		if (initialOptions.fetchOnMount && url) {
			fetchData(url, options);
		}
	}, [url, options, initialOptions.fetchOnMount, fetchData]);

	const triggerFetch = (
		fetchUrl: string,
		fetchOptions: FetchOptions = {},
		onSuccess?: (message: string) => void,
		onError?: (message: string) => void
	) => {
		setUrl(fetchUrl);
		setOptions(fetchOptions);
		fetchData(fetchUrl, fetchOptions, onSuccess, onError);
	};

	return { data, loading, error, triggerFetch };
};

export default useFetch;

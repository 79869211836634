import React, { useEffect, useRef, useState } from "react";
import { Client } from "@twilio/conversations";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { Box, Input } from "@twilio-paste/core";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";
import { actionCreators, AppState } from "../../../store";
import CreateConversationButton from "./CreateConversationButton";
import ConversationsList from "./ConversationsList";
import styles from "../styles";

import { useDispatch, useSelector } from "react-redux";
import { filterConversations } from "../../../store/action-creators";
import { UserInterface } from "../../../types/types";
import { useUser } from "../../../context/UserContext";
import { Chip, Stack } from "@mui/material";

interface ConvosContainerProps {
  client?: Client;
}

const ConversationsContainer: React.FC<ConvosContainerProps> = (
  props: ConvosContainerProps
) => {
  const [listHidden, hideList] = useState(false);
  const dispatch = useDispatch();
  const user = useUser();
  const [userSearchString, setUserSearchString] = useState("");
  const unreadMessages = useSelector((state: AppState) => state.unreadMessages);

  const handleSearch = (searchString: string) => {
    setUserSearchString(searchString);
    dispatch(filterConversations(searchString) as any);
  };

  const [topHeight, setTopHeight] = useState();
  const [showConversationFor, setShowConversationFor] = useState('all');
  const searchStringRef = useRef<any>("");

  const token = localStorage.getItem("sessionId");
  // const [filteredActiveUsers, setFilteredActiveUsers] = useState<Array<UserInterface> | []>([]);
  const [unreadMsgCount, setUnreadMsgCount] = useState<number>(0);

  // const fetchMyConnections = () => {
  //   try {
  //     // fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connections/my-connection/${user.id}?searchString=${userSearchString}&page=0&size=1000000`, {
  //     fetch(`${process.env.REACT_APP_BASE_URL}/api/active-users`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //       .then((res) => {
  //         if (res.status > 400) {
  //           throw new Error("api_error");
  //         } else {
  //           return res.json();
  //         }
  //       })
  //       .then((data) => {
  //         if (data !== null) {
  //           setFilteredActiveUsers(data);
  //         } else {
  //           setFilteredActiveUsers([]);
  //         }
  //       })
  //       .catch((err) => {
  //         setFilteredActiveUsers([]);
  //       });
  //   } catch (error) {
  //   }
  // }

  const handleClick = (activeMenu:string) => {
      setShowConversationFor(activeMenu);
  }

  useEffect(() => {
    // const sum:number = Object.values(unreadMessages).reduce((acc:number, value:number) => acc + value,0);
    const sum:number = Object.values(unreadMessages).reduce(
      (acc:number, value:number) => acc + value,
      0
    );
    setUnreadMsgCount(sum);

    const headerDiv = document.getElementById("app-header");
    const messageDiv = document.getElementById("messageDiv");
    //@ts-ignore
    setTopHeight(headerDiv?.clientHeight + messageDiv?.clientHeight);
    
    // fetchMyConnections();
  }, []);


  return (
    <Box
      // style={
      //   listHidden
      //     ? { ...styles.convosWrapper, ...styles.collapsedList }
      //     : styles.convosWrapper
      // }
      //style={styles.convosWrapper}
      // height={`calc(100vh - ${topHeight}px)`}
      className="leftPanelWidth" 
      // style={styles.convoCard}
    >
      <Box style={styles.newConvoButton}>
        <CreateConversationButton
          client={props.client}
          collapsed={listHidden}
        />
        <Box marginTop="space60">
          <Input
            aria-describedby="convo_string_search"
            id="convoString"
            name="convoString"
            type="text"
            placeholder="Search your conversation"
            onChange={(e) => handleSearch(e.target.value)}
            value={userSearchString}
            required
            autoFocus
            ref={searchStringRef}
          />
        </Box>
        <Box marginTop="space60">
          {/* <Input
            aria-describedby="convo_string_search"
            id="convoString"
            name="convoString"
            type="text"
            placeholder="Search your connection"
            onChange={(e) => handleSearch(e.target.value)}
            value={userSearchString}
            required
            autoFocus
            ref={searchStringRef}
          /> */}
          <Stack direction="row" spacing={1}>
            <Chip label="All" variant={ (showConversationFor != "message" && showConversationFor != "gigs" ) ? "filled" : "outlined"} onClick={() => handleClick('all')} />
            <Chip label="Message" variant={ showConversationFor == "message" ? "filled" : "outlined"} onClick={() => handleClick('message')} />
            <Chip label="Gigs" variant={ showConversationFor == "gigs" ? "filled" : "outlined"} onClick={() => handleClick('gigs')} />
          </Stack>
        </Box>
      </Box>
      <Box style={styles.convoList}>
         <ConversationsList client={props.client} searchString={userSearchString} showConversationFor={showConversationFor} setUserSearchString={setUserSearchString} /> 
      </Box>
      {/* <Box style={styles.collapseButtonBox}>
        <Box
          paddingTop="space30"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => hideList(!listHidden)}
        >
          {listHidden ? (
            <ChevronDoubleRightIcon decorative={false} title="Collapse" />
          ) : (
            <ChevronDoubleLeftIcon decorative={false} title="Collapse" />
          )}
        </Box>
      </Box> */}
    </Box>
  );
};

export default ConversationsContainer;

import {
	Box,
	Typography,
	Grid,
	Button,
	DialogContent,
	DialogTitle,
	Dialog,
	DialogActions,
	Switch,
	FormControlLabel,
} from "@mui/material";
import i18n from "../../l10n/strings.json";
import { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import TickCircle from "../../images/tick-circle-white.svg";
import AddCircle from "../../images/add-circle.svg";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";


const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 34,
	height: 22,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 20,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(9px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		width: 16.89,
		height: 16.89,
		borderRadius: 9.21,
		transition: theme.transitions.create(["width"], {
			duration: 200,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 22 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === "dark"
				? "rgba(255,255,255,.35)"
				: "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));
const ReportMusic = (props: any) => {
	const SelfDetails = useUser();
	const token = localStorage.getItem("sessionId");
	const [reasons, setReasons] = useState<any>([]);
	const [isBlock, setIsBlock] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [selectedButtons, setSelectedButtons] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	
	useEffect(() => {
		setSelectedButtons(props.usersReportForPostMap);
	}, [props]);
	useEffect(() => {
		props.open && getViolations();
	}, [props]);

	const handleAlertClose = () => {
		setShowAlert(false);
	};
	const handleClick = (index: any) => {
		// Check if the button is already selected
		if (selectedButtons.includes(index)) {
			// If it's already selected, remove it from the array
			setSelectedButtons(selectedButtons.filter((item: any) => item !== index));
			props.setSelectedViolations(
				selectedButtons.filter((item: any) => item !== index)
			);
		} else {
			// If it's not selected, add it to the array
			setSelectedButtons([...selectedButtons, index]);
			props.setSelectedViolations([...selectedButtons, index]);
		}
	};

	const handleReason = (event: any) => {
		setIsBlock(event.target.checked);
	};

	const isEnabled = () => {
		if (selectedButtons && selectedButtons.length > 0) {
			return false;
		} else {
			return true;
		}
	};
	const getViolations = () => {
		try {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/master-violations/get-all`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
				.then((res) => {
					if (res.status >= 400) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					setReasons(data);
				})
				.catch((err) => {
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const reportMusic = () => {
		try {
			props.setLoading(true);
			fetch(`${process.env.REACT_APP_BASE_URL}/api/moderations/report`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					offenderUserProfileId: props.userProfileId,
					musicId: props.musicId,
					violationTypes: selectedButtons,
					reportedById: SelfDetails.userProfileId,
					commentId: null,
				}),
			})
				.then((res) => {
					if (res.status !== 200 && res.status !== 201) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						if (isBlock) {
							try {
								fetch(
									`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`,
									{
										method: "POST",
										headers: {
											"Content-Type": "application/json",
											Authorization: `Bearer ${token}`,
										},
										body: JSON.stringify({
											requestFrom: {
												id: SelfDetails.userProfileId,
											},
											requestTo: {
												id: props.userProfileId,
											},
											connectionStatus: "BLOCKED",
											note: selectedButtons.join(","),
										}),
									}
								)
									.then((res) => {
										if(res.status === 502){
											navigate('/defaultPage')
										} else if (res.status !== 200 && res.status !== 201) {
											ErrorAlert(
												res.status,
												setAlertStatus,
												setShowAlert,
												setAlertMsg
											);
										} else {
											return res.json();
										}
									})
									.then((data) => {
										props.setConnectionStatus(data.connectionStatus);
										// if (isBlock) {
										//     props.removeBlockedUsersPosts()
										// }
										setReasons([]);
										props.handleClose();
									})
									.catch((err) => {
									})
									.finally(() => {
										props.setLoading(false);
									});
							} catch (error) {
								props.setLoading(false);
							}
						}
						return res.json();
					}
				})
				.then((data) => {
					props.handleClose();
					setSelectedButtons([]);
				})
				.catch((err) => {})
				.finally(() => {
					props.setLoading(false);
				});
		} catch (error) {
			props.setLoading(false);
		}
	};

	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				// onClose={props.handleClose()}
				open={props.open}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							className="dialog-title-custom-size"
							sx={{
								fontWeight: 500,
								color: "#000000",
							}}
						>
							Report/Block User
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item={true} xs={12}>
							<Typography>
								If you feel that this post violets our term & condition you can
								report to us and tell us what terms are violated below. Add an
								optional note to admin
							</Typography>

							<Box
								sx={{ backgroundColor: "#F8F8F8", my: 1, borderRadius: "6px" }}
							>
								<Grid
									container
									columnGap={2}
									rowGap={1}
									columns={{ xs: 4, sm: 8, md: 12 }}
									sx={{ py: 1, px: 4 }}
								>
									{reasons &&
										reasons.map((item: any) => (
											<Button
												key={item.id}
												sx={{
													border: "1px solid #0273E6",
													color: selectedButtons.includes(item.violationType)
														? "white"
														: "#0273E6",
													backgroundColor: selectedButtons.includes(
														item.violationType
													)
														? "#0273E6"
														: "",
													"&:hover": { background: "#EEF6FF" },
												}}
												onClick={() => handleClick(item.violationType)}
											>
												<img
													src={
														selectedButtons.includes(item.violationType)
															? TickCircle
															: AddCircle
													}
													alt={
														selectedButtons.includes(item.violationType)
															? "TickCircle"
															: "AddCircle"
													}
													height={"20px"}
												/>
												&nbsp;
												{item.violationType}
											</Button>
										))}
								</Grid>
							</Box>

							<FormControlLabel
								sx={{
									"& .MuiFormControlLabel-label": { color: "#5D5D65" },
									ml: 1,
								}}
								control={
									<AntSwitch
										checked={isBlock}
										onChange={handleReason}
										inputProps={{ "aria-label": "ant design" }}
									/>
								}
								// <Switch color='info' checked={isBlock} onChange={handleReason} />}
								label="Also Block this user"
							/>

							<Typography sx={{ color: "#999999", ml: 1, fontSize: "13px" }}>
								This user will be listed in your blocked user list in settings
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								className="cancel-button"
								size="large"
								variant="outlined"
								onClick={props.handleClose}
							>
								{i18n.dialog.cancel}
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								className="submit-button"
								size="large"
								variant="contained"
								onClick={() => reportMusic()}
								disabled={isEnabled()}
							>
								Report
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ReportMusic;

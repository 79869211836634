import EventEmitter3 from "events"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import adapter from 'webrtc-adapter';

class TurnTest extends EventEmitter3 {
    public TurnTest: any = undefined;

    iceServers: any
    candidates: any[]
    startTime: number | undefined
    endTime: number | undefined
    pc: RTCPeerConnection | undefined

    constructor () {
      super()
      this.iceServers = [{ urls: [process.env.REACT_APP_STUN_TEST_URL]}];
      this.candidates = []
      this.startTime = undefined
      this.endTime = undefined
    }
  
    start() {
      this.startTime = Date.now()
      this.pc = new RTCPeerConnection({iceServers: this.iceServers})
  
      this.pc.onicegatheringstatechange = (e: any) => {
        if (this.pc?.iceGatheringState === 'complete') {
          this.endTime = Date.now()
        }
        this.emit('icegatheringstatechange', this.pc?.iceGatheringState)
        return this.pc?.iceGatheringState;
      }
      
      this.pc.onicecandidate = (event: { candidate: any }) => {
        if (event.candidate) {
          this.candidates.push({time: Date.now(), candidate: event.candidate})
          this.emit('icecandidate', event.candidate)
          
          if(event.candidate.type === "srflx"){
            this.emit('icetestresult',true)
          }
        }
      }
  
      this.pc.createOffer({offerToReceiveAudio: true}).then((desc: any) => {
        return this.pc?.setLocalDescription(desc)
      }).catch((error: any) => {
        console.error(error)
        reject(error)
      })
    }
  
  
    stop() {
      this.pc!.onicegatheringstatechange = null
      this.pc!.onicecandidate = null
      this.pc?.close()
      this.pc = undefined
      this.candidates = []
    }
  }

function reject(error: any) {
    throw new Error("Function not implemented.")
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new TurnTest();
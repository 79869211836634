// MusicalEvents.tsx

import React, { useEffect, useState } from "react";
import GoogleEventCardCom from "./GoogleEventCardComp";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import { useUser } from "../../context/UserContext";
import InfiniteScroll from "react-infinite-scroll-component";

// const API_KEY = '751a02553a7783b151108c70a8c800483d2306bf902c0f3c9ae23639105d82b0';
const searchQuery = "musical events";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			borderRadius: "15px",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		linkColor: {
			color: "#0273E6 !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);
const GoogleEvents: React.FC = () => {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const classes = useStyles();

	const [eventList, setEventList] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [start, setStart] = useState<any>(0);
	const [errorMsg, setErrorMsg] = useState("");

	useEffect(() => {
		fetchDataForScroll();
	}, []);

	const fetchData = async () => {
		setLoading(true);
		setHasMore(true);

		// http://localhost:8085/api/events/get-musician-events?query=musicalevents&city=pune&start=30
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/events/get-musician-events?query=${searchQuery}&city=${user.location}&start=0`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					setLoading(false);

					if (res.status === 500) {
						setEventList([{ noEventFound: true }]);
					} else {
						console.error("Error:", res.statusText);
					}
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setLoading(false);
				if (data.events_results) {
					const eventData = data.events_results;

					if (eventData.length === 0) {
						setEventList([{ noEventFound: true }]);
					} else {
						const sortedEventList = eventData.slice().sort((a: any, b: any) => {
							const dateA: any = new Date(a.date.start_date);
							const dateB: any = new Date(b.date.start_date);
							return dateA - dateB;
						});
						setEventList(sortedEventList);
					}
				}
			})
			.catch((err: any) => {
				setLoading(false);
				setHasMore(false);
				console.error("Fetch error", err);
			});
	};

	const pageSize = 10; // Number of events to fetch per request
	const displaySize = 8; // Number of events to display at a time

	const fetchDataForScroll = async () => {
		eventList.length === 0 && setLoading(true);

		const nextPageStart = start + pageSize;

		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/events/get-musician-events?query=${searchQuery}&city=${user.location}&start=${nextPageStart}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					setLoading(false);

					if (res.status === 500) {
						setEventList([{ noEventFound: true }]);
					} else {
						console.error("Error:", res.statusText);
					}
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.events_results) {
					const eventData = data.events_results;

					if (eventData.length === 0) {
						setHasMore(false);
						setLoading(false);
					} else {
						const sortedEventList = eventData.slice().sort((a: any, b: any) => {
							const dateA: any = new Date(a.date.start_date);
							const dateB: any = new Date(b.date.start_date);
							return dateA - dateB;
						});

						// Only display the first 'displaySize' events
						setEventList([
							...eventList,
							...sortedEventList.slice(0, displaySize),
						]);
						setStart(nextPageStart);

						if (eventData.length < pageSize) {
							setHasMore(false);
						}
					}
				} else {
					setHasMore(false);
					setErrorMsg(data.error);
				}
			})
			.catch((err: any) => {
				setLoading(false);
				setHasMore(false);
				console.error("Fetch error", err);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<>
			{loading ? (
				<Grid container xs={12} justifyContent="center" sx={{ p: "16px" }}>
					<CircularProgress /> {/* The loading spinner */}
					<Grid container item justifyContent="center">
						Searching events...
					</Grid>
				</Grid>
			) : (
				<>
					<InfiniteScroll
						dataLength={eventList && eventList.length}
						next={fetchDataForScroll}
						hasMore={hasMore}
						loader={
							// loading && (
							<Box className={classes.spinner}>
								<CircularProgress />
							</Box>
							// )
						}
						endMessage={
							<p style={{ textAlign: "center" }}>
								<b>Yay! You have seen it all</b>
							</p>
						}
						style={{ overflow: "none" }}
					>
						{
							<Grid container display="flex" xs={12} direction="row">
								{eventList && eventList.length > 0 ? (
									eventList.map((event: any) => (
										<Grid
											item={true}
											sm={4}
											lg={3}
											sx={{ marginBottom: "12px" }}
											key={event.id}
										>
											{event.noEventFound ? (
												<div>No Google events found</div>
											) : (
												<GoogleEventCardCom data={event} />
											)}
										</Grid>
									))
								) : (
									<Grid
										container
										style={{ textAlign: "center", justifyContent: "center" }}
									>
										{errorMsg}
									</Grid>
								)}
							</Grid>
						}
					</InfiniteScroll>
				</>
			)}
		</>
	);
};

export default GoogleEvents;

import './social.css';
import { useState } from "react";
import {
    TextField,
    Button,
    Box,
    Paper,
    Grid,
    FormControl,
    FormHelperText,
    Container,
    Typography,
} from "@mui/material";
import GoogleLocationService from "../GoogleLocationService";
//import LogoWengor from "../../images/logo-new-horizon.svg";
import LogoWengor from "../../images/logo_Lutefish_app.svg";
import i18n from "../../l10n/strings.json";
import RightAarrow from "../../images/right arrow.svg";
import { useNavigate } from "react-router-dom";
import { useSocialUser } from '../../context/SocialSignupUser';
import validator from "validator";
import useAdminAuthorized from '../../hooks/useIsAdminAuthorized/useIsAdminAuthorized';

const UPPERCASEREGEX = /.*[A-Z].*/;
const NUMBEREREGEX = /.*[0-9].*/;
const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;

const SocialUp = () => {
    const type: String = "social";
    const socialUser = useSocialUser();
    const navigate = useNavigate();
    const [selectedLocation, setSelectedLocation] = useState("");
    const [dirtyLocation, setDirtyLocation] = useState(false);
    const [firstName, setFirstName] = useState<any>(socialUser.firstName);
    const [dirtyFirstName, setDirtyFirstName] = useState(false);
    const [lastName, setLastName] = useState<any>(socialUser.lastName);
    const [dirtyLastName, setDirtyLastName] = useState(false);
    const [email, setEmail] = useState(socialUser.email ? socialUser.email : '');
    const [dirtyEmail, setDirtyEmail] = useState(false);
    const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
    const socialProfile = (firstName: any, lastName: any) => {
        if (firstName !== '' && email !== '' && lastName !== '' && !isAdmin) {
            navigate("/socialProfileNew", {
                state: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    location: selectedLocation,
                    fbUserId: socialUser.id_token,
                    password: "",
                    type: type
                },
            });
        } else {
            setDirtyEmail(true)
            setDirtyFirstName(true)
            setDirtyLastName(true)
            setDirtyLocation(true)
        }
    }
    return (
        <>
            <Container maxWidth='xl'>
                <Box sx={{ justifyContent: "center" }} >
                    <Grid container direction="column" columns={{ xs: 4, sm: 8, md: 12 }}>

                        <Grid xs={3} sx={{ mt: 2 }}>
                            <img src={LogoWengor} alt="LuteFish Logo"></img>
                        </Grid>
                        <Grid xs={12} className='headingName'>
                            <Box sx={{ textAlign: 'center', mr: 1, mt: 2, ml: 1 }} >{i18n.socialUp.title}</Box>
                        </Grid>

                        <div className='social-up-main-div' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={10} sm={7} md={5} direction="column" component={Paper}
                                sx={{
                                    alignItems: "center",
                                    textAlign: "left",
                                    p: 5,
                                    scrollbarWidth: "none",
                                    boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
                                }}>

                                <FormControl sx={{ width: "100%" }}>
                                    <Box sx={{ textAlign: 'left', m: 1 }}>

                                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                                            <Grid xs={4}>
                                                <FormHelperText sx={{ ml: 0 }}>{i18n.socialUp.email}*</FormHelperText>
                                            </Grid>
                                            <Grid xs={8} sx={{ textAlign: "right" }}>
                                                <span className='social-support-text' style={{ fontSize: '14px', fontWeight: 400 }}>{i18n.socialUp.verificationText}</span>
                                            </Grid>
                                            <Grid xs={12}>
                                                <TextField
                                                    id="email"
                                                    type="email"
                                                    className='social-Field'
                                                    sx={{ color: '#888888' }}
                                                    value={email}
                                                    onChange={(event) => setEmail(event.target.value)}
                                                    variant="standard"
                                                    onFocus={() => setDirtyEmail(true)}
                                                    error={dirtyEmail && !validator.isEmail(email)}
                                                    required />
                                                {
                                                    dirtyEmail && !validator.isEmail(email) &&
                                                    <FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
                                                        {i18n.signUp.invalidEmailMsg}
                                                    </FormHelperText>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }} >
                                            <Grid xs={6} item >
                                                <FormHelperText sx={{ ml: 0 }}>{i18n.socialUp.firstName}*</FormHelperText>
                                                <TextField
                                                    id="firstName"
                                                    className='social-textField'
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value.trim())}
                                                    variant="outlined"
                                                    error={dirtyFirstName && (firstName?.length <= 1 || firstName?.length > 50 || NUMBEREREGEX.test(firstName) || SPECIALCHAREREGEX.test(firstName))}
                                                    onFocus={() => setDirtyFirstName(true)}
                                                    required />
                                                {
                                                    dirtyFirstName &&
                                                    <FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
                                                        {
                                                            firstName?.length <= 1 ? "Please enter First Name" :
                                                                firstName?.length > 50 || NUMBEREREGEX.test(firstName) || SPECIALCHAREREGEX.test(firstName) ? "Please enter valid First Name"
                                                                    : ""
                                                        }
                                                    </FormHelperText>
                                                }
                                            </Grid>
                                            <Grid xs={6} item>
                                                <FormHelperText sx={{ ml: 0 }}>{i18n.socialUp.lastName}*</FormHelperText>
                                                <TextField
                                                    id="lastName"
                                                    className='social-textField'
                                                    type="text"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value.trim())}
                                                    variant="outlined"
                                                    error={dirtyLastName && (lastName?.length <= 1 || lastName?.length > 50 || NUMBEREREGEX.test(lastName) || SPECIALCHAREREGEX.test(lastName))}
                                                    onFocus={() => setDirtyLastName(true)}
                                                    required />
                                                {
                                                    dirtyLastName &&
                                                    <FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
                                                        {
                                                            lastName?.length <= 1 ? "Please enter Last Name" :
                                                                lastName?.length > 50 || NUMBEREREGEX.test(lastName) || SPECIALCHAREREGEX.test(lastName) ? "Please enter valid Last Name"
                                                                    : ""
                                                        }
                                                    </FormHelperText>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                                            <Grid xs={4}>
                                                <FormHelperText sx={{ ml: 0 }}>{i18n.socialUp.location}*</FormHelperText>
                                            </Grid>
                                            <Grid xs={12}>
                                                <GoogleLocationService
                                                    defaultValue={selectedLocation}
                                                    onLocationChange={(locationDesc: string) => (setSelectedLocation(locationDesc))}
                                                    isError={dirtyLocation && selectedLocation === ''} />
                                                {
                                                    dirtyLocation &&
                                                    <FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
                                                        {
                                                            selectedLocation === "" ? "Please enter your location"
                                                                : ""
                                                        }
                                                    </FormHelperText>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                                            <Grid xs={12} sx={{ mt: 2 }}>
                                                <Button
                                                    className="next-button button-field-custom-font-size"
                                                    size="large"
                                                    // disabled={!isEnabled}
                                                    onClick={() => socialProfile(firstName, lastName)}
                                                    endIcon={<img src={RightAarrow} alt="Right Arrow" />}
                                                    fullWidth
                                                    variant="contained">
                                                   <Typography> {i18n.socialUp.button}</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                </FormControl>

                            </Grid>
                        </div>

                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default SocialUp;

import Grid from "@mui/material/Grid"
import UsersManagementFilter from "./UsersManagementFilter";
import UserManagementTb from "./UserManagementTb";

export const UserManagementContainer = () => {
    return (
			<Grid container xs={12} className="mainPanel">
				<Grid xs={3} item className="leftPanelWidth">
					<UsersManagementFilter />
				</Grid>
			<Grid xs={9} item sx={{ marginTop: "0.2rem" }} className="userManagTable centerPanelwoRight">
					<UserManagementTb />
				</Grid>
			</Grid>
		);
}
import React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import CloseIcon from "../../../icons/CloseIcon";

import useChatContext from "../../../hooks/useChatContext/useChatContext";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			height: "56px",
			background: "#000000",
			// borderBottom: "1px solid #E4E7E9",
			display: "flex",
			justifyContent: "space-between",
			// alignItems: "center",
			padding: "12px 12px",
			borderRadius: "8px",
		},
		text: {
			fontSize: "1rem",
			fontWeight: "bold",
			color: "#FFFFFF",
		},
		closeChatWindow: {
			cursor: "pointer",
			display: "flex",
			background: "transparent",
			border: "0",
			padding: "0.4em",
		},
	})
);

export default function ChatWindowHeader() {
	const classes = useStyles();
	const { setIsChatWindowOpen } = useChatContext();

	return (
		<div className={classes.container}>
			<div className={classes.text}>Chat</div>
			<button
				className={classes.closeChatWindow}
				onClick={() => setIsChatWindowOpen(false)}
			>
				<CloseIcon />
			</button>
		</div>
	);
}

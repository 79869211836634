import { useState, useContext, createContext } from "react";
// types
import PropTypes from "prop-types";
import type { ReactNode } from "react";
type FullscreenModeContextType = {
	isFullscreenMode: boolean;
	isTabHidden: boolean;
	setIsFullscreenMode: (isFullscreenMode: boolean) => void;
	setIsTabHidden: (isTabHidden: boolean) => void;
};
const FullscreenModeContext = createContext<FullscreenModeContextType>(null!);

export const useFullscreenModeContext = () => useContext(FullscreenModeContext);

type Props = {
	children: ReactNode;
};

export const FullscreenModeProvider = (props: Props) => {
	const [isFullscreenMode, setIsFullscreenMode] = useState<boolean>(false);
	const [isTabHidden, setIsTabHidden] = useState<boolean>(false);

	return (
		<FullscreenModeContext.Provider
			value={{
				isFullscreenMode,
				isTabHidden,
				setIsFullscreenMode,
				setIsTabHidden,
			}}
		>
			{props.children}
		</FullscreenModeContext.Provider>
	);
};

FullscreenModeProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

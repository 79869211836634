import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { height } from "@mui/system";
import Music from "../images/music-dark.svg";

const HelpAndTips = ({ title, text, notification }: any) => {
	return (
		<Grid container={true} xs={12} 
		sx={{height:{xs: "100px", sm: "130px", md: "170px", lg: "200px", xl: notification ? "302px" : "250px"}}}>
			<Card
				sx={{
					background: notification ? "#F0F0F0" : "customColor.gray1A",
					width: "100%",
					boxShadow: notification && "none"
				}}
			>
				<CardContent>
					<Grid container={true} sx={{ paddingBottom: 0 }}  xs={12}>
						<Grid item={true} columns={{ xs: 12 }}  xs={12}>
							<Grid container={true} >
								<Grid item={true} xs={2}>
									<img src={Music} alt="music" className="music-logo-custom-height" />
								</Grid>
								<Grid item={true} xs={10}>
									<Typography
									variant="h6"
									color="customColor.black"
									>
										{title}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item={true}>
							<Grid container={true}
								sx={{
									paddingBottom: 0,
									overflowY: "auto",
									scrollbarWidth: "none",
									maxHeight: { xs: "80px", sm: "100px", md: "110px", lg: "125px", xl:"170px"}
								}}
							>
								<Typography
									variant="h6"
									color="customColor.label"
								>
									{text}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default HelpAndTips;

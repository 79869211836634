import { useEffect, useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import clsx from "clsx";
import {
	GALLERY_VIEW_ASPECT_RATIO,
	GALLERY_VIEW_MARGIN,
} from "../../constants";
import { Grid, IconButton, PaginationItem } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { Pagination } from "@mui/lab";
import DraggableResizableContainer from "../DraggableResizableContainer";
import Participant from "../Participant/Participant";
import useGalleryViewLayout from "../../hooks/useGalleryViewLayout/useGalleryViewLayout";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useParticipantsContext from "../../hooks/useParticipantsContext/useParticipantsContext";
import { usePagination } from "./usePagination/usePagination";
import useDominantSpeaker from "../../hooks/useDominantSpeaker/useDominantSpeaker";
import { useAppState } from "../../state";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import usePeopleContext from "../../hooks/usePeopleContext/usePeopleContext";
import DimensionJsonObject from './ParticipantsDimensionsObject.json'

const CONTAINER_GUTTER = "20px";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			top : "62px",
			background: "#000000",
			position: "relative",
			gridArea: "1 / 1 / 2 / 3",
		},
		participantContainer: {
			position: "absolute",
			display: "flex",
			top: "10px",
			right: CONTAINER_GUTTER,
			bottom: "60px",
			left: CONTAINER_GUTTER,
			margin: "0 auto",
			alignContent: "center",
			flexWrap: "wrap",
			justifyContent: "center",
		},
		buttonContainer: {
			position: "absolute",
			top: 0,
			bottom: 0,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		pagination: {
				"& .MuiPaginationItem-root": {
					color: "white",
					background: "grey",
					borderRadius: "20px",
				},
				"& :hover": {
					"& .Mui-selected": {
						color: "black",
						background: "white",
					},
				},
				"& .MuiPaginationItem-previousNext": {
					background: "black",
				},
		},
		paginationButton: {
			color: "black",
			background: "rgba(255, 255, 255, 0.8)",
			width: "40px",
			height: "40px",
			"&:hover": {
				background: "rgba(255, 255, 255)",
			},
		},
		paginationContainer: {
			position: "absolute",
			top: `calc(100% - 50px)`,
			right: 0,
			bottom: 0,
			left: 0,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);

export function GalleryView() {
	const classes = useStyles();
	const { maxGalleryViewParticipants } = useAppState();
	const { isChatWindowOpen } = useChatContext();
	const { isPeopleWindowOpen } = usePeopleContext();
	const { room } = useVideoContext();
	const { galleryViewParticipants } = useParticipantsContext();
	const dominantSpeaker = useDominantSpeaker(true);

	const { paginatedParticipants, setCurrentPage, currentPage, totalPages } =
	usePagination([...galleryViewParticipants]);

	const filteredByKey = Object.fromEntries(
        Object.entries(DimensionJsonObject).filter(([key, value]) => (key as unknown as number) == paginatedParticipants.length))

    var array = filteredByKey[paginatedParticipants.length] ? filteredByKey[paginatedParticipants.length] : {};

	const getCols = (key:number) => {
		var a = Object.values(array);
		let b:any = a[key];
		// return a[newKey].getCols(indexKey);	// eslint-disable-line no-use-before-define
		let columnVal = b.column;
		return columnVal;
	}
	const galleryViewLayoutParticipantCount =
		currentPage === 1
			? paginatedParticipants.length
			: maxGalleryViewParticipants;
	const { participantVideoWidth, containerRef } = useGalleryViewLayout(
		galleryViewLayoutParticipantCount - 1
	);
	const [participantWidthState, setParticipantWidthState] = useState("");
	const [participantHeightState, setParticipantHeightState] = useState("");

	// useEffect(() => {
	// 	// calculateDimension()
	// }, [isChatWindowOpen, isPeopleWindowOpen, paginatedParticipants]);

	// Common function to calculate the dimension for the each participant card
	const calculateDimension = () => {
		let participantWidth;
		let participantHeight;
		// if (galleryViewParticipants.length <= 2) {
		// 	participantWidth = `${
		// 		document.getElementById("participantContainer")?.clientWidth! /
		// 			galleryViewParticipants.length -
		// 		galleryViewParticipants.length * 8
		// 	}px`;
		// 	participantHeight = `${document.getElementById("participantContainer")
		// 		?.clientHeight!}px`;
		// }
		// if (galleryViewParticipants.length === 3) {
		// 	// participantWidth = `${document.getElementById("participantContainer")?.clientWidth! /
		// 	// 	galleryViewParticipants.length -
		// 	// 	galleryViewParticipants.length * 4
		// 	// 	}px`;
		// 	participantWidth = '32%'
		// 	participantHeight = `${document.getElementById("participantContainer")
		// 		?.clientHeight!}px`;
		// }
		// if (galleryViewParticipants.length === 4) {
		// 	participantWidth = `${document.getElementById("participantContainer")?.clientWidth! /
		// 		galleryViewParticipants.length * 2 - galleryViewParticipants.length * 2
		// 		}px`;
		// 	participantHeight = `${document.getElementById("participantContainer")
		// 		?.clientHeight! / 2}px`;
		// }
		// if (galleryViewParticipants.length > 4) {
		// 	if (paginatedParticipants.length === 4) {
		// 		participantWidth = `${document.getElementById("participantContainer")?.clientWidth! /
		// 		paginatedParticipants.length * 2 - paginatedParticipants.length * 2
		// 			}px`;
		// 		participantHeight = `${document.getElementById("participantContainer")
		// 			?.clientHeight! / 2}px`;
		// 	}
		// }

		participantWidth = `${(100 / paginatedParticipants.length) - paginatedParticipants.length * 1}%`;
		// participantHeight = `${document.getElementById("participantContainer")?.clientHeight! / 2}px`;
		participantHeight = `${(100 / paginatedParticipants.length) - paginatedParticipants.length * 2}%`;
		if(galleryViewParticipants.length <= 3){
			participantHeight = `${(100 / paginatedParticipants.length) - paginatedParticipants.length * 2}%`;
			// participantHeight = `${100 - paginatedParticipants.length * 2}%`;
			participantHeight = `100%`;
		}
		if(galleryViewParticipants.length === 4){
			participantWidth = `${(100 / 2) - paginatedParticipants.length * 0.5}%`;
			participantHeight = `${(100 / 2) - paginatedParticipants.length * 0}%`;
			// participantHeight = `100%`;
		}

		if (galleryViewParticipants.length > 4) {
			if (paginatedParticipants.length === 4) {
				// participantWidth = `${document.getElementById("participantContainer")?.clientWidth! /
				// paginatedParticipants.length * 2 - paginatedParticipants.length * 2
				// 	}px`;
				// participantHeight = `${document.getElementById("participantContainer")
				// 	?.clientHeight! / 2}px`;
				participantWidth = `${(100 / 2) - paginatedParticipants.length * 0.5}%`;
				participantHeight = `${(100 / 2) - paginatedParticipants.length * 0}%`;
			}
		}

		if (currentPage !== 1) {
			if (paginatedParticipants.length <= 2) {
				participantWidth = `${
					document.getElementById("participantContainer")?.clientWidth! /
						paginatedParticipants.length -
					paginatedParticipants.length * 8
				}px`;
				participantHeight = `${document.getElementById("participantContainer")
					?.clientHeight!}px`;
			}
			if (paginatedParticipants.length === 3) {
				participantWidth = `${
					document.getElementById("participantContainer")?.clientWidth! /
						paginatedParticipants.length -
					paginatedParticipants.length * 2
				}px`;
				participantHeight = `${document.getElementById("participantContainer")
					?.clientHeight!}px`;
			}
			if (paginatedParticipants.length === 4) {
				participantWidth = `${document.getElementById("participantContainer")?.clientWidth! /
					paginatedParticipants.length * 2 - paginatedParticipants.length * 2
					}px`;
				participantHeight = `${document.getElementById("participantContainer")
					?.clientHeight! / 2}px`;
			}
		}
		setParticipantWidthState(participantWidth);
		setParticipantHeightState(participantHeight);
	}

	return (
		<div className={classes.container} id="videoContainer">
			<div className={classes.paginationContainer}>
				{totalPages > 1 && (
					<Pagination
						className={classes.pagination}
						page={currentPage}
						count={totalPages}
						onChange={(_, value) => setCurrentPage(value)}
						shape="rounded"
						color="primary"
						size="small"
						variant="outlined"
						renderItem={(item) => (
							<PaginationItem
								slots={{ previous: ArrowBack, next: ArrowForward }}
								{...item}
							/>
						)}
					/>
				)}
			</div>
			<div
				className={classes.participantContainer}
				ref={containerRef}
				id="participantContainer"
			>
				<DraggableResizableContainer>
					<div
						key={room!.localParticipant.sid}
						style={{
							width: "100%",
							height: "100%",
						}}
					>
						<Participant
							participant={room!.localParticipant}
							isLocalParticipant={room!.localParticipant}
							isDominantSpeaker={room!.localParticipant === dominantSpeaker}
						/>
					</div>
				</DraggableResizableContainer>
				<Grid container sx={{
						height: "100%",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between"
					}}
					spacing={1}
					>
					{paginatedParticipants.map((participant, indexKey) =>
						participant !== room!.localParticipant ? (
							<>
								<Grid item className="col assignedGRID" key={'assignedGRID---'+getCols(indexKey)} xs={getCols(indexKey)} >	
								<div
									key={participant.sid}
									style={{
										width: participantWidthState,
										height: "100%",
										margin: GALLERY_VIEW_MARGIN,
									}}
								>
									{/* <Grid item className="col" key={indexKey} xs={6}  >	 */}
										<Participant
											participant={participant}
											isLocalParticipant={participant === room!.localParticipant}
											isDominantSpeaker={participant === dominantSpeaker}
										/>
								</div>
								</Grid>
							</>
						) : (
							<></>
						)
					)}
				</Grid>
			</div>
		</div>
	);
}

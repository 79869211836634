import { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";

import StunTest from "../services/StunTest";
import i18n from "../l10n/strings.json";
import adapter from 'webrtc-adapter';

const CompatibilityTestScreen = (props: any) => {
	const [stunProgress, setStunProgress] = useState("null");
	const [stunResult, setStunResult] = useState(false);

	useEffect(() => {
		runSTUNTest();
	}, []);

	const runSTUNTest = async () => {
		setStunResult(false);
		StunTest.start();
		StunTest.on("icegatheringstatechange", (iceGatheringState) => {
			setStunProgress(iceGatheringState);
		});
		StunTest.on("icetestresult", (iceResult) => {
			setStunResult(iceResult);
		});
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="90vh"
			overflow="hidden"
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="center"
				borderBottom="1px solid"
				borderColor="#888888"
				width="100%"
				padding="16px"
			>
				<Typography
					variant="h2"
					fontWeight={500}
					color="#000000"
					textAlign="center"
				>
					{i18n.settings.compatibility.dialogTitle}
				</Typography>
			</Box>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				width="100%"
				padding="15px 16px"
				overflow="hidden"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					width="100%"
					padding="0 16px"
					overflow="hidden"
				>
					<iframe
						title="speed-ts"
						style={{
							border: "none",
							width: "100%",
							height: "100%",
							minHeight: "500px",
							pointerEvents: "none",
						}}
						src={process.env.REACT_APP_SPEED_TEST_URL}
					/>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="left"
					justifyContent="center"
					width="100%"
					padding="0 25%"
					height="200px"
				>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="left"
						justifyContent="center"
						width="100%"
						sx={{ marginLeft: "22%", marginBottom: "10px" }}
					>
						<Typography>
							<b>{i18n.settings.compatibility.doubleNatTest}</b>
							{stunProgress === "complete" ? (
								stunResult ? (
									<b style={{ color: "#259A12" }}>PASS</b>
								) : (
									<b style={{ color: "#F00" }}>FAILED</b>
								)
							) : (
								"PROCESSING"
							)}
						</Typography>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						alignItems="left"
						justifyContent="center"
						width="100%"
						sx={{ marginLeft: "22%" }}
					>

<div style={{visibility:'hidden'}}>
Your browser is <strong>{adapter.browserDetails.browser} {adapter.browserDetails.version ? adapter.browserDetails.version : 'unkown version'}						
				</strong>		</div>
						<Typography component="span">
							<b>Overall Performance: </b>
							Compatibility test results as PASS when Internet upload speed is
							above 10Mbps and <br />
							Double NAT Test is PASS.
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};


export default CompatibilityTestScreen;

import { createContext, useState, useContext, useEffect } from "react";

type MusicianFilterContextType = {
    musicianFilter: {
        isGlobalSearch: boolean,
        genres: [],
        cities: "",
        firstName: "",
        lastName: "",
        nickName: "",
        instrument: [],
        isOpenForCollaboration: boolean;
        acceptGigs: boolean;
        distanceFromCity:any;
        latitude: 0,
        longitude: 0,
    },
	setMusicianFilter: any,
    isFilterActive: Boolean,
    resetMusicianFilter: any,
    displayDistance:any,
    setDisplayDistance:any
    isDistanceActive: Boolean,
    setIsDistanceActive:any,
};

export const MusicFilterContext = createContext<MusicianFilterContextType>(null!);

export const MusicianFilterProvider = ({ children }: any) => {
    const [displayDistance, setDisplayDistance] = useState(25);
    const [isDistanceActive, setIsDistanceActive] = useState(false);

	const [musicianFilter, setMusicianFilter] = useState<any>({
        isGlobalSearch: false,
        genres: [],
        cities: "",
        firstName: "",
        lastName: "",
        nickName: "",
        instrument: [],
        isOpenForCollaboration: false,
        acceptGigs: false,
        distanceFromCity: 50,
        latitude: 0,
        longitude: 0,

    });
    const [isFilterActive, setIsFilterActive] = useState(false);

    const resetMusicianFilter:any = () => {
        setMusicianFilter({
            isGlobalSearch: false,
            genres: [],
            cities: "",
            firstName: "",
            lastName: "",
            nickName: "",
            instrument: [],
            isOpenForCollaboration: false,
            acceptGigs: false,
            distanceFromCity: 50,
            latitude: 0,
            longitude: 0,

        });
    }
    useEffect(() => {
        if(
            musicianFilter.genres.length > 0 || 
            musicianFilter.cities.length > 0 || 
            musicianFilter.instrument.length > 0 || 
            musicianFilter.firstName.length > 0 || 
            musicianFilter.lastName.length > 0 || 
            musicianFilter.nickName.length > 0 || 
            musicianFilter.isOpenForCollaboration || 
            musicianFilter.acceptGigs || 
            musicianFilter.distanceFromCity !== 50){
            setIsFilterActive(true);
        }else{
            setIsFilterActive(false);
        }
    }, [musicianFilter])

	return (
		<MusicFilterContext.Provider
			value={{
				musicianFilter, 
				setMusicianFilter,
                isFilterActive,
                resetMusicianFilter,
                displayDistance,
                setDisplayDistance,
                isDistanceActive,
                setIsDistanceActive,
			}}
		>
			{children}
		</MusicFilterContext.Provider>
	);
};

// Custom hook to consume the context
export function useMusiciansFilter() {
    return useContext(MusicFilterContext);
}
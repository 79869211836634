import { useState, useEffect } from "react";
import { Participant } from "twilio-video";
import { useAppState } from "../../../state";

export function usePagination(participants: Participant[]) {
    const [currentPage, setCurrentPage] = useState(1); // Pages are 1 indexed
    const { maxGalleryViewParticipants } = useAppState();
    const userSession = localStorage.getItem("userSession") ?? "";
    const userSessions = JSON.parse(userSession);

    let totalPages = Math.ceil(
        participants.length / maxGalleryViewParticipants
    );
    const isBeyondLastPage = currentPage > totalPages;

    useEffect(() => {
        if (isBeyondLastPage) {
            setCurrentPage(totalPages);
        }
    }, [isBeyondLastPage, totalPages]);

    const checkDeviceId = (identity: string): string | undefined => {
        return userSessions?.attendees.find(
            (attendee: any) => attendee.email === identity
        ).deviceId;
    };

    let paginatedParticipants = participants.slice(
        (currentPage - 1) * maxGalleryViewParticipants,
        currentPage * maxGalleryViewParticipants
    ).map(participant => ({
        ...participant,
        deviceId: checkDeviceId(participant.identity),
    }));

    return { paginatedParticipants, setCurrentPage, currentPage, totalPages };
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import useUserDetails from "../hooks/useUserDetails/useUserDetails";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";


const RemoveDeviceSetting = () => {

    const user = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("sessionId");
    const {userId, userProfileId, email, userObject: userDetails, userName,userStatus,userObject} = location?.state || {}

    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

    const {adminSetUserId} = useAdminContext();
    const deleteDeviceHandler = () => {
        const authorities = user.authorities.map(
            (authority: { name: string }) => authority.name
        );
        const fetchDetailsOf = userId && (isAdminUser()) ? userId : user.id;
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-devices/remove-device/${fetchDetailsOf}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                if(res.status === 502){
                    navigate('/defaultPage')
                } else if (res.status >= 400) {
                } else {
                    if (isAdminUser()) {
                        navigate("/home/admin/settings/deviceSetup/addadmindevice", {
                            state: { userId, userProfileId, email, type: "edit", userDetails, userName, userStatus, userObject },
                        });
                    }
                    else {
                        navigate(`/home/settings/deviceSetup/add`);
                    }
                }
            })
            .then((res) => { });
    };

    const isAdminUser = () => {
        if (user?.authorities[0].name === 'ROLE_USER' || user?.authorities[0].name === 'ROLE_COLLAB_USER') {
            return false;
        } else {
            return true;
        }
    };

    const isEnable = () => {
		if (user?.authorities[0].name === "ROLE_ADMIN"  || user?.authorities[0].name === 'ROLE_SUPER_ADMIN'
        || user?.authorities[0].name === 'ROLE_USER'|| user?.authorities[0].name === 'ROLE_COLLAB_USER'
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <Button
                //className="cancel-button button-field-custom-font-size"
                size="large"
                variant="outlined"
                disabled={!isEnable()}
                onClick={() => setOpenConfirmationDialog(true)}
                sx={{
                    // right: 0,
                    float: "left",
                    // mt: 2,
                    fontSize: '0.9rem',
                    lineHeight: '20px',
                    border: '2px solid #0273E6',
                    color: '#0273E6',
                    transition: 'border 0.1s',
                    '&:hover': {
                        border: '2px solid #0273E6',
                        backgroundColor: '#E9F1FF'
                    },
                }}
            >
                {i18n.settings.deviceSetup.remove}
            </Button>
            <Dialog
                open={openConfirmationDialog}
                sx={{
                    border: "1px solid #888888",
                    boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                    borderRadius: "8px",
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            margin: -1
                        }}
                    >
                        <IconButton
                            aria-label="close dialogbox"
                            onClick={() => setOpenConfirmationDialog(false)}
                            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                event.preventDefault()
                            }
                            edge="end"
                            sx={{ marginLeft: "90%" }}
                        >
                            <img src={CloseIcon} alt="close" />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            margin: -3
                        }}
                    >
                        <h3>{i18n.settings.deviceSetup.confirmation.title}</h3>
                    </Box>
                </DialogTitle>
                <DialogContent
                    sx={{
                        marginLeft: "48px",
                        marginRight: "48px",
                        padding: "20px 0",
                    }}
                >
                    <Typography sx={{ fontSize: "16px" }}>
                        {i18n.settings.deviceSetup.confirmation.desc}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ mt: -2 }}>
                    <Button
                        sx={{ color: "#0273E6", border: '2px solid #0273E6',
                            '&:hover': {
                                border: '2px solid #0273E6',
                                backgroundColor: '#E9F1FF'
                          },  }}
                        //className="cancel-button"
                        size="large"
                        variant="outlined"
                        onClick={() => setOpenConfirmationDialog(false)}
                    >
                        {i18n.settings.deviceSetup.confirmation.cancelBtn}
                    </Button>
                    <Button
                       // className="submit-button"
                        disabled={user.userAccountStatus === "SUSPEND"}
                        size="large"
                        variant="contained"
                        type="submit"
                        onClick={deleteDeviceHandler}
                    >
                        {i18n.settings.deviceSetup.confirmation.removeBtn}

                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default RemoveDeviceSetting;

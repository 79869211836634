import { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardActions,
	CardContent,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useUser } from "../context/UserContext";
import CloseAccount from "../dialogBox/CloseAccount";
import EditNickName from "../dialogBox/EditNickName";
import EditDateOfBirth from "../dialogBox/EditDateOfBirth";
import EditLocation from "../dialogBox/EditLocation";
import ChangePassword from "../dialogBox/ChangePassword";
import EditEmail from "../dialogBox/EditEmail";
import EditName from "../dialogBox/EditName";
// services
import User from "../services/user";
import EditIcon from "../images/edit.svg";
import EditDisabledIcon from "../images/editDisableIcon.svg";
import i18n from "../l10n/strings.json";
import { useAlertStatus } from "../context/AlertStatusContext";
import { useLocation } from "react-router-dom";
import { useManageFilter } from "../components/AdminDashboard/ManageUserDashBoard/ManagementProvider/useManageFilter";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";
import { format, parse } from "date-fns";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";

type MessageList = {
	[key: string]: string;
};

const AccountListItemText = styled(ListItemText)({
	"& .MuiListItemText-primary": {
		fontStyle: "normal",
		fontSize: "1rem",
		fontWeight: 400,
		color: "#7A7985",
	},
	"& .MuiListItemText-secondary": {
		fontFamily: "Lexend",
		fontStyle: "normal",
		fontSize: "1.125rem",
		fontWeight: 500,
		color: "#000000",
	},
});

let messageList: MessageList = {
	nickNameSuccess: "Nickname updated successfully",
	nameSuccess: "Name updated successfully",
	birthDateSuccess: "Date of Birth updated successfully",
	locationSuccess: "Location updated successfully",
	passwordSuccess: "Password updated successfully",
	emailSuccess: "Email updated successfully",
};

function AccountSettingsPage() {
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();

	const [nickNameOpen, setNickNameOpen] = useState(false);
	const [nameOpen, setNameOpen] = useState(false);
	const [birthDateOpen, setBirthDateOpen] = useState(false);
	const [locationOpen, setLocationOpen] = useState(false);
	const [changePasswordOpen, setChangePasswordOpen] = useState(false);
	const [emailOpen, setEmailOpen] = useState(false);
	const [userData, setUserData] = useState<any>({});
	const [isLoading, setIsLoading] = useState(false);
	const [showCloseAccountDialog, setShowCloseAccountDialog] = useState(false);

	const location = useLocation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { userIdByAdmin, userProfileIdByAdmin, setIsDisabledOptions } =
		useManageFilter();

		const { setAdminSetUserId } = useAdminContext();

		useEffect(() => {
			setAdminSetUserId(location?.state?.user);
		}, [location?.state]);
		
	const fetchUserInfo = () => {
		let searchId;
		const userRole = localStorage.getItem("user");
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		let loggedInRole;

		if (userRole) {
			const userRoleObj = JSON.parse(userRole);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			loggedInRole = userRoleObj.authorities[0].name;
		}
		// if (loggedInRole === "ROLE_ADMIN") {
		searchId = location.state?.userId? location.state?.userId : user.id;
		// searchId = userIdByAdmin ? userIdByAdmin : user.id;
		// } else {
		// 	searchId = user.id;
		// }

		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/id`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id:searchId
			}),
		})
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				setIsLoading(false);
				if (result !== null) {
					setUserData(result);
				}
			});
	};

	const handleDialogClose = (success: boolean, editMode: string) => {
		if (success) {
			fetchUserInfo();
			updateAlertStatus(
				true,
				true,
				messageList[editMode as keyof typeof messageList]
			);
			User.fetchUserInfo(undefined);
		} else {
			updateAlertStatus(false, false, "");
		}
		setNameOpen(false);
		setNickNameOpen(false);
		setLocationOpen(false);
		setBirthDateOpen(false);
		setChangePasswordOpen(false);
		setEmailOpen(false);
	};

	useEffect(() => {
		fetchUserInfo();
		setIsLoading(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (location.state?.userId && user?.authorities[0]?.name === "ROLE_ADMIN") {
			setIsDisabledOptions(true);
		}
		return () => {
			setIsDisabledOptions(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const getDob = () => {
	// 	if (userData?.dateOfBirth) {
	// 	  try {
	// 		const parsedDate = parse(
	// 		  userData.dateOfBirth,
	// 		  "MM/dd/yyyy",
	// 		  new Date()
	// 		);

	// 		// Check if parsedDate is a valid date
	// 		if (isNaN(parsedDate.getTime())) {
	// 		  throw new Error("Invalid date string");
	// 		}

	// 		const formattedDate = format(parsedDate, "MM/dd/yyyy");
	// 		return formattedDate;
	// 	  } catch (error) {
	// 		console.error("Error parsing date:", error);
	// 		return ""; // Handle the error gracefully
	// 	  }
	// 	} else {
	// 	  return "";
	// 	}
	//   };
	  const getDob = () => {
		if (userData?.dateOfBirth) {
		  try {
			let parsedDate;
			if (userData.dateOfBirth.match(/^\d{1,2}\/\d{1,2}\/\d{4}$/)) {
				// Check if dateOfBirth is in mm/dd/yyyy format
				parsedDate = parse(
				  userData.dateOfBirth,
				  "MM/dd/yyyy",
				  new Date()
				);
			} else if (userData.dateOfBirth.match(/^\d{4}-\d{2}-\d{2}$/)) {
				// Check if dateOfBirth is in yyyy-mm-dd format
				parsedDate = parse(
				  userData.dateOfBirth,
				  "yyyy-MM-dd",
				  new Date()
				);
			} else {
			  throw new Error("Invalid date format");
			}
	  
			// Check if parsedDate is a valid date
			if (isNaN(parsedDate.getTime())) {
			  throw new Error("Invalid date string");
			}
	  
			// Format the parsed date to the desired format (MM/dd/yyyy)
			const formattedDate = format(parsedDate, "MM/dd/yyyy");
			return formattedDate;
		  } catch (error) {
			console.error("Error parsing date:", error);
			return ""; // Handle the error gracefully
		  }
		} else {
		  return "";
		}
	  };


	if (isLoading) {
		return <div />;
	}

	return (
		<>
			<Grid container>
				<Grid item={true} xs={9} className="centerPanelWidth">
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
						}}
					>
						<CardContent
							sx={{
								padding: "24px",
							}}
						>
							<Typography
								className="card-title-custom-size"
								sx={{
									display: "flex",
									alignItems: "center",

									fontWeight: "500",
								}}
								color="#000000"
							>
								{" "}
								Account
							</Typography>
							<List>
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Nickname (Social handle)"
										secondary={`${
											userData?.nickname && userData?.nickname?.length > 50
												? userData?.nickname?.substring(0, 50) + "..."
												: userData?.nickname
										}`}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "0.75rem",
												lineHeight: "100%",
												textAlign: "right",
												color: "#C5C5C5",
												marginBottom: "10px",
											}}
										>
											{i18n.settings.accountSetup.nickNameHelpingText}
										</Typography>
										{user.userAccountStatus === "SUSPEND" ? (
											<img
												src={EditDisabledIcon}
												style={{ marginLeft: "70%" }}
												alt="Edit Icon"
												width="24px"
												height="24px"
											/>
										) : (
											<img
												src={EditIcon}
												style={{
													cursor: "pointer",
													marginLeft: "70%",
												}}
												alt="Edit Icon"
												width="25px"
												onClick={() => setNickNameOpen(true)}
											/>
										)}
									</ListItemIcon>
								</ListItem>
								<EditNickName
									userIdByAdmin={location.state?.userId}
									nickNameOpen={nickNameOpen}
									nickName={userData?.nickname}
									handleClose={handleDialogClose}
								/>
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									{/* <IconButton color="danger" onClick={() => (onRemoveRoom(itemId))}><Svg src="actions/removeCircle.svg" /></IconButton> */}

									<AccountListItemText
										primary="Name"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary={`${userData?.user?.firstName}  ${userData?.user?.lastName}`}
									/>
									<ListItemIcon>
										{user.userAccountStatus === "SUSPEND" ? (
											<img
												src={EditDisabledIcon}
												style={{ marginLeft: "60%" }}
												alt="Edit Icon"
												width="24px"
												height="24px"
											/>
										) : (
											<img
												src={EditIcon}
												style={{
													cursor: "pointer",
													marginLeft: "60%",
												}}
												alt="Edit Icon"
												width="25px"
												onClick={() => setNameOpen(true)}
											/>
										)}
									</ListItemIcon>
								</ListItem>
								<EditName
									userIdByAdmin={location.state?.userId}
									nameOpen={nameOpen}
									firstName={userData?.user?.firstName}
									lastName={userData?.user?.lastName}
									handleClose={handleDialogClose}
								/>
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									{/* <IconButton color="danger" onClick={() => (onRemoveRoom(itemId))}><Svg src="actions/removeCircle.svg" /></IconButton> */}

									<AccountListItemText
										primary="Date Of Birth"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary={userData?.dateOfBirth ? getDob() : " "}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "0.75rem",
												lineHeight: "100%",
												textAlign: "right",
												color: "#C5C5C5",
												marginBottom: "10px",
											}}
										>
											{i18n.settings.accountSetup.dobHelpingtext}
										</Typography>
										{user.userAccountStatus === "SUSPEND" ? (
											<img
												src={EditDisabledIcon}
												style={{ marginLeft: "80%" }}
												alt="Edit Icon"
												width="24px"
												height="24px"
											/>
										) : (
											<img
												src={EditIcon}
												style={{
													cursor: "pointer",
													marginLeft: "80%",
												}}
												alt="Edit Icon"
												width="25px"
												onClick={() => setBirthDateOpen(true)}
											/>
										)}
									</ListItemIcon>
								</ListItem>
								<EditDateOfBirth
									userIdByAdmin={location.state?.userId}
									birthDateOpen={birthDateOpen}
									dateOfBirth={userData?.dateOfBirth}
									handleClose={handleDialogClose}
								/>
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Home City"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary={userData?.location}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "0.75rem",
												lineHeight: "100%",
												textAlign: "right",
												color: "#C5C5C5",
												marginBottom: "10px",
											}}
										>
											{i18n.settings.accountSetup.locationHelpingText}
										</Typography>
										{user.userAccountStatus === "SUSPEND" ? (
											<img
												src={EditDisabledIcon}
												style={{ marginLeft: "80%" }}
												alt="Edit Icon"
												width="24px"
												height="24px"
											/>
										) : (
											<img
												src={EditIcon}
												style={{
													cursor: "pointer",
													marginLeft: "80%",
												}}
												alt="Edit Icon"
												width="25px"
												onClick={() => setLocationOpen(true)}
											/>
										)}
									</ListItemIcon>
								</ListItem>

								<EditLocation
									locationOpen={locationOpen}
									location={userData?.location}
									handleClose={handleDialogClose}
									userIdByAdmin={location.state?.userId}
								/>
							</List>
						</CardContent>
					</Card>

					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
							// height: "300px",
							marginTop: 3,
						}}
					>
						<CardContent
							sx={{
								padding: "24px",
							}}
						>
							<Typography
								className="card-title-custom-size"
								sx={{
									// fontSize: 24,
									display: "flex",
									alignItems: "center",

									fontWeight: "500",
								}}
								color="#000000"
							>
								Login
							</Typography>
							<List>
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Email"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary={userData?.user?.email}
									/>
									<ListItemIcon
										sx={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<Typography
											sx={{
												fontStyle: "normal",
												fontWeight: "500",
												fontSize: "0.75rem",
												lineHeight: "100%",
												textAlign: "right",
												color: "#C5C5C5",
												marginBottom: "10px",
											}}
										>
											{i18n.settings.accountSetup.emailHelpingText}
										</Typography>
										{user.userAccountStatus === "SUSPEND" ? (
											<img
												src={EditDisabledIcon}
												style={{ marginLeft: "80%" }}
												alt="Edit Icon"
												width="24px"
												height="24px"
											/>
										) : (
											<img
												src={EditIcon}
												style={{
													cursor: "pointer",
													marginLeft: "80%",
												}}
												alt="Edit Icon"
												width="25px"
												onClick={() => setEmailOpen(true)}
											/>
										)}
									</ListItemIcon>
								</ListItem>
								<EditEmail
									emailOpen={emailOpen}
									// email={userData?.user?.email}
									email={
										location.state?.email
											? location.state?.email
											: userData?.user?.email
									}
									handleClose={handleDialogClose}
								/>
								<Divider variant="fullWidth" component="li" />
								<ListItem sx={{ padding: "8px 0" }}>
									<AccountListItemText
										primary="Password"
										primaryTypographyProps={{
											sx: {
												color: "#7A7985",
											},
										}}
										secondary="xxxx"
									/>
									<ListItemIcon>
										{user.userAccountStatus === "SUSPEND" ? (
											<img
												src={EditDisabledIcon}
												style={{ marginLeft: "60%" }}
												alt="Edit Icon"
												width="24px"
												height="24px"
											/>
										) : (
											<img
												src={EditIcon}
												style={{
													cursor: "pointer",
													marginLeft: "60%",
												}}
												alt="Edit Icon"
												width="25px"
												onClick={() => setChangePasswordOpen(true)}
											/>
										)}
									</ListItemIcon>
								</ListItem>
								<ChangePassword
									userIdByAdmin={location.state?.userId}
									changePasswordOpen={changePasswordOpen}
									handleClose={handleDialogClose}
								/>
							</List>
							<Typography
								sx={{
									fontSize: 18,
									lineHeight: "25px",
									// padding: "50px 32px 0 32px",
								}}
							></Typography>
						</CardContent>
					</Card>
					{
						location?.state?.type !== "edit" && (
							<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "border-radius: 12px;",
							marginTop: 3,
							marginBottom: 3,
						}}
					>
						<CardContent
							sx={{
								padding: "24px",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							}}
						>
							<Typography
								className="card-title-custom-size"
								sx={{
									// fontSize: "24px",
									display: "flex",
									alignItems: "center",
									marginBottom: "10px",

									fontWeight: "500",
								}}
								color="#000000"
							>
								Close Account
							</Typography>
							<Typography
								sx={{
									fontSize: 16,
									lineHeight: "25px",
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",

									fontWeight: "500",
								}}
							>
								Once you close your account, your account will remain active for 30 days, then will be fully deleted.
								<CardActions
									sx={{
										justifyContent: "flex-end",
									}}
								>
									<Button
										sx={{
											width: "175px",
											height: "35px",
											border: "1.5px solid #FD4B24",
											color: "#FD4B24",
										}}
										className="cancel-button"
										size="large"
										variant="outlined"
										onClick={() => setShowCloseAccountDialog(true)}
									>
										Close Account
									</Button>
									<CloseAccount
										email={
											location.state?.email ? location.state?.email : user.email
										}
										userIdByAdmin={location.state?.userId}
										open={showCloseAccountDialog}
										handleClose={() => setShowCloseAccountDialog(false)}
									/>
								</CardActions>
							</Typography>
						</CardContent>
					</Card>
						)
					}
				</Grid>
				{/* <Grid item={true} xs={3} className="hideInTabPro rightPanelWidth"> */}
					{/* <HelpAndTips
						title={i18n.settings.accountSetup.instructions.helpAndTips.title}
						text={i18n.settings.accountSetup.instructions.helpAndTips.text}
						sx={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}
					/> */}
					{/* <MyActivitiesSidePanel /> */}
				{/* </Grid> */}
			</Grid>
		</>
	);
}

export default AccountSettingsPage;

class callService {
	async fetchApi(
		method: string,
		address: string,
		headerParams: any | null,
		bodyParams: any | null,
		isBodyParamUnique: boolean = false,
		isFileUpload: boolean = false
	): Promise<Response> {
		const token = localStorage.getItem("sessionId");
		const requestOptions: RequestInit = {
			method: method,
			headers: {
				Authorization: `Bearer ${token}`,
				...(!isFileUpload && { "Content-Type": "application/json" }),
			},
		};

		if (bodyParams instanceof FormData) {
			requestOptions.body = bodyParams;
		} else {
			if (isBodyParamUnique) {
				requestOptions.body = bodyParams;
			} else {
				requestOptions.body = JSON.stringify(bodyParams);
			}
		}

		if (headerParams !== null) {
			const attach = getHeaderParams(headerParams);
			const Url1 = `${process.env.REACT_APP_BASE_URL}/api/${address}/${attach}`;
			try {
				const response = await fetch(Url1, requestOptions);
				if (!response.ok) {
					if (response.status === 404) {
						throw new Error("Resource not found");
					} else if (response.status === 500) {
						throw new Error("Server error");
					} else {
						throw new Error(`HTTP error ${response.status}`);
					}
				}
				return response;
			} catch (error: any) {
				throw new Error(`Error fetching data: ${error.message}`);
			}
		} else {
			const Url2 = `${process.env.REACT_APP_BASE_URL}/api/${address}`;
			try {
				const response = await fetch(Url2, requestOptions);
				if (!response.ok) {
					if (response.status === 404) {
						throw new Error("Resource not found");
					} else if (response.status === 500) {
						throw new Error("Server error");
					} else {
						throw new Error(`HTTP error ${response.status}`);
					}
				}
				return response;
			} catch (error: any) {
				throw new Error(`Error fetching data: ${error.message}`);
			}
		}

		async function getHeaderParams(params: any): Promise<string> {
			const queryString = Object.keys(params)
				.map(
					(key) =>
						`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
				)
				.join("&");
			return queryString;
		}
	}
}

export const Api = {
	callService: new callService(),
};

import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	Typography,
	Box,
	Grid,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import { useUser } from "../context/UserContext";
import ErrorAlert from "../components/Error/ErrorAlert";
import ErrorAlertDisplay from "../components/Error/ErrorAlertDisplay";
import { Link, useNavigate } from "react-router-dom";

const EditBriefBio = (props: any) => {
	const [bio, setBio] = useState(props.bio);
	const [dirtyBriefBio, setDirtyBriefBio] = useState(false);
	const user = useUser();
	const token = localStorage.getItem("sessionId");

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [originalBio, setOriginalBio] = useState(props.bio);
    const navigate = useNavigate();
	useEffect(() => {
		setOriginalBio(bio);
	}, [props.bio]);

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleClose = () => {
		setBio(originalBio);
		props.handleClose(false);
	};

	const handleUpdateBio = (event: any) => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-bio`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				user: {
					id: user.id,
				},
				profileBio: bio,
			}),
		})
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					props.setBio(bio);
					props.handleClose(false);
					return res.json();
				}
			})
			.then((result) => {
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};
	return (
		<>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				open={props.bioOpen}
			>
				<ErrorAlertDisplay
					alertMsg={alertMsg}
					alertStatus={alertStatus}
					showAlert={showAlert}
					handleAlertClose={handleAlertClose}
				/>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Typography variant="h6">Edit Bio</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={4}>
							<FormHelperText>{i18n.socialProfile.bio}</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<TextField
								id="bio"
								className="social-textField"
								value={bio}
								onChange={(e: any) => setBio(e.target.value)}
								multiline
								rows={4}
								variant="outlined"
								onFocus={() => setDirtyBriefBio(true)}
								error={dirtyBriefBio && bio?.length > 3000}
								required
							/>
							{dirtyBriefBio && (
								<FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
									{bio?.length > 3000 ? "Maximum 3000 characters allowed" : ""}
								</FormHelperText>
							)}
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							flexWrap: "wrap",
							pt: 1,
							flexDirection: "row-reverse",
						}}
					>
						<Button
							sx={{ borderRadius: "17px" }}
							size="small"
							variant="outlined"
							onClick={handleClose}
						>
							Cancel
						</Button>
						<Button
							size="small"
							variant="contained"
							sx={{ borderRadius: "17px" }}
							onClick={handleUpdateBio}
							disabled={bio && bio.length > 3000} 
						>
							Save
						</Button>
					</Box>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default EditBriefBio;

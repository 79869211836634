

import { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    TextField,
    Typography,
    Box,
    Autocomplete,
    Grid,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import { makeStyles } from "@mui/styles";
import InstrumentsRating from "../components/Social/instrumentsRating";
import { useUser } from "../context/UserContext";
import InstrumentSkillLevel from "../components/Musicians/InstrumentSkills";
import ErrorAlert from "../components/Error/ErrorAlert";
import ErrorAlertDisplay from '../components/Error/ErrorAlertDisplay';
import { error } from "console";
import { Link, useNavigate } from "react-router-dom";
// import ErrorAlert from "../ErrorAlert";

const useStyles = makeStyles((theme: any) => ({

    root: {
        color: "white",
        '& .MuiAutocomplete-tag': {
            backgroundColor: '#0273E6',
            color: 'white',
            '&:hover': {
                backgroundColor: '#0273E6',
                color: 'white',
            }
        },
        '& .MuiSvgIcon-root': {
            color: 'white !important'
        },
        '& .MuiAutocomplete-clearIndicator': {
            '&:hover': {
                backgroundColor: '#7A7985 !important'
            },
            backgroundColor: '#7A7985 !important',
            height: '18px',
            width: '18px'
        }
    }
}));
type Instrument = {
    id: string | number;
    label: string;
    icon: string;
};
const EditInstruments = (props: any) => {

    const classes = useStyles();
    const [instrumentsValue, setInstrumentsValue] = useState<any>(props.instrumentsValue)
    const [dirtyInstrument, setDirtyInstrument] = useState(false);
    const [rating, setRating] = useState<number | null>(2);
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [skillLevelValues, setSKillLevelValues] = useState<any>([]);
    const [instruments, setInstruments] = useState<any>([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const [originalInstruments, setOriginalInstruments] = useState<any>([]);
    const [displaySave, setDisplaySave] = useState(instrumentsValue.length === 0 ? true : false)
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        setInstrumentsValue(props.instrumentsValue)
    }, [props])

    useEffect(() => {
        getInstrumentsList();
    }, []);

    useEffect(() => {
        setOriginalInstruments(instrumentsValue);
    }, [props.instrumentsOpen]);

    const getInstrumentsList = () => {
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setInstruments(data);
                setOriginalInstruments(data);
            })
            .catch((err) => {
                setShowAlert(true);
                setAlertStatus(false);
                setAlertMsg("Network Error! Please try again..");
            });
    };
    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const handleClose = () => {
        setInstrumentsValue(originalInstruments);
        props.handleClose(false);

    };

    const updateInstrumentFilter = (instrument: string, skillLevel: number) => {
        setSKillLevelValues([...skillLevelValues, ...[{ 'id': instrument, 'skillLevel': skillLevel }]])
    }

    const ArrayOfInstruments: any = []

    instrumentsValue.map((inst: any) => {
        ArrayOfInstruments.push(
            {
                skillLevel: inst.skillLevel,
                instrument: {
                    id: inst.id,
                    instrumentName: inst.instrumentName
                }
            }
        )

        skillLevelValues.map((skill: any) => {
            ArrayOfInstruments.map((value: any) => {
                if (skill.id === value.instrument.id) {
                    value.skillLevel = skill.skillLevel ? skill.skillLevel : 0
                }
            })
        })
    })
    const handleUpdateInstruments = (event: any) => {
        setDisplaySave(true)
        setIsLoading(true)
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/user-instruments-list/${user.userProfileId}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(ArrayOfInstruments),
            }
        )
            .then((res) => {
                if(res.status === 502){
                    navigate('/defaultPage')
                } else if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
                } else {
                    props.handleInstruments(ArrayOfInstruments);
                    setInstrumentsValue([])
                    setSKillLevelValues([])
                    props.handleClose(false);
                    setDisplaySave(false)
                    setIsLoading(false)
                    return res.json();
                }
            })
            .catch((error) => {
                setShowAlert(true);
                setAlertStatus(false);
                setAlertMsg("Network Error! Please try again..");
                setDisplaySave(false)
                setIsLoading(false)
            });
    }

    const IsEnable = () => {
        if (ArrayOfInstruments.every((obj: any) => obj.skillLevel !== undefined && obj.skillLevel !== null) && instrumentsValue.length !== 0) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            <Dialog
                sx={{
                    border: "1px solid #888888",
                    boxShadow: "customColor.dropShadow",
                    borderRadius: "8px"
                }}
                fullWidth
                maxWidth="sm"
                open={props.instrumentsOpen}
            >
                <ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
                <DialogTitle
                    className="edit-heading-name"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Typography variant="h6" >
                        Edit Instruments
                    </Typography>
                </DialogTitle>

                <DialogContent>
                    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid xs={9}>
                            <FormHelperText>{i18n.socialProfile.instrument}*</FormHelperText>
                        </Grid>
                        <Grid xs={12} sx={{ m: 0.5 }}>
                            <Autocomplete
                                multiple
                                id="instruments"
                                classes={classes}
                                className='social-textField'
                                value={instrumentsValue}
                                onChange={(event, newValue) => {
                                    setInstrumentsValue(newValue as any);
                                }}
                                options={instruments.sort((a: any, b: any) => a.instrumentName.localeCompare(b.instrumentName))}
                                getOptionLabel={(option) => option.instrumentName || ''}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // placeholder="Instruments"
                                        onFocus={() => setDirtyInstrument(true)}
                                        error={dirtyInstrument || (instrumentsValue.length === 0)}
                                    />)}
                            />
                            {
                                dirtyInstrument || instrumentsValue.length === 0 ?
                                    <FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
                                        {
                                            instrumentsValue.length === 0 ? "Select at least one Instrument"
                                                : ""
                                        }
                                    </FormHelperText>
                                    : ''}

                            {
                                ArrayOfInstruments.map((instrument: any) => {
                                    return <InstrumentSkillLevel instrument={instrument.instrument.id} skillLevel={instrument.skillLevel ? instrument.skillLevel : 0} editable={true} updateInstrumentFilter={updateInstrumentFilter} instrumentLabel={instrument.instrument.instrumentName} />
                                })
                            }
                            <FormHelperText sx={{ color: "#FD4B24", textAlign: 'right', mr: 0 }}>
                                {ArrayOfInstruments.every((obj: any) => obj.skillLevel !== undefined && obj.skillLevel !== null && obj.skillLevel !== 0) ?
                                    '' : "Rate your skills"}
                            </FormHelperText>
                        </Grid>

                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', pt: 1, flexDirection: 'row-reverse' }}>
                        <Button
                            sx={{ borderRadius: '17px' }}
                            size="small"
                            variant="outlined"
                            onClick={handleClose}
                        >Cancel
                        </Button>
                        {isLoading ?
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                                disabled={IsEnable()}
                            > Saving..
                            </Button>
                            :
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                                onClick={handleUpdateInstruments}
                                disabled={IsEnable()}
                            >   Save
                            </Button>
                        }
                    </Box>
                </DialogActions>

            </Dialog>
        </>
    )
}

export default EditInstruments;
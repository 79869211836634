import { useNavigate } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Box,
	Typography,
	Grid,
	IconButton,
} from "@mui/material";
import i18n from "../l10n/strings.json";
import CloseIcon from "../images/close.svg";
import { clearAllLocalstorage, signOut } from "../utils";
import { useUser } from "../context/UserContext";

const LogoutDialog = (props: any) => {
	const navigate = useNavigate();
	const user = useUser();
	const logout = () => {
		// clearLocalstorage();
		clearAllLocalstorage();
		navigate("/login");
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="xs"
			open={props.open}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={logout}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
						sx={{ marginLeft: "90%" }}
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.dialog.sessionExpired}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent
				sx={{
					padding: "5px 20px",
				}}
			>
				<Grid
					container
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography>{i18n.dialog.sessionExpiredText}</Typography>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid
					container
					columnSpacing={1}
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<Grid item={true}>
						<Button
							sx={{ width: "120px" }}
							className="submit-button"
							size="large"
							variant="contained"
							onClick={logout}
						>
							{i18n.dialog.ok}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};
export default LogoutDialog;

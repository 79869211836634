import Grid from "@mui/material/Grid";
import DeviceManagementTb from "./DeviceManagementTb";
import DeviceManagementFilter from "./DeviceManagementFilter";

export const DeviceManagementContainer = () => {
	return (
		<Grid xs={12} container columnGap={2} >
			<Grid xs={3} item className="userFilter">
				<DeviceManagementFilter />
			</Grid>
			<Grid xs={8.8} item style={{ marginTop: "0.2rem" }} className="userManagTable">
				<DeviceManagementTb />
			</Grid>
		</Grid>
	);
};

import { EventEmitter } from "events";
import type { UserInterface } from "../types/types";
/**
 * Fetches and stores the user info in memory.
 * Fires changed event in case of user is logged in or out.
 *
 * @event changed
 * @event loggedIn
 * @class User
 * @extends EventEmitter
 */
class User extends EventEmitter {
	public defaultUser: string = JSON.stringify({
		id: 1,
		login: "",
		firstName: "",
		lastName: "",
		email: "",
		imageUrl: "",
		activated: false,
	});
	public user: undefined = undefined;

	constructor() {
		super();
	}

	public async fetchUserInfo(callback: any | undefined) {
		const token = localStorage.getItem("sessionId");
		fetch(`${process.env.REACT_APP_BASE_URL}/api/account`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if(res.status >= 400){
					return callback(res);
				}
				let collabStatus = res.authorities?.find(
					(a: any) => a.name === "ROLE_COLLAB_USER"
				);
				res = { ...res, isCollaborationActive: collabStatus ? true : false };

				// for now we keeping the collaboration visible to all users
				// res = {...res, isCollaborationActive: true }
				this.user = res;
				localStorage.removeItem("user");
				localStorage.setItem("user", JSON.stringify(res));
				this.emit("userChanged", res as UserInterface);
				if (callback) {
					callback(res);
				}
			});
	}

	public getDefaultUser(): UserInterface {
		if (this.user) {
			return this.user;
		}

		const _user = JSON.parse(localStorage.getItem("user") ?? this.defaultUser);

		return _user ?? undefined;
	}
}
export default new User();


import { useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	TextField,
	IconButton,
	Box,
	Typography,
	Grid,
	Alert,
	Snackbar,
	AlertTitle,
	TextareaAutosize
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useUser } from "../../../context/UserContext";
// import i18n from "../l10n/strings.json";
import CloseIcon from "../../../images/close.svg";
import { useAlertStatus } from "../../../context/AlertStatusContext";

const AdminAvatarPrompt = (props: any) => {
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus()
	const [inputText, setInputText] = useState('');
	const [count, setCount] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isEnabled, setIsEnabled] = useState(false);

	const [animal, setAnimal] = useState('[Favorite Animal]');
	const [instrument, setInstrument] = useState('[Musical Instrument]');
	const [genreAttire, setGenreAttire] = useState('[Favorite Genre Attire]');
	const [genre, setGenre] = useState('[Genre]');

	const isSaveEnabled =
		inputText.includes(animal) &&
		inputText.includes(instrument) &&
		(inputText.includes(genreAttire) &&
		inputText.includes(genre) || inputText.includes(genre)
		) &&
		inputText.length <= 512

	const handleButtonClick = (placeholder: any) => {
		setInputText(inputText + ' ' + placeholder);
	};

	const handleClose = () => {
		props.handleClose(false)
	};

	const handleInputChange = (event: any) => {
		setInputText(event.target.value);

	};

	const saveAIPromot = async () => {
		setIsLoading(true);
		setCount(count + 1);
		const reqBody = {
			id: count,
			promptText: inputText,
			userId: user?.userProfileId
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/avatar-prompts/create`, {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(reqBody),
			});

			if (response.ok) {
				setTimeout(() => {
					setIsLoading(false);
					setInputText('');
					props.handleClose(false);
					updateAlertStatus(true, true, 'Avatar Image Generation Prompt added!');
					props.onPromptSuccess();
				}, 1000);
			} else {
				updateAlertStatus(true, false, 'Network Error! Please try again..');
			}
		} catch (error) {
			updateAlertStatus(true, false, 'Network Error! Please try again..');
		}
	};


	return (
		<>

			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				open={props.avatarPromptOpen}
			>
				<DialogTitle
					className="edit-heading-name"
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={handleClose}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%" }}
						>
							<img src={CloseIcon} alt="close" />
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							// className="dialog-title-custom-size"
							sx={{
								fontSize: "20px",
								fontWeight: 200,
								color: "#000000",
							}}
						>
							AI Avatar Prompt
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						padding: "5px 20px",
					}}
				>
					<Grid
						container
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						{/* <Grid item={true} xs={12}>
						<FormHelperText
							sx={{
								fontSize: "1rem",
								color: "#7A7985",
							}}
							id="outlined-weight-helper-text"
						>
							Date
						</FormHelperText>
					</Grid> */}
						<Grid item={true} xs={12}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								{/* <TextField type="text"
									value={inputText}
									onChange={handleInputChange}
									placeholder="Enter text here"
								/> */}
								<TextareaAutosize
									value={inputText}
									onChange={handleInputChange}
									placeholder="Enter text here"
									style={{
										width: '100%',
										minHeight: '100px',
										padding: '5px',
										fontSize: '18px',
										color: '#888888',
										borderRadius: '8px',
										resize: 'none',
									}}
								/>
								{inputText.length > 512 && <p style={{ color: 'red', fontSize: '12px' }}>Character limit exceeded (512 characters max)</p>}
							</LocalizationProvider>
						</Grid>

					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid>
						<Button
							sx={{
								width: '100%', borderColor: "#0273E6", color: "#0273E6", fontSize: 'clamp(8px, 4vw, 14px) !important',
								'&:hover': {
									backgroundColor: '#81d3fc',
									color: '#FFFFFF',
									borderColor: "#0273E6"
								}
							}}
							// className="cancel-button"
							size="large"
							variant="outlined"
							onClick={() => handleButtonClick(animal)}
							disabled={
								inputText.includes(animal) ? !isEnabled : isEnabled
							}
						>
							+ Favorite Animal
						</Button>
					</Grid>
					<Grid>
						<Button
							 sx={{
                                width: '100%', borderColor: "#0273E6", color: "#0273E6", fontSize: 'clamp(8px, 4vw, 14px) !important', padding:'7px !important',
                                '&:hover': {
                                    backgroundColor: '#81d3fc',
                                    color: '#FFFFFF',
                                    borderColor: "#0273E6"
                                }
                            }}
							// className="cancel-button"
							size="large"
							variant="outlined"
							onClick={() => handleButtonClick(instrument)}
							disabled={
								inputText.includes(instrument) ? !isEnabled : isEnabled
							}
						>
							+ Musical Instrument
						</Button>
					</Grid>
					<Grid>
						<Button
							sx={{
								width: '100%', borderColor: "#0273E6", color: "#0273E6", fontSize: 'clamp(8px, 4vw, 14px) !important',
								'&:hover': {
									backgroundColor: '#81d3fc',
									color: '#FFFFFF',
									borderColor: "#0273E6"
								}
							}}
							// className="cancel-button"
							size="large"
							variant="outlined"
							onClick={() => handleButtonClick(genreAttire)}
							disabled={
								inputText.includes(genreAttire) ? !isEnabled : isEnabled

							}
						>
							+ Favorite Genre Attire
						</Button>
					</Grid>
				</DialogActions>
				<DialogActions>
					<Grid>
						<Button
							sx={{
								width: '100%', borderColor: "#0273E6", color: "#0273E6", fontSize: 'clamp(8px, 4vw, 14px) !important',
								'&:hover': {
									backgroundColor: '#81d3fc',
									color: '#FFFFFF',
									borderColor: "#0273E6"
								}
							}}
							// className="cancel-button"
							size="large"
							variant="outlined"
							onClick={() => handleButtonClick(genre)}
							disabled={
								inputText.includes(genre) ? !isEnabled : isEnabled
							}
						>
							+ Genre
						</Button>
					</Grid>
				</DialogActions>
				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								sx={{ width: "120px" }}
								className="cancel-button"
								size="large"
								variant="outlined"
								onClick={handleClose}
							>
								cancel
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								sx={{ width: "120px" }}
								className="submit-button"
								size="large"
								variant="contained"
								onClick={saveAIPromot}
								disabled={
									!isSaveEnabled || !inputText
								}
							>
								{isLoading ? "Saving.." : "Save"}
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		</>
	);
};
export default AdminAvatarPrompt;

import { useState } from "react";
import {
	Grid,
	Typography,
	Button,
	Box
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";
import AdminDeviceImportExport from "./AdminDeviceImortExport";
import AdminDeviceAddEditModal from "./AdminDeviceAddEditModal";
import AdminDeviceManagementFilters from "./AdminDeviceFilters";
import DeviceManagementManagementTable from "./AdminDeviceTable";
import AdminDeleteDeviceModal from "./AdminDeleteDeviceModal";

const useStyles = makeStyles(() =>
	createStyles({
		shadowedBox: {
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			borderRadius: "18px !important",
		},
		addDeviceButton: {
			borderRadius: "18px !important",
			textAlign: 'center',
			color: 'white',
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			cursor: "pointer",
			margin: "18px"
		},
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
	})
);

type RouteParams = {
	menuId: string;
};

type deviceInfo = object | Array<Object>;

export default function DeviceManagement() {
	const classes = useStyles();
	const param = useParams<RouteParams>();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditingDevice, setIsEditingDevice] = useState(false);
	const [isDeletingDevice, setIsDeletingDevice] = useState(false);
	const [currentDeviceInfo, setCurrentDeviceInfo] = useState<deviceInfo>({});

	const userRole = localStorage.getItem("user");
	let loggedInRole;
	if (userRole) {
		const userRoleObj = JSON.parse(userRole);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		loggedInRole = userRoleObj.authorities[0].name;
	}

	const userCanPerformActions = ["ROLE_ADMIN","ROLE_SUPER_ADMIN"].includes(loggedInRole);



	const handleEditDevice = (deviceInfo: object) => {
		console.log(deviceInfo);
		setCurrentDeviceInfo(deviceInfo);
		setIsEditingDevice(true);
		setIsModalOpen(true);
	}

	const handleDeleteDevice = (deviceInfo: deviceInfo) => {
		console.log(deviceInfo)
		setCurrentDeviceInfo(deviceInfo);
		setIsDeletingDevice(true);
	}

	const handleCloseModal = () => {
		setIsModalOpen(false);
		setIsDeletingDevice(false);
		setIsEditingDevice(false);
		setCurrentDeviceInfo({});
	}

	return (
		<>
			<Grid className="mainPanel">
				<Grid
					container={true}
					className="column jam-session mainPanel"
					sx={{ position: 'relative' }}
				>
					<Grid container xs={12} sx={{ backgroundColor: 'white', zIndex: '1', paddingTop: '25px', position: 'sticky', top: '70px' }}>
						<Grid item xs={4} py={2}>
							<Typography
								className="home-title-custom-size"
								style={{
									fontWeight: "500",
									fontSize: "36px !important",
									lineHeight: "49px",
									color: "#000000",
								}}
							>
								Device Inventory
							</Typography>
						</Grid>

						<Grid container
							direction="row" justifyContent="flex-end"
							alignItems="center" xs={8} alignSelf="right" sx={{ flex: 1 }}
						>
							<Button
								variant="contained" color="primary"
								className={classes.addDeviceButton}
								id="add-new-device"
								onClick={() => setIsModalOpen(true)}
								disabled={!userCanPerformActions}
							>
								Add New Device
							</Button>
							<AdminDeviceImportExport userCanPerformActions={userCanPerformActions}/>

						</Grid>

					</Grid>

					<Grid container sx={{ paddingTop: '10px', marginTop: '16px' }}>
						<Grid item xs={3}>
							<Box >
								<AdminDeviceManagementFilters />
							</Box>

						</Grid>

						<Grid item xs={9} className="centerPanelwoRight">
							<DeviceManagementManagementTable
								onEditDevice={handleEditDevice}
								onDeleteDevice={handleDeleteDevice}
								userCanPerformActions={userCanPerformActions}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid >
			<AdminDeviceAddEditModal open={isModalOpen} onClose={() => handleCloseModal()} isEditType={isEditingDevice} currentDevice={currentDeviceInfo} />
			<AdminDeleteDeviceModal open={isDeletingDevice} onClose={() => handleCloseModal()} currentDevice={currentDeviceInfo} />
		</>
	)
}

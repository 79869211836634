import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography,
	Box,
	Grid,
	rgbToHex,
} from "@mui/material";
import { useUser } from "../context/UserContext";
import CloseIcon from "../images/close.svg";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";
import i18n from "../l10n/strings.json";
import RightAarrow from "../images/right arrow.svg";

const CloseAccount = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	const user = useUser();

	const [deleteVal, setDeleteVal] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [dirty, setDirty] = useState(false);
	const [iserror, setError] = useState(false);

	useEffect(() => {
		if (props.emailOpen) {
			setPassword("");
			setError(false);
		}
	}, [props.emailOpen]);

	const handleCloseAccount = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/deactivate-user`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				// id: user.id,
				id: props.userIdByAdmin ? props.userIdByAdmin : user.id,
				// email: user.email,
				email: props.email,
				password: password,
			}),
		}).then((response) => {
			if (response.status >= 400) {
				setError(true);
				throw new Error();
			} else {
				localStorage.removeItem("sessionId");
				if (user?.authorities[0]?.name === "ROLE_USER") {
					navigate("/login", {
						state: { success: true, action: "closeAccount" },
					});
				}
				props.handleClose();
			}
		});
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="sm"
			onClose={props.handleClose}
			open={props.open}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={props.handleClose}
						onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
							event.preventDefault()
						}
						edge="end"
					>
						<img src={CloseIcon} alt="close" />
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						marginTop: "-30px"
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
						}}
					>
						{i18n.dialog.closeAccount}
					</Typography>
				</Box>
			</DialogTitle>
			<>
				<DialogContent>
					<Grid container xs={12} columnSpacing={2}>
						<Grid item={true} xs={12}>
							<Typography
								sx={{
									fontSize: "1.125rem",
									color: "#000000",
									fontFamily: "Lexend",
									fontWeight: "500",
									paddingBottom: "20px",
									display: "flex",
									textAlign: "center",
								}}
								id="outlined-weight-helper-text"
							>
								{i18n.dialog.closeAccountInfo}
							</Typography>
						</Grid>
						<Grid item={true} xs={12}>
							<FormHelperText
								sx={{
									fontSize: "1rem",
									color: "#7A7985",
								}}
								id="outlined-weight-helper-text"
							>
								Type{" "}
								<b style={{ color: "#000000" }}>
									{i18n.dialog.delete.toUpperCase()}
								</b>{" "}
								below to confirm
							</FormHelperText>
							<TextField
								fullWidth
								sx={{
									marginBottom: "20px",
									background:
										"linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #D7D7D7, #D7D7D7)",
									border: "1px solid #D7D7D7",
								}}
								value={deleteVal}
								onChange={(e) => setDeleteVal(e.target.value.toUpperCase())}
								onFocus={() => setDirty(true)}
								error={
									dirty &&
									(deleteVal !== "DELETE" ||
										deleteVal.length < 6 ||
										deleteVal.length >= 7)
								}
							/>
						</Grid>
						<Grid item={true} xs={12}>
							<FormHelperText
								sx={{
									fontSize: "1rem",
									color: "#7A7985",
								}}
								id="outlined-weight-helper-text"
							>
								{i18n.dialog.accountPassword}
								<a
									href="/#/forgotPassword"
									style={{
										fontFamily: "Lexend",
										fontStyle: "normal",
										fontSize: "14px",
										color: "#0A6BFE",
										float: "right",
									}}
								>
									{i18n.dialog.forgotPassword}
								</a>
							</FormHelperText>
							<OutlinedInput
								type={showPassword ? "password" : "text"}
								fullWidth
								onChange={(e) => {
									setError(false);
									setPassword(e.target.value);
								}}
								onFocus={() => setDirty(true)}
								onCopy={(e) => e.preventDefault()}
								onPaste={(e) => e.preventDefault()}
								onCut={(e) => e.preventDefault()}
								error={
									iserror ||
									(dirty && (password.length < 8 || password.length > 16))
								}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => setShowPassword((show) => !show)}
											onMouseDown={(
												event: React.MouseEvent<HTMLButtonElement>
											) => event.preventDefault()}
											edge="end"
										>
											{!showPassword ? (
												<img src={VisibleOn} alt="eye" />
											) : (
												<img src={VisibleOff} alt="Eye Slash" />
											)}
										</IconButton>
									</InputAdornment>
								}
								sx={{
									marginBottom: "20px",
									background:
										"linear-gradient(0deg, #F8F8F8, #F8F8F8), linear-gradient(0deg, #D7D7D7, #D7D7D7)",
									border: "1px solid #D7D7D7",
								}}
							/>

							{iserror && (
								<FormHelperText
									sx={{
										color: "#FD4B24",
										textAlign: "right",
									}}
								>
									{i18n.dialog.invalidPassword}
								</FormHelperText>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
				// sx={{
				// 	marginRight: "38px",
				// 	marginBottom: "24px",
				// }}
				>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								sx={{ width: "135px" }}
								className="cancel-button"
								size="large"
								variant="outlined"
								onClick={props.handleClose}
							>
								{i18n.dialog.cancel}
							</Button>
						</Grid>

						<Grid item={true}>
							<Button
								className="cancel-button"
								size="large"
								variant="contained"
								
								disabled={
									password.length <= 7 ||
									password === "" ||
									iserror ||
									deleteVal !== "DELETE"
								}
								onClick={handleCloseAccount}
								endIcon={<img src={RightAarrow} alt="Right Arrow"  style={{marginRight: "5px"}}/>}
								//sx={{backgroundColor: "#EB2A00"}}
								sx={{ backgroundColor: "#FF702A" }}
							>
								{i18n.dialog.closeAccount}
								{/* <Typography>{i18n.dialog.closeAccount}</Typography> */}
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</>
		</Dialog>
	);
};
export default CloseAccount;

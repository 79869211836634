import React, { useCallback, useRef } from "react";

import { IconButton, Tooltip } from "@mui/material";
// import VideoOffIcon from "../../../icons/VideoOffIcon";
// import VideoOnIcon from "../../../icons/VideoOnIcon";
import VideoOn from "../../../images/video.svg";
import VideoOff from "../../../images/video-slash.svg";
import useDevices from "../../../hooks/useDevices/useDevices";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import i18n from "../../../l10n/strings.json";

export default function ToggleVideoButton(props: {
	disabled?: boolean;
	className?: string;
}) {
	const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
	const lastClickTimeRef = useRef(0);
	const { hasVideoInputDevices } = useDevices();

	const toggleVideo = useCallback(() => {
		if (Date.now() - lastClickTimeRef.current > 500) {
			lastClickTimeRef.current = Date.now();
			toggleVideoEnabled();
		}
	}, [toggleVideoEnabled]);

	return (
		<Tooltip
			title={
				isVideoEnabled
					? i18n.jamSession.stopVideoTooltip
					: i18n.jamSession.startVideoTooltip
			}
		>
			<IconButton
				className={props.className}
				onClick={toggleVideo}
				disabled={!hasVideoInputDevices || props.disabled}
				// startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
			>
				{isVideoEnabled ? (
					<img src={VideoOn} alt="video-on" />
				) : (
					<img src={VideoOff} alt="video-off" />
				)}
			</IconButton>
		</Tooltip>
	);
}

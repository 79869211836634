import { useEffect, useState } from "react";
import { Snackbar, AlertTitle, Alert } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import ChatWindowHeader from "./ChatWindowHeader/ChatWindowHeader";
import ChatInput from "./ChatInput/ChatInput";
import clsx from "clsx";
import MessageList from "./MessageList/MessageList";
import useChatContext from "../../hooks/useChatContext/useChatContext";
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chatWindowContainer: {
			background: "#000000",
			zIndex: 9,
			display: "flex",
			flexDirection: "column",
			border: "2px solid #7A7985",
			margin: "0px 0 10px 0",
			borderRadius: "8px",
			width: "96%",
			position: "relative",
			right: "1%",
			[theme.breakpoints.down("sm")]: {
				position: "fixed",
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				zIndex: 100,
			},
		},
		hide: {
			display: "none",
		},
	})
);

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

export default function ChatWindow() {
	const classes = useStyles();
	const { room } = useVideoContext();
	const localParticipant = room!.localParticipant;
	const { isChatWindowOpen, messages, conversation } = useChatContext();
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	const [open, setOpen] = useState(false);
	const [messageNotification, setMessageNotification] = useState("");
	const [name, setName] = useState("");

	const userSession = localStorage.getItem("userSession") ?? "";
	const userSessions = JSON.parse(userSession);

	useEffect(() => {
		const handleMessageNotification = (message: any) => {
			const isLocalParticipant =
				localParticipant.identity === message.state.author;
			const participantDetails = userSessions.attendees.find(
				(name: any) => name.email === message.state.author
			);

			const firstNameLetter = participantDetails?.firstName?.charAt(0);
			const lastNameLetter = participantDetails?.lastName?.charAt(0);
			if (!isLocalParticipant) {
				const startName = firstNameLetter.concat(lastNameLetter);
				setName(startName);
				if (!isChatWindowOpen) {
					setOpen(true);
					setMessageNotification(message.state.body);
				} else {
					setOpen(false);
				}
			}
		};

		if (conversation) {
			conversation.on("messageAdded", handleMessageNotification);
		}
	}, [messages, conversation, isChatWindowOpen]);
	return (
		<>
			<Snackbar
				open={open}
				onClose={() => setOpen(false)}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={6000}
				sx={{
					"&.MuiSnackbar-anchorOriginTopRight": {
						top: isFullscreenMode ? "80px" : isTabHidden ? "80px" : "145px",
					},
					"& .MuiPaper-root": {
						background: "#5C5C5C",
						color: "#000000",
					},
					"& MuiPaper-root &.MuiAlert-root": {
						padding: "0px",
					},
				}}
			>
				<Alert
					onClose={() => setOpen(false)}
					icon={" "}
					sx={{
						padding: 0,
						"& .MuiAlert-message": {
							display: "flex",
							alignItems: "center",
							background: "#5C5C5C ",
						},
						"& .MuiAlertTitle-root": {
							color: "#000000",
						},
						"& .MuiAlert-action": {
							marginRight: "0px",
							padding: "0 0 0 16px",
						},
					}}
				>
					<div className="messageNotificationIcon">{name}</div>
					<AlertTitle>{messageNotification}</AlertTitle>
				</Alert>
			</Snackbar>
			<aside
				className={clsx(classes.chatWindowContainer, {
					[classes.hide]: !isChatWindowOpen,
				})}
				style={{	top: isFullscreenMode ? "135px" : "62px",position: "relative",}}
			>
				<ChatWindowHeader />
				<MessageList messages={messages} />
				<ChatInput
					conversation={conversation!}
					isChatWindowOpen={isChatWindowOpen}
				/>
			</aside>
		</>
	);
}

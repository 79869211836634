import React from 'react';
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1.425em 0 0.083em',
      fontSize: '12px',
      color: '#FFFFFF',
      width: "100%",
    },
    isLocalParticipantInfo: {
      display: 'none',
      // fontSize: '12px',
      // color: '#606B85',
    }
  })
);

interface MessageInfoProps {
  author: string;
  isLocalParticipant: boolean;
}

export default function MessageInfo({ author, isLocalParticipant }: MessageInfoProps) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.messageInfoContainer, {
      [classes.isLocalParticipantInfo]: isLocalParticipant,
    })}>
      <div>{isLocalParticipant ? `` : author}</div>
    </div>
  );
}


export function saveTextAsFile(textToWrite: any, fileNameToSaveAs: string, fileType: string) {
  let textFileAsBlob = new Blob([textToWrite], { type: fileType });
  let downloadLink = document.createElement('a');
  downloadLink.setAttribute('target', '_blank');
  downloadLink.download = fileNameToSaveAs;
  downloadLink.innerHTML = 'Download File';

  if (window.webkitURL != null) {
      downloadLink.href = window.webkitURL.createObjectURL(
          textFileAsBlob
      );
  } else {
      downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
  }

  downloadLink.click();
}
import { Typography, Divider, Grid } from "@mui/material";

import { useEffect, useState } from "react";
import { ReccomendationTemplate } from "./ReccomendationTemplate";
import SkeletonArtists from "./SkeletonArtists";
import EventCardCom from "../../Events/EventCardCom";
import UserCardShort from "../../Musicians/UserCardShort";
import { MusiciansRecommendedSkeleton } from "./MusiciansRecommendedSkeleton";

export const MusiciansRecommended = () => {
	const token = localStorage.getItem("sessionId");
	const [similarArtists, setSimilarArtists] = useState([]);
	const [showSkeleton, setShowSkeleton] = useState(true);

	useEffect(() => {
		getSimilarArtistsRecommendations();
	}, []);

    const getSimilarArtistsRecommendations = (page = 0, size = 8, retryCount = 0) => {
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/recommendation/similar-artist?page=${page}&size=${size}
                `,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
                    setSimilarArtists(data);
                    if (data.length < 4 && retryCount < 2) {
                        // Retry with the same page and size
                        getSimilarArtistsRecommendations(page, size + 8, retryCount + 1);
                    }
					setShowSkeleton(false);
				})
				.catch((err) => {
				})
				.finally(() => {});
		} catch (error) {
		}
	};

	return (
		<>
			<Grid container xs={12}>
                {
                    showSkeleton ? (
                        <MusiciansRecommendedSkeleton />
                    ) :
                    similarArtists.length > 0 ? (
					<>
						{similarArtists.slice(0, 4).map((artist, index) => (
							<UserCardShort
								user={artist}
								refreshList={getSimilarArtistsRecommendations}
							/>
						))}
						<Divider variant="middle" light sx={{ ml: 1, mr: 1, mb: 1 }} />
					</>
				) : (
					<Typography
						variant="body1"
						sx={{ fontSize: "14px", fontWeight: "400", textAlign: "center" }}
					>
						No recommendations present
					</Typography>
				)}
			</Grid>
		</>
	);
};

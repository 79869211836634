import { useState, useEffect } from "react";
import {
	Outlet,
	useParams,
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import {
	Typography,
	Grid,
	Button,

} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, styled } from "@mui/material/styles";
// contexts
import { useUser } from "../../../context/UserContext";
import SettingsAlert from "../../../pages/SettingsAlert";
import { useMusiciansFilter } from "../../Musicians/MusicProvider/useMusicianFilter";

import UserModerationFilter from "./UserModerationFilter";
import ContentModerationFilter from "./ContentModerationFilter";
import ContentModerationTable from "./ContentModerationTable";
import UsersModerationTable from "./UsersModerationTable";
import { NoBackpackSharp } from "@mui/icons-material";
// import UserManagementTb from "../UserManagementTb";
// import DeviceManagementTb from "./DeviceManagementTb";

type RouteParams = {
	menuId: string;
};

const musiciansMenu = [
	{
		id: "all",
		label: "Content",
		navigateMenu: "",
	},
	{
		id: "connections",
		label: "Users",
		navigateMenu: "users",
	},
];



const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			padding: "1% 2%",
			borderRadius: "15px",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
	})
);

const ModerationManagement = () => {
	const param = useParams<RouteParams>();
	const classes = useStyles();
	const user = useUser();
	const navigate = useNavigate();
	const location = useLocation();
	const token = localStorage.getItem("sessionId");
	const musicFilter = useMusiciansFilter();

	const [genres, setGenres] = useState("");
	const [activeMenu, setActiveMenu] = useState("");
	const [activeSubMenu, setActiveSubMenu] = useState("invitations-received");

	useEffect(() => {
		let urlMenu = window.location.href.split("/").pop();
		if (urlMenu === "connections" || urlMenu === "invitations") {
			setActiveMenu(urlMenu);
		} else {
			setActiveMenu("all");
		}
	}, []);

	const changeMenu = (menu: any) => {
		setActiveMenu(menu.id);
		navigate(menu.navigateMenu);
	};

	const changeSubMenu = (value: string) => {
		setActiveSubMenu(value);
	};

	return (
		<>
			<SettingsAlert />
			<Grid container sx={{ margin: 'auto' }} className={"userContainer mainPanel column"}>
				<Grid item container sx={{  backgroundColor: 'white', zIndex: '1',paddingTop: '43px', position:'sticky' }}>
					<Grid item xs={4.5} >
						<Typography sx={{
							fontWeight: "500",
							fontSize: "36px !important",
							lineHeight: "49px",
							color: "#000000",
						}}>
							{activeMenu === "all" ? "Content Moderation" : "Users Moderation"}
						</Typography>
					</Grid>
					<Grid item xs={7.5} sx={{ textAlignLast: 'center' }}>
						{!musicFilter.isFilterActive &&
							musiciansMenu.map((menu: any) =>
								<Button
									variant="contained"
									sx={{
										background: menu.id === activeMenu ? "#0273E6" : '#F5F5F5',
										"&:hover": { background: menu.id === activeMenu ? "#0273E6" : '#EEF6FF' },
										height: "36px",
										width: '200px',
										fontSize: '18px',
										fontWeight: 500,
										color: menu.id === activeMenu ? "white" : '#0273E6',
										mr: 1,
										fontFamily:'Lexend'
									}}
									onClick={() => changeMenu(menu)}
									key={menu.id}
								>
									{menu.label}
								</Button>
							)}
					</Grid>
				</Grid>


				<Grid item container className="mainPanel" sx={{mt: "20px"}}>
					<Grid item xs={3} sx={{overflowY: "hidden", display:"flex"}} className="leftPanelWidth">
						{activeMenu === "all" ? <ContentModerationFilter /> : <UserModerationFilter />}
					</Grid>
					<Grid item xs={9} className="userManagTable centerPanelwoRight">
						{activeMenu === "all" ? <ContentModerationTable /> : <UsersModerationTable />}
					</Grid>
				</Grid>

			</Grid>


			{/* <Grid className="center-align" spacing={{ xs: 1 }}>
				<Grid
					container={true}
					className="column"
					sx={{
						width: "80%",
					}}
					rowSpacing={{ xs: 1 }}
				>
					<Grid
						item={true}
						className="center-align"
						style={{
							padding: "10px",
						}}
					>
						<SettingsAlert />
					</Grid>
					<Grid
						item={true}
						sx={{
							position: "fixed",
							marginTop: "2em",
							zIndex: 99,
							width: "100%",
							backgroundColor: "white",
						}}
					>
						<Grid
							container={true}
							columns={{ xs: 12 }}
							columnSpacing={{ xs: 1 }}
						>
							<Grid item={true} xs={3}>
								<Typography
									sx={{
										// fontStyle: "normal",
										fontWeight: "500",
										fontSize: "36px",
										lineHeight: "49px",
										color: "#000000",
									}}
								>
									{activeMenu === "all" ? "Content Moderation" : "Users Moderation"}
								</Typography>
							</Grid>
							{!musicFilter.isFilterActive && (
								<Grid item container={true} sx={{}} xs={7} gap={1}>
									{musiciansMenu.map((menu: any) => {
										return menu.id === activeMenu ? (
											<Button
												variant="contained"
												sx={{
													background: "#0273E6",
													"&:hover": { background: "#0273E6" },
													height: "36px",
													width: '200px',
													fontSize: '18px',
													fontWeight: 500,
												}}
												className={classes.activeMenu}
												onClick={() => changeMenu(menu)}
											>
												{menu.label}
											</Button>
										) : (
											<Button
												variant="text"
												sx={{
													"&:hover": { background: "#EEF6FF" },
													height: "36px",
													background: "#F5F5F5",
													width: '200px',
													fontSize: '18px',
													fontWeight: 500
												}}
												className={classes.musicMenu}
												onClick={() => changeMenu(menu)}
											>
												{menu.label}
											</Button>
										);
									})}
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid
						item={true}
						className="rowa"
						sx={{ marginTop: "5em", zIndex: 1 }}
					>
						<Grid
							container={true}
							columns={{ xs: 12 }}
							style={{ display: "flex" }}
							columnSpacing={{ xs: 1 }}
							rowSpacing={{ xs: 1 }}
						>
							<Grid xs={3} item >
								{activeMenu === "all" ? <ContentModerationFilter/> : <UserModerationFilter/>}
							</Grid>
							<Grid item xs={9} md={9} xl={9} sx={{ marginLeft: "25em" }}>
								<Outlet />
							</Grid>
						</Grid>
					</Grid>
					<Grid container xs={12} columnGap={1}>
						<Grid xs={3} item>
							{activeMenu === "all" ? <ContentModerationFilter /> : <UserModerationFilter />}
						</Grid>
						<Grid xs={8} item>
							{activeMenu === "all" ? <ContentModerationTable /> : <UsersModerationTable />}
						</Grid>
					</Grid>
				</Grid>
			</Grid> */}
		</>
	);
};

export default ModerationManagement;

import { useState, useEffect } from "react";
import {
	Outlet,
	useParams,
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import {
	Alert,
	Box,
	MenuList,
	MenuItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Divider,
	Grid,
	Button,
} from "@mui/material";
// contexts
import { useUser } from "../context/UserContext";
import Account from "../images/account.svg";
import Security from "../images/security.svg";
//import Preferences from "../images/pref.svg";
import DeviceSetup from "../images/keyboard.svg";
import ActiveAccount from "../images/active-account.svg";
import ActiveSecurity from "../images/active-security.svg";
//import ActivePreferences from "../images/active-preff.svg";
import ActiveDeviceSetup from "../images/device-setup.svg";
import Next from "../images/next.svg";
import SettingsAlert from "./SettingsAlert";
import RemoveDeviceSetting from "./RemoveDeviceSetting";
import Privacy from "../images/setting-4.svg";
import Card from "../images/card-pos.svg";
import Profile from "../images/profile-remove.svg";
import Notification from "../images/notification-bing1.svg";
import Dollar from "../images/dollar-circle.svg";
import Cards from "../images/cards1.svg";
import ProfileCircle from "../images/profile-circle11.svg";
import EditSocialSetting from "../images/user-octagon edit.svg";
import { useUserManageFilter } from "../components/AdminDashboard/ManageUserDashBoard/UserManagementProvider/useUserManageFilter";
import CompatibilityPopup from "./CompatibilityTestPage";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import useAdminAuthorized from "../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
//import useAdminAuthorized from "../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import RightAarrow from "../images/right arrow.svg";
import AddDeviceDialog from "../dialogBox/AddDeviceDialog";
import ErrorIcon from "../images/danger.svg";

type RouteParams = {
	menuId: any;
};

const SettingsMenu = () => {
	const param = useParams<RouteParams>();
	const user = useUser();
	const navigate = useNavigate();
	// let [searchParams] = useSearchParams();
	// const term = searchParams.get();
	const location = useLocation();

	const token = localStorage.getItem("sessionId");
	const [showCompatibilityPopup, setShowCompatibilityPopup] = useState(false);
	const isDisabledOptions = useUserManageFilter() || {};
	const [open, setOpen] = useState(false);
	const [activeMenu, setActiveMenu] = useState(param.menuId ?? "deviceSetup");
	const { adminSetUserId } = useAdminContext();
	const [isAlreadyDevicePresent, setIsAlreadyDevicePresent] = useState(false);
	//const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const invoiceURL: string = process.env.REACT_APP_DEVICE_INVOICE_URL || "";
	const [loading, setLoading] = useState(true);
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	const getDevice = () => {
		const authorities = user.authorities.map(
			(authority: { name: string }) => authority.name
		);
		const fetchDeviceOf = adminSetUserId ? adminSetUserId.id : user.id;
		const fetchApi =
			adminSetUserId && isAdmin
				? `user-devices/${adminSetUserId.id}`
				: "active-user-device";
		fetch(`${process.env.REACT_APP_BASE_URL}/api/${fetchApi}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					navigate(`/home/settings/deviceSetup/device`);
				} else {
					navigate(`/home/settings/deviceSetup/add`);
				}
			})
			.catch((err) => {
				navigate(`/home/settings/deviceSetup/add`);
			});
	};

	const handleCompatibilityTest = () => {
		//setShowCompatibilityPopup(true);
		const url = `${process.env.REACT_APP_BASE_URL_FRONTEND}`;
		const baseUrl = new URL(url).origin;
		window.open(baseUrl + "/speedtest", "_blank");
	};
	const {
		userId,
		userProfileId,
		email,
		user: userDetails,
	} = location?.state || {};
	const handleSuccess = () => {
		if (location?.state?.userId) {
			navigate("/home/admin/settings/deviceSetup/device", {
				state: { userId, userProfileId, email, type: "edit", userDetails },
			});
		} else {
			navigate(`/home/settings/${param.menuId}/device`);
		}
	};

	const handleDialogClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (param.menuId === "deviceSetup") {
			getDevice();
		}
		setActiveMenu(param.menuId);
	}, [param.menuId]);

	useEffect(() => {
		getSubscriptionPlans();
	}, []);

	const changeMenu = (value: string) => {
		setActiveMenu(value);
		if (value === "deviceSetup") {
			getDevice();
		} else {
			let user = localStorage.getItem("user");
			if (user) {
				const userDetails = JSON.parse(user);
				console.log(userDetails.deviceId + " user ddevice id");
				if (userDetails.deviceId) {
					setIsAlreadyDevicePresent(true);
				}
			}
			navigate(`/home/settings/${value}/${value}`);
		}
	};
	const getSubscriptionPlans = async () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/master-subscription-plans`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})

			.then((data) => {
				if (data.length > 0) {
					// Parse features if they are JSON strings
					const plansWithParsedFeatures = data.map((plan: any) => {
						if (typeof plan.features === "string") {
							try {
								plan.features = JSON.parse(plan.features);
							} catch (error) {
								console.error(
									"Failed to parse features for plan:",
									plan.name,
									error
								);
								plan.features = [];
							}
						} else if (!Array.isArray(plan.features)) {
							plan.features = [];
						}
						return plan;
					});
					setSubscriptionPlans(plansWithParsedFeatures);
				}
			})
			.catch((err) => {})
			.finally(() => {
				setLoading(false);
			});
	};

	//const sealToken: string = process.env.REACT_APP_SEAL_SUB || "";
	const checkOut = (plan: any) => {

		// if (loading) return; // Prevent API call if already loading
		// setLoading(true); // Reset loading state
		// fetch(`https://app.sealsubscriptions.com/shopify/merchant/api/quick-checkout-url`, {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/checkout`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				//"X-Seal-Token": sealToken,
				// "X-Seal-Token": "seal_token_04gf07v4d5nbhanxzwrfaicre65bl3yiiindmhxq"      //pick this from env as per environment
			},
			body: JSON.stringify({
				action: "create",
				items: [
					{
						variant_id: plan.shopifyVariantId,
						quantity: 1,
						//selling_plan: plan.shopifySellingPlanId
						inventory_item_id: plan.inventoryItemId, //fetch from master api
						product_id: plan.shopifyProductId,
					},
				],
				attributes: {
					checkout: {
						email: user.email,
						shipping_address: {
							first_name: user.firstName,
							last_name: user.lastName,
							address1: user.location ? user.location : "",
							phone: "",
							zip: "",
							city: "",
							province: "",
							country: "",
						},
					},
				},
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.success) {
					// To Open in a new browsr window
					window.open(data.payload.quick_checkout_url, "_blank");
				}
			})
			.catch((err) => {
				// setActiveDevice(null);
			})
			.finally(() => {
				// setLoading(false);
			});
	};
	return (
		<>
			<Grid className="mainPanel" spacing={{ xs: 1 }}>
				<Grid
					container={true}
					className="column mainPanel"
					sx={{
						width: "80%",
					}}
					rowSpacing={{ xs: 1 }}
				>
					<Grid
						item={true}
						className="center-align"
						style={{
							padding: "10px",
						}}
					>
						<SettingsAlert />
					</Grid>
					<Grid item={true}>
						<Grid
							container={true}
							columns={{ xs: 12 }}
							sx={{
								display: "flex",
								flexDirection: "row",
								mt: location.pathname === "/home/settings/plans/plans" ? 7 : 2,
							}}
							className="mainPanel"
						>
							<Grid
								item={true}
								xs={3}
								className="center-align leftPanelWidth"
								sx={{ maxWidth: { md: "288px" }, marginx: { md: "16px" } }}
							>
								<Typography
									sx={{
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "2.25rem",
										lineHeight: "1.875rem",
										color: "#000000",
									}}
								>
									Settings
								</Typography>
							</Grid>
							<Grid
								item={true}
								xs={9}
								sx={{
									display: "flex",
									justifyContent: { xl: "end", md: "end" },
									maxWidth: { md: "74%", xl:"912px" },
								}}
							>
								{/* Hiding for social-launch */}
								{(location.pathname === "/home/settings/deviceSetup/device" ||
									location.pathname === "/home/settings/device/device" ||
									location.pathname === "/home/settings/deviceSetup/add" ||
									location.pathname === "/home/settings/device/add") && (
									<>
										<Grid
											item={true}
											xs={12}
											sx={{
												display: "flex",
												justifyContent: { xl: "end", md: "end" },
												maxWidth: { md: "690px", xl: "912px" },
											}}
										>
											<Button
												//className="cancel-button button-field-custom-font-size"
												size="large"
												variant="outlined"
												sx={{
													fontSize: "0.9rem",
													lineHeight: "20px",
													border: "2px solid #0273E6",
													color: "#0273E6",
													transition: "border 0.1s",
													marginRight: { xl: "24px", md: "16px" },
													"&:hover": {
														border: "2px solid #0273E6",
														backgroundColor: "#E9F1FF",
													},
													minWidth: "120px",
												}}
												onClick={() => window.open(invoiceURL)}
											>
												Invoices
											</Button>
											<Button
												//className="cancel-button button-field-custom-font-size"
												size="large"
												variant="outlined"
												onClick={handleCompatibilityTest}
												sx={{
													fontSize: "0.9rem",
													lineHeight: "20px",
													border: "2px solid #0273E6",
													color: "#0273E6",
													transition: "border 0.1s",
													marginRight: { xl: "24px", md: "16px" },
													"&:hover": {
														border: "2px solid #0273E6",
														backgroundColor: "#E9F1FF",
													},
													minWidth: "200px",
													padding: { md: "7px 16px " },
												}}
											>
												Compatibility Test
											</Button>
											<Button
												className="submit-button"
												size="large"
												sx={{
													minWidth: "200px",
													marginRight: { xl: "24px", md: "16px" },
												}}
												endIcon={<img src={RightAarrow} alt="Right Arrow" />}
												variant="contained"
												onClick={() => setOpen(true)}
											>
												<Typography sx={{ fontSize: "1rem !important" }}>
													Add New Device
												</Typography>
											</Button>
											{ subscriptionPlans.map((plan: any, index) => {
													return (plan.type === "Electronics" &&
													plan.name?.toLowerCase() === "lutefishstream") ? (
														<Button
														className="submit-button"
														size="large"
														sx={{ minWidth: "200px" }}
														endIcon={<img src={RightAarrow} alt="Right Arrow" />}
														variant="contained"
														onClick={() => {
															checkOut(plan);
														}}
														>
														<Typography>Buy Device</Typography>
														</Button>
													) : "";
												})
												}
										</Grid>
									</>
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item={true} className="row mainPanel">
						<Grid
							container={true}
							columns={{ xs: 12 }}
							className=" mainPanel"
							sx={{ display: "flex", marginTop: "10px" }}
						>
							<Grid item={true} xs={3} className="leftPanelWidth">
								<Box>
									<MenuList
										sx={{
											background: "#FFFFFF",
											boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											borderRadius: "12px",
										}}
									>
										<MenuItem
											onClick={() => changeMenu("account")}
											sx={{
												padding: "10px 0 10px 14px",
												whiteSpace: "normal",
												overflowX: "auto",
												backgroundColor: `${
													activeMenu === "account" && "#EAEAEA"
												}`,
											}}
										>
											<ListItemIcon>
												{activeMenu === "account" ? (
													<img src={ProfileCircle} alt="Account&Login" />
												) : (
													<img src={ProfileCircle} alt="Account&Login" />
												)}
											</ListItemIcon>
											<ListItemText>Account & Login</ListItemText>
											<ListItemIcon>
												{activeMenu === "account" && (
													<img src={Next} alt="active" />
												)}
											</ListItemIcon>
										</MenuItem>
										<Divider variant="fullWidth" component="li" />
										<MenuItem
											onClick={() => changeMenu("security")}
											sx={{
												padding: "10px 0 10px 14px",
												whiteSpace: "normal",
												overflowX: "auto",
												backgroundColor: `${
													activeMenu === "security" && "#EAEAEA"
												}`,
											}}
											disabled={isAdmin}
										>
											<ListItemIcon>
												{activeMenu === "security" ? (
													<img src={ActiveSecurity} alt="ActiveSecurity" />
												) : (
													<img src={Security} alt="Security" />
												)}
											</ListItemIcon>
											<ListItemText>Security</ListItemText>
											<ListItemIcon>
												{activeMenu === "security" && (
													<img src={Next} alt="active" />
												)}
											</ListItemIcon>
										</MenuItem>
										{/* {(user.isCollaborationActive && */}
										 {(user.authorities[0]?.name === "ROLE_USER" ||
											(user.authorities[0]?.name === "ROLE_ADMIN" &&
											adminSetUserId)) && (
										<>
											<Divider variant="fullWidth" component="li" />
											<MenuItem
												onClick={() => changeMenu("deviceSetup")}
												sx={{
													padding: "10px 0 10px 14px",
													whiteSpace: "normal",
													overflowX: "auto",
													backgroundColor: `${
														activeMenu === "deviceSetup" && "#EAEAEA"
													}`,
												}}
												// disabled={isDisabledOptions}
											>
												<ListItemIcon>
													{activeMenu === "deviceSetup" ? (
														<img src={ActiveDeviceSetup} alt="Device Setup" />
													) : (
														<img src={DeviceSetup} alt="Device Setup" />
													)}
												</ListItemIcon>
												<ListItemText>Device Setup</ListItemText>
												<ListItemIcon>
													{activeMenu === "deviceSetup" && (
														<img src={Next} alt="active" />
													)}
												</ListItemIcon>
											</MenuItem>
										</>
									)}
										<Divider variant="fullWidth" component="li" />
										<MenuItem
											onClick={() => changeMenu("plans")}
											sx={{
												padding: "10px 0 10px 14px",
												whiteSpace: "normal",
												overflowX: "auto",
												backgroundColor: `${
													activeMenu === "plans" && "#EAEAEA"
												}`,
											}}
											disabled={isAdmin}
										>
											<ListItemIcon>
												{activeMenu === "plans" ? (
													<img src={Dollar} alt="ActiveSunscription" />
												) : (
													<img src={Dollar} alt="plans" />
												)}
											</ListItemIcon>
											<ListItemText>Subscription</ListItemText>
											<ListItemIcon>
												{activeMenu === "plans" && (
													<img src={Next} alt="active" />
												)}
											</ListItemIcon>
										</MenuItem>
									</MenuList>
								</Box>

								{(user.authorities[0]?.name === "ROLE_USER" ||
									(isAdmin && isDisabledOptions)) && (
									<>
										<Grid item={true} xs={9} sx={{ my: 1 }}>
											<Typography
												sx={{
													fontStyle: "normal",
													fontWeight: "500",
													fontSize: "1.5rem",
													lineHeight: "49px",
													color: "#000000",
												}}
											>
												Social
											</Typography>
										</Grid>
										<Box>
											<MenuList
												sx={{
													background: "#FFFFFF",
													boxShadow:
														"0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
													borderRadius: "12px",
													mt: 1,
												}}
											>
												<MenuItem
													onClick={() => changeMenu("socialProfileSetting")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${
															activeMenu === "socialProfileSetting" && "#EAEAEA"
														}`,
													}}
													disabled={isAdmin}
												>
													<ListItemIcon>
														{activeMenu === "socialProfileSetting" ? (
															<img
																src={EditSocialSetting}
																alt="SocialProfile"
															/>
														) : (
															<img
																src={EditSocialSetting}
																alt="SocialProfile"
															/>
														)}
													</ListItemIcon>
													<ListItemText>Social Profile</ListItemText>
													<ListItemIcon>
														{activeMenu === "socialProfileSetting" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
												<Divider variant="fullWidth" component="li" />
												<MenuItem
													onClick={() => changeMenu("privacy")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${
															activeMenu === "privacy" && "#EAEAEA"
														}`,
													}}
													disabled={isAdmin}
												>
													<ListItemIcon>
														{activeMenu === "privacy" ? (
															<img src={Privacy} alt="Privacy" />
														) : (
															<img src={Privacy} alt="Privacy" />
														)}
													</ListItemIcon>
													<ListItemText>Privacy</ListItemText>
													<ListItemIcon>
														{activeMenu === "privacy" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
												<Divider variant="fullWidth" component="li" />
												{/* Hiding collaboaration content for social-site launch*/}
												{/* {user.isCollaborationActive === true ?  */}
												{(
													<>
														<MenuItem
															onClick={() => changeMenu("gigs")}
															sx={{
																padding: "10px 0 10px 14px",
																whiteSpace: "normal",
																overflowX: "auto",
																backgroundColor: `${
																	activeMenu === "gigs" && "#EAEAEA"
																}`,
															}}
															disabled={isAdmin}
														>
															<ListItemIcon>
																{activeMenu === "gigs" ? (
																	<img src={Cards} alt="Gigs" />
																) : (
																	<img src={Cards} alt="Gigs" />
																)}
															</ListItemIcon>
															<ListItemText>Gigs & Collaboration</ListItemText>
															<ListItemIcon>
																{activeMenu === "gigs" && (
																	<img src={Next} alt="active" />
																)}
															</ListItemIcon>
														</MenuItem>
														<Divider variant="fullWidth" component="li" />
													</>
												) 
												// : (
												// 	<></>
												// )
											}
												<MenuItem
													onClick={() => changeMenu("notificationSettings")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${
															activeMenu === "notificationSettings" && "#EAEAEA"
														}`,
													}}
													disabled={isAdmin}
												>
													<ListItemIcon>
														{activeMenu === "notificationSettings" ? (
															<img src={Notification} alt="Notifications" />
														) : (
															<img src={Notification} alt="Notifications" />
														)}
													</ListItemIcon>
													<ListItemText>Notifications</ListItemText>
													<ListItemIcon>
														{activeMenu === "notificationSettings" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
												<Divider variant="fullWidth" component="li" />
												<MenuItem
													onClick={() => changeMenu("blockedUsers")}
													sx={{
														padding: "10px 0 10px 14px",
														whiteSpace: "normal",
														overflowX: "auto",
														backgroundColor: `${
															activeMenu === "blockedUser" && "#EAEAEA"
														}`,
													}}
													disabled={isAdmin}
												>
													<ListItemIcon>
														{activeMenu === "blockedUsers" ? (
															<img src={Profile} alt="BlockedUser" />
														) : (
															<img src={Profile} alt="BlockedUser" />
														)}
													</ListItemIcon>
													<ListItemText>Blocked user list</ListItemText>
													<ListItemIcon>
														{activeMenu === "blockedUsers" && (
															<img src={Next} alt="active" />
														)}
													</ListItemIcon>
												</MenuItem>
											</MenuList>
										</Box>

										{/* Hiding for social-launch */}
										{/* <Grid item={true} xs={9} sx={{ my: 1 }}>
												<Typography
													sx={{
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "1.5rem",
														lineHeight: "49px",
														color: "#000000",
													}}
												>
													Payments
												</Typography>
											</Grid>
											<Box>
												<MenuList
													sx={{
														background: "#FFFFFF",
														boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
														borderRadius: "12px",
														mt: 1,
													}}
												>
													<MenuItem
														onClick={() => changeMenu("plans")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "plans" && "#EAEAEA"
																}`,
														}}

													>
														<ListItemIcon>
															{activeMenu === "plans" ? (
																<img src={Card} alt="Payment" />
															) : (
																<img src={Card} alt="Payment" />
															)}
														</ListItemIcon>
														<ListItemText>Payment</ListItemText>
														<ListItemIcon>
															{activeMenu === "plans" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("subscriptionPayment")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "subscriptionPayment" && "#EAEAEA"
																}`,
														}}

													>
														<ListItemIcon>
															{activeMenu === "subscriptionPayment" ? (
																<img src={Dollar} alt="Subscription" />
															) : (
																<img src={Dollar} alt="Subscription" />
															)}
														</ListItemIcon>
														<ListItemText>Subscription & Invoices</ListItemText>
														<ListItemIcon>
															{activeMenu === "subscriptionPayment" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
												</MenuList>
											</Box>*/}
									</>
								)}
							</Grid>
							<Grid
								item
								xs={9}
								className={`${
									activeMenu === "socialProfileSetting" ||
									activeMenu === "subscriptionPayment" ||
									activeMenu === "deviceSetup" ||
									activeMenu === "plans"
										? "centerPanelSocial"
										: "centerPanelWidth"
								}`}
							>
								<Outlet />
							</Grid>
							{user.authorities[0].name === "ROLE_USER" && (
								<Grid
									item
									className={`hideInTabPro rightPanelWidth`}
									hidden={
										activeMenu === "socialProfileSetting" ||
										activeMenu === "subscriptionPayment" ||
										activeMenu === "deviceSetup" ||
										activeMenu === "plans"
									}
								>
									{activeMenu !== "socialProfileSetting" && <MyActivitiesSidePanel />}
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{showCompatibilityPopup && (
				<CompatibilityPopup
					open={showCompatibilityPopup}
					onClose={() => setShowCompatibilityPopup(false)}
				/>
			)}

			<AddDeviceDialog
				open={open}
				onSuccess={handleSuccess}
				onClose={handleDialogClose}
			/>
		</>
	);
};

export default SettingsMenu;

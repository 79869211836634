import {
	Avatar,
	Button,
	ButtonBase,
	Chip,
	Grid,
	IconButton,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import Calender from "../../images/calendar-2.svg";
import Ticket from "../../images/ticket.svg";
import Location from "../../images/location.svg";
import calendarIcon from "../../images/calendar.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { format } from "date-fns";


import MicrophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Xylophone_Marimba.svg';
import SaxophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Sousaphone.svg';
import AccordionG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Accordian.svg';
import GuitarG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Acoustic Guitar.svg';
import Alto_SaxophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Alto Sax.svg';
import BagpipesG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Bagpipes.svg';
import BanjoG from "../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Banjo.svg";
import BaritoneEG from "../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Baritone_Euphonium.svg";
import BaritoneSG from "../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Baritone Sax.svg";
import BClarinetG from "../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Bass Clarinet.svg";
import BassGuitarG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Bass Guitar.svg';
import BassoonG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Bassoon.svg';
import BellsG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Bells.svg';
import CelloG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Cello.svg';
import ClarinetG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Clarinet.svg';
import ContrabassoonG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Contrabassoon.svg';
import CornetG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Cornet.svg';
import DrumsG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Drums.svg';
import DulcimerG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Dulcimer.svg';
import EGuitarG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Electric Guitar.svg';
import English_HornG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_English Horn.svg';
import FlugelhornG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Flugelhorn.svg';
import FluteG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Flute.svg';
import French_HornG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_French Horn.svg';
import HarmonicaG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Harmonica.svg';
import HarpG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Harp.svg';
import KeyboardG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Keyboard.svg';
import MandolinG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Mandolin.svg';
import OboeG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Oboe.svg';
import OrganG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Organ.svg';
import PercussionG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Percussion.svg';
import PianoG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Piano.svg';
import PiccoloG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Piccolo.svg';
import Piccolo_TrumpetG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Piccolo Trumpet.svg';
import RecorderG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Recorder.svg';
import SitarG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Sitar.svg';
import Soprano_SaxophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Soprano Sax.svg';
import SousaphoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Sousaphone.svg';
import SynthesizerG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Synthesizer.svg';
import Tenor_SaxophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Tenor Sax.svg';
import TromboneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Trombone.svg';
import TrumpetG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Trumpet.svg';
import TubaG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Tuba.svg';
import UkuleleG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Ukulele.svg';
import Upright_BassG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Upright Bass.svg';
import ViolaG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Viola.svg';
import ViolinG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Violin.svg';
import XylophoneG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Xylophone_Marimba.svg';
import VoiceG from '../../images/grayInstrumentsIcons/Lutefish_Icons_Outlines_White_Voice.svg';
import DefaultIconG from '../../images/Instrument placeholder.svg';


const HorizontalCard = (props: any) => {
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [genresValue, setGenresValue] = useState([]);
	const [instrumentArr, setInstrumentArr] = useState([]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getGenres = () => {
		setGenresValue([]);
		if (props.type === "event" && props.eventData?.genres) {
			const prevGenres = props.eventData.genres;
			const resultArray = prevGenres
				.split(",")
				.map((item: any) => ({ title: item.trim() }));
			setGenresValue(resultArray);
		}
	};

	const getInstruments = () => {
		setInstrumentArr([]);
		if (props.type === "gig" && props.eventData?.masterInstruments) {
			const instruments = props.eventData.masterInstruments.map(
				(instrument: any) => instrument.name
			);
			setInstrumentArr(instruments);
		}
	};


	const instrumentIcons: { [key: string]: any } = {
		Accordion: AccordionG,
		Banjo: BanjoG,
		"Bass Guitar": BassGuitarG,
		Drums: DrumsG,
		Flute: FluteG,
		Guitar: GuitarG,
		Keyboard: KeyboardG,
		Microphone: MicrophoneG,
		Bagpipes: BagpipesG,
		Piano: PianoG,
		Saxophone: SaxophoneG,
		Trumpet: TrumpetG,
		Violin: ViolinG,
		"Acoustic Guitar": GuitarG,
		"Alto Saxophone": Alto_SaxophoneG,
		"Baritone/Euphonium" : BaritoneEG,
		"Baritone Saxophone" : BaritoneSG,
		"Bass Clarinet": BClarinetG,
		Bassoon : BassoonG,
		Bells : BellsG,
		Cello : CelloG,
		Clarinet : ClarinetG,
		Contrabassoon : ContrabassoonG,
		Cornet : CornetG,
		Dulcimer : DulcimerG,
		"Electric Guitar": EGuitarG,
		"English Horn" : English_HornG,
		Flugelhorn : FlugelhornG,
		"French Horn": French_HornG,
		Harmonica: HarmonicaG,
		Harp:HarpG,
		Mandolin : MandolinG,
		Oboe : OboeG,
		Organ : OrganG,
		Percussion : PercussionG,
		Piccolo : PiccoloG,
		"Piccolo Trumpet": Piccolo_TrumpetG,
		Recorder : RecorderG,
		Sitar : SitarG,
		"Soprano Saxophone": Soprano_SaxophoneG,
		Sousaphone: SousaphoneG,
		Synthesizer: SynthesizerG,
		"Tenor Saxophone": Tenor_SaxophoneG,
		Trombone : TromboneG,
		Tuba : TubaG,
		Ukulele : UkuleleG,
		"Upright Bass": Upright_BassG,
		Viola: ViolaG,
	    "Xylophone/Marimba" :XylophoneG,
		"Voice Alto" : VoiceG,
		"Voice Baritone": VoiceG,
		 "Voice Bass" : VoiceG,
		 "Voice Mezzo Soprano": VoiceG,
		 "Voice Soprano": VoiceG,
		 "Voice Tenor": VoiceG
	
	  };
	  
	  const getIcons = (instrument: string) => {
		const iconSrc = instrumentIcons[instrument];
		return iconSrc ? (
		  <img
			src={iconSrc}
			style={{ height: "24px", width: "24px" }}
			alt={instrument}
		  />
		) : (
		  <img
			src={DefaultIconG}
			style={{ height: "24px", width: "24px" }}
			alt="DefaultIcon"
		  />
		);
	  };
	  
	
	const viewDetails = () => {
		if (props.type === "event") {
			navigate("/events/eventDetails/" + props.eventData.id);
		} else if (props.type === "gig") {
			navigate("/gigs/gigDetails/" + props.eventData.id);
		}
	};



	const String = `${props.eventData?.venue} ${props.eventData.cityCountry}`;
	const concatenatedString = String.replace(/#/g, '');

	const utcTime: string = props.eventData.startDateTime;
	const date = new Date(utcTime);
	const start = new Date(props.eventData.startDateTime);
	const end = new Date(props.eventData.endDateTime);
	const current = new Date();
	const finalFormattedDate = format(date, "MMM dd, yyyy - hh:mm a");

	useEffect(() => {
		if (props.type === "event") {
			//getGenres();
		} else {
			getInstruments();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps


	}, []);


	return (
		<Paper
			sx={{
				p: 2,
				margin: "auto",
				flexGrow: 1,
				height: "150px",
				borderRadius: "12px",
				marginLeft: "-8px",
				//Please do not remove this code : as this box need boxshadow as per figma!!!
				background: "#fff",
				border: "none",
				boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
			}}
			className="horizonCardWidth"
		>
			<Grid container xs={12}>
				<Grid item xs={1.8}>
					<ButtonBase sx={{ width: 128, height: 128 }}>
						<img
							alt="complex"
							src={
								props.type === "gig"
									? props.eventData.thumbnailUrl && props.eventData.thumbnailUrl !== ""
										? process.env.REACT_APP_PICTURE_URL +
										props.eventData.thumbnailUrl
										: process.env.REACT_APP_PICTURE_URL +
										"/defaultimages/gig-thumbnail.svg"
									: props.type === "event"
										? props.eventData.thumbnailUrl && props.eventData.thumbnailUrl !== ""
											? process.env.REACT_APP_PICTURE_URL +
											props.eventData.thumbnailUrl
											: process.env.REACT_APP_PICTURE_URL +
											"/defaultimages/event-thumbnail.svg"
										: ""
							}
							style={{ width: "120px", height: "120px", borderRadius: "12px" }}
						/>
					</ButtonBase>
				</Grid>
				<Grid item xs={10.2} className="horiCenterContent">
					<Grid item container>
						<Grid item xs={6} display={"flex"} sx={{ marginTop: "0.25rem" }}>
							<Typography
								gutterBottom
								sx={{
									fontFamily: "Lexend",
									fontSize: "1.125rem",
									fontStyle: "normal",
									fontWeight: 600,
									lineHeight: "normal",
									marginLeft: "0.5rem",
									marginTop: "0.25rem",
								}}
							>
								{props.eventData.title}
							</Typography>
							{current >= start && current <= end && (
								<Chip
									label={
										props.eventData.type === "GIG" ? "Live Gig" : "Live Event"
									}
									style={{
										marginBottom: "5px",
										marginLeft: "10px",
										color: "white",
										backgroundColor: "#196AEB",
									}}
								/>
							)}
						</Grid>
						<Grid item xs={3} sx={{ textAlign: "end" }}>
							<Button
								variant="text"
								startIcon={<img src={Ticket} alt="Tickets" />}
							>
								<span style={{ color: "#888888" }}>
									Tickets: ${props.eventData.cost}
								</span>
							</Button>
						</Grid>
						<Grid item xs={2} sx={{ textAlign: "end" }}>
							<Button
								sx={{ width: "116px", height: "30px" }}
								variant="contained"
								startIcon={
									<img
										src={Calender}
										alt="View"
										style={{ width: "18px", height: "18px" }}
									/>
								}
								onClick={viewDetails}
							>
								<Typography
									component="span"
									sx={{
										fontSize: "16px",
										fontWeight: 400,
										lineHeight: "20px",
										color: "#FFFFFF",
									}}
								>
									View
								</Typography>
							</Button>
						</Grid>
					</Grid>

					<Grid item xs container direction="column">
						<Grid item>
							<Typography variant="body2" gutterBottom>
								<IconButton color="primary" size="small">
									<img
										src={Location}
										alt="Location"
										style={{ height: "24px", width: "24px" }}
									/>
								</IconButton>
								<Typography
									sx={{
										fontFamily: "Lexend",
										fontStyle: "normal",
										fontWeight: "400",
										fontSize: "14px",
										lineHeight: "20px",
										display: "contents",
										color: "#5B5B65",
									}}
								>
									{concatenatedString &&
										concatenatedString.length > 40 &&
										props.eventData.venue !== null
										? props.eventData.cityCountry === null
											? props.eventData.venue.substring(0, 40) + "..."
											: concatenatedString.substring(0, 40) + "..."
										: concatenatedString.substring(0, 40) + "..."}
								</Typography>
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								sx={{ marginLeft: "0.5rem" }}
							>
								{props.type === "event"
									? props.eventData.description !== null &&
										props.eventData.description !== "null"
										? props.eventData.description.length > 40
											? props.eventData.description.substring(0, 40) + "..."
											: props.eventData.description
										: ""
									: props.eventData.requirements &&
										props.eventData.requirements !== "undefined"
										? props.eventData.requirements.length > 40
											? props.eventData.requirements.substring(0, 40) + "..."
											: props.eventData.requirements
										: ""}
							</Typography>
						</Grid>
					</Grid>
					<Grid container xs={12} display={"flex"} flexDirection={"row"}>
						<Grid item={true} xs={6}>
							<IconButton color="primary" size="small">
								<img
									src={calendarIcon}
									alt="Date"
									style={{ height: "24px", width: "24px" }}
								/>
							</IconButton>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontStyle: "normal",
									fontWeight: "500",
									fontSize: "18px",
									lineHeight: "22.5px",
									display: "contents",
								}}
							>
								{" "}
								{finalFormattedDate}
							</Typography>
						</Grid>
						<Grid item={true} xs={6}>
							{props.type === "gig" ? (
								<Grid item={true} display={"flex"} justifyContent={"flex-end"}>
									<Stack direction="row" spacing={1}>
										{props.eventData?.instrumentArray
											?.slice(0, 1)
											.map((name: string) => (
												<Chip
												key={name}
												avatar={getIcons(name)}
												label={name}
											/>
											))}
										{props.eventData?.instrumentArray?.length > 1 && (
											<Chip label={`+${props.eventData?.instrumentArray.length - 1}`} />
										)}
									</Stack>
								</Grid>
							) : (
								<Grid item={true} display={"flex"} justifyContent={"flex-end"}>
									<Stack
										direction="row"
										spacing={1}
										sx={{ flexWrap: "wrap", rowGap: 1 }}
									>
										{props.eventData.genresArray
											.slice(0, 1)
											.map((genre: any, index: any) => (
												<Chip key={index} label={genre} />
											))}
										{props.eventData.genresArray.length > 1 && (
											<Chip
												label={`+${props.eventData.genresArray.length - 1}`}
											/>
										)}
									</Stack>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default HorizontalCard;

import { Grid, IconButton } from "@mui/material"
import CustomAudioPlayer from "../../pages/CustomAudioPlayer"
import CancelIcon from '../../images/Posts/close-circle.svg';
import Edit from '../../images/Posts/edit.svg';
import ReplaceIcon from '../../images/Posts/Replace.svg'
import Delete from '../../images/Posts/delete.svg';

const MusicView = ({ music, handleDeleteMusic, handleEditMusic }: any) => {

    return (
        <Grid container>
            <Grid item xs={10}>
                {/* <CustomAudioPlayer audioUrl={music} displayDirectly={true} activeMenu="" /> */}
                <audio   crossOrigin="anonymous" controls controlsList="nodownload noremoteplayback noplaybackrate" >
				<source src={music} type="audio/mpeg" />
			</audio>
            </Grid>
            <Grid item xs={1} sx={{ alignSelf: 'center' }}>
                <IconButton id="photo-Cancel"
                    aria-label="Edit" size='small'
                    onClick={() => handleDeleteMusic()}
                >
                    <img src={Delete} alt="Edit" style={{ height: '24px', width: '24px' }} />
                </IconButton>
            </Grid>
            <Grid item xs={1} sx={{ alignSelf: 'center' }}>
                <IconButton id="photo-Cancel"
                    aria-label="Edit" size='small'
                    onClick={() => handleEditMusic()}
                >
                    <img src={ReplaceIcon} alt="Edit" style={{ height: '24px', width: '24px' }} />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default MusicView
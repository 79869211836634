import { useState, useEffect, useCallback, useRef } from 'react';

const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const useDebouncedFields = (initialState: Record<string, string | null>, delay: number = 500) => {
  const [fields, setFields] = useState(initialState);

  const debouncedSetField = useCallback(
    debounce((field: string, value: string | null) => {
      setFields((prev) => ({ ...prev, [field]: value }));
    }, delay),
    [delay]
  );

  const handleFieldChange = useCallback(
    (field: string) => (event: any) => {
      const newValue = event.target.value;
      debouncedSetField(field, newValue);
    },
    [debouncedSetField]
  );

  return { fields, handleFieldChange };
};

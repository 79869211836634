import { createContext, useContext, useEffect, useRef, useState } from "react";
import { format } from "date-fns";

export const ContentModerationFilterContext = createContext<any>(null);

export const ContentModerationFilterProvider = ({ children }: any) => {
	const token = localStorage.getItem("sessionId");
	const [updatePendingCount, setUpdatePendingCount] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(20);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);

	const [postType, setPostType] = useState("");
	const [violationType, setViolationType] = useState("")
	const [orderByField, setOrderByField] = useState("");

	const [searchUserName, setSearchUserName] = useState("");
	const inputRef = useRef(null);

	const [contentModerationFilter, setContentModerationFilter] = useState<any>({
		isGlobalSearch: true,
		genres: [],
		cities: "",
		firstName: "",
		lastName: "",
		nickName: "",
		instrument: [],
		isOpenForCollaboration: false,
		acceptGigs: false,
		email: null,
		moderationStatus: null
	});

	interface Data {
		name: string;
		reportDate: string;
		reportType: string;
		count: string;
		status: string;
		actions: string;
	}

	const [isFilterActive, setIsFilterActive] = useState(false);
	const [rowData, setRowData] = useState<Data[]>([]);
	const resetUserModerationFilter: any = () => {
		setContentModerationFilter({
			isGlobalSearch: true,
			genres: [],
			cities: "",
			firstName: "",
			lastName: "",
			nickName: "",
			instrument: [],
			isOpenForCollaboration: false,
			acceptGigs: false,
			email: null,
			moderationStatus: null
		});
	};

	useEffect(() => {
		if (
			contentModerationFilter.genres.length > 0 ||
			contentModerationFilter.cities.length > 0 ||
			contentModerationFilter.instrument.length > 0 ||
			contentModerationFilter.firstName.length > 0 ||
			contentModerationFilter.lastName.length > 0 ||
			contentModerationFilter.nickName.length > 0 ||
			contentModerationFilter.isOpenForCollaboration ||
			contentModerationFilter.acceptGigs  ||
			contentModerationFilter.email !== null ||
			contentModerationFilter.moderationStatus !== null
		) {
			setIsFilterActive(true);
		} else {
			setIsFilterActive(false);
		}
	}, [contentModerationFilter]);

	const getAllUsers = (userObj: any) => {
		try {

			const nextPage = pageNumber + 1;
			const requestBody = {
				userId: userObj?.userId ? userObj?.userId : null,
				globalSearch: contentModerationFilter.isGlobalSearch,
				firstName: contentModerationFilter.firstName,
				lastName: contentModerationFilter.lastName,
				nickname: contentModerationFilter.nickName,
				location: contentModerationFilter.cities,
				genres: contentModerationFilter.genres,
				instruments: contentModerationFilter.instrument,
				openForCollab: contentModerationFilter.isOpenForCollaboration,
				openForGigs: contentModerationFilter.acceptGigs,
				moderationStatus: contentModerationFilter.moderationStatus,
				email: contentModerationFilter.email,
			};

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/search?forType=Content&violationType=${violationType}&moderationStatus=&postType=${postType}&userAccountStatus=&orderBy=${orderByField}&page=${page}&size=${rowsPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {

						const updatedRows = data.map((item: any) => ({
							name: `${item.offenderUserProfile.user.firstName} ${item.offenderUserProfile.user.lastName}`, // Use the 'id' property from the API response
							userProfileId: item.offenderUserProfile.id,
							nickName:  item.offenderUserProfile.nickname,
							reportDate: item.reportedAt, // Use the 'status' property from the API response
							reportType: item.reportType, // Combine first and last name
							count: item.count.totalUserReporting, // Hardcoded value
							status: item.status, // Use the 'activationDate' property from the API response
							actions: "Edit", // Hardcoded value
							postId: item.postId,
							totalIssueCount: item.count.totalIssueCount,
							totalUserReporting: item.count.totalUserReporting,
							contentHide: item.content.isHidden,
							offenderUserProfileId: item.offenderUserProfile.id,
							contentId: item.content.id,
							userAccountStatus: item.offenderUserProfile.userAccountStatus,
							violationsType: item.violationPerCount,
							allUserCount: item.allUserCount,
						}));

						setRowData((prevRowData) => [...prevRowData, ...updatedRows]);
						setPageNumber(nextPage);
					}
				})
				.catch((err) => {
					setHasMore(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	const handleUsersReset = (userObj: any) => {
		try {
			setHasMore(true);
			setPageNumber(0);
			setPage(0);
			const firstName = searchUserName || "";
			const lastName = searchUserName || "";
			const nickName = searchUserName || "";

			const requestBody = {
				userId: userObj?.userId ? userObj?.userId : null,
				globalSearch: contentModerationFilter.isGlobalSearch,
				firstName: firstName,
				lastName: lastName,
				nickname: nickName,
				location: contentModerationFilter.cities,
				genres: contentModerationFilter.genres,
				instruments: contentModerationFilter.instrument,
				openForCollab: contentModerationFilter.isOpenForCollaboration,
				openForGigs: contentModerationFilter.acceptGigs,
				email: contentModerationFilter.email,
				moderationStatus: contentModerationFilter.moderationStatus
			};

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/search?forType=Content&violationType=${violationType}&moderationStatus=&postType=${postType}&userAccountStatus=&orderBy=${orderByField}&page=0&size=${rowsPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(requestBody),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
						setRowData([]);
					} else {
						const updatedRows = data.map((item: any) => ({
							name: `${item.offenderUserProfile.user.firstName} ${item.offenderUserProfile.user.lastName}`, // Use the 'id' property from the API response
							reportDate: item.reportedAt, // Use the 'status' property from the API response
							nickName:  item.offenderUserProfile.nickname,
							reportType: item.reportType, // Combine first and last name
							count: item.count.totalUserReporting, // Hardcoded value
							status: item.status, // Use the 'activationDate' property from the API response
							actions: "Edit", // Hardcoded value
							postId: item.postId,
							totalIssueCount: item.count.totalIssueCount,
							totalUserReporting: item.count.totalUserReporting,
							contentHide: item.content.isHidden,
							offenderUserProfileId: item.offenderUserProfile.id,
							contentId: item.content.id,
							userAccountStatus: item.offenderUserProfile.userAccountStatus,
							violationsType: item.violationPerCount,
							profilePhoto: item.offenderUserProfile.profilePhotoUrl,
							myMusicId: item.content.myMusicId,
							profileTitle: item.offenderUserProfile.profileTitle,
							myMusicUrl: item.content.mediaUrl,
							musicTitle: item.content.contentMessage,
							allUserCount: item.allUserCount,
						}));

						setRowData(updatedRows);
					}
				})
				.catch((err) => {
					setHasMore(false);
				});
		} catch (error) {
			// setLoading(false);
		}
	};

	return (
		<ContentModerationFilterContext.Provider
			value={{
				contentModerationFilter,
				setContentModerationFilter,
				getAllUsers,
				isFilterActive,
				resetUserModerationFilter,
				rowData,
				setRowData,
				hasMore,
				handleUsersReset,
				postType,
				setPostType,
				violationType,
				setViolationType,
				orderByField,
				setOrderByField,
				searchUserName,
				setSearchUserName,
				inputRef,
				setPageNumber,
				updatePendingCount,
				setUpdatePendingCount,
				rowsPerPage,
				setRowsPerPage,
				page,
				setPage,
			}}>
			{children}
		</ContentModerationFilterContext.Provider>
	)
}

export function useContentModerationFilter() {
	return useContext(ContentModerationFilterContext);
}

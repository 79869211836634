import fetchIntercept from "fetch-intercept";
import User from "./user";

export const AuthInterceptor = () => {
	fetchIntercept.register({
		request: function (url, config) {
			return [url, config];
		},

		requestError: function (error) {
			return Promise.reject(error);
		},

		response: function (response) {
			if (response.status === 401) {
				User.emit("unauthorized", response);
			}
			return response;
		},

		responseError: function (error) {
			return Promise.reject(error);
		},
	});
};

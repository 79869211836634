import React from "react";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import CloseIcon from "../../../icons/CloseIcon";

import usePeopleContext from "../../../hooks/usePeopleContext/usePeopleContext";

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            height: "56px",
            background: "#000000",
            // borderBottom: "1px solid #E4E7E9",
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "center",
            padding: "12px 12px",
            borderRadius: "8px",
            backgroundColor: "#093969"
        },
        text: {
            fontSize: "1rem",
            fontWeight: "bold",
            color: "#FFFFFF",
        },
        closeChatWindow: {
            cursor: "pointer",
            display: "flex",
            background: "transparent",
            border: "0",
            padding: "0.4em",
        },
    })
);

export default function PeopleWindowHeader() {
    const classes = useStyles();
    const { setIsPeopleWindowOpen } = usePeopleContext();

    return (
        <div className={classes.container}>
            <div className={classes.text}>People</div>
            <button
                className={classes.closeChatWindow}
                onClick={() => setIsPeopleWindowOpen(false)}
            >
                <CloseIcon />
            </button>
        </div>
    );
}

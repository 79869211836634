import { useState, useEffect } from "react";

type Location = string;

const useLocationAutocomplete = (searchString: string): Location[] => {
	const [options, setOptions] = useState<Location[]>([]);
	const token = localStorage.getItem("sessionId");

	useEffect(() => {
		const fetchData = () => {
			if (searchString.trim() === "") {
				setOptions([]);
				return;
			}

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/public/get/cities?searchString=${searchString}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((response) => {
					if (!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}
					return response.json();
				})
				.then((data) => {
					setOptions(data);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		};

		const debounceTimeout = setTimeout(() => {
			fetchData();
		}, 300);

		return () => {
			clearTimeout(debounceTimeout);
			setOptions([]);
		};
	}, [searchString, token]);

	return options;
};

export default useLocationAutocomplete;

import React from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const TimePickerCmp = (props: any) => {
	const theme = useTheme();
	return <TimePicker
		maxTime={props.maxTime}
		minTime={props.minTime}
		value={props.endDate}
		onChange={(newValue: Date | null) => {
			props.setEndDate(newValue);
		}}
		onError={(reason, value) => {
			if (reason) {
				props.setErrorDate(true);
			} else {
				props.setErrorDate(false);
			}
		}}
		renderInput={(params) => <TextField {...params}
			sx={{
				input: { color: 'customColor.black', fontSize: theme.typography.subtitle1.fontSize,
						fontWeight: theme.typography.subtitle1.fontWeight, 
					},
				"&.MuiTextField-root": {
					backgroundColor: "customColor.gray1",
					width: "100%",
				},
			}} />}
	/>
};
export default TimePickerCmp;


import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormHelperText,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography,
	Box,
	Grid
} from "@mui/material";
import i18n from "../l10n/strings.json";
import SuccessMark from "../images/tick-circle.svg";
import CloseIcon from "../images/close.svg";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";

const EditEmail = (props: any) => {
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();

	const [dialogueMode, setDialogueMode] = useState("Email");
	const [newEmail, setNewEmail] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [newEmailOTP, setNewEmailOTP] = useState("");
	const [dirty, setDirty] = useState(false);
	const [iserror, setError] = useState(false);
	const [isEmailerror, setEmailError] = useState(false);
	const [isPassworderror, setPasswordError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	useEffect(() => {
		if (props.emailOpen) {
			setDialogueMode("Email");
			setNewEmail("");
			setPassword("");
			setNewEmailOTP("");
			setDirty(false);
			setError(false);
			setEmailError(false);
			setPasswordError(false);
		}
	}, [props.emailOpen]);

	const handleClose = () => {
		if (dialogueMode === "NewEmailSuccess") {
			localStorage.removeItem("sessionId");
			localStorage.removeItem("user");
			localStorage.removeItem("activeDevice");
			navigate("/login");
		} else {
			props.handleClose(false);
		}
	}
	const handleCancel = () => {
		setError(false);
		setEmailError(false);
		setPasswordError(false);
		props.handleClose(false);
	}
	const updateEmail = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/updateEmail`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				email: props.email,
				newEmail: newEmail,
				password: password,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((result) => {
				if (result.status >= 400) {
					if (result.title === "Email is already in use!") {
						setEmailError(true);
						setErrorMessage(i18n.settings.accountSetup.accountExists);
					} else if (result.title === "Incorrect password") {
						setPasswordError(true);
						setErrorMessage(i18n.settings.accountSetup.currentPasswordErrorMsg)
					}
					throw new Error();
				} else {
					setEmailError(false);
					setPasswordError(false);
					setDialogueMode("verifyCode");
				}
			})
			.catch((error) => {
				// setError(true);
			});
	};

	const emailOTP = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/EditEmailVerifyOtp`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					email: props.email,
					newEmail: newEmail,
					otp: parseInt(newEmailOTP),
				}),
			}
		)
			.then((response) => {
				if (response.status >= 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				setError(false);
				if (result.status === "OK") {
					setDialogueMode("NewEmailSuccess");
				}
				if (result.status === "BAD_REQUEST") {
					setError(true);
				}
			})
			.catch((error) => {
				setError(true);
			});
	};

	const handleResendOtp = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/updateEmail/resend-otp`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					email: props.email,
					newEmail: newEmail,
					otp: parseInt(newEmailOTP),
				}),
			}
		)
			.then((response) => {
				if (response.status >= 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				setError(false);
				if (result.status === "BAD_REQUEST") {
					setError(true);
				}
			})
			.catch((error) => {
				setError(true);
			});
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px"
			}}
			fullWidth
			maxWidth="sm"
			open={props.emailOpen}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					<IconButton
						aria-label="close dialogbox"
						onClick={handleClose}
						onMouseDown={(
							event: React.MouseEvent<HTMLButtonElement>
						) => event.preventDefault()}
						edge="end">
						<img
							src={CloseIcon}
							alt="close"
						/>
					</IconButton>
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography className="dialog-title-custom-size"
						sx={{
							fontWeight: 500,
							color: "#000000",
						}}
					>
						{i18n.settings.accountSetup.editEmailDialog}
					</Typography>
				</Box>
			</DialogTitle>
			{dialogueMode === "Email" ?
				<>
					<DialogContent
						sx={{
							padding: "0px 20px",
						}}
					>
						<Grid container>
							<Grid item={true} xs={12}>
								<Typography sx={{
									// fontSize: "1.125rem",
									color: "#000000",
									
									fontWeight: "500",
								}} id="outlined-weight-helper-text"
								>
									{i18n.dialog.verifyAccount}
								</Typography>
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText
									sx={{
										fontSize: "1rem",
										color: "#7A7985",
									}}
									id="outlined-weight-helper-text"
								>
									{i18n.dialog.newEmail}
								</FormHelperText>
								<TextField
									fullWidth
									sx={{
										background: "#F8F8F8",
										marginBottom: "5px"
									}}
									value={newEmail}
									onChange={(e) => {
										setEmailError(false);
										setNewEmail(e.target.value);
									}
									}
									variant="outlined"
								/>
								{
									isEmailerror && <Typography className="typography-field-custom-font-size" sx={{
										color: "#FD4B24",
										textAlign: "right"
									}}>{errorMessage}</Typography>
								}
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText
									sx={{
										fontSize: "1rem",
										color: "#7A7985",
									}}
									id="outlined-weight-helper-text"
								>
									{i18n.dialog.accountPassword}
									<a
										href="/#/forgotPassword"
										style={{
											
											fontStyle: "normal",
											fontSize: "1rem",
											color: "#0A6BFE",
											float: "right"
										}}
									>
										{i18n.dialog.forgotPassword}
									</a>
								</FormHelperText>
								<OutlinedInput
									type={showPassword ? "text" : "password"}
									sx={{
										background: "#F8F8F8",
									}}
									fullWidth
									onChange={(e) => {
										setPasswordError(false);
										setPassword(e.target.value);
									}}
									onFocus={() => setDirty(true)}
									onCopy={(e) => (e.preventDefault())}
									onPaste={(e) => (e.preventDefault())}
									onCut={(e) => (e.preventDefault())}
									error={isPassworderror || (dirty && (password.length < 8 || password.length > 16))}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword((show) => !show)}
												onMouseDown={(
													event: React.MouseEvent<HTMLButtonElement>
												) => event.preventDefault()}
												edge="end"
											>
												{showPassword ? (
													<img src={VisibleOn} alt="eye" />
												) : (
													<img src={VisibleOff} alt="Eye Slash" />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
								{isPassworderror && <FormHelperText sx={{
									color: "#FD4B24",
									textAlign: "right"
								}}>{errorMessage}</FormHelperText>}
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Grid container
							columnSpacing={1}
							style={{
								display: "flex", flexDirection: "row", justifyContent: "flex-end",
							}}
						>
							<Grid item={true}>
								<Button
									sx={{ width: "120px" }}
									className="cancel-button"
									size="large"
									variant="outlined"
									onClick={handleCancel}
								>
									{i18n.dialog.cancel}
								</Button>
							</Grid>
							<Grid item={true}>
								<Button
									className="submit-button"
									size="large"
									variant="contained"
									sx={{ width: "120px" }}
									disabled={props.email === newEmail || newEmail === "" || password === "" || isEmailerror || isPassworderror}
									onClick={updateEmail}
								>
									{i18n.dialog.next}
								</Button>
							</Grid>
						</Grid>
					</DialogActions>
				</>
				: dialogueMode === "verifyCode" ?
					<>
						<DialogContent
							sx={{
								padding: "5px 20px",
							}}
						>
							<Grid container>
								<Grid item={true} xs={12}>
									<Typography sx={{
										fontSize: "1.125rem",
										color: "#000000",
										
										fontWeight: "500",
										paddingBottom: "10px"
									}} id="outlined-weight-helper-text"
									>
										{i18n.dialog.mailVerificationCodeMsg}
									</Typography>
								</Grid>
								<Grid item={true} xs={12}>
									<FormHelperText
										sx={{
											fontSize: "1rem",
											color: "#7A7985",
											display: "flex",
											flexWrap: "nowrap",
											flexDirection: "row",
											justifyContent: "space-between"
										}}
										id="outlined-weight-helper-text"
									>
										<Typography sx={{
											textAlign: "center",
										}} id="outlined-weight-helper-text"
										>
											{i18n.dialog.verificationCode}
										</Typography>
										<Button
											onClick={handleResendOtp}
											style={{
												padding: 0,
												
												fontStyle: "normal",
												fontSize: "1rem",
												color: "#0A6BFE",
												background: "transparent",
												border: "none",
												outline: "0",
												textDecoration: "underline",
											}}
										>
											{i18n.dialog.resendCode}
										</Button>
									</FormHelperText>
								</Grid>
								<Grid item={true} xs={12}>
									<TextField id="verify-code"
										fullWidth
										sx={{ background: "#F8F8F8", marginBottom: "5px" }}
										value={newEmailOTP}
										onChange={(e) => {
											setNewEmailOTP(e.target.value);
											setError(false);
										}}										
										onFocus={() => setDirty(true)}
										error={iserror || newEmailOTP.length > 6}
										variant="outlined"
									/>
									{
										((iserror && dirty) || newEmailOTP.length > 6) ?
											(<FormHelperText sx={{
												color: "#FD4B24",
												textAlign: "right"
											}}>
												{"Verification code does not match"}
											</FormHelperText>
											)
										: ""
									}
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid>
								<Button
									className="submit-button"
									size="large"
									variant="contained"
									sx={{ width: "135px" }}
									disabled={newEmailOTP.length === 0}
									onClick={emailOTP}
								>
									{i18n.dialog.next}
								</Button>
							</Grid>
						</DialogActions>
					</> : dialogueMode === "NewEmailSuccess" &&
					<>
						<DialogContent>
							<Grid>
								<img src={SuccessMark} height="64px" width="100%" alt="success-mark" />
								<Typography sx={{
									fontSize: "1.125rem",
									color: "#000000",
									
									fontWeight: "500",
									paddingBottom: "5px",
									textAlign: "center"
								}} id="outlined-weight-helper-text"
								>
									{i18n.dialog.newEmailVerification}
								</Typography>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Grid>
								<Button
									className="submit-button"
									size="large"
									variant="contained"
									sx={{ width: "135px" }}
									onClick={handleClose}
								>
									{i18n.dialog.next}
								</Button>
							</Grid>
						</DialogActions>
					</>

			}

		</Dialog>
	)
};
export default EditEmail;

import { useState, useEffect, useRef, SyntheticEvent } from "react";
import {
	Typography,
	Grid,
    Paper,
	Stack,
    Button,
    LinearProgress,
    Link
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import CenterCircularProgress from "../CenterCircularProgress";
import RightArrow from "../../images/arrow-right-orange.svg";
import GoldBadge from "../../images/Gold-badge.svg";
import CloudStorage from "../../images/cloud storage 2.svg";

import { DeviceInterface } from "../../types/types";
// contexts
import { useUser } from "../../context/UserContext";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		linearGradBtn: {
			width: "100%",
            // margin: "0.5em 0 !important",
            background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
            borderRadius: "18px !important",
		},
	})
);

const ShopRightSideBar = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [activeDevice, setActiveDevice] = useState<DeviceInterface | null>(null);
    const navigate = useNavigate();

    const getActiveDevice = async () => {
        setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/active-user-device`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setActiveDevice(data);
					// getActiveUsers();
				} else {
					setActiveDevice(null);
				}
			})
			.catch((err) => {
				setActiveDevice(null);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(false)
        getActiveDevice()
	}, []);

	if (loading) {
		return <CenterCircularProgress />;
	}

	return (
		<>
            {
                (activeDevice == null) ?
                    <>
                        {/* My Subscription section */}
                        <Paper sx={{padding: "2",  borderRadius: "12px !important", boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77) !important"}}>
                            <Typography variant="h3">
                                My Subscription
                            </Typography>

                            <Grid container p={1}>
                                <Grid item xs={4}>
                                    <img src={GoldBadge} alt="Badge" />
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2">STANDARD PLAN</Typography>

                                    <Typography variant="subtitle2">
                                        5GB Storage
                                        <LinearProgress
                                            variant="determinate"
                                            value={50}
                                            color="primary"
                                        />
                                        2.8 GB used
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid item>
                                    <Typography variant="body1" fontWeight="600">Subscription Start date:</Typography>
                                    <Typography variant="subtitle2">24/04/2023</Typography>
                                </Grid>

                                <Grid item marginTop={2}>
                                    <Typography variant="body1" fontWeight="600">Next Billing</Typography>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">24/04/2023</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body1">$200</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Subscription Details */}
                                <Grid item xs={12} p={1}>
                                    <Grid item marginY={1}>
                                        <Button
                                            className={classes.linearGradBtn}
                                            variant="contained"
                                            // onClick={() => navigate(`/mymusic`) }
                                            endIcon={<img src={RightArrow} alt="Buy Again" />}
                                            size="large"
                                        >
                                            <Typography>Upgrade</Typography>
                                        </Button>

                                    </Grid>

                                    <Grid item marginY={1}>
                                        <Button
                                            // className={classes.linearGradBtn}
                                            sx={{width: "100%"}}
                                            variant="outlined"
                                            // onClick={() => navigate(`/mymusic`) }
                                            // endIcon={<img src={RightArrow} alt="Buy Again" />}
                                            size="large"
                                        >
                                            <Typography>Cancel Subscription</Typography>
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                      

                      
                    </>
                :
                    <>
                        {/* My Subscription section */}
                        <Paper sx={{padding: 1,  borderRadius: "12px !important", boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77) !important"}}>
                            <Typography className="center-align" variant="h3">
                                Subscription
                            </Typography>
                            <Grid container xs={12} p={1} >
                                <Grid item xs={12} className="center-align">
                                    <img src={GoldBadge} alt="Badge" />
                                </Grid>
                                <Grid item xs={12} className="center-align" padding={2} >
                                    <Grid xs={10} >
                                        <Typography variant="h6" className="center-align" sx={{fontSize: "1rem", fontFamily: "Lexend", color: "#7A7985", fontWeight: 500}}>When you purchase audio box, you have to select one of the subscription. <br /> <br /> Subscription will give you access to conduct jam session upload and share your music and use your audio box. <br /> <br /> You can select subscription on device purchase screen</Typography>
                                    </Grid>
                                </Grid>
                                {/* </Grid> */}

                                {/* Subscription Details */}
                                <Grid item xs={12} p={1}>
                                    <Grid item marginY={1}>
                                        <Button
                                            // className={classes.linearGradBtn}
                                            sx={{width: "100%", fontFamily: "Lexend", fontSize: "18px !important"}}
                                            variant="outlined"
                                            onClick={() => navigate(`/plans`) }
                                            endIcon={<img src={RightArrow} alt="View Plans" />}
                                            size="large"
                                        >
                                            Buy Subscription
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </>
            }
		</>
	);
};

export default ShopRightSideBar;

import { useState, useMemo, useContext, createContext } from "react";
import PropTypes from "prop-types";
// services
// types
import type { ReactNode } from "react";

const defaultValue = {
	showAlert: false,
	alertStatus: false,
	alertMsg: "",
	updateAlertStatus: (show: boolean, isSuccess: boolean, msg: string) => { }
};

const AlertStatusContext = createContext(defaultValue);

export const useAlertStatus = () => (useContext(AlertStatusContext));

type Props = {
	children: ReactNode;
};

export const AlertStatusProvider = (props: Props) => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");

	const value = useMemo(() => ({
		showAlert: showAlert,
		alertStatus: alertStatus,
		alertMsg: alertMsg,
		updateAlertStatus: (show: boolean, isSuccess: boolean, msg: string) => {
			setAlertMsg(msg);
			setShowAlert(show);
			setAlertStatus(isSuccess);
		}
	}), [showAlert, alertStatus, alertMsg]);

	return (
		<AlertStatusContext.Provider value={value}>
			{props.children}
		</AlertStatusContext.Provider>
	);
};

AlertStatusProvider.propTypes = {
	children: PropTypes.node.isRequired
};

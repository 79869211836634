import { useState } from "react";
import { useParams } from "react-router-dom";
import {
	Typography,
	Divider,
	Grid,
	FormHelperText,
	TextField,
	Button,
	Select,
	MenuItem
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../../context/UserContext";
import i18n from "../../../l10n/strings.json";

import { useTheme } from "@mui/material/styles";
import { useAdminDevciceManagement } from "./useAdminDeviceManagement";
import { Manufacturer, MfgYears } from "./AdminConstants";

type RouteParams = {
	menuId: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			padding: "1% 17px",
			borderRadius: "15px",
			//boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		backBtn: {
			width: "100%",
			fontWeight: "500 !important",
			color: "#0273E6 !important",
		},
		root: {
			color: "white",
			// '& .MuiAutocomplete-tag': {
			//     backgroundColor: 'blue',
			//     color: 'white'
			// },
			"& .MuiSvgIcon-root": {
				color: "white",
			},
			"& .MuiAutocomplete-endAdornment": {
				"& .MuiSvgIcon-root": {
					color: "#000 !important",
				},
			},
			"& .MuiChip-root.MuiChip-filled": {
				backgroundColor: "#0273E6",
				fontFamily: "Lexend",
				fontWeight: "400",
				color: "white",
				"& svg.MuiChip-deleteIcon": {
					color: "white !important",
				},
				"& span.MuiAutocomplete-tag ": {
					color: "black !important",
					backgroundColor: "white !important",
				},
			},
		},
	})
);

const AdminDeviceManagementFilters = () => {
	const param = useParams<RouteParams>();
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const {
		deviceManagementFilter,
		setDeviceManagementFilter,
		isFilterActive,
		resetDeviceManagementFilter,
		manufacturer
	} = useAdminDevciceManagement()
debugger

	const theme = useTheme();
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [joinDate, setJoinDate] = useState<Date | null>(null);



	const updateSearchFilter = (field: string, value: any) => {
		switch (field) {
			case "deviceManufacturedBy":
				setDeviceManagementFilter({
					...deviceManagementFilter,
					deviceManufacturedBy: value,
					isGlobalSearch: false,
				});
				break;
			case "deviceManufacturedYear":

				setDeviceManagementFilter({
					...deviceManagementFilter,
					isGlobalSearch: false,
					deviceManufacturedYear: value
				});
				break;
			case "deviceVersion":
				setDeviceManagementFilter({
					...deviceManagementFilter,
					isGlobalSearch: false,
					deviceVersion: value
				});
				break;
			default:
				break;
		}
	};

	const clearFilter = () => {
		resetDeviceManagementFilter();
	};

	return (
		<>
			<Grid
				container
				margin={0.5}
				xs={12}
				sx={{ height: "fit-content", position: "fixed", width: "288px" }}
				className={`${classes.shadowedBox}`}
			>
				<Grid item xs={12}>
					<Typography
						variant="h5"
						sx={{
							textAlign: "center",
							fontWeight: "500 !important",
							fontSize: "24px !important",
						}}
					>
						Filters
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<FormHelperText className="jamsession-label">
						{i18n.deviceManagement.filters.manufacturedByTitle}
					</FormHelperText>


					<Select
						labelId="demo-simple-select-label"
						value={deviceManagementFilter.deviceManufacturedBy}
						onChange={(event: any) => updateSearchFilter("deviceManufacturedBy", event.target.value)}
						sx={{
							backgroundColor: "customColor.gray1",
							color: "customColor.black",
							width: "100%",
							fontSize: "1rem",
							fontWeight: "500",
							lineHeight: "20px",
						}}
						MenuProps={{
							className: "newEvent",
						}}
					>
						{manufacturer.map(({ manufacturerName, id }:Manufacturer) => (
							<MenuItem key={id} value={id}>
								{manufacturerName}
							</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid item xs={12}>
					<FormHelperText className="jamsession-label">
						{i18n.deviceManagement.filters.manufacturedYearTitle}
					</FormHelperText>
					<Select
						labelId="demo-simple-select-label"
						value={deviceManagementFilter.deviceManufacturedYear}
						onChange={(event: any) => updateSearchFilter("deviceManufacturedYear", event.target.value)}
						sx={{
							backgroundColor: "customColor.gray1",
							color: "customColor.black",
							width: "100%",
							fontSize: "1rem",
							fontWeight: "500",
							lineHeight: "20px",
						}}
						MenuProps={{
							className: "newEvent",
						}}
					>
						{MfgYears.map((duration: any) => (
							<MenuItem key={duration} value={duration}>
								{duration}
							</MenuItem>
						))}
					</Select>
				</Grid>

				<Grid item xs={12} marginBottom={2}>
					<FormHelperText className="jamsession-label">
						{i18n.deviceManagement.filters.deviceVersionTitle}
					</FormHelperText>
					<TextField
						inputProps={{
							style: {
								background: "#F8F8F8",

								fontStyle: "normal",
								fontSize: "1rem",
								lineHeight: "25px",
								fontWeight: 900,
								color: "#000000",
								padding: "15px",
							},
						}}
						fullWidth
						variant="outlined"
						value={deviceManagementFilter.deviceVersion}
						onChange={(event) =>
							updateSearchFilter("deviceVersion", event.target.value)
						}
						size="small"
					></TextField>

				</Grid>


				{isFilterActive && (
					<>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid container xs={12} justifyContent="center" marginY={2}>
							<Button
								variant="text"
								sx={{ border: "2px solid #0273E6", color: "#0273E6" }}
								size="small"
								onClick={() => clearFilter()}
							>
								<Typography variant="h6">Clear Filter</Typography>
							</Button>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default AdminDeviceManagementFilters;

import { createContext, useState, useContext, useEffect } from "react";

interface SellingPlan {
    selling_plan_id: number;
    option: string; 
}

type UserActiveSubscriptionContextType = {
    activeSubscription: Array<Object>,
    purchasedSubscriptions: Array<Object>,
    isDeviceAvailable: Boolean,
    isSubscribed: Boolean,
	updateUserActiveSubscription: any,
    deviceDetails: any,
    sealSubscriptionSellingPlans: SellingPlan[],
    invoiceMagicLink: string
};

export const UserActiveSubscriptionContext = createContext<UserActiveSubscriptionContextType>(null!);

export const UserActiveSubscriptionProvider = ({ children }: any) => {
    const [activeSubscription, setActiveSubscription] = useState([]);
    const [purchasedSubscriptions, setPurchasedSubscriptions] = useState([]);
    const [isDeviceAvailable, setIsDeviceAvailable] = useState<Boolean>(false);
    const [isSubscribed, setIsSubscribed] = useState<Boolean>(false);
    const [deviceDetails, setDeviceDetails] = useState<Boolean>(false);
    const [sealSubscriptionSellingPlans, setSealSubscriptionSellingPlans] = useState<SellingPlan[]>([]);
    const [invoiceMagicLink, setInvoiceMagicLink] = useState('');

    const token = localStorage.getItem("sessionId");   	

    const updateUserActiveSubscription:any = () => {
        getUserActiveSubscription();
    }

    useEffect(() => {
        getUserActiveSubscription();
       // getSealSubSellingPlans();     
    }, [])
    
    const getUserActiveSubscription = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/active-user-subscriptions`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
        .then((res) => {
            if (res.status > 400) {
                throw new Error("api_error");
            } else {
                return res.json();
            }
        })
        .then((data) => {
            if (data && data !== null && data.length > 0) {
                let checkDevice = data.find((plan:any) => plan.masterSubscriptionPlan.type === "Electronics" && plan.masterSubscriptionPlan.status)
                if(checkDevice != undefined && checkDevice){
                    setDeviceDetails(checkDevice);
                    setIsDeviceAvailable(true);
                }

                let checkSubscribed = data.find((plan:any) => plan.masterSubscriptionPlan.type === "Software" && plan.masterSubscriptionPlan.status)
                if(checkSubscribed != undefined && checkSubscribed){
                    setActiveSubscription(checkSubscribed);
                    setIsSubscribed(true);
                }

                setPurchasedSubscriptions(data);
            }
        })
        .catch((err) => {
            // setActiveDevice(null);
        })
	};

    const getSealSubSellingPlans = async () => {
		const url = `${process.env.REACT_APP_BASE_URL}/api/public/subscription-rules`;
		try {
			const resp = await fetch(url, {
				method: "GET",
				headers: {
					"Content-Type": "application/json"
				}
			});
			const data = await resp.json();
			if (data.payload) {
				const { selling_plans } = data.payload?.subscription_rules[0];
				setSealSubscriptionSellingPlans(selling_plans);
			}
		} catch (e) {
			throw new Error("api_error");
		}
	}

	return (
		<UserActiveSubscriptionContext.Provider
			value={{
				activeSubscription,
                purchasedSubscriptions,
                isDeviceAvailable,
                isSubscribed,
                updateUserActiveSubscription,
                deviceDetails,
                sealSubscriptionSellingPlans,
                invoiceMagicLink
			}}
		>
			{children}
		</UserActiveSubscriptionContext.Provider>
	);
};

// Custom hook to consume the context
export function useActiveSubscription() {
    return useContext(UserActiveSubscriptionContext);
}
import { useContext } from "react";
import { PeopleContext } from "../../components/PeopleProvider";

export default function usePeopleContext() {
    const context = useContext(PeopleContext);
    if (!context) {
        throw new Error("useVideoContext must be used within a VideoProvider");
    }
    return context as any;
}

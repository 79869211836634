import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import Unmute from "../../../images/microphone-2Mic-Outlined.svg";
import Mute from "../../../images/microphone-slash.svg";
// import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useUser } from "../../../context/UserContext";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";

export default function AudioButton(props: any) {
	const twilioSessionId = localStorage.getItem("twilioSessionId");

	const user = useUser();

	let participantDetailList = JSON.parse(
		localStorage.getItem("participantVolumeDetails") ?? ""
	);
	const audioDetails = participantDetailList.find(
		(p: any) => p.email === props.participant.email
	);
	const [participantAudio, setParticipantAudio] = useState(
		audioDetails.audio.master.audio_status === "mute" ? false : true
	);
	const [volume, setVolume] = useState(audioDetails.audio.master.audio_level);
	useEffect(() => {
		WebsocketClientUSerStatus.on("audioControl", onMessageReceived);
		return () => {
			WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
		};
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const partcipantAudioControl = () => {
		let audioStatus = "unmute";

		if (participantAudio) {
			audioStatus = "mute";
			// audioLevel = 0;
		}

		let message = {
			action: "audio_control",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			created_for: props.participant.email,
			// audio_status: audioStatus,
			// audio_level: volume ?? 50,
			audio: {
				updated_for: "master",
				master: {
					audio_status: audioStatus,
					audio_level: volume ?? 50,
				},
				channel_1: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_1.audio_level,
				},
				channel_2: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.channel_2.audio_level,
				},
				mic: {
					audio_status: audioStatus,
					audio_level: audioDetails.audio.mic.audio_level,
				},
			},
		};
		WebsocketClientUSerStatus.handleAudioControl(message);
	};

	const onMessageReceived = (data: any) => {

		if (data.metadata.created_by === props.participant.email) {
			if (data.metadata.audio.master.audio_status === "mute") {
				setParticipantAudio(false);
			} else {
				setParticipantAudio(true);
				setVolume(data.metadata.audio.master.audio_level);
			}
		}
	};
	return (
		<>
			<IconButton onClick={partcipantAudioControl}>
				{participantAudio ? (
					<img
						style={{ padding: "0 20px", height: "16px", width: "16px" }}
						src={Unmute}
						alt="unmute"
					/>
				) : (
					<img
						style={{ padding: "0 20px", height: "16px", width: "16px" }}
						src={Mute}
						alt="mic"
					/>
				)}
			</IconButton>
		</>
	);
}

import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useAlertStatus } from "../context/AlertStatusContext";
import ErrorIcon from "../images/danger.svg";
import SuccessIcon from "../images/tick-square.svg";
import i18n from "../l10n/strings.json";

const SettingsAlert = (props: any) => {
	const { updateAlertStatus, showAlert, alertStatus, alertMsg } =
		useAlertStatus();
	return (
		<>
			{showAlert && (
				<Snackbar
					sx={{
						"&.MuiSnackbar-anchorOriginTopCenter": {
							top: "80px",
						},
					}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={showAlert}
					autoHideDuration={
						alertMsg === i18n.settings.deviceSetup.pingInprocess ? 9500 : 6000
					}
					onClose={() => updateAlertStatus(false, false, "")}
				>
					<Alert
						onClose={() => updateAlertStatus(false, false, "")}
						icon={
							alertStatus ? (
								<img
									src={SuccessIcon}
									alt="error-icon"
									width="40px"
									style={{ marginRight: "10px" }}
								/>
							) : (
								<img
									src={ErrorIcon}
									alt="error-icon"
									width="40px"
									style={{ marginRight: "10px" }}
								/>
							)
						}
						sx={{
							backgroundColor: "#FFFFFF",
							boxShadow: "0px 1px 1px 1px #D9E7FFC4",
							borderRadius: "6px",
							fontSize: "1.125rem",
							fontWeight: "500",
							// width: "90%",
							color: "#000000",
							alignContent: "center",
							paddingTop: 1,
						}}
					>
						<AlertTitle
							sx={{
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "#000000",
								display: "flex",
								margin: "auto",
								padding: "5px",
								justifyContent: "center !important",
							}}
						>
							{alertMsg}
						</AlertTitle>
					</Alert>
				</Snackbar>
			)}
		</>
	);
};

export default SettingsAlert;

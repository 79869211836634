export const Constants = {
	duration: [
		{ label: "00 hr:30 min", value: 30 },
		{ label: "01 hr:00 min", value: 60 },
		{ label: "01 hr:30 min", value: 90 },
		{ label: "02 hr:00 min", value: 120 },
		{ label: "02 hr:30 min", value: 150 },
		{ label: "03 hr:00 min", value: 180 },
		{ label: "03 hr:30 min", value: 210 },
		{ label: "04 hr:00 min", value: 240 },
		// { label: "04 hr:30 min", value: 270 },
		// { label: "05 hr:00 min", value: 300 },
		// { label: "05 hr:30 min", value: 330 },
		// { label: "06 hr:00 min", value: 360 },
	],

	hubServers: [
		{ label: "Hub Server 1", value: 1 },
		{ label: "Hub Server 2", value: 2 },
		{ label: "Hub Server 3", value: 3 },
	],
	
	gigDuration: [
		{ label: "Select Duration", value: 0 },
		{ label: "Anytime", value: 36500 },
		{ label: "Next 7 days", value: 7 },
		{ label: "Next 30 days", value: 30 },
	],

	modelType: [
		{ label: "Wenger device1.1", value: 1.1 },
		{ label: "Wenger device1.2", value: 1.2 },
		{ label: "Wenger device1.3", value: 1.3 },
		{ label: "Wenger device1.4", value: 1.4 },
	],

	timezoneList: [
		{
			label: "Africa/Abidjan (UTC+00:00)",
			value: "Africa/Abidjan",
		},
		{
			label: "Africa/Accra (UTC+00:00)",
			value: "Africa/Accra",
		},
		{
			label: "Africa/Addis_Ababa (UTC+03:00)",
			value: "Africa/Addis_Ababa",
		},
		{
			label: "Africa/Algiers (UTC+01:00)",
			value: "Africa/Algiers",
		},
		{
			label: "Africa/Asmara (UTC+03:00)",
			value: "Africa/Asmara",
		},
		{
			label: "Africa/Asmera (UTC+03:00)",
			value: "Africa/Asmera",
		},
		{
			label: "Africa/Bamako (UTC+00:00)",
			value: "Africa/Bamako",
		},
		{
			label: "Africa/Bangui (UTC+01:00)",
			value: "Africa/Bangui",
		},
		{
			label: "Africa/Banjul (UTC+00:00)",
			value: "Africa/Banjul",
		},
		{
			label: "Africa/Bissau (UTC+00:00)",
			value: "Africa/Bissau",
		},
		{
			label: "Africa/Blantyre (UTC+02:00)",
			value: "Africa/Blantyre",
		},
		{
			label: "Africa/Brazzaville (UTC+01:00)",
			value: "Africa/Brazzaville",
		},
		{
			label: "Africa/Bujumbura (UTC+02:00)",
			value: "Africa/Bujumbura",
		},
		{
			label: "Africa/Cairo (UTC+02:00)",
			value: "Africa/Cairo",
		},
		{
			label: "Africa/Casablanca (UTC+01:00)",
			value: "Africa/Casablanca",
		},
		{
			label: "Africa/Ceuta (UTC+01:00)",
			value: "Africa/Ceuta",
		},
		{
			label: "Africa/Conakry (UTC+00:00)",
			value: "Africa/Conakry",
		},
		{
			label: "Africa/Dakar (UTC+00:00)",
			value: "Africa/Dakar",
		},
		{
			label: "Africa/Dar_es_Salaam (UTC+03:00)",
			value: "Africa/Dar_es_Salaam",
		},
		{
			label: "Africa/Djibouti (UTC+03:00)",
			value: "Africa/Djibouti",
		},
		{
			label: "Africa/Douala (UTC+01:00)",
			value: "Africa/Douala",
		},
		{
			label: "Africa/El_Aaiun (UTC+01:00)",
			value: "Africa/El_Aaiun",
		},
		{
			label: "Africa/Freetown (UTC+00:00)",
			value: "Africa/Freetown",
		},
		{
			label: "Africa/Gaborone (UTC+02:00)",
			value: "Africa/Gaborone",
		},
		{
			label: "Africa/Harare (UTC+02:00)",
			value: "Africa/Harare",
		},
		{
			label: "Africa/Johannesburg (UTC+02:00)",
			value: "Africa/Johannesburg",
		},
		{
			label: "Africa/Juba (UTC+03:00)",
			value: "Africa/Juba",
		},
		{
			label: "Africa/Kampala (UTC+03:00)",
			value: "Africa/Kampala",
		},
		{
			label: "Africa/Khartoum (UTC+02:00)",
			value: "Africa/Khartoum",
		},
		{
			label: "Africa/Kigali (UTC+02:00)",
			value: "Africa/Kigali",
		},
		{
			label: "Africa/Kinshasa (UTC+01:00)",
			value: "Africa/Kinshasa",
		},
		{
			label: "Africa/Lagos (UTC+01:00)",
			value: "Africa/Lagos",
		},
		{
			label: "Africa/Libreville (UTC+01:00)",
			value: "Africa/Libreville",
		},
		{
			label: "Africa/Lome (UTC+00:00)",
			value: "Africa/Lome",
		},
		{
			label: "Africa/Luanda (UTC+01:00)",
			value: "Africa/Luanda",
		},
		{
			label: "Africa/Lubumbashi (UTC+02:00)",
			value: "Africa/Lubumbashi",
		},
		{
			label: "Africa/Lusaka (UTC+02:00)",
			value: "Africa/Lusaka",
		},
		{
			label: "Africa/Malabo (UTC+01:00)",
			value: "Africa/Malabo",
		},
		{
			label: "Africa/Maputo (UTC+02:00)",
			value: "Africa/Maputo",
		},
		{
			label: "Africa/Maseru (UTC+02:00)",
			value: "Africa/Maseru",
		},
		{
			label: "Africa/Mbabane (UTC+02:00)",
			value: "Africa/Mbabane",
		},
		{
			label: "Africa/Mogadishu (UTC+03:00)",
			value: "Africa/Mogadishu",
		},
		{
			label: "Africa/Monrovia (UTC+00:00)",
			value: "Africa/Monrovia",
		},
		{
			label: "Africa/Nairobi (UTC+03:00)",
			value: "Africa/Nairobi",
		},
		{
			label: "Africa/Ndjamena (UTC+01:00)",
			value: "Africa/Ndjamena",
		},
		{
			label: "Africa/Niamey (UTC+01:00)",
			value: "Africa/Niamey",
		},
		{
			label: "Africa/Nouakchott (UTC+00:00)",
			value: "Africa/Nouakchott",
		},
		{
			label: "Africa/Ouagadougou (UTC+00:00)",
			value: "Africa/Ouagadougou",
		},
		{
			label: "Africa/Porto-Novo (UTC+01:00)",
			value: "Africa/Porto-Novo",
		},
		{
			label: "Africa/Sao_Tome (UTC+01:00)",
			value: "Africa/Sao_Tome",
		},
		{
			label: "Africa/Timbuktu (UTC+00:00)",
			value: "Africa/Timbuktu",
		},
		{
			label: "Africa/Tripoli (UTC+02:00)",
			value: "Africa/Tripoli",
		},
		{
			label: "Africa/Tunis (UTC+01:00)",
			value: "Africa/Tunis",
		},
		{
			label: "Africa/Windhoek (UTC+02:00)",
			value: "Africa/Windhoek",
		},
		{
			label: "America/Adak (UTC-10:00)",
			value: "America/Adak",
		},
		{
			label: "America/Anchorage (UTC-09:00)",
			value: "America/Anchorage",
		},
		{
			label: "America/Anguilla (UTC-04:00)",
			value: "America/Anguilla",
		},
		{
			label: "America/Antigua (UTC-04:00)",
			value: "America/Antigua",
		},
		{
			label: "America/Araguaina (UTC-03:00)",
			value: "America/Araguaina",
		},
		{
			label: "America/Argentina/Buenos_Aires (UTC-03:00)",
			value: "America/Argentina/Buenos_Aires",
		},
		{
			label: "America/Argentina/Catamarca (UTC-03:00)",
			value: "America/Argentina/Catamarca",
		},
		{
			label: "America/Argentina/ComodRivadavia (UTC-03:00)",
			value: "America/Argentina/ComodRivadavia",
		},
		{
			label: "America/Argentina/Cordoba (UTC-03:00)",
			value: "America/Argentina/Cordoba",
		},
		{
			label: "America/Argentina/Jujuy (UTC-03:00)",
			value: "America/Argentina/Jujuy",
		},
		{
			label: "America/Argentina/La_Rioja (UTC-03:00)",
			value: "America/Argentina/La_Rioja",
		},
		{
			label: "America/Argentina/Mendoza (UTC-03:00)",
			value: "America/Argentina/Mendoza",
		},
		{
			label: "America/Argentina/Rio_Gallegos (UTC-03:00)",
			value: "America/Argentina/Rio_Gallegos",
		},
		{
			label: "America/Argentina/Salta (UTC-03:00)",
			value: "America/Argentina/Salta",
		},
		{
			label: "America/Argentina/San_Juan (UTC-03:00)",
			value: "America/Argentina/San_Juan",
		},
		{
			label: "America/Argentina/San_Luis (UTC-03:00)",
			value: "America/Argentina/San_Luis",
		},
		{
			label: "America/Argentina/Tucuman (UTC-03:00)",
			value: "America/Argentina/Tucuman",
		},
		{
			label: "America/Argentina/Ushuaia (UTC-03:00)",
			value: "America/Argentina/Ushuaia",
		},
		{
			label: "America/Aruba (UTC-04:00)",
			value: "America/Aruba",
		},
		{
			label: "America/Asuncion (UTC-03:00)",
			value: "America/Asuncion",
		},
		{
			label: "America/Atikokan (UTC-05:00)",
			value: "America/Atikokan",
		},
		{
			label: "America/Atka (UTC-10:00)",
			value: "America/Atka",
		},
		{
			label: "America/Bahia (UTC-03:00)",
			value: "America/Bahia",
		},
		{
			label: "America/Bahia_Banderas (UTC-06:00)",
			value: "America/Bahia_Banderas",
		},
		{
			label: "America/Barbados (UTC-04:00)",
			value: "America/Barbados",
		},
		{
			label: "America/Belem (UTC-03:00)",
			value: "America/Belem",
		},
		{
			label: "America/Belize (UTC-06:00)",
			value: "America/Belize",
		},
		{
			label: "America/Blanc-Sablon (UTC-04:00)",
			value: "America/Blanc-Sablon",
		},
		{
			label: "America/Boa_Vista (UTC-04:00)",
			value: "America/Boa_Vista",
		},
		{
			label: "America/Bogota (UTC-05:00)",
			value: "America/Bogota",
		},
		{
			label: "America/Boise (UTC-07:00)",
			value: "America/Boise",
		},
		{
			label: "America/Buenos_Aires (UTC-03:00)",
			value: "America/Buenos_Aires",
		},
		{
			label: "America/Cambridge_Bay (UTC-07:00)",
			value: "America/Cambridge_Bay",
		},
		{
			label: "America/Campo_Grande (UTC-04:00)",
			value: "America/Campo_Grande",
		},
		{
			label: "America/Cancun (UTC-05:00)",
			value: "America/Cancun",
		},
		{
			label: "America/Caracas (UTC-04:00)",
			value: "America/Caracas",
		},
		{
			label: "America/Catamarca (UTC-03:00)",
			value: "America/Catamarca",
		},
		{
			label: "America/Cayenne (UTC-03:00)",
			value: "America/Cayenne",
		},
		{
			label: "America/Cayman (UTC-05:00)",
			value: "America/Cayman",
		},
		{
			label: "America/Chicago (UTC-06:00)",
			value: "America/Chicago",
		},
		{
			label: "America/Chihuahua (UTC-07:00)",
			value: "America/Chihuahua",
		},
		{
			label: "America/Coral_Harbour (UTC-05:00)",
			value: "America/Coral_Harbour",
		},
		{
			label: "America/Cordoba (UTC-03:00)",
			value: "America/Cordoba",
		},
		{
			label: "America/Costa_Rica (UTC-06:00)",
			value: "America/Costa_Rica",
		},
		{
			label: "America/Creston (UTC-07:00)",
			value: "America/Creston",
		},
		{
			label: "America/Cuiaba (UTC-04:00)",
			value: "America/Cuiaba",
		},
		{
			label: "America/Curacao (UTC-04:00)",
			value: "America/Curacao",
		},
		{
			label: "America/Danmarkshavn (UTC+00:00)",
			value: "America/Danmarkshavn",
		},
		{
			label: "America/Dawson (UTC-08:00)",
			value: "America/Dawson",
		},
		{
			label: "America/Dawson_Creek (UTC-07:00)",
			value: "America/Dawson_Creek",
		},
		{
			label: "America/Denver (UTC-07:00)",
			value: "America/Denver",
		},
		{
			label: "America/Detroit (UTC-05:00)",
			value: "America/Detroit",
		},
		{
			label: "America/Dominica (UTC-04:00)",
			value: "America/Dominica",
		},
		{
			label: "America/Edmonton (UTC-07:00)",
			value: "America/Edmonton",
		},
		{
			label: "America/Eirunepe (UTC-05:00)",
			value: "America/Eirunepe",
		},
		{
			label: "America/El_Salvador (UTC-06:00)",
			value: "America/El_Salvador",
		},
		{
			label: "America/Ensenada (UTC-08:00)",
			value: "America/Ensenada",
		},
		{
			label: "America/Fort_Nelson (UTC-07:00)",
			value: "America/Fort_Nelson",
		},
		{
			label: "America/Fort_Wayne (UTC-05:00)",
			value: "America/Fort_Wayne",
		},
		{
			label: "America/Fortaleza (UTC-03:00)",
			value: "America/Fortaleza",
		},
		{
			label: "America/Glace_Bay (UTC-04:00)",
			value: "America/Glace_Bay",
		},
		{
			label: "America/Godthab (UTC-03:00)",
			value: "America/Godthab",
		},
		{
			label: "America/Goose_Bay (UTC-04:00)",
			value: "America/Goose_Bay",
		},
		{
			label: "America/Grand_Turk (UTC-05:00)",
			value: "America/Grand_Turk",
		},
		{
			label: "America/Grenada (UTC-04:00)",
			value: "America/Grenada",
		},
		{
			label: "America/Guadeloupe (UTC-04:00)",
			value: "America/Guadeloupe",
		},
		{
			label: "America/Guatemala (UTC-06:00)",
			value: "America/Guatemala",
		},
		{
			label: "America/Guayaquil (UTC-05:00)",
			value: "America/Guayaquil",
		},
		{
			label: "America/Guyana (UTC-04:00)",
			value: "America/Guyana",
		},
		{
			label: "America/Halifax (UTC-04:00)",
			value: "America/Halifax",
		},
		{
			label: "America/Havana (UTC-05:00)",
			value: "America/Havana",
		},
		{
			label: "America/Hermosillo (UTC-07:00)",
			value: "America/Hermosillo",
		},
		{
			label: "America/Indiana/Indianapolis (UTC-05:00)",
			value: "America/Indiana/Indianapolis",
		},
		{
			label: "America/Indiana/Knox (UTC-06:00)",
			value: "America/Indiana/Knox",
		},
		{
			label: "America/Indiana/Marengo (UTC-05:00)",
			value: "America/Indiana/Marengo",
		},
		{
			label: "America/Indiana/Petersburg (UTC-05:00)",
			value: "America/Indiana/Petersburg",
		},
		{
			label: "America/Indiana/Tell_City (UTC-06:00)",
			value: "America/Indiana/Tell_City",
		},
		{
			label: "America/Indiana/Vevay (UTC-05:00)",
			value: "America/Indiana/Vevay",
		},
		{
			label: "America/Indiana/Vincennes (UTC-05:00)",
			value: "America/Indiana/Vincennes",
		},
		{
			label: "America/Indiana/Winamac (UTC-05:00)",
			value: "America/Indiana/Winamac",
		},
		{
			label: "America/Indianapolis (UTC-05:00)",
			value: "America/Indianapolis",
		},
		{
			label: "America/Inuvik (UTC-07:00)",
			value: "America/Inuvik",
		},
		{
			label: "America/Iqaluit (UTC-05:00)",
			value: "America/Iqaluit",
		},
		{
			label: "America/Jamaica (UTC-05:00)",
			value: "America/Jamaica",
		},
		{
			label: "America/Jujuy (UTC-03:00)",
			value: "America/Jujuy",
		},
		{
			label: "America/Juneau (UTC-09:00)",
			value: "America/Juneau",
		},
		{
			label: "America/Kentucky/Louisville (UTC-05:00)",
			value: "America/Kentucky/Louisville",
		},
		{
			label: "America/Kentucky/Monticello (UTC-05:00)",
			value: "America/Kentucky/Monticello",
		},
		{
			label: "America/Knox_IN (UTC-06:00)",
			value: "America/Knox_IN",
		},
		{
			label: "America/Kralendijk (UTC-04:00)",
			value: "America/Kralendijk",
		},
		{
			label: "America/La_Paz (UTC-04:00)",
			value: "America/La_Paz",
		},
		{
			label: "America/Lima (UTC-05:00)",
			value: "America/Lima",
		},
		{
			label: "America/Los_Angeles (UTC-08:00)",
			value: "America/Los_Angeles",
		},
		{
			label: "America/Louisville (UTC-05:00)",
			value: "America/Louisville",
		},
		{
			label: "America/Lower_Princes (UTC-04:00)",
			value: "America/Lower_Princes",
		},
		{
			label: "America/Maceio (UTC-03:00)",
			value: "America/Maceio",
		},
		{
			label: "America/Managua (UTC-06:00)",
			value: "America/Managua",
		},
		{
			label: "America/Manaus (UTC-04:00)",
			value: "America/Manaus",
		},
		{
			label: "America/Marigot (UTC-04:00)",
			value: "America/Marigot",
		},
		{
			label: "America/Martinique (UTC-04:00)",
			value: "America/Martinique",
		},
		{
			label: "America/Matamoros (UTC-06:00)",
			value: "America/Matamoros",
		},
		{
			label: "America/Mazatlan (UTC-07:00)",
			value: "America/Mazatlan",
		},
		{
			label: "America/Mendoza (UTC-03:00)",
			value: "America/Mendoza",
		},
		{
			label: "America/Menominee (UTC-06:00)",
			value: "America/Menominee",
		},
		{
			label: "America/Merida (UTC-06:00)",
			value: "America/Merida",
		},
		{
			label: "America/Metlakatla (UTC-09:00)",
			value: "America/Metlakatla",
		},
		{
			label: "America/Mexico_City (UTC-06:00)",
			value: "America/Mexico_City",
		},
		{
			label: "America/Miquelon (UTC-03:00)",
			value: "America/Miquelon",
		},
		{
			label: "America/Moncton (UTC-04:00)",
			value: "America/Moncton",
		},
		{
			label: "America/Monterrey (UTC-06:00)",
			value: "America/Monterrey",
		},
		{
			label: "America/Montevideo (UTC-03:00)",
			value: "America/Montevideo",
		},
		{
			label: "America/Montreal (UTC-05:00)",
			value: "America/Montreal",
		},
		{
			label: "America/Montserrat (UTC-04:00)",
			value: "America/Montserrat",
		},
		{
			label: "America/Nassau (UTC-05:00)",
			value: "America/Nassau",
		},
		{
			label: "America/New_York (UTC-05:00)",
			value: "America/New_York",
		},
		{
			label: "America/Nipigon (UTC-05:00)",
			value: "America/Nipigon",
		},
		{
			label: "America/Nome (UTC-09:00)",
			value: "America/Nome",
		},
		{
			label: "America/Noronha (UTC-02:00)",
			value: "America/Noronha",
		},
		{
			label: "America/North_Dakota/Beulah (UTC-06:00)",
			value: "America/North_Dakota/Beulah",
		},
		{
			label: "America/North_Dakota/Center (UTC-06:00)",
			value: "America/North_Dakota/Center",
		},
		{
			label: "America/North_Dakota/New_Salem (UTC-06:00)",
			value: "America/North_Dakota/New_Salem",
		},
		{
			label: "America/Ojinaga (UTC-07:00)",
			value: "America/Ojinaga",
		},
		{
			label: "America/Panama (UTC-05:00)",
			value: "America/Panama",
		},
		{
			label: "America/Pangnirtung (UTC-05:00)",
			value: "America/Pangnirtung",
		},
		{
			label: "America/Paramaribo (UTC-03:00)",
			value: "America/Paramaribo",
		},
		{
			label: "America/Phoenix (UTC-07:00)",
			value: "America/Phoenix",
		},
		{
			label: "America/Port-au-Prince (UTC-05:00)",
			value: "America/Port-au-Prince",
		},
		{
			label: "America/Port_of_Spain (UTC-04:00)",
			value: "America/Port_of_Spain",
		},
		{
			label: "America/Porto_Acre (UTC-05:00)",
			value: "America/Porto_Acre",
		},
		{
			label: "America/Porto_Velho (UTC-04:00)",
			value: "America/Porto_Velho",
		},
		{
			label: "America/Puerto_Rico (UTC-04:00)",
			value: "America/Puerto_Rico",
		},
		{
			label: "America/Punta_Arenas (UTC-03:00)",
			value: "America/Punta_Arenas",
		},
		{
			label: "America/Rainy_River (UTC-06:00)",
			value: "America/Rainy_River",
		},
		{
			label: "America/Rankin_Inlet (UTC-06:00)",
			value: "America/Rankin_Inlet",
		},
		{
			label: "America/Recife (UTC-03:00)",
			value: "America/Recife",
		},
		{
			label: "America/Regina (UTC-06:00)",
			value: "America/Regina",
		},
		{
			label: "America/Resolute (UTC-06:00)",
			value: "America/Resolute",
		},
		{
			label: "America/Rio_Branco (UTC-05:00)",
			value: "America/Rio_Branco",
		},
		{
			label: "America/Rosario (UTC-03:00)",
			value: "America/Rosario",
		},
		{
			label: "America/Santa_Isabel (UTC-08:00)",
			value: "America/Santa_Isabel",
		},
		{
			label: "America/Santarem (UTC-03:00)",
			value: "America/Santarem",
		},
		{
			label: "America/Santiago (UTC-03:00)",
			value: "America/Santiago",
		},
		{
			label: "America/Santo_Domingo (UTC-04:00)",
			value: "America/Santo_Domingo",
		},
		{
			label: "America/Sao_Paulo (UTC-03:00)",
			value: "America/Sao_Paulo",
		},
		{
			label: "America/Scoresbysund (UTC-01:00)",
			value: "America/Scoresbysund",
		},
		{
			label: "America/Shiprock (UTC-07:00)",
			value: "America/Shiprock",
		},
		{
			label: "America/Sitka (UTC-09:00)",
			value: "America/Sitka",
		},
		{
			label: "America/St_Barthelemy (UTC-04:00)",
			value: "America/St_Barthelemy",
		},
		{
			label: "America/St_Johns (UTC-03:30)",
			value: "America/St_Johns",
		},
		{
			label: "America/St_Kitts (UTC-04:00)",
			value: "America/St_Kitts",
		},
		{
			label: "America/St_Lucia (UTC-04:00)",
			value: "America/St_Lucia",
		},
		{
			label: "America/St_Thomas (UTC-04:00)",
			value: "America/St_Thomas",
		},
		{
			label: "America/St_Vincent (UTC-04:00)",
			value: "America/St_Vincent",
		},
		{
			label: "America/Swift_Current (UTC-06:00)",
			value: "America/Swift_Current",
		},
		{
			label: "America/Tegucigalpa (UTC-06:00)",
			value: "America/Tegucigalpa",
		},
		{
			label: "America/Thule (UTC-04:00)",
			value: "America/Thule",
		},
		{
			label: "America/Thunder_Bay (UTC-05:00)",
			value: "America/Thunder_Bay",
		},
		{
			label: "America/Tijuana (UTC-08:00)",
			value: "America/Tijuana",
		},
		{
			label: "America/Toronto (UTC-05:00)",
			value: "America/Toronto",
		},
		{
			label: "America/Tortola (UTC-04:00)",
			value: "America/Tortola",
		},
		{
			label: "America/Vancouver (UTC-08:00)",
			value: "America/Vancouver",
		},
		{
			label: "America/Virgin (UTC-04:00)",
			value: "America/Virgin",
		},
		{
			label: "America/Whitehorse (UTC-08:00)",
			value: "America/Whitehorse",
		},
		{
			label: "America/Winnipeg (UTC-06:00)",
			value: "America/Winnipeg",
		},
		{
			label: "America/Yakutat (UTC-09:00)",
			value: "America/Yakutat",
		},
		{
			label: "America/Yellowknife (UTC-07:00)",
			value: "America/Yellowknife",
		},
		{
			label: "Antarctica/Casey (UTC+08:00)",
			value: "Antarctica/Casey",
		},
		{
			label: "Antarctica/Davis (UTC+07:00)",
			value: "Antarctica/Davis",
		},
		{
			label: "Antarctica/DumontDUrville (UTC+10:00)",
			value: "Antarctica/DumontDUrville",
		},
		{
			label: "Antarctica/Macquarie (UTC+11:00)",
			value: "Antarctica/Macquarie",
		},
		{
			label: "Antarctica/Mawson (UTC+05:00)",
			value: "Antarctica/Mawson",
		},
		{
			label: "Antarctica/McMurdo (UTC+13:00)",
			value: "Antarctica/McMurdo",
		},
		{
			label: "Antarctica/Palmer (UTC-03:00)",
			value: "Antarctica/Palmer",
		},
		{
			label: "Antarctica/Rothera (UTC-03:00)",
			value: "Antarctica/Rothera",
		},
		{
			label: "Antarctica/South_Pole (UTC+13:00)",
			value: "Antarctica/South_Pole",
		},
		{
			label: "Antarctica/Syowa (UTC+03:00)",
			value: "Antarctica/Syowa",
		},
		{
			label: "Antarctica/Troll (UTC+00:00)",
			value: "Antarctica/Troll",
		},
		{
			label: "Antarctica/Vostok (UTC+06:00)",
			value: "Antarctica/Vostok",
		},
		{
			label: "Arctic/Longyearbyen (UTC+01:00)",
			value: "Arctic/Longyearbyen",
		},
		{
			label: "Asia/Aden (UTC+03:00)",
			value: "Asia/Aden",
		},
		{
			label: "Asia/Almaty (UTC+06:00)",
			value: "Asia/Almaty",
		},
		{
			label: "Asia/Amman (UTC+02:00)",
			value: "Asia/Amman",
		},
		{
			label: "Asia/Anadyr (UTC+12:00)",
			value: "Asia/Anadyr",
		},
		{
			label: "Asia/Aqtau (UTC+05:00)",
			value: "Asia/Aqtau",
		},
		{
			label: "Asia/Aqtobe (UTC+05:00)",
			value: "Asia/Aqtobe",
		},
		{
			label: "Asia/Ashgabat (UTC+05:00)",
			value: "Asia/Ashgabat",
		},
		{
			label: "Asia/Ashkhabad (UTC+05:00)",
			value: "Asia/Ashkhabad",
		},
		{
			label: "Asia/Atyrau (UTC+05:00)",
			value: "Asia/Atyrau",
		},
		{
			label: "Asia/Baghdad (UTC+03:00)",
			value: "Asia/Baghdad",
		},
		{
			label: "Asia/Bahrain (UTC+03:00)",
			value: "Asia/Bahrain",
		},
		{
			label: "Asia/Baku (UTC+04:00)",
			value: "Asia/Baku",
		},
		{
			label: "Asia/Bangkok (UTC+07:00)",
			value: "Asia/Bangkok",
		},
		{
			label: "Asia/Barnaul (UTC+07:00)",
			value: "Asia/Barnaul",
		},
		{
			label: "Asia/Beirut (UTC+02:00)",
			value: "Asia/Beirut",
		},
		{
			label: "Asia/Bishkek (UTC+06:00)",
			value: "Asia/Bishkek",
		},
		{
			label: "Asia/Brunei (UTC+08:00)",
			value: "Asia/Brunei",
		},
		{
			label: "Asia/Calcutta (UTC+05:30)",
			value: "Asia/Calcutta",
		},
		{
			label: "Asia/Chita (UTC+09:00)",
			value: "Asia/Chita",
		},
		{
			label: "Asia/Choibalsan (UTC+08:00)",
			value: "Asia/Choibalsan",
		},
		{
			label: "Asia/Chongqing (UTC+08:00)",
			value: "Asia/Chongqing",
		},
		{
			label: "Asia/Chungking (UTC+08:00)",
			value: "Asia/Chungking",
		},
		{
			label: "Asia/Colombo (UTC+05:30)",
			value: "Asia/Colombo",
		},
		{
			label: "Asia/Dacca (UTC+06:00)",
			value: "Asia/Dacca",
		},
		{
			label: "Asia/Damascus (UTC+02:00)",
			value: "Asia/Damascus",
		},
		{
			label: "Asia/Dhaka (UTC+06:00)",
			value: "Asia/Dhaka",
		},
		{
			label: "Asia/Dili (UTC+09:00)",
			value: "Asia/Dili",
		},
		{
			label: "Asia/Dubai (UTC+04:00)",
			value: "Asia/Dubai",
		},
		{
			label: "Asia/Dushanbe (UTC+05:00)",
			value: "Asia/Dushanbe",
		},
		{
			label: "Asia/Famagusta (UTC+02:00)",
			value: "Asia/Famagusta",
		},
		{
			label: "Asia/Gaza (UTC+02:00)",
			value: "Asia/Gaza",
		},
		{
			label: "Asia/Harbin (UTC+08:00)",
			value: "Asia/Harbin",
		},
		{
			label: "Asia/Hebron (UTC+02:00)",
			value: "Asia/Hebron",
		},
		{
			label: "Asia/Ho_Chi_Minh (UTC+07:00)",
			value: "Asia/Ho_Chi_Minh",
		},
		{
			label: "Asia/Hong_Kong (UTC+08:00)",
			value: "Asia/Hong_Kong",
		},
		{
			label: "Asia/Hovd (UTC+07:00)",
			value: "Asia/Hovd",
		},
		{
			label: "Asia/Irkutsk (UTC+08:00)",
			value: "Asia/Irkutsk",
		},
		{
			label: "Asia/Istanbul (UTC+03:00)",
			value: "Asia/Istanbul",
		},
		{
			label: "Asia/Jakarta (UTC+07:00)",
			value: "Asia/Jakarta",
		},
		{
			label: "Asia/Jayapura (UTC+09:00)",
			value: "Asia/Jayapura",
		},
		{
			label: "Asia/Jerusalem (UTC+02:00)",
			value: "Asia/Jerusalem",
		},
		{
			label: "Asia/Kabul (UTC+04:30)",
			value: "Asia/Kabul",
		},
		{
			label: "Asia/Kamchatka (UTC+12:00)",
			value: "Asia/Kamchatka",
		},
		{
			label: "Asia/Karachi (UTC+05:00)",
			value: "Asia/Karachi",
		},
		{
			label: "Asia/Kashgar (UTC+06:00)",
			value: "Asia/Kashgar",
		},
		{
			label: "Asia/Kathmandu (UTC+05:45)",
			value: "Asia/Kathmandu",
		},
		{
			label: "Asia/Katmandu (UTC+05:45)",
			value: "Asia/Katmandu",
		},
		{
			label: "Asia/Khandyga (UTC+09:00)",
			value: "Asia/Khandyga",
		},
		{
			label: "Asia/Kolkata (UTC+05:30)",
			value: "Asia/Kolkata",
		},
		{
			label: "Asia/Krasnoyarsk (UTC+07:00)",
			value: "Asia/Krasnoyarsk",
		},
		{
			label: "Asia/Kuala_Lumpur (UTC+08:00)",
			value: "Asia/Kuala_Lumpur",
		},
		{
			label: "Asia/Kuching (UTC+08:00)",
			value: "Asia/Kuching",
		},
		{
			label: "Asia/Kuwait (UTC+03:00)",
			value: "Asia/Kuwait",
		},
		{
			label: "Asia/Macao (UTC+08:00)",
			value: "Asia/Macao",
		},
		{
			label: "Asia/Macau (UTC+08:00)",
			value: "Asia/Macau",
		},
		{
			label: "Asia/Magadan (UTC+11:00)",
			value: "Asia/Magadan",
		},
		{
			label: "Asia/Makassar (UTC+08:00)",
			value: "Asia/Makassar",
		},
		{
			label: "Asia/Manila (UTC+08:00)",
			value: "Asia/Manila",
		},
		{
			label: "Asia/Muscat (UTC+04:00)",
			value: "Asia/Muscat",
		},
		{
			label: "Asia/Nicosia (UTC+02:00)",
			value: "Asia/Nicosia",
		},
		{
			label: "Asia/Novokuznetsk (UTC+07:00)",
			value: "Asia/Novokuznetsk",
		},
		{
			label: "Asia/Novosibirsk (UTC+07:00)",
			value: "Asia/Novosibirsk",
		},
		{
			label: "Asia/Omsk (UTC+06:00)",
			value: "Asia/Omsk",
		},
		{
			label: "Asia/Oral (UTC+05:00)",
			value: "Asia/Oral",
		},
		{
			label: "Asia/Phnom_Penh (UTC+07:00)",
			value: "Asia/Phnom_Penh",
		},
		{
			label: "Asia/Pontianak (UTC+07:00)",
			value: "Asia/Pontianak",
		},
		{
			label: "Asia/Pyongyang (UTC+09:00)",
			value: "Asia/Pyongyang",
		},
		{
			label: "Asia/Qatar (UTC+03:00)",
			value: "Asia/Qatar",
		},
		{
			label: "Asia/Qyzylorda (UTC+06:00)",
			value: "Asia/Qyzylorda",
		},
		{
			label: "Asia/Rangoon (UTC+06:30)",
			value: "Asia/Rangoon",
		},
		{
			label: "Asia/Riyadh (UTC+03:00)",
			value: "Asia/Riyadh",
		},
		{
			label: "Asia/Saigon (UTC+07:00)",
			value: "Asia/Saigon",
		},
		{
			label: "Asia/Sakhalin (UTC+11:00)",
			value: "Asia/Sakhalin",
		},
		{
			label: "Asia/Samarkand (UTC+05:00)",
			value: "Asia/Samarkand",
		},
		{
			label: "Asia/Seoul (UTC+09:00)",
			value: "Asia/Seoul",
		},
		{
			label: "Asia/Shanghai (UTC+08:00)",
			value: "Asia/Shanghai",
		},
		{
			label: "Asia/Singapore (UTC+08:00)",
			value: "Asia/Singapore",
		},
		{
			label: "Asia/Srednekolymsk (UTC+11:00)",
			value: "Asia/Srednekolymsk",
		},
		{
			label: "Asia/Taipei (UTC+08:00)",
			value: "Asia/Taipei",
		},
		{
			label: "Asia/Tashkent (UTC+05:00)",
			value: "Asia/Tashkent",
		},
		{
			label: "Asia/Tbilisi (UTC+04:00)",
			value: "Asia/Tbilisi",
		},
		{
			label: "Asia/Tehran (UTC+03:30)",
			value: "Asia/Tehran",
		},
		{
			label: "Asia/Tel_Aviv (UTC+02:00)",
			value: "Asia/Tel_Aviv",
		},
		{
			label: "Asia/Thimbu (UTC+06:00)",
			value: "Asia/Thimbu",
		},
		{
			label: "Asia/Thimphu (UTC+06:00)",
			value: "Asia/Thimphu",
		},
		{
			label: "Asia/Tokyo (UTC+09:00)",
			value: "Asia/Tokyo",
		},
		{
			label: "Asia/Tomsk (UTC+07:00)",
			value: "Asia/Tomsk",
		},
		{
			label: "Asia/Ujung_Pandang (UTC+08:00)",
			value: "Asia/Ujung_Pandang",
		},
		{
			label: "Asia/Ulaanbaatar (UTC+08:00)",
			value: "Asia/Ulaanbaatar",
		},
		{
			label: "Asia/Ulan_Bator (UTC+08:00)",
			value: "Asia/Ulan_Bator",
		},
		{
			label: "Asia/Urumqi (UTC+06:00)",
			value: "Asia/Urumqi",
		},
		{
			label: "Asia/Ust-Nera (UTC+10:00)",
			value: "Asia/Ust-Nera",
		},
		{
			label: "Asia/Vientiane (UTC+07:00)",
			value: "Asia/Vientiane",
		},
		{
			label: "Asia/Vladivostok (UTC+10:00)",
			value: "Asia/Vladivostok",
		},
		{
			label: "Asia/Yakutsk (UTC+09:00)",
			value: "Asia/Yakutsk",
		},
		{
			label: "Asia/Yangon (UTC+06:30)",
			value: "Asia/Yangon",
		},
		{
			label: "Asia/Yekaterinburg (UTC+05:00)",
			value: "Asia/Yekaterinburg",
		},
		{
			label: "Asia/Yerevan (UTC+04:00)",
			value: "Asia/Yerevan",
		},
		{
			label: "Atlantic/Azores (UTC-01:00)",
			value: "Atlantic/Azores",
		},
		{
			label: "Atlantic/Bermuda (UTC-04:00)",
			value: "Atlantic/Bermuda",
		},
		{
			label: "Atlantic/Canary (UTC+00:00)",
			value: "Atlantic/Canary",
		},
		{
			label: "Atlantic/Cape_Verde (UTC-01:00)",
			value: "Atlantic/Cape_Verde",
		},
		{
			label: "Atlantic/Faeroe (UTC+00:00)",
			value: "Atlantic/Faeroe",
		},
		{
			label: "Atlantic/Faroe (UTC+00:00)",
			value: "Atlantic/Faroe",
		},
		{
			label: "Atlantic/Jan_Mayen (UTC+01:00)",
			value: "Atlantic/Jan_Mayen",
		},
		{
			label: "Atlantic/Madeira (UTC+00:00)",
			value: "Atlantic/Madeira",
		},
		{
			label: "Atlantic/Reykjavik (UTC+00:00)",
			value: "Atlantic/Reykjavik",
		},
		{
			label: "Atlantic/South_Georgia (UTC-02:00)",
			value: "Atlantic/South_Georgia",
		},
		{
			label: "Atlantic/St_Helena (UTC+00:00)",
			value: "Atlantic/St_Helena",
		},
		{
			label: "Atlantic/Stanley (UTC-03:00)",
			value: "Atlantic/Stanley",
		},
		{
			label: "Australia/ACT (UTC+11:00)",
			value: "Australia/ACT",
		},
		{
			label: "Australia/Adelaide (UTC+10:30)",
			value: "Australia/Adelaide",
		},
		{
			label: "Australia/Brisbane (UTC+10:00)",
			value: "Australia/Brisbane",
		},
		{
			label: "Australia/Broken_Hill (UTC+10:30)",
			value: "Australia/Broken_Hill",
		},
		{
			label: "Australia/Canberra (UTC+11:00)",
			value: "Australia/Canberra",
		},
		{
			label: "Australia/Currie (UTC+11:00)",
			value: "Australia/Currie",
		},
		{
			label: "Australia/Darwin (UTC+09:30)",
			value: "Australia/Darwin",
		},
		{
			label: "Australia/Eucla (UTC+08:45)",
			value: "Australia/Eucla",
		},
		{
			label: "Australia/Hobart (UTC+11:00)",
			value: "Australia/Hobart",
		},
		{
			label: "Australia/LHI (UTC+11:00)",
			value: "Australia/LHI",
		},
		{
			label: "Australia/Lindeman (UTC+10:00)",
			value: "Australia/Lindeman",
		},
		{
			label: "Australia/Lord_Howe (UTC+11:00)",
			value: "Australia/Lord_Howe",
		},
		{
			label: "Australia/Melbourne (UTC+11:00)",
			value: "Australia/Melbourne",
		},
		{
			label: "Australia/NSW (UTC+11:00)",
			value: "Australia/NSW",
		},
		{
			label: "Australia/North (UTC+09:30)",
			value: "Australia/North",
		},
		{
			label: "Australia/Perth (UTC+08:00)",
			value: "Australia/Perth",
		},
		{
			label: "Australia/Queensland (UTC+10:00)",
			value: "Australia/Queensland",
		},
		{
			label: "Australia/South (UTC+10:30)",
			value: "Australia/South",
		},
		{
			label: "Australia/Sydney (UTC+11:00)",
			value: "Australia/Sydney",
		},
		{
			label: "Australia/Tasmania (UTC+11:00)",
			value: "Australia/Tasmania",
		},
		{
			label: "Australia/Victoria (UTC+11:00)",
			value: "Australia/Victoria",
		},
		{
			label: "Australia/West (UTC+08:00)",
			value: "Australia/West",
		},
		{
			label: "Australia/Yancowinna (UTC+10:30)",
			value: "Australia/Yancowinna",
		},
		{
			label: "Brazil/Acre (UTC-05:00)",
			value: "Brazil/Acre",
		},
		{
			label: "Brazil/DeNoronha (UTC-02:00)",
			value: "Brazil/DeNoronha",
		},
		{
			label: "Brazil/East (UTC-03:00)",
			value: "Brazil/East",
		},
		{
			label: "Brazil/West (UTC-04:00)",
			value: "Brazil/West",
		},
		{
			label: "CET (UTC+01:00)",
			value: "CET",
		},
		{
			label: "CST6CDT (UTC-06:00)",
			value: "CST6CDT",
		},
		{
			label: "Canada/Atlantic (UTC-04:00)",
			value: "Canada/Atlantic",
		},
		{
			label: "Canada/Central (UTC-06:00)",
			value: "Canada/Central",
		},
		{
			label: "Canada/Eastern (UTC-05:00)",
			value: "Canada/Eastern",
		},
		{
			label: "Canada/Mountain (UTC-07:00)",
			value: "Canada/Mountain",
		},
		{
			label: "Canada/Newfoundland (UTC-03:30)",
			value: "Canada/Newfoundland",
		},
		{
			label: "Canada/Pacific (UTC-08:00)",
			value: "Canada/Pacific",
		},
		{
			label: "Canada/Saskatchewan (UTC-06:00)",
			value: "Canada/Saskatchewan",
		},
		{
			label: "Canada/Yukon (UTC-08:00)",
			value: "Canada/Yukon",
		},
		{
			label: "Chile/Continental (UTC-03:00)",
			value: "Chile/Continental",
		},
		{
			label: "Chile/EasterIsland (UTC-05:00)",
			value: "Chile/EasterIsland",
		},
		{
			label: "Cuba (UTC-05:00)",
			value: "Cuba",
		},
		{
			label: "EET (UTC+02:00)",
			value: "EET",
		},
		{
			label: "EST5EDT (UTC-05:00)",
			value: "EST5EDT",
		},
		{
			label: "Egypt (UTC+02:00)",
			value: "Egypt",
		},
		{
			label: "Eire (UTC+00:00)",
			value: "Eire",
		},
		{
			label: "Etc/GMT (UTC+00:00)",
			value: "Etc/GMT",
		},
		{
			label: "Etc/GMT+0 (UTC+00:00)",
			value: "Etc/GMT+0",
		},
		{
			label: "Etc/GMT+1 (UTC-01:00)",
			value: "Etc/GMT+1",
		},
		{
			label: "Etc/GMT+10 (UTC-10:00)",
			value: "Etc/GMT+10",
		},
		{
			label: "Etc/GMT+11 (UTC-11:00)",
			value: "Etc/GMT+11",
		},
		{
			label: "Etc/GMT+12 (UTC-12:00)",
			value: "Etc/GMT+12",
		},
		{
			label: "Etc/GMT+2 (UTC-02:00)",
			value: "Etc/GMT+2",
		},
		{
			label: "Etc/GMT+3 (UTC-03:00)",
			value: "Etc/GMT+3",
		},
		{
			label: "Etc/GMT+4 (UTC-04:00)",
			value: "Etc/GMT+4",
		},
		{
			label: "Etc/GMT+5 (UTC-05:00)",
			value: "Etc/GMT+5",
		},
		{
			label: "Etc/GMT+6 (UTC-06:00)",
			value: "Etc/GMT+6",
		},
		{
			label: "Etc/GMT+7 (UTC-07:00)",
			value: "Etc/GMT+7",
		},
		{
			label: "Etc/GMT+8 (UTC-08:00)",
			value: "Etc/GMT+8",
		},
		{
			label: "Etc/GMT+9 (UTC-09:00)",
			value: "Etc/GMT+9",
		},
		{
			label: "Etc/GMT-0 (UTC+00:00)",
			value: "Etc/GMT-0",
		},
		{
			label: "Etc/GMT-1 (UTC+01:00)",
			value: "Etc/GMT-1",
		},
		{
			label: "Etc/GMT-10 (UTC+10:00)",
			value: "Etc/GMT-10",
		},
		{
			label: "Etc/GMT-11 (UTC+11:00)",
			value: "Etc/GMT-11",
		},
		{
			label: "Etc/GMT-12 (UTC+12:00)",
			value: "Etc/GMT-12",
		},
		{
			label: "Etc/GMT-13 (UTC+13:00)",
			value: "Etc/GMT-13",
		},
		{
			label: "Etc/GMT-14 (UTC+14:00)",
			value: "Etc/GMT-14",
		},
		{
			label: "Etc/GMT-2 (UTC+02:00)",
			value: "Etc/GMT-2",
		},
		{
			label: "Etc/GMT-3 (UTC+03:00)",
			value: "Etc/GMT-3",
		},
		{
			label: "Etc/GMT-4 (UTC+04:00)",
			value: "Etc/GMT-4",
		},
		{
			label: "Etc/GMT-5 (UTC+05:00)",
			value: "Etc/GMT-5",
		},
		{
			label: "Etc/GMT-6 (UTC+06:00)",
			value: "Etc/GMT-6",
		},
		{
			label: "Etc/GMT-7 (UTC+07:00)",
			value: "Etc/GMT-7",
		},
		{
			label: "Etc/GMT-8 (UTC+08:00)",
			value: "Etc/GMT-8",
		},
		{
			label: "Etc/GMT-9 (UTC+09:00)",
			value: "Etc/GMT-9",
		},
		{
			label: "Etc/GMT0 (UTC+00:00)",
			value: "Etc/GMT0",
		},
		{
			label: "Etc/Greenwich (UTC+00:00)",
			value: "Etc/Greenwich",
		},
		{
			label: "Etc/UCT (UTC+00:00)",
			value: "Etc/UCT",
		},
		{
			label: "Etc/UTC (UTC+00:00)",
			value: "Etc/UTC",
		},
		{
			label: "Etc/Universal (UTC+00:00)",
			value: "Etc/Universal",
		},
		{
			label: "Etc/Zulu (UTC+00:00)",
			value: "Etc/Zulu",
		},
		{
			label: "Europe/Amsterdam (UTC+01:00)",
			value: "Europe/Amsterdam",
		},
		{
			label: "Europe/Andorra (UTC+01:00)",
			value: "Europe/Andorra",
		},
		{
			label: "Europe/Astrakhan (UTC+04:00)",
			value: "Europe/Astrakhan",
		},
		{
			label: "Europe/Athens (UTC+02:00)",
			value: "Europe/Athens",
		},
		{
			label: "Europe/Belfast (UTC+00:00)",
			value: "Europe/Belfast",
		},
		{
			label: "Europe/Belgrade (UTC+01:00)",
			value: "Europe/Belgrade",
		},
		{
			label: "Europe/Berlin (UTC+01:00)",
			value: "Europe/Berlin",
		},
		{
			label: "Europe/Bratislava (UTC+01:00)",
			value: "Europe/Bratislava",
		},
		{
			label: "Europe/Brussels (UTC+01:00)",
			value: "Europe/Brussels",
		},
		{
			label: "Europe/Bucharest (UTC+02:00)",
			value: "Europe/Bucharest",
		},
		{
			label: "Europe/Budapest (UTC+01:00)",
			value: "Europe/Budapest",
		},
		{
			label: "Europe/Busingen (UTC+01:00)",
			value: "Europe/Busingen",
		},
		{
			label: "Europe/Chisinau (UTC+02:00)",
			value: "Europe/Chisinau",
		},
		{
			label: "Europe/Copenhagen (UTC+01:00)",
			value: "Europe/Copenhagen",
		},
		{
			label: "Europe/Dublin (UTC+00:00)",
			value: "Europe/Dublin",
		},
		{
			label: "Europe/Gibraltar (UTC+01:00)",
			value: "Europe/Gibraltar",
		},
		{
			label: "Europe/Guernsey (UTC+00:00)",
			value: "Europe/Guernsey",
		},
		{
			label: "Europe/Helsinki (UTC+02:00)",
			value: "Europe/Helsinki",
		},
		{
			label: "Europe/Isle_of_Man (UTC+00:00)",
			value: "Europe/Isle_of_Man",
		},
		{
			label: "Europe/Istanbul (UTC+03:00)",
			value: "Europe/Istanbul",
		},
		{
			label: "Europe/Jersey (UTC+00:00)",
			value: "Europe/Jersey",
		},
		{
			label: "Europe/Kaliningrad (UTC+02:00)",
			value: "Europe/Kaliningrad",
		},
		{
			label: "Europe/Kiev (UTC+02:00)",
			value: "Europe/Kiev",
		},
		{
			label: "Europe/Kirov (UTC+03:00)",
			value: "Europe/Kirov",
		},
		{
			label: "Europe/Lisbon (UTC+00:00)",
			value: "Europe/Lisbon",
		},
		{
			label: "Europe/Ljubljana (UTC+01:00)",
			value: "Europe/Ljubljana",
		},
		{
			label: "Europe/London (UTC+00:00)",
			value: "Europe/London",
		},
		{
			label: "Europe/Luxembourg (UTC+01:00)",
			value: "Europe/Luxembourg",
		},
		{
			label: "Europe/Madrid (UTC+01:00)",
			value: "Europe/Madrid",
		},
		{
			label: "Europe/Malta (UTC+01:00)",
			value: "Europe/Malta",
		},
		{
			label: "Europe/Mariehamn (UTC+02:00)",
			value: "Europe/Mariehamn",
		},
		{
			label: "Europe/Minsk (UTC+03:00)",
			value: "Europe/Minsk",
		},
		{
			label: "Europe/Monaco (UTC+01:00)",
			value: "Europe/Monaco",
		},
		{
			label: "Europe/Moscow (UTC+03:00)",
			value: "Europe/Moscow",
		},
		{
			label: "Europe/Nicosia (UTC+02:00)",
			value: "Europe/Nicosia",
		},
		{
			label: "Europe/Oslo (UTC+01:00)",
			value: "Europe/Oslo",
		},
		{
			label: "Europe/Paris (UTC+01:00)",
			value: "Europe/Paris",
		},
		{
			label: "Europe/Podgorica (UTC+01:00)",
			value: "Europe/Podgorica",
		},
		{
			label: "Europe/Prague (UTC+01:00)",
			value: "Europe/Prague",
		},
		{
			label: "Europe/Riga (UTC+02:00)",
			value: "Europe/Riga",
		},
		{
			label: "Europe/Rome (UTC+01:00)",
			value: "Europe/Rome",
		},
		{
			label: "Europe/Samara (UTC+04:00)",
			value: "Europe/Samara",
		},
		{
			label: "Europe/San_Marino (UTC+01:00)",
			value: "Europe/San_Marino",
		},
		{
			label: "Europe/Sarajevo (UTC+01:00)",
			value: "Europe/Sarajevo",
		},
		{
			label: "Europe/Saratov (UTC+04:00)",
			value: "Europe/Saratov",
		},
		{
			label: "Europe/Simferopol (UTC+03:00)",
			value: "Europe/Simferopol",
		},
		{
			label: "Europe/Skopje (UTC+01:00)",
			value: "Europe/Skopje",
		},
		{
			label: "Europe/Sofia (UTC+02:00)",
			value: "Europe/Sofia",
		},
		{
			label: "Europe/Stockholm (UTC+01:00)",
			value: "Europe/Stockholm",
		},
		{
			label: "Europe/Tallinn (UTC+02:00)",
			value: "Europe/Tallinn",
		},
		{
			label: "Europe/Tirane (UTC+01:00)",
			value: "Europe/Tirane",
		},
		{
			label: "Europe/Tiraspol (UTC+02:00)",
			value: "Europe/Tiraspol",
		},
		{
			label: "Europe/Ulyanovsk (UTC+04:00)",
			value: "Europe/Ulyanovsk",
		},
		{
			label: "Europe/Uzhgorod (UTC+02:00)",
			value: "Europe/Uzhgorod",
		},
		{
			label: "Europe/Vaduz (UTC+01:00)",
			value: "Europe/Vaduz",
		},
		{
			label: "Europe/Vatican (UTC+01:00)",
			value: "Europe/Vatican",
		},
		{
			label: "Europe/Vienna (UTC+01:00)",
			value: "Europe/Vienna",
		},
		{
			label: "Europe/Vilnius (UTC+02:00)",
			value: "Europe/Vilnius",
		},
		{
			label: "Europe/Volgograd (UTC+04:00)",
			value: "Europe/Volgograd",
		},
		{
			label: "Europe/Warsaw (UTC+01:00)",
			value: "Europe/Warsaw",
		},
		{
			label: "Europe/Zagreb (UTC+01:00)",
			value: "Europe/Zagreb",
		},
		{
			label: "Europe/Zaporozhye (UTC+02:00)",
			value: "Europe/Zaporozhye",
		},
		{
			label: "Europe/Zurich (UTC+01:00)",
			value: "Europe/Zurich",
		},
		{
			label: "GB (UTC+00:00)",
			value: "GB",
		},
		{
			label: "GB-Eire (UTC+00:00)",
			value: "GB-Eire",
		},
		{
			label: "GMT (UTC+00:00)",
			value: "GMT",
		},
		{
			label: "GMT0 (UTC+00:00)",
			value: "GMT0",
		},
		{
			label: "Greenwich (UTC+00:00)",
			value: "Greenwich",
		},
		{
			label: "Hongkong (UTC+08:00)",
			value: "Hongkong",
		},
		{
			label: "Iceland (UTC+00:00)",
			value: "Iceland",
		},
		{
			label: "Indian/Antananarivo (UTC+03:00)",
			value: "Indian/Antananarivo",
		},
		{
			label: "Indian/Chagos (UTC+06:00)",
			value: "Indian/Chagos",
		},
		{
			label: "Indian/Christmas (UTC+07:00)",
			value: "Indian/Christmas",
		},
		{
			label: "Indian/Cocos (UTC+06:30)",
			value: "Indian/Cocos",
		},
		{
			label: "Indian/Comoro (UTC+03:00)",
			value: "Indian/Comoro",
		},
		{
			label: "Indian/Kerguelen (UTC+05:00)",
			value: "Indian/Kerguelen",
		},
		{
			label: "Indian/Mahe (UTC+04:00)",
			value: "Indian/Mahe",
		},
		{
			label: "Indian/Maldives (UTC+05:00)",
			value: "Indian/Maldives",
		},
		{
			label: "Indian/Mauritius (UTC+04:00)",
			value: "Indian/Mauritius",
		},
		{
			label: "Indian/Mayotte (UTC+03:00)",
			value: "Indian/Mayotte",
		},
		{
			label: "Indian/Reunion (UTC+04:00)",
			value: "Indian/Reunion",
		},
		{
			label: "Iran (UTC+03:30)",
			value: "Iran",
		},
		{
			label: "Israel (UTC+02:00)",
			value: "Israel",
		},
		{
			label: "Jamaica (UTC-05:00)",
			value: "Jamaica",
		},
		{
			label: "Japan (UTC+09:00)",
			value: "Japan",
		},
		{
			label: "Kwajalein (UTC+12:00)",
			value: "Kwajalein",
		},
		{
			label: "Libya (UTC+02:00)",
			value: "Libya",
		},
		{
			label: "MET (UTC+01:00)",
			value: "MET",
		},
		{
			label: "MST7MDT (UTC-07:00)",
			value: "MST7MDT",
		},
		{
			label: "Mexico/BajaNorte (UTC-08:00)",
			value: "Mexico/BajaNorte",
		},
		{
			label: "Mexico/BajaSur (UTC-07:00)",
			value: "Mexico/BajaSur",
		},
		{
			label: "Mexico/General (UTC-06:00)",
			value: "Mexico/General",
		},
		{
			label: "NZ (UTC+13:00)",
			value: "NZ",
		},
		{
			label: "NZ-CHAT (UTC+13:45)",
			value: "NZ-CHAT",
		},
		{
			label: "Navajo (UTC-07:00)",
			value: "Navajo",
		},
		{
			label: "PRC (UTC+08:00)",
			value: "PRC",
		},
		{
			label: "PST8PDT (UTC-08:00)",
			value: "PST8PDT",
		},
		{
			label: "Pacific/Apia (UTC+14:00)",
			value: "Pacific/Apia",
		},
		{
			label: "Pacific/Auckland (UTC+13:00)",
			value: "Pacific/Auckland",
		},
		{
			label: "Pacific/Bougainville (UTC+11:00)",
			value: "Pacific/Bougainville",
		},
		{
			label: "Pacific/Chatham (UTC+13:45)",
			value: "Pacific/Chatham",
		},
		{
			label: "Pacific/Chuuk (UTC+10:00)",
			value: "Pacific/Chuuk",
		},
		{
			label: "Pacific/Easter (UTC-05:00)",
			value: "Pacific/Easter",
		},
		{
			label: "Pacific/Efate (UTC+11:00)",
			value: "Pacific/Efate",
		},
		{
			label: "Pacific/Enderbury (UTC+13:00)",
			value: "Pacific/Enderbury",
		},
		{
			label: "Pacific/Fakaofo (UTC+13:00)",
			value: "Pacific/Fakaofo",
		},
		{
			label: "Pacific/Fiji (UTC+12:00)",
			value: "Pacific/Fiji",
		},
		{
			label: "Pacific/Funafuti (UTC+12:00)",
			value: "Pacific/Funafuti",
		},
		{
			label: "Pacific/Galapagos (UTC-06:00)",
			value: "Pacific/Galapagos",
		},
		{
			label: "Pacific/Gambier (UTC-09:00)",
			value: "Pacific/Gambier",
		},
		{
			label: "Pacific/Guadalcanal (UTC+11:00)",
			value: "Pacific/Guadalcanal",
		},
		{
			label: "Pacific/Guam (UTC+10:00)",
			value: "Pacific/Guam",
		},
		{
			label: "Pacific/Honolulu (UTC-10:00)",
			value: "Pacific/Honolulu",
		},
		{
			label: "Pacific/Johnston (UTC-10:00)",
			value: "Pacific/Johnston",
		},
		{
			label: "Pacific/Kiritimati (UTC+14:00)",
			value: "Pacific/Kiritimati",
		},
		{
			label: "Pacific/Kosrae (UTC+11:00)",
			value: "Pacific/Kosrae",
		},
		{
			label: "Pacific/Kwajalein (UTC+12:00)",
			value: "Pacific/Kwajalein",
		},
		{
			label: "Pacific/Majuro (UTC+12:00)",
			value: "Pacific/Majuro",
		},
		{
			label: "Pacific/Marquesas (UTC-09:30)",
			value: "Pacific/Marquesas",
		},
		{
			label: "Pacific/Midway (UTC-11:00)",
			value: "Pacific/Midway",
		},
		{
			label: "Pacific/Nauru (UTC+12:00)",
			value: "Pacific/Nauru",
		},
		{
			label: "Pacific/Niue (UTC-11:00)",
			value: "Pacific/Niue",
		},
		{
			label: "Pacific/Norfolk (UTC+11:00)",
			value: "Pacific/Norfolk",
		},
		{
			label: "Pacific/Noumea (UTC+11:00)",
			value: "Pacific/Noumea",
		},
		{
			label: "Pacific/Pago_Pago (UTC-11:00)",
			value: "Pacific/Pago_Pago",
		},
		{
			label: "Pacific/Palau (UTC+09:00)",
			value: "Pacific/Palau",
		},
		{
			label: "Pacific/Pitcairn (UTC-08:00)",
			value: "Pacific/Pitcairn",
		},
		{
			label: "Pacific/Pohnpei (UTC+11:00)",
			value: "Pacific/Pohnpei",
		},
		{
			label: "Pacific/Ponape (UTC+11:00)",
			value: "Pacific/Ponape",
		},
		{
			label: "Pacific/Port_Moresby (UTC+10:00)",
			value: "Pacific/Port_Moresby",
		},
		{
			label: "Pacific/Rarotonga (UTC-10:00)",
			value: "Pacific/Rarotonga",
		},
		{
			label: "Pacific/Saipan (UTC+10:00)",
			value: "Pacific/Saipan",
		},
		{
			label: "Pacific/Samoa (UTC-11:00)",
			value: "Pacific/Samoa",
		},
		{
			label: "Pacific/Tahiti (UTC-10:00)",
			value: "Pacific/Tahiti",
		},
		{
			label: "Pacific/Tarawa (UTC+12:00)",
			value: "Pacific/Tarawa",
		},
		{
			label: "Pacific/Tongatapu (UTC+13:00)",
			value: "Pacific/Tongatapu",
		},
		{
			label: "Pacific/Truk (UTC+10:00)",
			value: "Pacific/Truk",
		},
		{
			label: "Pacific/Wake (UTC+12:00)",
			value: "Pacific/Wake",
		},
		{
			label: "Pacific/Wallis (UTC+12:00)",
			value: "Pacific/Wallis",
		},
		{
			label: "Pacific/Yap (UTC+10:00)",
			value: "Pacific/Yap",
		},
		{
			label: "Poland (UTC+01:00)",
			value: "Poland",
		},
		{
			label: "Portugal (UTC+00:00)",
			value: "Portugal",
		},
		{
			label: "ROK (UTC+09:00)",
			value: "ROK",
		},
		{
			label: "Singapore (UTC+08:00)",
			value: "Singapore",
		},
		{
			label: "SystemV/AST4 (UTC-04:00)",
			value: "SystemV/AST4",
		},
		{
			label: "SystemV/AST4ADT (UTC-04:00)",
			value: "SystemV/AST4ADT",
		},
		{
			label: "SystemV/CST6 (UTC-06:00)",
			value: "SystemV/CST6",
		},
		{
			label: "SystemV/CST6CDT (UTC-06:00)",
			value: "SystemV/CST6CDT",
		},
		{
			label: "SystemV/EST5 (UTC-05:00)",
			value: "SystemV/EST5",
		},
		{
			label: "SystemV/EST5EDT (UTC-05:00)",
			value: "SystemV/EST5EDT",
		},
		{
			label: "SystemV/HST10 (UTC-10:00)",
			value: "SystemV/HST10",
		},
		{
			label: "SystemV/MST7 (UTC-07:00)",
			value: "SystemV/MST7",
		},
		{
			label: "SystemV/MST7MDT (UTC-07:00)",
			value: "SystemV/MST7MDT",
		},
		{
			label: "SystemV/PST8 (UTC-08:00)",
			value: "SystemV/PST8",
		},
		{
			label: "SystemV/PST8PDT (UTC-08:00)",
			value: "SystemV/PST8PDT",
		},
		{
			label: "SystemV/YST9 (UTC-09:00)",
			value: "SystemV/YST9",
		},
		{
			label: "SystemV/YST9YDT (UTC-09:00)",
			value: "SystemV/YST9YDT",
		},
		{
			label: "Turkey (UTC+03:00)",
			value: "Turkey",
		},
		{
			label: "UCT (UTC+00:00)",
			value: "UCT",
		},
		{
			label: "US/Alaska (UTC-09:00)",
			value: "US/Alaska",
		},
		{
			label: "US/Aleutian (UTC-10:00)",
			value: "US/Aleutian",
		},
		{
			label: "US/Arizona (UTC-07:00)",
			value: "US/Arizona",
		},
		{
			label: "US/Central (UTC-06:00)",
			value: "US/Central",
		},
		{
			label: "US/East-Indiana (UTC-05:00)",
			value: "US/East-Indiana",
		},
		{
			label: "US/Eastern (UTC-05:00)",
			value: "US/Eastern",
		},
		{
			label: "US/Hawaii (UTC-10:00)",
			value: "US/Hawaii",
		},
		{
			label: "US/Indiana-Starke (UTC-06:00)",
			value: "US/Indiana-Starke",
		},
		{
			label: "US/Michigan (UTC-05:00)",
			value: "US/Michigan",
		},
		{
			label: "US/Mountain (UTC-07:00)",
			value: "US/Mountain",
		},
		{
			label: "US/Pacific (UTC-08:00)",
			value: "US/Pacific",
		},
		{
			label: "US/Samoa (UTC-11:00)",
			value: "US/Samoa",
		},
		{
			label: "UTC (UTC+00:00)",
			value: "UTC",
		},
		{
			label: "Universal (UTC+00:00)",
			value: "Universal",
		},
		{
			label: "W-SU (UTC+03:00)",
			value: "W-SU",
		},
		{
			label: "WET (UTC+00:00)",
			value: "WET",
		},
		{
			label: "Zulu (UTC+00:00)",
			value: "Zulu",
		},
	],

	// timezone: [
	// 	"Africa/Abidjan",
	// 	"Africa/Accra",
	// 	"Africa/Algiers",
	// 	"Africa/Bissau",
	// 	"Africa/Cairo",
	// 	"Africa/Casablanca",
	// 	"Africa/Ceuta",
	// 	"Africa/El_Aaiun",
	// 	"Africa/Johannesburg",
	// 	"Africa/Juba",
	// 	"Africa/Khartoum",
	// 	"Africa/Lagos",
	// 	"Africa/Maputo",
	// 	"Africa/Monrovia",
	// 	"Africa/Nairobi",
	// 	"Africa/Ndjamena",
	// 	"Africa/Sao_Tome",
	// 	"Africa/Tripoli",
	// 	"Africa/Tunis",
	// 	"Africa/Windhoek",
	// 	"America/Adak",
	// 	"America/Anchorage",
	// 	"America/Araguaina",
	// 	"America/Argentina/Buenos_Aires",
	// 	"America/Argentina/Catamarca",
	// 	"America/Argentina/Cordoba",
	// 	"America/Argentina/Jujuy",
	// 	"America/Argentina/La_Rioja",
	// 	"America/Argentina/Mendoza",
	// 	"America/Argentina/Rio_Gallegos",
	// 	"America/Argentina/Salta",
	// 	"America/Argentina/San_Juan",
	// 	"America/Argentina/San_Luis",
	// 	"America/Argentina/Tucuman",
	// 	"America/Argentina/Ushuaia",
	// 	"America/Asuncion",
	// 	"America/Atikokan",
	// 	"America/Bahia",
	// 	"America/Bahia_Banderas",
	// 	"America/Barbados",
	// 	"America/Belem",
	// 	"America/Belize",
	// 	"America/Blanc-Sablon",
	// 	"America/Boa_Vista",
	// 	"America/Bogota",
	// 	"America/Boise",
	// 	"America/Cambridge_Bay",
	// 	"America/Campo_Grande",
	// 	"America/Cancun",
	// 	"America/Caracas",
	// 	"America/Cayenne",
	// 	"America/Chicago",
	// 	"America/Chihuahua",
	// 	"America/Costa_Rica",
	// 	"America/Creston",
	// 	"America/Cuiaba",
	// 	"America/Curacao",
	// 	"America/Danmarkshavn",
	// 	"America/Dawson",
	// 	"America/Dawson_Creek",
	// 	"America/Denver",
	// 	"America/Detroit",
	// 	"America/Edmonton",
	// 	"America/Eirunepe",
	// 	"America/El_Salvador",
	// 	"America/Fort_Nelson",
	// 	"America/Fortaleza",
	// 	"America/Glace_Bay",
	// 	"America/Goose_Bay",
	// 	"America/Grand_Turk",
	// 	"America/Guatemala",
	// 	"America/Guayaquil",
	// 	"America/Guyana",
	// 	"America/Halifax",
	// 	"America/Havana",
	// 	"America/Hermosillo",
	// 	"America/Indiana/Indianapolis",
	// 	"America/Indiana/Knox",
	// 	"America/Indiana/Marengo",
	// 	"America/Indiana/Petersburg",
	// 	"America/Indiana/Tell_City",
	// 	"America/Indiana/Vevay",
	// 	"America/Indiana/Vincennes",
	// 	"America/Indiana/Winamac",
	// 	"America/Inuvik",
	// 	"America/Iqaluit",
	// 	"America/Jamaica",
	// 	"America/Juneau",
	// 	"America/Kentucky/Louisville",
	// 	"America/Kentucky/Monticello",
	// 	"America/La_Paz",
	// 	"America/Lima",
	// 	"America/Los_Angeles",
	// 	"America/Maceio",
	// 	"America/Managua",
	// 	"America/Manaus",
	// 	"America/Martinique",
	// 	"America/Matamoros",
	// 	"America/Mazatlan",
	// 	"America/Menominee",
	// 	"America/Merida",
	// 	"America/Metlakatla",
	// 	"America/Mexico_City",
	// 	"America/Miquelon",
	// 	"America/Moncton",
	// 	"America/Monterrey",
	// 	"America/Montevideo",
	// 	"America/Nassau",
	// 	"America/New_York",
	// 	"America/Nipigon",
	// 	"America/Nome",
	// 	"America/Noronha",
	// 	"America/North_Dakota/Beulah",
	// 	"America/North_Dakota/Center",
	// 	"America/North_Dakota/New_Salem",
	// 	"America/Nuuk",
	// 	"America/Ojinaga",
	// 	"America/Panama",
	// 	"America/Pangnirtung",
	// 	"America/Paramaribo",
	// 	"America/Phoenix",
	// 	"America/Port-au-Prince",
	// 	"America/Port_of_Spain",
	// 	"America/Porto_Velho",
	// 	"America/Puerto_Rico",
	// 	"America/Punta_Arenas",
	// 	"America/Rainy_River",
	// 	"America/Rankin_Inlet",
	// 	"America/Recife",
	// 	"America/Regina",
	// 	"America/Resolute",
	// 	"America/Rio_Branco",
	// 	"America/Santarem",
	// 	"America/Santiago",
	// 	"America/Santo_Domingo",
	// 	"America/Sao_Paulo",
	// 	"America/Scoresbysund",
	// 	"America/Sitka",
	// 	"America/St_Johns",
	// 	"America/Swift_Current",
	// 	"America/Tegucigalpa",
	// 	"America/Thule",
	// 	"America/Thunder_Bay",
	// 	"America/Tijuana",
	// 	"America/Toronto",
	// 	"America/Vancouver",
	// 	"America/Whitehorse",
	// 	"America/Winnipeg",
	// 	"America/Yakutat",
	// 	"America/Yellowknife",
	// 	"Antarctica/Casey",
	// 	"Antarctica/Davis",
	// 	"Antarctica/DumontDUrville",
	// 	"Antarctica/Macquarie",
	// 	"Antarctica/Mawson",
	// 	"Antarctica/Palmer",
	// 	"Antarctica/Rothera",
	// 	"Antarctica/Syowa",
	// 	"Antarctica/Troll",
	// 	"Antarctica/Vostok",
	// 	"Asia/Almaty",
	// 	"Asia/Amman",
	// 	"Asia/Anadyr",
	// 	"Asia/Aqtau",
	// 	"Asia/Aqtobe",
	// 	"Asia/Ashgabat",
	// 	"Asia/Atyrau",
	// 	"Asia/Baghdad",
	// 	"Asia/Baku",
	// 	"Asia/Bangkok",
	// 	"Asia/Barnaul",
	// 	"Asia/Beirut",
	// 	"Asia/Bishkek",
	// 	"Asia/Brunei",
	// 	"Asia/Chita",
	// 	"Asia/Choibalsan",
	// 	"Asia/Colombo",
	// 	"Asia/Damascus",
	// 	"Asia/Dhaka",
	// 	"Asia/Dili",
	// 	"Asia/Dubai",
	// 	"Asia/Dushanbe",
	// 	"Asia/Famagusta",
	// 	"Asia/Gaza",
	// 	"Asia/Hebron",
	// 	"Asia/Ho_Chi_Minh",
	// 	"Asia/Hong_Kong",
	// 	"Asia/Hovd",
	// 	"Asia/Irkutsk",
	// 	"Asia/Jakarta",
	// 	"Asia/Jayapura",
	// 	"Asia/Jerusalem",
	// 	"Asia/Kabul",
	// 	"Asia/Kamchatka",
	// 	"Asia/Karachi",
	// 	"Asia/Kathmandu",
	// 	"Asia/Khandyga",
	// 	"Asia/Calcutta",
	// 	"Asia/Krasnoyarsk",
	// 	"Asia/Kuala_Lumpur",
	// 	"Asia/Kuching",
	// 	"Asia/Macau",
	// 	"Asia/Magadan",
	// 	"Asia/Makassar",
	// 	"Asia/Manila",
	// 	"Asia/Nicosia",
	// 	"Asia/Novokuznetsk",
	// 	"Asia/Novosibirsk",
	// 	"Asia/Omsk",
	// 	"Asia/Oral",
	// 	"Asia/Pontianak",
	// 	"Asia/Pyongyang",
	// 	"Asia/Qatar",
	// 	"Asia/Qostanay",
	// 	"Asia/Qyzylorda",
	// 	"Asia/Riyadh",
	// 	"Asia/Sakhalin",
	// 	"Asia/Samarkand",
	// 	"Asia/Seoul",
	// 	"Asia/Shanghai",
	// 	"Asia/Singapore",
	// 	"Asia/Srednekolymsk",
	// 	"Asia/Taipei",
	// 	"Asia/Tashkent",
	// 	"Asia/Tbilisi",
	// 	"Asia/Tehran",
	// 	"Asia/Thimphu",
	// 	"Asia/Tokyo",
	// 	"Asia/Tomsk",
	// 	"Asia/Ulaanbaatar",
	// 	"Asia/Urumqi",
	// 	"Asia/Ust-Nera",
	// 	"Asia/Vladivostok",
	// 	"Asia/Yakutsk",
	// 	"Asia/Yangon",
	// 	"Asia/Yekaterinburg",
	// 	"Asia/Yerevan",
	// 	"Atlantic/Azores",
	// 	"Atlantic/Bermuda",
	// 	"Atlantic/Canary",
	// 	"Atlantic/Cape_Verde",
	// 	"Atlantic/Faroe",
	// 	"Atlantic/Madeira",
	// 	"Atlantic/Reykjavik",
	// 	"Atlantic/South_Georgia",
	// 	"Atlantic/Stanley",
	// 	"Australia/Adelaide",
	// 	"Australia/Brisbane",
	// 	"Australia/Broken_Hill",
	// 	"Australia/Darwin",
	// 	"Australia/Eucla",
	// 	"Australia/Hobart",
	// 	"Australia/Lindeman",
	// 	"Australia/Lord_Howe",
	// 	"Australia/Melbourne",
	// 	"Australia/Perth",
	// 	"Australia/Sydney",
	// 	"CET",
	// 	"CST6CDT",
	// 	"EET",
	// 	"EST",
	// 	"EST5EDT",
	// 	"Etc/GMT",
	// 	"Etc/GMT+1",
	// 	"Etc/GMT+10",
	// 	"Etc/GMT+11",
	// 	"Etc/GMT+12",
	// 	"Etc/GMT+2",
	// 	"Etc/GMT+3",
	// 	"Etc/GMT+4",
	// 	"Etc/GMT+5",
	// 	"Etc/GMT+6",
	// 	"Etc/GMT+7",
	// 	"Etc/GMT+8",
	// 	"Etc/GMT+9",
	// 	"Etc/GMT-1",
	// 	"Etc/GMT-10",
	// 	"Etc/GMT-11",
	// 	"Etc/GMT-12",
	// 	"Etc/GMT-13",
	// 	"Etc/GMT-14",
	// 	"Etc/GMT-2",
	// 	"Etc/GMT-3",
	// 	"Etc/GMT-4",
	// 	"Etc/GMT-5",
	// 	"Etc/GMT-6",
	// 	"Etc/GMT-7",
	// 	"Etc/GMT-8",
	// 	"Etc/GMT-9",
	// 	"Etc/UTC",
	// 	"Europe/Amsterdam",
	// 	"Europe/Andorra",
	// 	"Europe/Astrakhan",
	// 	"Europe/Athens",
	// 	"Europe/Belgrade",
	// 	"Europe/Berlin",
	// 	"Europe/Brussels",
	// 	"Europe/Bucharest",
	// 	"Europe/Budapest",
	// 	"Europe/Chisinau",
	// 	"Europe/Copenhagen",
	// 	"Europe/Dublin",
	// 	"Europe/Gibraltar",
	// 	"Europe/Helsinki",
	// 	"Europe/Istanbul",
	// 	"Europe/Kaliningrad",
	// 	"Europe/Kiev",
	// 	"Europe/Kirov",
	// 	"Europe/Lisbon",
	// 	"Europe/London",
	// 	"Europe/Luxembourg",
	// 	"Europe/Madrid",
	// 	"Europe/Malta",
	// 	"Europe/Minsk",
	// 	"Europe/Monaco",
	// 	"Europe/Moscow",
	// 	"Europe/Oslo",
	// 	"Europe/Paris",
	// 	"Europe/Prague",
	// 	"Europe/Riga",
	// 	"Europe/Rome",
	// 	"Europe/Samara",
	// 	"Europe/Saratov",
	// 	"Europe/Simferopol",
	// 	"Europe/Sofia",
	// 	"Europe/Stockholm",
	// 	"Europe/Tallinn",
	// 	"Europe/Tirane",
	// 	"Europe/Ulyanovsk",
	// 	"Europe/Uzhgorod",
	// 	"Europe/Vienna",
	// 	"Europe/Vilnius",
	// 	"Europe/Volgograd",
	// 	"Europe/Warsaw",
	// 	"Europe/Zaporozhye",
	// 	"Europe/Zurich",
	// 	"HST",
	// 	"Indian/Chagos",
	// 	"Indian/Christmas",
	// 	"Indian/Cocos",
	// 	"Indian/Kerguelen",
	// 	"Indian/Mahe",
	// 	"Indian/Maldives",
	// 	"Indian/Mauritius",
	// 	"Indian/Reunion",
	// 	"MET",
	// 	"MST",
	// 	"MST7MDT",
	// 	"PST8PDT",
	// 	"Pacific/Apia",
	// 	"Pacific/Auckland",
	// 	"Pacific/Bougainville",
	// 	"Pacific/Chatham",
	// 	"Pacific/Chuuk",
	// 	"Pacific/Easter",
	// 	"Pacific/Efate",
	// 	"Pacific/Enderbury",
	// 	"Pacific/Fakaofo",
	// 	"Pacific/Fiji",
	// 	"Pacific/Funafuti",
	// 	"Pacific/Galapagos",
	// 	"Pacific/Gambier",
	// 	"Pacific/Guadalcanal",
	// 	"Pacific/Guam",
	// 	"Pacific/Honolulu",
	// 	"Pacific/Kiritimati",
	// 	"Pacific/Kosrae",
	// 	"Pacific/Kwajalein",
	// 	"Pacific/Majuro",
	// 	"Pacific/Marquesas",
	// 	"Pacific/Nauru",
	// 	"Pacific/Niue",
	// 	"Pacific/Norfolk",
	// 	"Pacific/Noumea",
	// 	"Pacific/Pago_Pago",
	// 	"Pacific/Palau",
	// 	"Pacific/Pitcairn",
	// 	"Pacific/Pohnpei",
	// 	"Pacific/Port_Moresby",
	// 	"Pacific/Rarotonga",
	// 	"Pacific/Tahiti",
	// 	"Pacific/Tarawa",
	// 	"Pacific/Tongatapu",
	// 	"Pacific/Wake",
	// 	"Pacific/Wallis",
	// ],
	mixerRange: [
		{ label: "-12 dB", value: -12 },
		{ label: " ", value: -6 },
		{ label: "0 dB", value: 0 },
		{ label: " ", value: 6 },
		{ label: "+12dB", value: 12 },
	],
	emojis: [
		{
			key: "heart",
			emoji: "❤️",
		},
		{
			key: "clap",
			emoji: "👏",
		},
		{
			key: "smiling",
			emoji: "😍",
		},
		{
			key: "wow",
			emoji: "😮",
		},
		{
			key: "thumsup",
			emoji: "👍",
		},
	],
	RecordingCmd: {
		START: "START",
		STOP: "STOP",
	},
	LeaveCmd: {
		STOP_STATUS: "SESSION_STOPPED",
	},
};

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Grid,
  CircularProgress
} from "@mui/material";
import CloseIcon from "../../../images/close.svg";
import i18n from "../../../l10n/strings.json";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import { useAdminDevciceManagement } from "./useAdminDeviceManagement";
import { isArray } from "lodash";

export default function AdminDeleteDeviceModal(props: any) {
  
  const token = localStorage.getItem("sessionId");
  const { open, onClose, currentDevice } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { updateAlertStatus } = useAlertStatus();
  const { fetchDevicesData, setSelectedDeviceIds } = useAdminDevciceManagement();


  let deviceIds: Array<String> = [];

  if(isArray(currentDevice)){
     deviceIds = currentDevice;
  }else{
    deviceIds = [currentDevice.deviceId]
  }

  const delteDeviceInfoKey = isArray(currentDevice) ? 'deleteDevicesInfo' : 'deleteDeviceInfo';

  const deleteDevice = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/devices/${currentDevice.id}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });

      if (response.ok) {
        setTimeout(() => {
          setIsLoading(false);
          fetchDevicesData();
          onClose();
          updateAlertStatus(true, true, `Device (${currentDevice.deviceId}) has been deleted successfully!`);
        }, 1000);

      } else {
        const {message} = await response.json()
        updateAlertStatus(true, false, message);
        setIsLoading(false);
      }
    } catch (error) {
      updateAlertStatus(true, false, 'Network Error! Please try again..');
      setIsLoading(false);
    }
  };

  const bulkDeleteDevices = async() => {
    console.log(deviceIds);
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/devices/bulk-delete`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ids: deviceIds})
      });

      if (response.ok) {
        setTimeout(() => {
          setIsLoading(false);
          fetchDevicesData();
          setSelectedDeviceIds([])
          onClose();
          updateAlertStatus(true, true, ` ${deviceIds.length} Device(s) has been deleted successfully!`);
        }, 1000);

      } else {
        const {message} = await response.json()
        updateAlertStatus(true, false, message);
        setIsLoading(false);
      }
    } catch (error) {
      updateAlertStatus(true, false, 'Network Error! Please try again..');
      setIsLoading(false);
    }
  }

  const handleDeleteDevice = async () => {
    if(isArray(currentDevice)){
      await bulkDeleteDevices();
    }else{
      await deleteDevice()
    }
  };

  return (
    <Dialog
      sx={{
        border: "1px solid #888888",
        boxShadow: "customColor.dropShadow",
        borderRadius: "8px",
      }}
      fullWidth
      maxWidth="xs"
      open={open}
    >
      <DialogTitle
        className="edit-heading-name"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            aria-label="close dialogbox"
            onClick={onClose}
            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
              event.preventDefault()
            }
            edge="end"
            sx={{ marginLeft: "90%" }}
          >
            <img src={CloseIcon} alt="close" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Typography
            // className="dialog-title-custom-size"
            sx={{
              fontSize: "20px",
              fontWeight: 200,
              color: "#000000",
            }}
          >
            Delete Device
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ padding: "5px 20px" }}>
        <Grid container columnSpacing={2} rowSpacing={3} >
          <Grid item xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center"
            }}
            sx={{
              div: {
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              },
            }}
          >
            {/* <FormHelperText sx={{ marginLeft: "0px !important" }}> */}
            <Typography
              variant="body4"
              color="customColor.label"
              sx={{ marginLeft: "0px !important" }}
            >
              {i18n.deviceManagement.filters[delteDeviceInfoKey]}
            </Typography>
            {/* </FormHelperText> */}
          </Grid>
          <Grid item xs={12}
            marginBottom={5}
            style={{
              display: isArray(currentDevice) ? 'none': "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center"
            }}
            sx={{
              div: {
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "20px",
              },
            }}
          >
            <Typography
              variant="body4"
              color="customColor.label"
              sx={{ marginLeft: "0px !important" }}
            >
              {i18n.deviceManagement.filters.deviceIdTitle}: <b>{deviceIds.join(' ')}</b>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container columnSpacing={1}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid item={true}>
            <Button
              className="cancel-button"
              size="large"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item={true}>
            <Button

              className="submit-button"
              size="large"
              variant="contained"
            onClick={handleDeleteDevice}
            disabled={isLoading}
            >
              {isLoading ? <>Submitting... <CircularProgress size={18}/></> : <>Delete Device</>}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

import { Box, Grid } from "@mui/material";
import { memo, useEffect, useState, useRef } from "react";
import AssignRole from "./AssignRole";
import AdminAvatarGeneration from "./AdminAvatarGeneration";
import { AdminUsersLayout } from "../AdminUsersLayout/AdminUsersLayout";

const AdminDetails = (props: any) => {
	const [loading, setLoading] = useState(false);

	interface EnhancedTableToolbarProps {
		activeMenuTitle: string;
		menus: Array<any>;
	}

	let content;

	if (props.activeMenu === "assignRole") {
		content = <AssignRole />;
	}
	if (props.activeMenu === "avatarprompt") {
		content = (
			<AdminAvatarGeneration
				avatarPromptOpen={props.avatarPromptOpen}
				handleDialogClose={props.handleDialogClose}
			/>
		);
	}
	if (props.activeMenu === "adminUsers") {
		content = (
			<AdminUsersLayout />
		);
	}

	return (
		<>
			<Grid
				container={true}
				spacing={2}
				columns={{ xs: 12 }}
				sx={{ width: "100%" }}
			>
				<Grid item={true} xs={12}>
					{content}
				</Grid>
			</Grid>
		</>
	);
};

export default AdminDetails;

export default function ErrorAlert (status:any, setAlertStatus:any, setShowAlert:any, setAlertMsg:any) {
    
     if (status <= 400) {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Error! Please try again..");
            // throw new Error("api_error")
        }else if (status > 400 && status < 500) {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Something went wrong..");
            // throw new Error("api_error");
        }else if(status >= 500){
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
            // throw new Error("api_error");
        }
    return (
        <>
        </>
    )
}
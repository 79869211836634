import { Card, Divider } from "@mui/material";
import SimilarArtists from "./similarArtists";
import Events from "./events";
import Sponsored from "./sponsored";

const MyActivitiesSidePanel = () => {
	return (
		<>
			<Card
				sx={{
					mb: 3,
					minWidth: 288,
					width: 288,
					maxWidth: "288px",
					height: "fit-content",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "12px",
				}}
			>
				<SimilarArtists />
				{/* <Divider variant="middle" light sx={{ ml: 1, mr: 1}} /> */}
				<Events />
				{/* <Divider variant="middle" light sx={{ ml: 1, mr: 1}} /> */}
				{/* <Sponsored /> */}
			</Card>
		</>
	);
};
export default MyActivitiesSidePanel;

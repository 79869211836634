import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	Typography,
	Divider,
	Grid,
	FormHelperText,
	TextField,
	Checkbox,
	FormControlLabel,
	Autocomplete,
	Button,
	FormControl,
	Slider,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import i18n from "../../l10n/strings.json";
import InstrumentSkillLevel from "./InstrumentSkills";

import Guitar from "../../images/guitar.svg";
import Drums from "../../images/drums.svg";
import Violin from "../../images/Violin.svg";
import Trumpet from "../../images/trumpet.svg";

import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";

// import useMusiciansFilter from "../../hooks/useMusicians/useMusiciansFilter";
import { useMusiciansFilter } from "./MusicProvider/useMusicianFilter";
import useLocationAutocomplete from "../../hooks/useLocationAutocomplete/useLocationAutocomplete";
import GoogleLocationService from "../GoogleLocationService";

type RouteParams = {
	menuId: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			padding: "1% 2%",
			borderRadius: "15px",
			//boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		backBtn: {
			width: "100%",
			fontWeight: "500 !important",
			color: "#0273E6 !important",
		},
		root: {
			color: "white",
			// '& .MuiAutocomplete-tag': {
			//     backgroundColor: 'blue',
			//     color: 'white'
			// },
			"& .MuiSvgIcon-root": {
				color: "white",
			},
			"& .MuiAutocomplete-endAdornment": {
				"& .MuiSvgIcon-root": {
					color: "#000 !important",
				},
			},
			"& .MuiChip-root.MuiChip-filled": {
				backgroundColor: "#0273E6",
				fontFamily: "Lexend",
				fontWeight: "400",
				margin: "3px",
				color: "white",
				"& svg.MuiChip-deleteIcon": {
					color: "white !important",
				},
				"& span.MuiAutocomplete-tag ": {
					color: "black !important",
					backgroundColor: "white !important",
				},
			},
		},
	})
);

type Genres = {
	// id: string;
	title: string;
};

type Instrument = {
	id: string;
	instrumentName: string;
	icon?: string;
};

const genreFilter: Array<Genres> = [
	{ title: "Americana" },
	{ title: "Avant-garde" },
	{ title: "Baroque" },
	{ title: "Big Band" },
	{ title: "Bluegrass" },
	{ title: "Blues" },
	{ title: "Bollywood" },
	{ title: "Broadway/Showtunes" },
	{ title: "Chamber-Music" },
	{ title: "Classic Rock" },
	{ title: "Classical" },
	{ title: "Contemporary Classical" },
	{ title: "Country" },
	{ title: "Disco" },
	{ title: "Drumline" },
	{ title: "EDM" },
	{ title: "Electronic/Dance" },
	{ title: "Folk" },
	{ title: "Funk" },
	{ title: "Gospel" },
	{ title: "Hip-hop/Rap" },
	{ title: "House" },
	{ title: "Indie/Alternative" },
	{ title: "Jazz" },
	{ title: "K-Pop" },
	{ title: "Latin" },
	{ title: "Metal" },
	{ title: "Minimalism" },
	{ title: "New Age" },
	{ title: "Opera" },
	{ title: "Orchestral" },
	{ title: "Pop" },
	{ title: "Punk" },
	{ title: "R&B" },
	{ title: "Ragtime" },
	{ title: "Reggae" },
	{ title: "Reggaeton" },
	{ title: "Religious" },
	{ title: "Rock" },
	{ title: "Romantic" },
	{ title: "Salsa" },
	{ title: "Ska" },
	{ title: "Soul" },
	{ title: "Soundtrack" },
	{ title: "Swing" },
	{ title: "Symphonic" },
	{ title: "Techno" },
	{ title: "Wind Ensemble" },
];
const marks = [
	{
		value: 0,
		label: 25,
	},
	{
		value: 25,
		label: 50,
	},
	{
		value: 50,
		label: 100,
	},
	{
		value: 75,
		label: 500,
	},
	{
		value: 100,
		label: "Any",
	},
];
const MusiciansFilter = () => {
	const param = useParams<RouteParams>();
	const classes = useStyles();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const {
		musicianFilter,
		setMusicianFilter,
		isFilterActive,
		resetMusicianFilter,
		displayDistance,
		setDisplayDistance,
		setIsDistanceActive,
	} = useMusiciansFilter();

	const [pageTitle, setPageTitle] = useState("");
	const [genres, setGenres] = useState("");
	const [instrumentList, setInstrumentList] = useState<Array<Instrument>>([]);
	const [selectedInstrumentFilter, setSelectedInstrumentsFilter] = useState<
		Array<Instrument> | []
	>([]);
	const [searchString, setSearchString] = useState("");
	const optionsLocationAutoComplete = useLocationAutocomplete(searchString);

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);

	const updateSelectedInstruments = (newInstrumentSet: any) => {
		setSelectedInstrumentsFilter(newInstrumentSet);
	};

	const updateInstrumentFilter = (instrument: string, skillLevel: number) => {};

	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					return { lat, lng };
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const isGlobalSearchTrue = (musicianFilter: any) => {
		if (
			musicianFilter.genres.length > 0 ||
			musicianFilter.cities.length > 0 ||
			musicianFilter.instrument.length > 0 ||
			musicianFilter.isOpenForCollaboration ||
			musicianFilter.acceptGigs
		)
			return false;
		return true;
	};

	const updateSearchFilter = (field: string, value: any) => {
		switch (field) {
			case "musicianName":
				setMusicianFilter({
					...musicianFilter,
					firstName: value,
					lastName: value,
					nickName: value,
				});
				break;
			case "genres":
				var selectedGenres: any = [];
				value.map((genre: any) => {
					selectedGenres.push(genre.title);
				});
				setMusicianFilter({ ...musicianFilter, genres: selectedGenres });
				break;
			case "instruments":
				var selectedInstrument: any = [];
				value.map((inst: any) => {
					selectedInstrument.push(inst.instrumentName);
				});
				setMusicianFilter({
					...musicianFilter,
					instrument: selectedInstrument,
				});
				break;
			case "collaboration":
				setMusicianFilter({ ...musicianFilter, isOpenForCollaboration: value });
				break;
			case "location":
				getLocationLatLng(value).then((result) => {
					setMusicianFilter((prevState: any) => {
						return {
							...prevState,
							latitude: result.lat,
							longitude: result.lng,
						};
					});
				});
				setMusicianFilter((prevData: any) => {
					if (prevData.cities === value) {
						setIsDistanceActive(true);

						return {
							...prevData,
							cities: value,
						};
					} else {
						setIsDistanceActive(false);
						return {
							...prevData,
							cities: value,
						};
					}
				});
				break;
			case "acceptGigs":
				setMusicianFilter({ ...musicianFilter, acceptGigs: value });
				break;
			case "distanceFromCity":
				setMusicianFilter({
					...musicianFilter,
					distanceFromCity: value,
				});
				break;

			default:
				break;
		}
	};

	const clearFilter = () => {
		resetMusicianFilter();
	};

	useEffect(() => {
		getInstrumentsList();
	}, []);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				const dataCopy = [...data];
				const sortedData = dataCopy.sort((a, b) =>
					a.instrumentName.localeCompare(b.instrumentName)
				);
				setInstrumentList(sortedData);
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};
	const handleSearchStringChange = (event: any) => {
		setSearchString(event.target.value);
	};
	function valuetext(value: number) {
		return `${value}`;
	}
	return (
		<>
			<Grid
				className={`${classes.shadowedBox} fliter`}
				container
				xs={12}
				sx={{ height: "fit-content", width: "288px" }}
				spacing={1}
			>
				<Grid item xs={12}>
					<Typography
						variant="h5"
						sx={{
							textAlign: "center",
							fontWeight: "500 !important",
							fontSize: "24px !important",
						}}
					>
						Filters
					</Typography>
				</Grid>

				{/* ADDED A NEW FILTER CONSTRAINT AS NEW FIGMA CHANGES MENTIONED IN NHPWC-943 */}
				<Grid item xs={12} sx={{ pr: "10px" }}>
					<FormHelperText className="jamsession-label">
						{i18n.musicians.filters.musicianNameTitle}
					</FormHelperText>
					<TextField
						inputProps={{
							style: {
								background: "#F8F8F8",

								fontStyle: "normal",
								fontSize: "1rem",
								lineHeight: "25px",
								fontWeight: 900,
								color: "#000000",
								padding: "15px",
							},
						}}
						fullWidth
						variant="outlined"
						value={musicianFilter.firstName}
						onChange={(event) =>
							updateSearchFilter("musicianName", event.target.value)
						}
						error={musicianFilter.firstName.length > 64}
						size="small"
					></TextField>
					<FormHelperText
						sx={{
							fontSize: "0.8125rem",
							color: "#FD4B24",
							textAlign: "right",
						}}
					>
						{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
					</FormHelperText>
				</Grid>

				<Grid item xs={12} sx={{ pr: "10px" }}>
					<FormHelperText className="jamsession-label">
						{i18n.musicians.filters.genresTitle}
					</FormHelperText>
					{/* <TextField
                        inputProps={{
                            style: {
                                background: "#F8F8F8",

                                fontStyle: "normal",
                                fontSize: "1.125rem",
                                lineHeight: "25px",
                                fontWeight: 900,
                                color: "#000000",
                                // padding:"10px",
                            },
                        }}
                        fullWidth
                        variant="outlined"
                        value={genres}
                        onChange={(event) =>
                            setGenres(event.target.value.trimStart())
                        }
                        error={genres.length > 64}
                        size="small"
                    ></TextField> */}
					<Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags"
						options={genreFilter}
						getOptionLabel={(option) => option.title}
						classes={classes}
						// getOptionDisabled={(option) => !!selectedInstrumentFilter.find(element => element.id === option.id)}
						// defaultValue={}
						value={genreFilter.filter((genre: any) => {
							return musicianFilter.genres.includes(genre.title as never);
						})}
						renderInput={(params) => <TextField {...params} />}
						sx={{ width: "100%" }}
						disableClearable={false}
						onChange={(event, newGenres) =>
							updateSearchFilter("genres", newGenres)
						}
					/>
					<FormHelperText
						sx={{
							fontSize: "0.8125rem",
							color: "#FD4B24",
							textAlign: "right",
						}}
					>
						{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
					</FormHelperText>
				</Grid>

				{/* <Grid item xs={12}>
                    <FormHelperText className="jamsession-label">
                        {i18n.musicians.filters.dateTitle}
                    </FormHelperText>
                    <TextField
                        inputProps={{
                            style: {
                                background: "#F8F8F8",

                                fontStyle: "normal",
                                fontSize: "1.125rem",
                                lineHeight: "25px",
                                fontWeight: 900,
                                color: "#000000",
                                // padding:"10px",
                            },
                        }}
                        fullWidth
                        variant="outlined"
                        value={genres}
                        onChange={(event) =>
                            setGenres(event.target.value.trimStart())
                        }
                        error={genres.length > 64}
                        size="small"
                    ></TextField>
                    <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64
                            ? "Enter maximum 64 characters"
                            : ""}
                    </FormHelperText>
                </Grid> */}

				<Grid item xs={12} sx={{ pr: "10px" }}>
					<FormHelperText className="jamsession-label">
						{i18n.musicians.filters.cityTitle}
					</FormHelperText>
					<GoogleLocationService
						onLocationChange={(locationDesc: string) => {
							updateSearchFilter("location", locationDesc);
						}}
					/>
					{/* <TextField
                        inputProps={{
                            style: {
                                background: "#F8F8F8",

                                fontStyle: "normal",
                                fontSize: "1rem",
                                lineHeight: "25px",
                                fontWeight: 900,
                                color: "#000000",
                                padding:"15px",
                            },
                        }}
                        fullWidth
                        variant="outlined"
                        value={musicianFilter.cities}
                        onChange={(event) =>
                            updateSearchFilter("location", event.target.value)
                        }
                        error={musicianFilter.cities.length > 64}
                        size="small"
                    ></TextField> */}
					{/* <FormHelperText
                        sx={{
                            fontSize: "0.8125rem",
                            color: "#FD4B24",
                            textAlign: "right",
                        }}
                    >
                        {genres?.length > 64
                            ? "Enter maximum 64 characters"
                            : ""}
                    </FormHelperText> */}
				</Grid>

				<Grid item xs={12} sx={{ pr: "10px" }}>
					<FormHelperText className="jamsession-label">
						{i18n.musicians.filters.distanceTitle}
					</FormHelperText>
					{/* <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" /> */}
					<Slider
						defaultValue={25}
						disabled={
							musicianFilter.cities == null ||
							musicianFilter.cities.length === 0
						}
						value={displayDistance}
						step={null}
						marks={marks}
						onChange={(event, value) => {
							// Handle the value change
							let updatedValue: any;
							marks.map((mark) => {
								if (mark.value === value) {
									if (mark.value === 100) {
										updatedValue = 100000;
										setDisplayDistance(100);
									} else {
										updatedValue = mark.label;
										setDisplayDistance(mark.value);
									}
									updateSearchFilter("distanceFromCity", +updatedValue);
								}
							});
							// setTest(value);
						}}
						valueLabelFormat={valuetext}
					/>
					<FormHelperText
						sx={{
							fontSize: "0.8125rem",
							color: "#FD4B24",
							textAlign: "right",
						}}
					>
						{genres?.length > 64 ? "Enter maximum 64 characters" : ""}
					</FormHelperText>
				</Grid>

				<Grid item xs={12} sx={{ pr: "10px" }}>
					<FormHelperText className="jamsession-label">
						{i18n.musicians.filters.instrumentsTitle}
					</FormHelperText>
					{/* <Autocomplete
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags1"
                        options={instrumentList}
                        getOptionLabel={(option) => option.instrumentName}
                        classes={classes}
                        // getOptionDisabled={(option) => !!selectedInstrumentList.find(element => element.id === option.id)}
                        // defaultValue={}
                        value={instrumentList.filter((ins:any) => {return musicianFilter.instrument.includes(ins.instrumentName as never)} ) }
                        renderInput={(params) => (
                            <TextField {...params} />
                            )}
                            sx={{ width: '100%' }}
                            onChange={(event, newInstrument) => updateSearchFilter("instruments", newInstrument) }
                            /> */}
					<Autocomplete
						multiple
						limitTags={1}
						id="multiple-limit-tags1"
						classes={classes}
						value={instrumentList.filter((ins: any) => {
							return musicianFilter.instrument.includes(
								ins.instrumentName as never
							);
						})}
						options={instrumentList.sort((a: any, b: any) =>
							a.instrumentName.localeCompare(b.instrumentName)
						)}
						getOptionLabel={(option) => option.instrumentName || ""}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						// filterSelectedOptions
						renderInput={(params) => <TextField {...params} />}
						sx={{ width: "100%" }}
						onChange={(event, newInstrument) =>
							updateSearchFilter("instruments", newInstrument)
						}
					/>
				</Grid>

				{/* Instruments Music Notes */}
				<Grid item xs={12} marginY={1} sx={{ pr: "10px" }}>
					<Grid item>
						{selectedInstrumentFilter.map((instrument) => {
							return (
								<InstrumentSkillLevel
									instrument={instrument.id}
									skillLevel={0}
									editable={true}
									updateInstrumentFilter={updateInstrumentFilter}
								/>
							);
						})}
					</Grid>
				</Grid>

				{/* <Grid item xs={12} sx={{pr:"10px"}}>
                    <Divider />
                </Grid>

							{/* Hiding Collaboration Related settings for social-site launch */}
				{/* <Grid item xs={12} sx={{pr:"10px"}}>
                    <FormControlLabel control={<Checkbox onChange={(event) => updateSearchFilter('collaboration', event.target.checked )} sx={{'&.Mui-checked': {color: '#0273e6'}}}/>} label="Open for Collaboration" checked={musicianFilter.isOpenForCollaboration}/>
                </Grid>


                <Grid item xs={12} sx={{pr:"10px"}}>
                    <FormControlLabel control={<Checkbox onChange={(event) => updateSearchFilter('acceptGigs', event.target.checked )} sx={{'&.Mui-checked': {color: '#0273e6'}}}/>} label="Accept Gigs" checked={musicianFilter.acceptGigs} />
                </Grid> */}

				{isFilterActive && (
					<>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid container xs={12} justifyContent="center" marginY={2}>
							<Button
								variant="text"
								sx={{ border: "2px solid #0273E6", color: "#0273E6" }}
								size="small"
								onClick={() => clearFilter()}
							>
								<Typography variant="h6">Clear Filter</Typography>
							</Button>
						</Grid>
					</>
				)}
			</Grid>
		</>
	);
};

export default MusiciansFilter;

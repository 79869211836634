import * as React from "react";
import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";

import Search from "../../../images/search.svg";
import CloseIcon from "../../../images/close.svg";
import EditIcon from "../../../images/edit.svg";
import DeleteIcon from "../../../images/Admin/trash.svg";

import { visuallyHidden } from "@mui/utils";
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	OutlinedInput,
} from "@mui/material";
import { useEffect, useRef, useState, KeyboardEvent } from "react";
import { useAdminDevciceManagement } from "./useAdminDeviceManagement";

import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useParams } from "react-router-dom";
import { isEmpty, omit } from "lodash";
import { Manufacturer	 } from "./AdminConstants";
// import DeviceActions from "./DeviceActions";

const useStyles = makeStyles(() =>
	createStyles({
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		recordingBtn: {
			width: "100%",
			background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		searchField: {
			// border: "1px solid #C5C5C5",
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				borderRadius: "17px",
			},
		},
		optionButtons: {
			border: "1.5px solid #0273E6 !important",
			borderRadius: "15px !important",
			color: "#0273E6 !important",
			// fontFamily: 'Lexend !important',
			fontStyle: "normal !important",
			fontWeight: "bold !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
		checkBoxFill: {
			color: "#1D2D53 !important",
		},
		actionButtons: {
			fontSize: "1rem",
			fontWeight: "400",
			fontFamily: "Lexend",
			color: "#1D2D53",
		},
	})
);

interface Data {
	deviceId: string,
	deviceManufacturedBy: string,
	deviceManufacturedYear: string,
	deviceVersion: string,
	actions: any
}


type Order = "asc" | "desc";



interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
	width: string;
}

const headCells: readonly HeadCell[] = [
	{
		id: "deviceId",
		numeric: false,
		disablePadding: true,
		label: "Device Id",
		width: "29%",
	},
	{
		id: "deviceManufacturedBy",
		numeric: false,
		disablePadding: false,
		label: "Mfg By",
		width: "25%",
	},
	{
		id: "deviceManufacturedYear",
		numeric: false,
		disablePadding: false,
		label: "Mfg Year",
		width: "16%",
	},
	{
		id: "deviceVersion",
		numeric: false,
		disablePadding: false,
		label: "Version",
		width: "15%",
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "Actions",
		width: "10%",
	},
];

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	isHeadCkeckBoxChecked: any;
	isHeadCheckBoxIndeterminate: any;
	userCanPerformActions: boolean;
}

interface EnhancedTableToolbarProps {
	numSelected: number;
	actionAllCheckBox: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const [transferDevice, setTransferDevice] = useState(false);
	const { userId } = useParams();
	const {
		onSelectAllClick,
		order,
		orderBy,
		isHeadCkeckBoxChecked,
		isHeadCheckBoxIndeterminate,
		userCanPerformActions
	} = props;

	const {
		setOrderByField,
		handleFilterReset,
		orderByField,
	} = useAdminDevciceManagement();

	const classes = useStyles();

	useEffect(() => {
		if (userId) {
			setTransferDevice(true);
		}
	}, []);

	const handleSorting = (field: any) => {
		setOrderByField(() => {
			const newOrderByField = orderByField === field ? `${field}Desc` : field;
			return newOrderByField;
		});
	};

	useEffect(() => {
		if (!isEmpty(orderByField)) {
			handleFilterReset();
		}

	}, [orderByField]);

	return (
		<TableHead sx={{ backgroundColor: "#C2C2C3" }}>
			<TableRow>
				{!transferDevice && (
					<TableCell padding="checkbox"
						sx={{ bgcolor: '#F5F5F5', borderRight: '1px solid #C2C2C3 !important', }}
						style={{
							cursor:
								!userCanPerformActions
									? "not-allowed"
									: "pointer",
						}}
					>
						{/* <Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
						/> */}
						<Checkbox
							className={classes.checkBoxFill}
							color="primary"
							indeterminate={isHeadCheckBoxIndeterminate}
							checked={isHeadCkeckBoxChecked}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
							disabled={!userCanPerformActions}
							style={{
								opacity: !userCanPerformActions ? 0.5 : 1,
							}}
						/>
					</TableCell>
				)}
				{headCells.map((headCell: any, index: number) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						width={headCell.width}
						sx={{
							span: {
								fontWeight: 500,
								//fontSize: "14px !important",
								color: "#000",
								fontFamily: "Lexend",
							},
							bgcolor: '#F5F5F5',
							borderRight: index < headCells.length - 1 ? '1px solid #C2C2C3 !important' : 'none',
							paddingLeft: '8px !important',
						}}
					>
						{headCell.id !== "actions" ? (
							<TableSortLabel
								// active={orderBy === headCell.id}
								active={orderByField === headCell.id}
								// direction={orderBy === headCell.id ? order : "asc"}
								direction={orderByField === headCell.id ? "asc" : "desc"}
								// onClick={createSortHandler(headCell.id)}
								onClick={() => handleSorting(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: "16px",
									color: "#000",
									fontFamily: "Lexend",
								}}
								variant="body1"
								color="textPrimary"
							>
								{headCell.label}
							</Typography>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const { numSelected, actionAllCheckBox } = props;
	const classes = useStyles();
	const [searchMode, setSearchMode] = useState(false);
	const searchRef = useRef("");

	const {
		handleFilterReset,
		searchDeviceId,
		setSearchDeviceId,
		inputRef
	} = useAdminDevciceManagement();

	const handleDeviceSearch = () => {
		if (searchDeviceId !== "") {
			setSearchMode(true);
			const inputValue = inputRef.current.value;
			setSearchDeviceId(inputValue);
			handleFilterReset();
		} else {
			handleDeviceReset();
		}
	};

	const handleDeviceReset = () => {
		setSearchMode(false);
		const inputValue = inputRef.current.value;
		setSearchDeviceId(inputValue);
		handleFilterReset();
		setSearchDeviceId("");
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleDeviceSearch();
		}
	}

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: "#fff",
				}),
			}}
		>
			{numSelected > 0 ? (
				<Grid container xs={12}>
					<Grid item xs={2}>
						<Typography
							sx={{ flex: "1 1 100%" }}
							color="inherit"
							variant="subtitle1"
							component="div"
						>
							{numSelected} selected
						</Typography>
					</Grid>
					<Grid item xs={10} sx={{ display: "flex", justifyContent: 'flex-end' }}>
						<div
							className={classes.searchField}
							style={{ marginRight: "10px" }}
						>
							<FormControl fullWidth variant="outlined" size="small">
								<OutlinedInput
									id="outlined-adornment-password"
									type="text"
									value={searchDeviceId}
									onChange={(e) => setSearchDeviceId(e.target.value)}
									inputRef={searchRef}
									onKeyDown={handleKeyDown}
									endAdornment={
										<>
											{searchMode ? (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleDeviceReset}
														edge="end"
													>
														<img src={CloseIcon} alt="Reset Search" />
													</IconButton>
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleDeviceSearch}
														edge="end"
													>
														<img src={Search} alt="Search" />
													</IconButton>
												</InputAdornment>
											)}
										</>
									}
									placeholder="Search Device ID"
									// onKeyDown={handleKeyPress}
									autoComplete="off"
									ref={inputRef}
									sx={{
										width: "273px",
										height: "30px",
									}}
								/>
							</FormControl>
						</div>

						{numSelected > 0 && (
							<>
								<Button
									onClick={() => actionAllCheckBox()}
									variant="outlined"
									sx={{
										width: "120px",
										marginRight: 1,
										height: "30px",
										borderRadius: "15px",
										border: "1.5px solid #1D2D53",
									}}
								>
									<span className={classes.actionButtons}>Delete</span>
								</Button>
							</>
						)}
					</Grid>
				</Grid>
			) : (
				<Grid container xs={12} spacing={1} style={{ width: "100%" }}>
					<Grid item xs={4} className={classes.searchField}>
						<FormControl fullWidth variant="outlined" size="small">
							<OutlinedInput
								id="outlined-adornment-password"
								type="text"
								value={searchDeviceId}
								onChange={(e) => setSearchDeviceId(e.target.value)}
								inputRef={searchRef}
								onKeyDown={handleKeyDown}
								endAdornment={
									<>
										{searchMode ? (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleDeviceReset}
													edge="end"
												>
													<img src={CloseIcon} alt="Reset Search" />
												</IconButton>
											</InputAdornment>
										) : (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleDeviceSearch}
													edge="end"
												>
													<img src={Search} alt="Search" />
												</IconButton>
											</InputAdornment>
										)}
									</>
								}
								placeholder="Search Device ID"
								// onKeyDown={handleKeyPress}
								autoComplete="off"
								ref={inputRef}
								sx={{
									width: "220px",
									height: "30px",
								}}
							/>
						</FormControl>
					</Grid>
				</Grid>
			)}
		</Toolbar>
	);
}

export default function DeviceManagementTable(props: any) {

	const { onEditDevice, onDeleteDevice, userCanPerformActions } = props;
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof Data>("deviceId");
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const classes = useStyles();

	const {
		deviceManagementFilter,
		setDeviceManagementFilter,
		rowData,
		fetchDevicesData,
		handleFilterReset,
		hasMore,
		selectedDeviceIds,
		setSelectedDeviceIds,
		manufacturer
	} = useAdminDevciceManagement();

	useEffect(() => {
		setSelectedDeviceIds([]);
		setDeviceManagementFilter({
			isGlobalSearch: true,
			deviceManufacturedBy: "",
			deviceManufacturedYear: "",
			deviceVersion: "",
		});
	}, []);




	useEffect(() => {
		if (!isEmpty(omit(deviceManagementFilter, 'isGlobalSearch'))) {
			handleFilterReset();
		}

	}, [deviceManagementFilter]);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const rowCount = rowData.length;

	const isHeaderCheckboxChecked =
		selectedDeviceIds.length === rowCount && selectedDeviceIds.length > 0;
	const isHeaderCheckboxIndeterminate =
		selectedDeviceIds.length > 0 && selectedDeviceIds.length < rowCount;

	const handleHeaderCheckboxClick = () => {
		if (isHeaderCheckboxChecked) {
			// If header checkbox is already checked, deselect all rows
			setSelectedDeviceIds([]);
			// setSelected([]);
		} else {
			// If header checkbox is not checked, select all rows
			const allDevicesId = rowData.map((row: any) => row.id);
			setSelectedDeviceIds(allDevicesId);
			// setSelected(allDevicesId);
		}

	};

	const handleClick = (event: any, id: any) => {
		const selectedIndex = selectedDeviceIds.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = [...selectedDeviceIds, id];
		} else {
			newSelected = [
				...selectedDeviceIds.slice(0, selectedIndex),
				...selectedDeviceIds.slice(selectedIndex + 1),
			];
		}

		setSelectedDeviceIds(newSelected);
		// setSelected(newSelected);

	};

	const isSelected = (id: number) =>
		selectedDeviceIds.indexOf(id) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

	const adminActionAllCheckBox = () => {
		onDeleteDevice(selectedDeviceIds);
		setAnchorEl(null);
	};

	const handleEditUserClick = (device: object) => {
		onEditDevice(device)
	};

	const formatMfgBy = (mfgBy: string) => {
		const { manufacturerName } = manufacturer.find((item: Manufacturer) => item.id === mfgBy) || {};
		return manufacturerName || mfgBy;
	}

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					mb: 2,
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
				}}
			>
				<InfiniteScroll
					dataLength={rowData.length}
					next={
						() => fetchDevicesData(true)
					}
					hasMore={hasMore}
					loader={
						<Box className={classes.spinner}>
							<CircularProgress />
						</Box>
					}
					endMessage={
						rowData.length > 0 && !hasMore ? (
						  <p style={{ textAlign: "center" }}>
							<b>Yay! You have seen it all</b>
						  </p>
						) : null
					  }
				>
					<EnhancedTableToolbar
						actionAllCheckBox={adminActionAllCheckBox}
						numSelected={selectedDeviceIds.length}
					/>

					{rowData.length === 0 && !hasMore ? (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								height: "70px",
								textAlign: "center",
							}}
						>
							<Typography sx={{ fontSize: "16px !important" }}>
								No devices present
							</Typography>
						</div>
					) : (
						<TableContainer
							sx={{ width: "96%", margin: "0 auto", border: "1px solid #c2c2c3", marginBottom: '20px' }}
						>
							<Table
								sx={{ minWidth: 750 }}
								aria-labelledby="tableTitle"
								size={dense ? "small" : "medium"}
							>
								<EnhancedTableHead
									numSelected={selectedDeviceIds.length}
									order={order}
									orderBy={orderBy}
									isHeadCkeckBoxChecked={isHeaderCheckboxChecked}
									isHeadCheckBoxIndeterminate={isHeaderCheckboxIndeterminate}
									onSelectAllClick={handleHeaderCheckboxClick}
									onRequestSort={handleRequestSort}
									rowCount={rowData.length}
									userCanPerformActions={userCanPerformActions}
								/>
								<TableBody>
									{rowData.length === 0 && hasMore && (
										<TableRow>
											<TableCell colSpan={7} align="center">
												<CircularProgress />
											</TableCell>
										</TableRow>
									)}
									{rowData.map((row: any, index: any) => {
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;
										const isColumDisabled = row.isDeviceAssigned || !userCanPerformActions;
										const isColumDisabledAndHasSelectedRows = selectedDeviceIds.length > 0  || isColumDisabled;
										return (
											<TableRow
												hover
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.userName}
												selected={isItemSelected}
												sx={{ cursor: "pointer" }}
											>
												<TableCell padding="checkbox"
													style={{
														opacity: isColumDisabled ? 0.5 : 1,
														cursor:
															isColumDisabled
																? "not-allowed"
																: "pointer",
													}}
												>
													<Checkbox
														className={classes.checkBoxFill}
														color="primary"
														// checked={isItemSelected}
														checked={
															selectedDeviceIds.includes(row.id) ||
															isItemSelected
														}
														onClick={(event) =>
															handleClick(event, row.id)
														}
														inputProps={{
															"aria-labelledby": labelId,
														}}
														disabled={isColumDisabled}
													/>
												</TableCell>

												<TableCell
													component="th"
													id={labelId}
													scope="row"
													padding="none"
													sx={{
														maxWidth: "130px",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														paddingLeft: 1,
														fontSize: "16px !important"
													}}
												>
													{row.deviceId}
												</TableCell>
												<TableCell sx={{ p: 0, fontSize: "16px !important", paddingLeft: "10px"  }} align="left">{formatMfgBy(row.deviceManufacturedBy)}</TableCell>
												<TableCell sx={{ p: 0, fontSize: "16px !important", paddingLeft: "10px" }} align="left">{row.deviceManufacturedYear}</TableCell>
												<TableCell sx={{ p: 0, fontSize: "16px !important", paddingLeft: "10px" }} align="left">{row.deviceVersion}</TableCell>
												<TableCell sx={{ p: 0 }} align="left">
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "left",
															opacity: isColumDisabledAndHasSelectedRows ? 0.5 : 1,
															cursor:
															isColumDisabledAndHasSelectedRows
																	? "not-allowed"
																	: "pointer",
														}}
													>

														<img
															onClick={() => {
																handleEditUserClick(
																	row
																);
															}}
															src={EditIcon}
															alt="edit-icon"
															style={{
																pointerEvents:
																isColumDisabledAndHasSelectedRows
																		? "none"
																		: "auto",
															}}
														/>

														<div
															style={{
																height: "28px",
																width: "1px",
																backgroundColor: "#C2C2C3",
																marginLeft: "6px",
															}}
														></div>

														<img
															onClick={() => {
																onDeleteDevice(
																	row
																);
															}}
															alt="delete-icon"
															src={DeleteIcon}
															style={{
																pointerEvents:
																isColumDisabledAndHasSelectedRows
																		? "none"
																		: "auto",
																marginLeft: "6px"
															}}
														/>

													</div>
												</TableCell>
											</TableRow>
										);
									})}
									{emptyRows > 0 && (
										<TableRow
											style={{ height: (dense ? 33 : 53) * emptyRows }}
										>
											<TableCell colSpan={7} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</InfiniteScroll>
			</Paper>
		</Box>
	);
}

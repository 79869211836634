import {
	Grid,
	Typography,
	Button,
	IconButton,
	Avatar,
	Divider,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import Location from "../../images/location-white.svg";
import Edit from "../../images/edit-2.svg";
import People from "../../images/people.svg";
import Clock from "../../images/clock.svg";
import Calendar from "../../images/calendar1.svg";
import Music from "../../images/music1.svg";
import CardsNo from "../../images/cards no.svg";
import { Link, useNavigate } from "react-router-dom";
import MessageText from "../../images/message-text1.svg";
import Connect from "../../images/profile-add.svg";
import MessageNo from "../../images/message-text no.svg";
import UserRemove from "../../images/user-remove.svg";
import InstrumentSkillLevel from "../Musicians/InstrumentSkills";
import MusicNo from "../../images/music no.svg";
import Cards from "../../images/cards.svg";
import { SocialUSerDetails } from "../../types/types";
import TopBar from "../../images/Profile top gradiant orange.svg";
import BottomBar from "../../images/Profile bottom gradiant blue.svg";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import PeopleRemove from "../../images/profile-remove.svg";
import { useUser } from "../../context/UserContext";
import CloseCircled from "../../images/close-circle.svg";
import TickCircleOrange from "../../images/tick-circle-blue.svg";
import ConnectRequestDialog from "./connectRequestDialog";
import ClockNo from "../../images/clock no.svg";
import VerifyAccount from "../../dialogBox/VerifyAccount";

// Genres
import Americana from "../../icons/Genres/White Outlines/WhiteOutlines_Americana.svg";
import AvantGarde from "../../icons/Genres/White Outlines/WhiteOutlines_Avante-Garde.svg";
import Baroque from "../../icons/Genres/White Outlines/WhiteOutlines_Baroque.svg";
import BigBand from "../../icons/Genres/White Outlines/WhiteOutlines_Big Band.svg";
import Bluegrass from "../../icons/Genres/White Outlines/WhiteOutlines_Bluegrass.svg";
import Blues from "../../icons/Genres/White Outlines/WhiteOutlines_Blues.svg";
import Bollywood from "../../icons/Genres/White Outlines/WhiteOutlines_Bollywood.svg";
import BroadwayShowtunes from "../../icons/Genres/White Outlines/WhiteOutlines_Broadway-Showtunes.svg";
import ChamberMusic from "../../icons/Genres/White Outlines/WhiteOutlines_Chamber Music.svg";
import ClassicRock from "../../icons/Genres/White Outlines/WhiteOutlines_Classic Rock.svg";
import Classical from "../../icons/Genres/White Outlines/WhiteOutlines_Classical.svg";
import ContemporaryClassical from "../../icons/Genres/White Outlines/WhiteOutlines_Contemporary Classical.svg";
import Country from "../../icons/Genres/White Outlines/WhiteOutlines_Country.svg";
import Disco from "../../icons/Genres/White Outlines/WhiteOutlines_Disco.svg";
import Drumline from "../../icons/Genres/White Outlines/WhiteOutlines_Drumline.svg";
import EDM from "../../icons/Genres/White Outlines/WhiteOutlines_EDM.svg";
import ElectronicDance from "../../icons/Genres/White Outlines/WhiteOutlines_Electronic-Dance.svg";
import Folk from "../../images/genres/Folk.png"; //image not available
import Funk from "../../icons/Genres/White Outlines/WhiteOutlines_Funk.svg";
import Genere from "../../images/genres/Genere.png";
import Gospel from "../../icons/Genres/White Outlines/WhiteOutlines_Gospel.svg";
import HiphopRap from "../../icons/Genres/White Outlines/WhiteOutlines_HipHop-Rap.svg";
import House from "../../icons/Genres/White Outlines/WhiteOutlines_House.svg";
import IndieAlternative from "../../icons/Genres/White Outlines/WhiteOutlines_Indie-Alt.svg";
import Jazz from "../../icons/Genres/White Outlines/WhiteOutlines_Jazz.svg";
import KPop from "../../icons/Genres/White Outlines/WhiteOutlines_KPop.svg";
import Latin from "../../icons/Genres/White Outlines/WhiteOutlines_Latin.svg";
import Metal from "../../icons/Genres/White Outlines/WhiteOutlines_Metal.svg";
import Minimalism from "../../icons/Genres/White Outlines/WhiteOutlines_Minimalism.svg";
import NewAge from "../../icons/Genres/White Outlines/WhiteOutlines_New Age.svg";
import Opera from "../../icons/Genres/White Outlines/WhiteOutlines_Opera.svg";
import Orchestral from "../../icons/Genres/White Outlines/WhiteOutlines_Orchestral.svg";
import Pop from "../../icons/Genres/White Outlines/WhiteOutlines_Pop.svg";
import Punk from "../../icons/Genres/White Outlines/WhiteOutlines_Punk.svg";
import RB from "../../icons/Genres/White Outlines/WhiteOutlines_R&B.svg";
import Ragtime from "../../icons/Genres/White Outlines/WhiteOutlines_Ragtime.svg";
import Reggae from "../../icons/Genres/White Outlines/WhiteOutlines_Reggae.svg";
import Reggaeton from "../../icons/Genres/White Outlines/WhiteOutlines_Reggaeton.svg";
import Religious from "../../icons/Genres/White Outlines/WhiteOutlines_Religious.svg";
import Rock from "../../icons/Genres/White Outlines/WhiteOutlines_Rock.svg";
import Romantic from "../../icons/Genres/White Outlines/WhiteOutlines_Romantic.svg";
import Salsa from "../../icons/Genres/White Outlines/WhiteOutlines_Salsa.svg";
import Ska from "../../icons/Genres/White Outlines/WhiteOutlines_Ska-18.svg";
import Soul from "../../icons/Genres/White Outlines/WhiteOutlines_Soul.svg";
import Soundtrack from "../../icons/Genres/White Outlines/WhiteOutlines_Soundtrack.svg";
import Swing from "../../icons/Genres/White Outlines/WhiteOutlines_Swing.svg";
import Symphonic from "../../icons/Genres/White Outlines/WhiteOutlines_Symphonic.svg";
import Techno from "../../icons/Genres/White Outlines/WhiteOutlines_Techno.svg";
import WindEnsemble from "../../icons/Genres/White Outlines/WhiteOutlines_Wind Ensemble.svg";
import DeleteProfile from "../../images/deleteProfile.svg";
import EditProfile from "../../images/editProfile.svg";
import CloseIcon from "../../images/close.svg";
import ErrorAlert from "../Error/ErrorAlert";
import User from "../../services/user";
import ViewImage from "./ViewImage";
import ChooseProfilePictureDialog from "./ChooseProfilePictureDialog";

import { Client } from "@twilio/conversations";
import { useAlertStatus } from "../../context/AlertStatusContext";
import { addConversation, onlyCreateConversation } from "../Messages/api";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../store";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const getIcons = (name: string) => {
	switch (name) {
		case "Americana":
			return (
				<img
					src={Americana}
					style={{ height: "24px", width: "24px" }}
					alt="Americana"
				/>
			);
		case "Avant-garde":
			return (
				<img
					src={AvantGarde}
					style={{ height: "24px", width: "24px" }}
					alt="AvantGarde"
				/>
			);
		case "Baroque":
			return (
				<img
					src={Baroque}
					style={{ height: "24px", width: "24px" }}
					alt="Baroque"
				/>
			);
		case "Big Band":
			return (
				<img
					src={BigBand}
					style={{ height: "24px", width: "24px" }}
					alt="Big Band"
				/>
			);
		case "Bluegrass":
			return (
				<img
					src={Bluegrass}
					style={{ height: "24px", width: "24px" }}
					alt="Bluegrass"
				/>
			);
		case "Blues":
			return (
				<img
					src={Blues}
					style={{ height: "24px", width: "24px" }}
					alt="Blues"
				/>
			);
		case "Bollywood":
			return (
				<img
					src={Bollywood}
					style={{ height: "24px", width: "24px" }}
					alt="Bollywood"
				/>
			);
		case "Broadway/Showtunes":
			return (
				<img
					src={BroadwayShowtunes}
					style={{ height: "24px", width: "24px" }}
					alt="Broadway/Showtunes"
				/>
			);
		case "Chamber-Music":
			return (
				<img
					src={ChamberMusic}
					style={{ height: "24px", width: "24px" }}
					alt="Chamber-Music"
				/>
			);
		case "Classic Rock":
			return (
				<img
					src={ClassicRock}
					style={{ height: "24px", width: "24px" }}
					alt="Classic Rock"
				/>
			);
		case "Classical":
			return (
				<img
					src={Classical}
					style={{ height: "24px", width: "24px" }}
					alt="Classical"
				/>
			);
		case "Contemporary Classical":
			return (
				<img
					src={ContemporaryClassical}
					style={{ height: "24px", width: "24px" }}
					alt="Contemporary Classical"
				/>
			);
		case "Country":
			return (
				<img
					src={Country}
					style={{ height: "24px", width: "24px" }}
					alt="Country"
				/>
			);
		case "Disco":
			return (
				<img
					src={Disco}
					style={{ height: "24px", width: "24px" }}
					alt="Disco"
				/>
			);
		case "Drumline":
			return (
				<img
					src={Drumline}
					style={{ height: "24px", width: "24px" }}
					alt="Drumline"
				/>
			);
		case "EDM":
			return (
				<img src={EDM} style={{ height: "24px", width: "24px" }} alt="EDM" />
			);
		case "Electronic/Dance":
			return (
				<img
					src={ElectronicDance}
					style={{ height: "24px", width: "24px" }}
					alt="Electronic-Dance"
				/>
			);
		case "Folk":
			return (
				<img src={Folk} style={{ height: "24px", width: "24px" }} alt="Folk" />
			);
		case "Funk":
			return (
				<img src={Funk} style={{ height: "24px", width: "24px" }} alt="Funk" />
			);
		case "Genere":
			return (
				<img
					src={Genere}
					style={{ height: "24px", width: "24px" }}
					alt="Genere"
				/>
			);
		case "Gospel":
			return (
				<img
					src={Gospel}
					style={{ height: "24px", width: "24px" }}
					alt="Gospel"
				/>
			);
		case "Hip-hop/Rap": 
			return (
				<img
					src={HiphopRap}
					style={{ height: "24px", width: "24px" }}
					alt="Hip-hop-Rap"
				/>
			);
		case "House":
			return (
				<img
					src={House}
					style={{ height: "24px", width: "24px" }}
					alt="House"
				/>
			);
		case "Indie/Alternative":
			return (
				<img
					src={IndieAlternative}
					style={{ height: "24px", width: "24px" }}
					alt="Indie-Alternative"
				/>
			);
		case "Jazz":
			return (
				<img src={Jazz} style={{ height: "24px", width: "24px" }} alt="Jazz" />
			);
		case "K-Pop":
			return (
				<img src={KPop} style={{ height: "24px", width: "24px" }} alt="K-Pop" />
			);
		case "Latin":
			return (
				<img
					src={Latin}
					style={{ height: "24px", width: "24px" }}
					alt="Latin"
				/>
			);
		case "Metal":
			return (
				<img
					src={Metal}
					style={{ height: "24px", width: "24px" }}
					alt="Metal"
				/>
			);
		case "Minimalism":
			return (
				<img
					src={Minimalism}
					style={{ height: "24px", width: "24px" }}
					alt="Minimalism"
				/>
			);
		case "New Age":
			return (
				<img
					src={NewAge}
					style={{ height: "24px", width: "24px" }}
					alt="New Age"
				/>
			);
		case "Opera":
			return (
				<img
					src={Opera}
					style={{ height: "24px", width: "24px" }}
					alt="Opera"
				/>
			);
		case "Orchestral":
			return (
				<img
					src={Orchestral}
					style={{ height: "24px", width: "24px" }}
					alt="Orchestral"
				/>
			);
		case "Pop":
			return (
				<img src={Pop} style={{ height: "24px", width: "24px" }} alt="Pop" />
			);
		case "Punk":
			return (
				<img src={Punk} style={{ height: "24px", width: "24px" }} alt="Punk" />
			);
		case "R&B":
			return (
				<img src={RB} style={{ height: "24px", width: "24px" }} alt="R&B" />
			);
		case "Ragtime":
			return (
				<img
					src={Ragtime}
					style={{ height: "24px", width: "24px" }}
					alt="Ragtime"
				/>
			);
		case "Reggae":
			return (
				<img
					src={Reggae}
					style={{ height: "24px", width: "24px" }}
					alt="Reggae"
				/>
			);
		case "Reggaeton":
			return (
				<img
					src={Reggaeton}
					style={{ height: "24px", width: "24px" }}
					alt="Reggaeton"
				/>
			);
		case "Religious":
			return (
				<img
					src={Religious}
					style={{ height: "24px", width: "24px" }}
					alt="Religious"
				/>
			);
		case "Rock":
			return (
				<img src={Rock} style={{ height: "24px", width: "24px" }} alt="Rock" />
			);
		case "Romantic":
			return (
				<img
					src={Romantic}
					style={{ height: "24px", width: "24px" }}
					alt="Romantic"
				/>
			);
		case "Salsa":
			return (
				<img
					src={Salsa}
					style={{ height: "24px", width: "24px" }}
					alt="Salsa"
				/>
			);
		case "Ska":
			return (
				<img src={Ska} style={{ height: "24px", width: "24px" }} alt="Ska" />
			);
		case "Soul":
			return (
				<img src={Soul} style={{ height: "24px", width: "24px" }} alt="Soul" />
			);
		case "Soundtrack":
			return (
				<img
					src={Soundtrack}
					style={{ height: "24px", width: "24px" }}
					alt="Soundtrack"
				/>
			);
		case "Swing":
			return (
				<img
					src={Swing}
					style={{ height: "24px", width: "24px" }}
					alt="Swing"
				/>
			);
		case "Symphonic":
			return (
				<img
					src={Symphonic}
					style={{ height: "24px", width: "24px" }}
					alt="Symphonic"
				/>
			);

			case "Techno":
			return (
				<img
					src={Techno}
					style={{ height: "24px", width: "24px" }}
					alt="Techno"
				/>
			);
		case "Wind Ensemble":
			return (
				<img
					src={WindEnsemble}
					style={{ height: "24px", width: "24px" }}
					alt="Wind Ensemble"
				/>
			);
		default:
			return (
				<img
					src={Blues}
					style={{ height: "24px", width: "24px" }}
					alt="Default"
				/>
			);
	}
};

const styles = {
	sideBar: {
		height: "100%",
		display: { xs: "block", md: "block", xl: "block" },
		width: "288px",
		// margin: '32px',
		backgroundSize: "cover",
		borderBottomRightRadius: "60px",
		borderTopLeftRadius: "60px",
		position: "absolute",
		color: "white",
	},
	topSide: {
		minHeight: "14%",
		backgroundColor: "#0273E6",
		borderTopLeftRadius: "60px",
		backgroundImage: `url('${TopBar}')`,
		backgroundRepeat: "no-repeat, repeat",
		backgroundSize: "contain",
	},
	middleSide: {
		minHeight: "67%",
		backgroundImage: `url('${BottomBar}')`,
		backgroundRepeat: "no-repeat, repeat",
		backgroundPosition: "bottom",
		borderBottomRightRadius: "60px",
		backgroundColor: "#0273E6",
		backgroundSize: "contain",
	},
};
interface Props {
	userDetails: SocialUSerDetails;
	ArrayOfInstruments: any;
	setIsLoading: any;
	getUserDetails: any;
}

const SideBar = (props: Props) => {
	const SelfDetails = useUser();
	const dispatch = useDispatch();
	const { updateCurrentConversation, addNotifications, updateParticipants } =
		bindActionCreators(actionCreators, dispatch);

	const { updateAlertStatus } = useAlertStatus();
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	const handleEditProfile = () => {
		navigate("/home/settings/socialProfileSetting/socialProfileSetting");
	};
	const permissions = props?.userDetails?.privacySettings
		? props?.userDetails?.privacySettings
		: {};
	const connectionpermission = props?.userDetails?.canSendConnection
	const user: any = props?.userDetails?.privacySettings
		? props?.userDetails?.privacySettings?.userProfile
		: {};
	const viewer = props?.userDetails?.loggedInUser?.ViewerStatus;
	const genres = user?.genres?.length > 0 ? user?.genres?.split(",") : null;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [deviceStatus, setDeviceStatus] = useState(false);
	const timeoutRef = useRef<any>(undefined);
	const [open, setOpen] = useState(false);
	const [openUnblockDialog, setOpenUnblockDialog] = useState(false);
	const [connectionStatus, setConnectionStatus] = useState("");
	const [maxItemsToShow, setMaxItemsToShow] = useState(4);
	const [maxItemsToShowInst, setMaxItemsToShowInst] = useState(4);
	const [EditVisibility, setEditVisibility] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [file, setFile] = useState<any>("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [result, setResult] = useState("");
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [openEdit, setOpenEdit] = useState(false);
	const [openDeleteDialog, setOPenDeleteDialog] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showAlert, setShowAlert] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertMsg, setAlertMsg] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertStatus, setAlertStatus] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);
	const [editImageStatus, setEditImageStatus] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const [loading, setLoading] = useState(false);

	const handleClick = () => {
		imagePreviewUrl && setModalOpen(true);
	};

	useEffect(() => {
		setImagePreviewUrl(
			(!isAdmin && viewer === "IN_CONNECTION" &&
				permissions?.viewProfilePermission ===
					"No one can view profile. Only me") ||
				(!isAdmin && viewer === "NOT_IN_CONNECTION" &&
					permissions?.viewProfilePermission ===
						"Only my connections can view full profile")
				? ""
				: user?.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${user?.profilePhotoUrl}`
				: ""
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		if (user && user.user?.id === SelfDetails.id) {
			setImagePreviewUrl(
				SelfDetails?.profilePhotoUrl
					? `${process.env.REACT_APP_PICTURE_URL}${SelfDetails?.profilePhotoUrl}`
					: ""
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [SelfDetails]);

	const handleViewAllGenres = () => {
		setMaxItemsToShow(genres.length);
	};

	const handleImageUrl = (callbackImageUrl: string) => {
		setImagePreviewUrl(callbackImageUrl);
	};

	const handleViewLessGenres = () => {
		setMaxItemsToShow(4);
	};

	const handleDialogClose = (success: boolean) => {
		setOpenDialog(false);
	};

	const handleViewAllINstrument = () => {
		setMaxItemsToShowInst(props.ArrayOfInstruments.length);
	};

	const handleViewLessInstrument = () => {
		setMaxItemsToShowInst(4);
	};

	const handleClose = () => {
		setOpen(false);
		setOpenUnblockDialog(false);
		setOpenEdit(false);
	};

	const sendConnectionRequest = (
		requestTo: number | undefined,
		requestStatus: string
	) => {
		try {
			props.setIsLoading(true);
			fetch(`${process.env.REACT_APP_BASE_URL}/api/user-connection-requests`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					requestFrom: {
						id: SelfDetails.userProfileId,
					},
					requestTo: {
						id: requestTo,
					},
					connectionStatus: requestStatus,
				}),
			})
				.then((res) => {
					if(res.status === 502){
						navigate('/defaultPage')
					} else if (res.status > 400) {
						throw new Error("api_error");
					} else {
						props.getUserDetails();
						return res.json();
					}
				})
				.then((data) => {
					props.getUserDetails();
				})
				.catch((err) => {
					props.getUserDetails();
				})
				.finally(() => {
					props.setIsLoading(false);
				});
		} catch (error) {
			props.getUserDetails();
			props.setIsLoading(false);
		}
	};

	/* Nilesh:19/01/24- As we are hiding the device status from sidebar so commented this ping device call. */
	// const handlePingDevice = (deviceId: any) => {
	// 	let message = {
	// 		action: "ping_device",
	// 		timestamp: new Date().toISOString(),
	// 		created_for: deviceId,
	// 		created_by: user?.user?.id,
	// 	};
	// 	WebsocketClientUSerStatus.handlePingDevice(message);
	// 	timeoutRef.current = setTimeout(() => {
	// 		setDeviceStatus(false);
	// 	}, 10000);
	// };

	// const onMessageReceived = (responseMsg: any) => {
	// 	if (responseMsg.action === "ping_device") {
	// 		if (responseMsg.metadata.ping_status === "ONLINE") {
	// 			setDeviceStatus(true);
	// 			globalThis.clearTimeout(timeoutRef.current);
	// 		} else {
	// 			setDeviceStatus(false);
	// 			globalThis.clearTimeout(timeoutRef.current);
	// 		}
	// 	}
	// };

	// useEffect(() => {
	// 	handlePingDevice(props.userDetails.deviceId);
	// 	WebsocketClientUSerStatus.on("ping_device", onMessageReceived);
	// 	return () => {
	// 		WebsocketClientUSerStatus.off("ping_device", onMessageReceived);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const toUnblockUser = {
		userProfile: { id: SelfDetails.userProfileId },
		blockedUser: user?.id,
		blockedUserName: `${user?.user?.firstName} ${user?.user?.lastName}`,
	};

	const formatTimeDifference = (createdAt: string) => {
		const currentDateTime: Date = new Date();
		const notificationDateTime = new Date(createdAt);
		const timeDiffInMilliseconds =
			currentDateTime.getTime() - notificationDateTime.getTime();

		const minutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));
		const hours = minutes / 60;
		const days = hours / 24;
		// const months = days / 30;
		// const years = Math.floor(months / 12);

		// if (minutes === 0 || minutes === -1) {
		//     return "now";
		// }
		// else if (minutes < 60) {
		//     return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
		// }
		// else
		if (hours <= 24) {
			return `Today`;
		} else if (days <= 7) {
			return `This week`;
		} else if (days <= 30) {
			return `This Month`;
		} else {
			return `More than a month`;
		}
	};

	useEffect(() => {
		const handleUserChanged = () => {
			setEditImageStatus(false);
			handleCloseDeleteDialog();
			handleClose();
		};

		User.on("userChanged", handleUserChanged);

		return () => {
			User.off("userChanged", handleUserChanged);
		};
	}, []);

	const deleteUpload = () => {
		setEditImageStatus(true);
		let formData = new FormData();
		formData.append("email", SelfDetails?.email);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/delete-profile-photo`,
			{
				method: "PUT",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					setEditImageStatus(false);
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					User.fetchUserInfo(() => {});
					setImagePreviewUrl("");
					// fetchUserInfo()
					handleCloseDeleteDialog();
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];
		setResult(URL.createObjectURL(file));
		setFile(file);
		setOpenEdit(true);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const photoUpload = (destFile: any) => {
		setEditImageStatus(true);
		const reader = new FileReader();
		let formData = new FormData();
		formData.append("profilePhoto", destFile);
		formData.append("email", SelfDetails.email);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-profiles/update-profile-photo`,
			{
				method: "PUT",
				headers: {
					//   "Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			}
		)
			.then((res) => {
				if(res.status === 502){
					navigate('/defaultPage')
			    } else if (res.status >= 400) {
					setEditImageStatus(false);
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					reader.readAsDataURL(destFile);
					// setImagePreviewUrl(URL.createObjectURL(destFile))
					User.fetchUserInfo(() => {});
					// fetchUserInfo()
				}
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const handleCloseDeleteDialog = () => {
		setOPenDeleteDialog(false);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const extractLocationInfo = (location: string | null): string => {
		if (location === null) {
			return ""; // or handle it in a way that makes sense for your application
		}
		const parts: string[] = location.split(",").map((part) => part.trim());
		const city: string = parts.slice(-3).join(", ");
		return city;
	};

	const sendMessage = async () => {
        setLoading(true);
		const conversationToken = localStorage.getItem("conversationToken");
		const client = new Client(conversationToken as string);
		let title = user.user.firstName + " " + user.user.lastName;
		let uniqueName = [user.user.email].sort().join("-");
		let uniqueNameForCheck = [user.user.email, SelfDetails.email]
			.sort()
			.join("-");
		// Before you use the client, subscribe to the `'initialized'` event.
		client.on("initialized", async () => {
			// const checkConversationExists = await client.getConversationByUniqueName(uniqueName);
			client
				.getConversationByUniqueName(uniqueNameForCheck)
				.then((conversationDetails) => {
					updateCurrentConversation(conversationDetails.sid);
					navigate("/messages");
					setLoading(false);
					// conversationDetails.sendMessage(gigMessage)
					// .then(async (convo) => {
					// 	updateAlertStatus(
					// 		true,
					// 		true,
					// 		"Applied for Gig successfully"
					// 	);
					// 	// setGigMessage("");
					// 	// setOpenSendMessage(false);
					// }).catch((error:any) => {
					// 	console.error('Error sending message:', error);
					// 	updateAlertStatus(
					// 		true,
					// 		false,
					// 		"Something went wrong"
					// 	);
					// });
				})
				.catch(async (error: any) => {
					if (error.message === "Not Found") {
						const convo = await onlyCreateConversation(
							title,
							uniqueName,
							updateParticipants,
							client,
							addNotifications,
							navigate,
							updateCurrentConversation
						);

						if (convo.sid) {
							const conversationDetails = await client.getConversationBySid(
								convo.sid
							);
							updateCurrentConversation(conversationDetails.sid);
							navigate("/messages");

							// conversationDetails.sendMessage(gigMessage).then(() => {
							// 	updateAlertStatus(
							// 		true,
							// 		true,
							// 		"Applied for Gig successfully"
							// 	);
							// 	// setGigMessage("");
							// 	// setOpenSendMessage(false)
							// }).catch((error:any) => {
							// 	console.error('Error sending message:', error);
							// });
						}
					} else {
						updateAlertStatus(true, false, "Something went wrong");
					}
                    setLoading(false);
				});
		});
	};
	const formatNameWithMaxLength = (
		name: string | undefined,
		maxChars: number
	): string => {
		return name && name.length > maxChars
			? name.substring(0, maxChars) + "..."
			: name || "";
	};

	const renderUserName = () => {
		if (user?.id === SelfDetails?.userProfileId) {
			return formatNameWithMaxLength(
				`${user?.user?.firstName} ${user?.user?.lastName}`,
				15
			);
		}

		const identityPermission =
			props?.userDetails?.privacySettings?.identityPermission;
		const isInConnection = viewer === "IN_CONNECTION";
		const isNameVisibleToAll = identityPermission === "Name is visible to all";
		const isNameVisibleOnlyToConnections =
			identityPermission ===
			"Name is visible only to my connections. Other see nickname";
		const showNickname =
			(isNameVisibleOnlyToConnections && !isInConnection) ||
			identityPermission === "Name is not visible. Instead just show nickname";

		if (showNickname) {
			return (
				<Typography variant="h3" sx={{ fontSize: "24px", textAlign: "center" }}>
					@{formatNameWithMaxLength(user?.nickname, 10)}
				</Typography>
			);
		} else if (
			isNameVisibleToAll ||
			(isNameVisibleOnlyToConnections && isInConnection)
		) {
			return formatNameWithMaxLength(
				`${user?.user?.firstName} ${user?.user?.lastName}`,
				15
			);
		}

		// Default case, you may want to handle this based on your specific logic
		return null;
	};

	return (
		<>
			<Grid sx={styles.sideBar}>
				<Grid sx={styles.topSide}>
					<Grid container sx={{ flexDirection: "column", rowGap: 1 }}>
						<Grid item sx={{ alignSelf: "center" }}>
							<IconButton
								sx={{
									"&:hover": { backgroundColor: "transparent" },
									width: 160,
									height: 160,
									marginTop: "-22px",
									marginLeft: "5px",
								}}
								onMouseEnter={() => setEditVisibility(true)}
								onMouseLeave={() => setEditVisibility(false)}
							>
								<Avatar
									alt={`${user?.user?.firstName} ${user?.user?.lastName}`}
									src={imagePreviewUrl}
									onClick={handleClick}
									sx={{
										width: 160,
										height: 160,
										border: "4px solid #e1a06c",
										marginLeft: "5px",
										backgroundColor: imagePreviewUrl ? "#CAD2D4" : "",
									}}
								/>
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
				{SelfDetails.userAccountStatus !== "SUSPEND" &&
					EditVisibility &&
					!isAdmin && viewer === "SELF" && (
						<div
							onMouseEnter={() => setEditVisibility(true)}
							onMouseLeave={() => setEditVisibility(false)}
							style={{
								backgroundColor: "rgba(255, 255, 255, 0.51)",
								margin: "auto",
								zIndex: "1",
								width: "160px",
								height: "80px",
								position: "absolute",
								marginTop: "-40px",
								left: "52%",
								transform: "translate(-50%, -50%)",
								borderRadius: "0 0 150px 150px",
							}}
						>
							<Grid container>
								<Grid xs={6} sx={{ textAlignLast: "start", mt: "3px" }}>
									<label>
										<img
											id="photo-edit"
											onClick={() => setOpenDialog(true)}
											src={EditProfile}
											alt="Upload"
											style={{
												cursor: "pointer",
												marginLeft: imagePreviewUrl !== "" ? "30px" : "60px",
												marginTop: "14px",
											}}
										/>
										{/* <input
                                        id="photo-edit"
                                        type="file"
                                        accept="image/*"
                                        style={{ display: "contents" }}
                                        onChange={(e) => handleOpenEditor(e)}
                                    /> */}
									</label>
								</Grid>
								{imagePreviewUrl !== "" && (
									<Grid xs={6} sx={{ textAlignLast: "end" }}>
										<IconButton
											size="small"
											onClick={() => setOPenDeleteDialog(true)}
											sx={{ marginRight: "30px", marginTop: "14px" }}
										>
											<img src={DeleteProfile} alt="" />
										</IconButton>
									</Grid>
								)}
							</Grid>
						</div>
					)}

				<Grid sx={styles.middleSide}>
					<Grid
						container
						sx={{ flexDirection: "column", pl: 1, rowGap: 1, pb: 3, pt: 1 }}
					>
						{/* {(viewer === 'SELF')
                            // || ((permissions?.identityPermission !== 'Name is not visible. Instead just show nickname' && viewer !== 'SELF')
                            || (permissions?.identityPermission === 'Name is visible only to my connections. Other see nickname' && viewer === 'IN_CONNECTION')
                            || (permissions?.identityPermission === 'Name is visible to all')
                            ?
                            <Grid item sx={{ alignSelf: 'self-start', pl: 1 }}>
                                <Tooltip title={`${user?.user?.firstName} ${user?.user?.lastName}`}>
                                    <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '32px' }} >
                                        {`${user?.user?.firstName && user?.user?.firstName?.length > 10 ? user?.user?.firstName?.substring(0, 10) + "..." : user?.user?.firstName}
                                    ${user?.user?.lastName && user?.user?.lastName?.length > 10 ? user?.user?.lastName?.substring(0, 10) + "..." : user?.user?.lastName}`}
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            : ""} */}
						{/* <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '32px', ml: '9px' }}>
                                                  {user?.id === SelfDetails?.userProfileId
                                                    ? formatNameWithMaxLength(`${user?.user?.firstName} ${user?.user?.lastName}`, 15)
                                                    : props?.userDetails?.privacySettings?.identityPermission ===
                                                    'Name is not visible. Instead just show nickname'
                                                    ? (
                                                        <>
                                                          <Typography variant="h3" sx={{ fontSize: '24px', textAlign: 'center' }}>
                                                            @{formatNameWithMaxLength(user?.nickname, 10)}
                                                          </Typography>
                                                        </>
                                                      )
                                                    : props?.userDetails?.privacySettings?.identityPermission ===
                                                    'Name is visible to all'
                                                    ? formatNameWithMaxLength(`${user?.user?.firstName} ${user?.user?.lastName}`, 15)
                                                    : props?.userDetails?.privacySettings?.identityPermission ===
                                                    'Name is visible only to my connections. Other see nickname'
                                                    ? viewer === 'IN_CONNECTION'
                                                    ? formatNameWithMaxLength(`${user?.user?.firstName} ${user?.user?.lastName}`, 15)
                                                    : (
                                                        <>
                                                          <Typography variant="h3" sx={{ fontSize: '24px', textAlign: 'center'  }}>
                                                            @{formatNameWithMaxLength(user?.nickname, 10)}
                                                          </Typography>
                                                        </>
                                                      )
                                                    : ''}
                                        </Typography> */}

						<Typography
							variant="h3"
							sx={{ fontWeight: "bold", fontSize: "32px", ml: "9px" }}
						>
							{renderUserName()}
						</Typography>

						<Grid
							item
							container
							sx={{ flexDirection: "column", pl: 1, rowGap: 1 }}
						>
							<Typography variant="caption" style={{ fontSize: "16px" }}>
								{props?.userDetails?.privacySettings?.identityPermission ===
									"Name is visible to all" ||
								(props?.userDetails?.privacySettings?.identityPermission ===
									"Name is visible only to my connections. Other see nickname" &&
									!isAdmin && viewer === "IN_CONNECTION") ||
								user?.id === SelfDetails?.userProfileId
									? formatNameWithMaxLength(`${user?.nickname}`, 18)
									: ""}
							</Typography>
							<Grid item container>
								<Typography
									variant="caption"
									sx={{ fontSize: "1rem", marginLeft: "-4px", display: "flex" }}
								>
									<img
										src={Location}
										style={{ width: "24px", height: "24px" }}
										alt="Location"
									/>
									{user?.location}
									{/* {extractLocationInfo(user?.location)} */}
								</Typography>
							</Grid>
						</Grid>
						{(!isAdmin && viewer !== "SELF" &&
							permissions?.viewProfilePermission ===
								"No one can view profile. Only me") ||
						(!isAdmin && viewer === "NOT_IN_CONNECTION" &&
							permissions?.viewProfilePermission ===
								"Only my connections can view full profile") ? (
							""
						) : (
							<>
								{SelfDetails.authorities[0].name !== "ROLE_ADMIN" && !isAdmin
									? SelfDetails.userAccountStatus !== "SUSPEND" && (
											<>
												{/* start of buttons */}
												{!isAdmin && viewer === "SELF" ? (
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															fullWidth
															sx={{
																fontSize: "16px",
																fontWeight: 600,
																fontFamily: "Lexend",
																backgroundColor: "white",
																borderRadius: "17px",
																color: "#0273E6",
																"&:hover": { background: "#EEF6FF" },
															}}
															variant="contained"
															onClick={handleEditProfile}
														>
															<img
																src={Edit}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Edit Profile
														</Button>
													</Grid>
												) : (
													""
												)}

												{!isAdmin && viewer !== "SELF" &&
												(permissions?.messagePermission ===
													"Anyone can message me without being in connection" ||
													(permissions?.messagePermission ===
														"Only connections can send me message" &&
														!isAdmin && viewer === "IN_CONNECTION")) ? (
													// ((viewer === "IN_CONNECTION" || viewer === "REQUEST_RECIEVED" || viewer === "REQUESTED" ||
													// connectionStatus === "PENDING") &&
													// 	permissions?.messagePermission !==
													// 		"No one can send me messages") ||
													// (permissions?.messagePermission ===
													// 	"Anyone can message me without being in connection" &&
													// 	viewer === "NOT_IN_CONNECTION")
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															fullWidth
															sx={{
																fontSize: "16px",
																backgroundColor: "white",
																borderRadius: "17px",
																color: "#0273E6",
																"&:hover": { background: "#EEF6FF" },
															}}
															variant="contained"
															onClick={sendMessage}
															disabled={loading}
														>
														{loading ? (
															<CircularProgress size={24} />
														) : (
															<>
															<img
																src={MessageText}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Message
															</>
														)}
														</Button>
													</Grid>
												) : (
													""
												)}

												{(!isAdmin && viewer === "NOT_IN_CONNECTION" ||
													!isAdmin && viewer === "REQUEST_REJECTED") && connectionpermission === true &&
												permissions?.connectionRequestPermission !==
													"No one can send me connection request" ? (
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															fullWidth
															sx={{
																fontSize: "16px",
																background:
																	"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
																borderRadius: "17px",
																color: "white",
															}}
															variant="contained"
															onClick={() => setOpen(true)}
														>
															<img
																src={Connect}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Connect
														</Button>
														<ConnectRequestDialog
															open={open}
															user={user}
															handleClose={handleClose}
															setLoading={props.setIsLoading}
															userProfileId={user?.id}
															getUserDetails={props.getUserDetails}
															setConnectionStatus={setConnectionStatus}
														/>
														{/* // musicTemp={musicTemp} setMusicTemp={setMusicTemp} */}
													</Grid>
												) : (
													""
												)}
												{viewer === "REQUESTED" ||
												connectionStatus === "PENDING" ? (
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															fullWidth
															sx={{
																fontSize: "16px",
																backgroundColor: "white",
																borderRadius: "17px",
																color: "#0273E6",
																"&:hover": { background: "#EEF6FF" },
															}}
															variant="contained"
															onClick={() =>
																sendConnectionRequest(user?.id, "WITHDRAWN")
															}
														>
															<img
																src={PeopleRemove}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Withdraw
														</Button>
													</Grid>
												) : viewer === "REQUEST_RECIEVED" ? (
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															sx={{
																fontSize: "16px",
																backgroundColor: "white",
																borderRadius: "17px",
																color: "#0273E6",
																marginLeft: "-2%",
																"&:hover": { background: "#EEF6FF" },
															}}
															color="success"
															variant="contained"
															onClick={() =>
																sendConnectionRequest(user?.id, "ACCEPTED")
															}
														>
															<img
																src={TickCircleOrange}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Accept
														</Button>
														&nbsp;
														<Button
															sx={{
																fontSize: "16px",
																backgroundColor: "white",
																borderRadius: "17px",
																color: "#0273E6",
																"&:hover": { background: "#EEF6FF" },
															}}
															variant="contained"
															onClick={() =>
																sendConnectionRequest(user?.id, "REJECTED")
															}
														>
															<img
																src={CloseCircled}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Reject
														</Button>
													</Grid>
												) : viewer === "UNBLOCK" ? (
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															fullWidth
															sx={{
																fontSize: "16px",
																backgroundColor: "white",
																borderRadius: "17px",
																color: "#0273E6",
																"&:hover": { background: "#EEF6FF" },
															}}
															variant="contained"
															onClick={() => setOpenUnblockDialog(true)}
														>
															<img
																src={PeopleRemove}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Unblock
														</Button>
														<VerifyAccount
															open={openUnblockDialog}
															handleClose={handleClose}
															unblock
															toUnblockUser={toUnblockUser}
															getUserDetails={props.getUserDetails}
															source="sideBar"
														/>
													</Grid>
												) : (
													""
												)}

												{viewer === "NOT_IN_CONNECTION" &&
												permissions?.connectionRequestPermission ===
													"No one can send me connection request" ? (
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															disabled
															fullWidth
															sx={{
																fontSize: "16px",
																backgroundColor: "#0273E6 ! important",
																borderRadius: "17px",
																color: "#CFD9DF ! important",
																border: "1px solid #CFD9DF",
															}}
															variant="contained"
														>
															<img
																src={UserRemove}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Not open for connection
														</Button>
													</Grid>
												) : viewer === "IN_CONNECTION" &&
												  permissions?.messagePermission ===
														"No one can send me messages" ? (
													<Grid
														item
														sx={{
															fontSize: "16px",
															alignSelf: "center",
															minWidth: "259px",
														}}
													>
														<Button
															disabled
															fullWidth
															sx={{
																backgroundColor: "#0273E6 ! important",
																borderRadius: "17px",
																color: "#CFD9DF ! important",
																border: "1px solid #CFD9DF",
															}}
															variant="contained"
														>
															<img
																src={MessageNo}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Not open for messages
														</Button>
													</Grid>
												) : (
													""
												)}

												{viewer === "NOT_IN_CONNECTION" &&
												permissions?.connectionRequestPermission ===
												"Only (1st level) friends of my connections can connect me" &&
													connectionpermission === false ? (
													<Grid
														item
														sx={{ alignSelf: "center", minWidth: "259px" }}
													>
														<Button
															disabled
															fullWidth
															sx={{
																fontSize: "16px",
																backgroundColor: "#0273E6 ! important",
																borderRadius: "17px",
																color: "#CFD9DF ! important",
																border: "1px solid #CFD9DF",
															}}
															variant="contained"
														>
															<img
																src={UserRemove}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Not open for connection
														</Button>
													</Grid>
												) : viewer === "IN_CONNECTION" &&
													permissions?.messagePermission ===
													"No one can send me messages" ? (
													<Grid
														item
														sx={{
															fontSize: "16px",
															alignSelf: "center",
															minWidth: "259px",
														}}
													>
														<Button
															disabled
															fullWidth
															sx={{
																backgroundColor: "#0273E6 ! important",
																borderRadius: "17px",
																color: "#CFD9DF ! important",
																border: "1px solid #CFD9DF",
															}}
															variant="contained"
														>
															<img
																src={MessageNo}
																style={{ width: "24px", height: "24px" }}
																alt="Edit"
															/>
															&nbsp; Not open for messages
														</Button>
													</Grid>
												) : (
													""
												)}

												{/* end of buttons */}
											</>
									  )
									: ""}

								<Grid item sx={{ mt: 2 }}>
									<Typography variant="h6" sx={{ fontSize: "24px" }}>
										Summary
									</Typography>
									<Divider
										sx={{ background: "#A5D1FF", mr: 1, my: 1 }}
										variant="fullWidth"
									/>
								</Grid>

								<Grid
									item
									container
									sx={{ flexDirection: "column", rowGap: 1 }}
								>
									<Grid item container>
										{viewer === "SELF" ? (
											<Button
												sx={{
													backgroundColor: "#3292F3",
													":hover": { backgroundColor: "#3292F3" },
													fontFamily: "Lexend",
												}}
												startIcon={<img src={People} alt="Connections" />}
												onClick={() => {
													if (viewer === "SELF") {
														navigate("/musicians/connections");
													}
												}}
											>
												{props.userDetails.totalConnections} connections
											</Button>
										) : (
											<>
												<img
													src={People}
													style={{ width: "24px", height: "24px" }}
													alt="connection"
												/>
												&nbsp;
												<Typography
													variant="caption"
													sx={{ fontSize: "14px", fontFamily: "Lexend" }}
												>
													{props.userDetails.totalConnections} connections
												</Typography>
											</>
										)}
									</Grid>
									{viewer !== "SELF" ? (
										<Grid item container>
											<Link
												to={`/musicians/mutuals/${user?.id}`}
												style={{ display: "flex" }}
											>
												<img
													src={People}
													style={{ width: "24px", height: "24px" }}
													alt="mutual"
												/>
												&nbsp;
												<Typography
													variant="caption"
													sx={{ color: "white", fontSize: "14px" }}
													onClick={() => {
														if (!isAdmin && viewer === "SELF") {
															navigate(
																`/musicians/mutuals/${props.userDetails?.loggedInUser}`
															);
														}
													}}
												>
													{props.userDetails.noOfMutualConnections} mutual
													connections
												</Typography>
											</Link>
										</Grid>
									) : (
										""
									)}

									{/* {viewer !== 'SELF' ?
                                <Grid container >
                                    <Grid xs={3}>
                                        <StyledAvatarGroup max={3}>
                                            <Avatar alt="Remy Sharp" sx={{ width: 17, height: 17, fontSize: '11px' }} src="/static/images/avatar/1.jpg" sizes="sm" />
                                            <Avatar alt="Travis Howard" sx={{ width: 17, height: 17, fontSize: '11px' }} src="/static/images/avatar/2.jpg" sizes="sm" />
                                            <Avatar alt="Travis Howard" sx={{ width: 17, height: 17, fontSize: '11px' }} src="/static/images/avatar/2.jpg" sizes="sm" />
                                            <Avatar alt="Travis Howard" sx={{ width: 17, height: 17, fontSize: '11px' }} src="/static/images/avatar/2.jpg" sizes="sm" />
                                        </StyledAvatarGroup>
                                    </Grid>

                                    <Grid xs={9}>&nbsp;
                                        <Typography variant="caption" >
                                        <img src={People} style={{ width: '13px', height: '13px' }} alt="Location" />&nbsp;
                                            {props.userDetails.noOfMutualConnections} mutual connections
                                        </Typography>
                                    </Grid>
                                </Grid>
                                : ""} */}

									{/* {viewer === "SELF" ||
									permissions?.onlineStatusPermission === "Visible to anyone" ||
									permissions?.onlineStatusPermission === "Hide last seen" ||
									//  || permissions?.onlineStatusPermission !== 'Visible to non') ||
									//     (permissions?.onlineStatusPermission === 'Visible to anyone' || viewer !== 'SELF') ||
									(viewer === "IN_CONNECTION" &&
										permissions?.onlineStatusPermission ===
											"Visible only to my connections") ? (
										<>
											{user?.online || viewer === "SELF" ? (
												<Grid item container>
													<img
														src={Clock}
														style={{ width: "24px", height: "24px" }}
														alt="Location"
													/>
													&nbsp;
													<Typography
														variant="caption"
														sx={{ fontSize: "14px" }}
													>
														User Online
													</Typography>
												</Grid>
											) : (
												""
											)}
											{!user?.online &&
											permissions?.onlineStatusPermission ===
												"Hide last seen" &&
											viewer !== "SELF" ? (
												<Grid item container>
													<img
														src={ClockNo}
														style={{ width: "24px", height: "24px" }}
														alt="Location"
													/>
													&nbsp;
													<Typography
														variant="caption"
														sx={{ fontSize: "14px" }}
													>
														User Offline
													</Typography>
												</Grid>
											) : (
												""
											)}

											{!user?.online &&
											permissions?.onlineStatusPermission !==
												"Hide last seen" &&
											viewer !== "SELF" ? (
												<Grid item container>
													<img
														src={ClockNo}
														style={{ width: "24px", height: "24px" }}
														alt="Location"
													/>
													&nbsp;
													<Typography
														variant="caption"
														sx={{ fontSize: "14px" }}
													>
														Last seen{" "}
														{formatTimeDifference(
															user?.lastSeen ? user?.lastSeen : ""
														)}
													</Typography>
												</Grid>
											) : (
												""
											)}
										</>
									) : (
										""
									)} */}
									{!isAdmin && viewer !== "SELF" &&
									(permissions?.onlineStatusPermission ===
										"Visible to anyone" ||
										(!isAdmin && viewer === "IN_CONNECTION" &&
											permissions?.onlineStatusPermission ===
												"Visible only to my connections")) ? (
										// <Grid item xs={3.5} container>

										<Grid item xs={3.5} container style={{ maxWidth: "95%" }}>
											<img
												src={
													user?.online || viewer === "SELF" ? Clock : ClockNo
												}
												style={{ width: "24px", height: "24px" }}
												alt="Location"
											/>
											&nbsp;
											<Typography variant="caption" sx={{ fontSize: "14px" }}>
												{/* {user?.online || viewer === 'SELF' ? 'Online' : 'Offline'} */}
												{user?.online || viewer === "SELF"
													? "Online"
													: `Last seen ${formatTimeDifference(
															user?.lastSeen || ""
													  )}`}
											</Typography>
										</Grid>
									) : (
										<Grid item xs={3.5} container style={{ maxWidth: "95%" }}>
										<img
												src={
													user?.online || viewer === "SELF" ? Clock : ClockNo
												}
												style={{ width: "24px", height: "24px" }}
												alt="Location"
											/>
											&nbsp;
										<Typography variant="caption" sx={{ fontSize: "14px" }}>
												{/* {user?.online || viewer === 'SELF' ? 'Online' : 'Offline'} */}
												{user?.online 
													? "Online"
													: "Offline"}
											</Typography>
											</Grid>
									)}
									{/* {viewer === 'SELF' ?
                                <Grid item container>
                                    <img src={deviceStatus ? Online : Offline} style={{ width: '24px', height: '24px' }} alt="Location" />&nbsp;
                                    <Typography variant="caption" sx={{ fontSize: '14px' }} >
                                        Device {deviceStatus ? 'Online' : 'Offline'}
                                    </Typography>
                                </Grid>
                                : ''} */}

									<Grid item container>
										<img
											src={Calendar}
											style={{ width: "24px", height: "24px" }}
											alt="Location"
										/>
										&nbsp;
										<Typography variant="caption" sx={{ fontSize: "14px" }}>
											Member since{" "}
											{new Date(
												user?.user?.createdDate ? user?.user?.createdDate : ""
											).toLocaleString("en-us", {
												month: "short",
												year: "numeric",
											})}
										</Typography>
									</Grid>

									{/* Hiding Collaboration Related setting for social-site launch */}
									{
										// user.isCollaborationActive === true ? 
										<>
											{!isAdmin && viewer === 'SELF' || permissions?.connectionRequestPermission !== 'No one can send me connection request' ?
											user?.openForCollab &&
											<Grid item container>
												<img src={Music} style={{ width: '24px', height: '24px' }} alt="Location" />&nbsp;
												<Typography variant="caption" sx={{ fontSize: '14px' }}>
													Open for Collaboration
												</Typography>
											</Grid>
											: ""}

												{(!isAdmin && viewer !== "SELF" &&
													permissions?.connectionRequestPermission ===
														"No one can send me connection request") ||
												(!isAdmin && viewer === "SELF" && user?.openForCollab === false) ? (
													<Grid item container>
														<img
															src={MusicNo}
															style={{ width: "24px", height: "24px" }}
															alt="Location"
														/>
														&nbsp;
														<Typography variant="caption" sx={{ fontSize: "14px" }}>
															Not open for Jam
														</Typography>
													</Grid>
												) : (
													""
												)}

												{!isAdmin && viewer === "SELF" ||
												permissions?.connectionRequestPermission !==
													"No one can send me connection request"
													? user?.openForGigs && (
															<Grid item container>
																<img
																	src={Cards}
																	style={{ width: "24px", height: "24px" }}
																	alt="Location"
																/>
																&nbsp;
																<Typography
																	variant="caption"
																	sx={{ fontSize: "14px" }}
																>
																	Open for Gigs
																</Typography>
															</Grid>
													)
													: ""}

										{(!isAdmin && viewer !== 'SELF' && permissions?.connectionRequestPermission === 'No one can send me connection request') || (!isAdmin && viewer === 'SELF' && user?.openForGigs === false) ?
											<Grid item container>
												<img src={CardsNo} style={{ width: '24px', height: '24px' }} alt="Location" />&nbsp;
												<Typography variant="caption" sx={{ fontSize: '14px' }}>
													Not open for Gigs
												</Typography>
											</Grid>
											: ''}

										</>
										// : <></>
									}
                        </Grid>
                        {props.ArrayOfInstruments.length > 0 ?
                            <>
                                <Grid item sx={{ mt: 2 }}>
                                    <Typography variant="h6" sx={{ fontSize: '24px' }} >Instruments</Typography>
                                    <Divider sx={{ background: '#A5D1FF', mr: 1, my: 1 }} variant="fullWidth" />
                                </Grid>
                                <Grid item sx={{ margin: "-6% 0 -6% -6%", ml: 0 }}>
                                    {
                                        props.ArrayOfInstruments.slice(0, maxItemsToShowInst).map((instrument: any) => {
                                            return <InstrumentSkillLevel instrument={instrument.instrument.id} skillLevel={instrument.skillLevel ? instrument.skillLevel : 0} editable={true} instrumentLabel={instrument.instrument.instrumentName} background='transparent' color='white' update='false' sideBar='true' IconSize='true' />
                                        })
                                    }
                                    {props.ArrayOfInstruments.length > maxItemsToShowInst ?
                                        <Button variant="text" onClick={handleViewAllINstrument} sx={{ color: 'white' }}>
                                            View All
                                        </Button>
                                        :
                                        props.ArrayOfInstruments.length > 4 &&
                                        <Button variant="text" onClick={handleViewLessInstrument} sx={{ color: 'white' }}>
                                            Show less
                                        </Button>
                                    }
                                </Grid>
                            </>
                            : ''}

								{genres && genres.length > 0 ? (
									<>
										<Grid item sx={{ mt: maxItemsToShowInst === 4 ? 5 : 9 }}>
											<Typography variant="h6" sx={{ fontSize: "24px" }}>
												Genre
											</Typography>
											<Divider
												sx={{ background: "#A5D1FF", mr: 1, my: 1 }}
												variant="fullWidth"
											/>
										</Grid>
										<Grid item>
											<Grid
												container
												rowGap={1}
												sx={{ color: "white" }}
												columns={{ xs: 4, sm: 8, md: 12 }}
											>
												{genres &&
													genres
														.slice(0, maxItemsToShow)
														.map((item: any, index: any) => (
															// <div key={index}>{item}</div>
															<Grid container item xs={12}>
																{/* <TripOriginIcon sx={{ color: "#" + Math.floor(Math.random() * 16777215).toString(16), fontSize: '12px' }} /> */}
																{getIcons(item)}&nbsp;
																{/* <Tooltip title={item}> */}
																<Typography
																	variant="caption"
																	sx={{ fontSize: "16px" }}
																>
																	{item}
																	{/* {`${item.length > 6 ? item.substring(0,6)+ "..." : item}`} */}
																</Typography>
																{/* {`${user?.user?.firstName && user?.user?.firstName?.length > 10 ? user?.user?.firstName?.substring(0, 10) + "..." : user?.user?.firstName}`
                                                }                                                */}
																{/* </Tooltip> */}
															</Grid>
														))}
												{genres.length > maxItemsToShow ? (
													<Button
														variant="text"
														onClick={handleViewAllGenres}
														sx={{ color: "white" }}
													>
														View All
													</Button>
												) : (
													genres.length > 4 && (
														<Button
															variant="text"
															onClick={handleViewLessGenres}
															sx={{ color: "white" }}
														>
															Show less
														</Button>
													)
												)}

												{/* {genres?.map((item: any) =>
                                            <Grid container item xs={12} >
                                                {getIcons(item)}&nbsp;
                                                <Typography variant="caption" sx={{ fontSize: '16px' }}>{item}
                                                </Typography>
                                            </Grid>
                                        )} */}
											</Grid>
										</Grid>
									</>
								) : (
									""
								)}
							</>
						)}
					</Grid>
				</Grid>
			</Grid>

			{/* <ImageCropper
                open={openEdit}
                onClose={handleClose}
                imagePreviewUrl={result}
                setImagePreviewUrl={setImagePreviewUrl}
                setResult={setFile}
                post={false}
                photoUpload={photoUpload}
                cover={false}
                editImageStatus={editImageStatus}
            /> */}

			{isModalOpen && (
				<ViewImage
					open={isModalOpen}
					handleClose={() => setModalOpen(false)}
					ImageUrl={imagePreviewUrl}
				/>
			)}

			{openDialog && (
				<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid item xs={12}>
						<ChooseProfilePictureDialog
							open={openDialog}
							handleClose={handleDialogClose}
							handleImageUrlCallback={handleImageUrl}
							permissions={permissions}
							editImageStatus={editImageStatus}
						/>
					</Grid>
				</Grid>
			)}

			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete Profile Picture</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							disabled={editImageStatus}
							onClick={() => deleteUpload()}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</>
	);
};

export default SideBar;

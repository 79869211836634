import * as React from "react";
import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Search from "../../../images/search.svg";
import CloseIcon from "../../../images/close.svg";
import { visuallyHidden } from "@mui/utils";
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	Menu,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { useEffect, useState, KeyboardEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDeviceManageFilter } from "./DeviceManagementProvider/useDeviceManageFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import DeviceActions from "./DeviceActions";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		recordingBtn: {
			width: "100%",
			background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		searchField: {
			"& fieldset": {
				borderWidth: "1px 1px 1px 0",
				borderStyle: "solid",
				borderColor: "#C5C5C5",
				borderTopRightRadius: "17px",
				borderBottomRightRadius: "17px",
			},
		},
		filterSearchByDropdown: {
			// border: "1px solid #C5C5C5",
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				// borderRadius: "17px",
				borderTopLeftRadius: "17px",
				borderBottomLeftRadius: "17px",
			},
		},
		optionButtons: {
			border: "1.5px solid #0273E6 !important",
			borderRadius: "15px !important",
			color: "#0273E6 !important",
			// fontFamily: 'Lexend !important',
			fontStyle: "normal !important",
			fontWeight: "bold !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
		checkBoxFill: {
			color: "#1D2D53 !important",
		},
	})
);

interface Data {
	deviceId: string;
	deviceStatus: string;
	userName: string;
	subscription: string;
	lastOnline: string;
	actions: string;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createData(
	deviceId: string,
	deviceStatus: string,
	userName: string,
	subscription: string,
	lastOnline: string,
	actions: string
): Data {
	return {
		deviceId,
		deviceStatus,
		userName,
		subscription,
		lastOnline,
		actions,
	};
}

// const rows = [
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// 	createData("1", "Inactive", "Pradip G", "Active", "2023-08-31 9:30AM", ""),
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = "asc" | "desc";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function stableSort<T>(
	array: readonly T[],
	comparator: (a: T, b: T) => number
) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
	width: string;
}

const headCells: readonly HeadCell[] = [
	{
		id: "deviceId",
		numeric: false,
		disablePadding: true,
		label: "DEVICE ID",
		width: "15%",
	},
	{
		id: "deviceStatus",
		numeric: false,
		disablePadding: false,
		label: "DEVICE STATUS",
		width: "15%",
	},
	{
		id: "userName",
		numeric: false,
		disablePadding: false,
		label: "USER NAME",
		width: "15%",
	},
	{
		id: "subscription",
		numeric: false,
		disablePadding: false,
		label: "SUBSCRIPTION",
		width: "15%",
	},
	{
		id: "lastOnline",
		numeric: false,
		disablePadding: false,
		label: "LAST ONLINE",
		width: "20%",
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "ACTIONS",
		width: "20%",
	},
];

interface EnhancedTableProps {
	numSelected: number;
	// onRequestSort: (
	// 	event: React.MouseEvent<unknown>,
	// 	property: keyof Data
	// ) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	// order: Order;
	orderBy: string;
	rowCount: number;
	isHeadCkeckBoxChecked: any;
	isHeadCheckBoxIndeterminate: any;
	headerCheckBoxAction: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		onSelectAllClick,
		// order,
		orderBy,
		// numSelected,
		// rowCount,
		// onRequestSort,
		isHeadCkeckBoxChecked,
		isHeadCheckBoxIndeterminate,
		// headerCheckBoxAction,
	} = props;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [order, setOrder] = React.useState<Order>("asc");

	const { setOrderByField, handleFilterReset, orderByField } =
		useDeviceManageFilter();

	// const createSortHandler =
	// 	(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
	// 		onRequestSort(event, property);
	// 	};

	const classes = useStyles();

	const handleSorting = (field: any) => {
		setOrderByField((prevValue: any) => {
			const newOrderByField = orderByField === field ? `${field}Desc` : field;
			return newOrderByField;
		});
	};

	useEffect(() => {
		handleFilterReset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderByField]);

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" sx={{bgcolor:'#F5F5F5',borderRight: '1px solid #C2C2C3 !important',}}>
					{/* <Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/> */}
					<Checkbox
						className={classes.checkBoxFill}
						color="primary"
						indeterminate={isHeadCheckBoxIndeterminate}
						checked={isHeadCkeckBoxChecked}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{headCells.map((headCell:any, index:number) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						// sortDirection={orderBy === headCell.id ? order : false}
						width={headCell.width}
						sx={{
							span:{ 
								fontWeight: 500,
								fontSize: "16px !important",
								color: "#000",
								fontFamily: "Lexend",
							},
							borderRight: index < headCells.length - 1 ? '1px solid #C2C2C3 !important' : 'none',
							paddingLeft: '8px !important',
							
							bgcolor:'#F5F5F5',

						}}
					>
						{headCell.id !== "actions" ? (
							<TableSortLabel
								// active={orderBy === headCell.id}
								active={orderByField === headCell.id}
								direction={orderByField === headCell.id ? "asc" : "desc"}
								// onClick={createSortHandler(headCell.id)}
								onClick={() => handleSorting(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: "16px",
									color: "#000",
									fontFamily: "Lexend",
								}}
								variant="body1"
								color="textPrimary"
							>
								{headCell.label}
							</Typography>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
	numSelected: number;
	actionAllCheckBox: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const { numSelected, actionAllCheckBox } = props;
	const classes = useStyles();
	const [searchMode, setSearchMode] = useState(false);

	const {
		// rowData,
		// setRowData,
		// setPageNumber,
		deviceStatus1,
		subscriptionStatus1,
		handleFilterReset,
		setSubscriptionStatus1,
		setDeviceStatus1,
		setSearchDeviceName,
		searchDeviceName,
		inputRef,
		filterById,
		setFilterById,
	} = useDeviceManageFilter();

	useEffect(() => {
		setSubscriptionStatus1("");
		setDeviceStatus1("");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const subscriptionMenu = [
		{ id: "menu", title: "All Types", value: "" },
		{ id: "menu1", title: "Free", value: "Free" },
		{ id: "menu2", title: "Subscriber", value: "Subscriber" },
	];
	const deviceStatusMenu = [
		{ id: "menu", title: "All Types", value: "" },
		{ id: "menu1", title: "Unregistered", value: "Unregistered" },
		{ id: "menu2", title: "Transferred", value: "Transferred" },
		{ id: "menu3", title: "Registered", value: "Registered" },
	];
	const filterByMenu = [
		{ id: "menu1", title: "User Name", value: "" },
		{ id: "menu2", title: "Device", value: "device" },
	];

	useEffect(() => {
		handleFilterReset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subscriptionStatus1, deviceStatus1]);

	const handleMusicSearch = () => {
		if (searchDeviceName !== "") {
			setSearchMode(true);

			const inputValue = inputRef.current.value;
			setSearchDeviceName(inputValue);

			handleFilterReset();
		}
	};
	const handleMusicReset = () => {
		setSearchMode(false);

		const inputValue = inputRef.current.value;
		setSearchDeviceName(inputValue);

		handleFilterReset();

		setSearchDeviceName("");
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if(e.key === "Enter") {
			handleMusicSearch();
		}
	}

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: "#fff",
				}),
			}}
		>
			{numSelected > 0 ? (
				<>
					<Grid container xs={12}>
						<Grid item xs={3}>
							<Typography
								sx={{
									flex: "1 1 100%",
									fontSize: "1.125rem",
									fontWeight: "500",
									color: "#000",
								}}
								color="inherit"
								variant="subtitle1"
								component="div"
							>
								{numSelected} selected
							</Typography>
						</Grid>
						<Grid item xs={9}>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									gap: "15px",
								}}
							>
								<div style={{ display: "flex" }}>
									<div className={classes.filterSearchByDropdown}>
										<FormControl sx={{ width: "80%" }}>
											<Select
												size="small"
												style={{ paddingLeft: "10px" }}
												displayEmpty
												inputProps={{ "aria-label": "Without label" }}
												id="demo-simple-select"
												value={filterById}
												sx={{ width: "97px", height: "30px" }}
												onChange={(e) => {
													setFilterById(e.target.value);
												}}
											>
												{filterByMenu.map((menu: any) => {
													return (
														<MenuItem
															value={menu.value}
															// onClick={() => handleDeviceStatus(menu.id)}
														>
															<span
																style={{
																	fontSize: "1rem",
																	fontWeight: "400",
																	fontFamily: "Lexend",
																}}
															>
																{menu.title}
															</span>
														</MenuItem>
													);
												})}
											</Select>
										</FormControl>
									</div>
									<div className={classes.searchField}>
										<FormControl variant="outlined">
											<OutlinedInput
												id="outlined-adornment-password"
												type="text"
												value={searchDeviceName}
												onChange={(e) => setSearchDeviceName(e.target.value)}
												ref={inputRef}
												onKeyDown={handleKeyDown}
												endAdornment={
													<>
														{searchMode ? (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={handleMusicReset}
																	edge="end"
																>
																	<img src={CloseIcon} alt="Reset Search" />
																</IconButton>
															</InputAdornment>
														) : (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={handleMusicSearch}
																	edge="end"
																>
																	<img src={Search} alt="Search" />
																</IconButton>
															</InputAdornment>
														)}
													</>
												}
												placeholder={
													filterById === ""
														? "Search user name"
														: "Search Device ID"
												}
												// onKeyDown={handleKeyPress}
												sx={{
													width: "166px",
													height: "30px",
												}}
												autoComplete="off"
											/>
										</FormControl>
									</div>
								</div>

								{numSelected > 0 && (
									<Button
										onClick={() => actionAllCheckBox("SUSPEND")}
										variant="outlined"
										sx={{
											width: "209px",
											height: "30px",
											marginRight: 1,
											borderRadius: "15px",
											border: "1.5px solid #1D2D53",
										}}
									>
										<span
											style={{
												fontSize: "1rem",
												fontWeight: "400",
												fontFamily: "Lexend",
												color: "#1D2D53",
											}}
										>
											Unregister Device
										</span>
									</Button>
								)}
							</div>
						</Grid>
						{/* <Grid item xs={3}>

						</Grid> */}
					</Grid>
				</>
			) : (
				<Grid container  spacing={1}  xs={12} >
					<Grid item xs={6}>
						<div style={{ display: "flex" }}>
							<div className={classes.filterSearchByDropdown}>
								<FormControl sx={{ width: "80%" }}>
									<Select
										size="small"
										style={{ paddingLeft: "10px" }}
										displayEmpty
										inputProps={{ "aria-label": "Without label" }}
										id="demo-simple-select"
										value={filterById}
										sx={{ width: "130px", height: "30px" }}
										onChange={(e) => {
											setFilterById(e.target.value);
										}}
									>
										{filterByMenu.map((menu: any) => {
											return (
												<MenuItem
													value={menu.value}
													// onClick={() => handleDeviceStatus(menu.id)}
												>
													<span
														style={{
															fontSize: "1rem",
															fontWeight: "400",
															fontFamily: "Lexend",
														}}
													>
														{menu.title}
													</span>
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</div>
							<div className={classes.searchField}>
								<FormControl variant="outlined">
									<OutlinedInput
										id="outlined-adornment-password"
										type="text"
										value={searchDeviceName}
										onChange={(e) => setSearchDeviceName(e.target.value)}
										ref={inputRef}
										onKeyDown={handleKeyDown}
										endAdornment={
											<>
												{searchMode ? (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleMusicReset}
															edge="end"
														>
															<img src={CloseIcon} alt="Reset Search" />
														</IconButton>
													</InputAdornment>
												) : (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleMusicSearch}
															edge="end"
														>
															<img src={Search} alt="Search" />
														</IconButton>
													</InputAdornment>
												)}
											</>
										}
										placeholder={
											filterById === ""
												? "Search user name"
												: "Search Device ID"
										}
										// onKeyDown={handleKeyPress}
										sx={{
											width: "180px",
											height: "30px",
										}}
										autoComplete="off"
									/>
								</FormControl>
							</div>
						</div>
					</Grid>
					<Grid item xs={3}>
						<FormControl sx={{ width: "80%" }}>
							<Select
								size="small"
								style={{ paddingRight: "10px" }}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								className={classes.folderDropdown}
								id="demo-simple-select"
								value={subscriptionStatus1}
								sx={{ width: "110px", height: "30px" }}
								onChange={(e) => setSubscriptionStatus1(e.target.value)}
							>
								{subscriptionMenu.map((menu: any) => {
									return (
										<MenuItem value={menu.value}>
											<span
												style={{
													fontSize: "1rem",
													fontWeight: "400",
													fontFamily: "Lexend",
												}}
											>
												{menu.title}
											</span>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={3}>
						<FormControl sx={{ width: "80%" }}>
							<Select
								size="small"
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								className={classes.folderDropdown}
								id="demo-simple-select"
								value={deviceStatus1}
								sx={{ width: "110px", height: "30px" }}
								onChange={(e) => setDeviceStatus1(e.target.value)}
							>
								{/* <MenuItem value="default">Device Status</MenuItem> */}
								{deviceStatusMenu.map((menu: any) => {
									return (
										<MenuItem
											value={menu.value}
											// onClick={() => handleDeviceStatus(menu.id)}
										>
											<span
												style={{
													fontSize: "1rem",
													fontWeight: "400",
													fontFamily: "Lexend",
												}}
											>
												{menu.title}
											</span>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			)}
			{/* {numSelected > 0 && (
				<Button
					onClick={() => actionAllCheckBox("SUSPEND")}
					variant="outlined"
					sx={{ width: "209px", height: "30px", marginRight: 1 }}
				>
					<span
						style={{
							fontSize: "1rem",
							fontWeight: "400",
							fontFamily: "Lexend",
							color: "#1D2D53",
						}}
					>
						Unregister Device
					</span>
				</Button>
			)} */}
		</Toolbar>
	);
}

export default function DeviceManagementTb() {
	// const [order, setOrder] = React.useState<Order>("asc");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [orderBy, setOrderBy] = React.useState<keyof Data>("deviceId");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [selected, setSelected] = React.useState<readonly string[]>([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [rows, setRows] = useState<Data[]>([]);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const [openDialog, setOpenDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [option, setOption] = useState<any>();
	const [type, setType] = useState("");
	const navigate = useNavigate();
	const [headerCheckBoxClicked, setHeaderCheckBoxClicked] = useState(false);

	const {
		deviceManagementFilter,
		// filterByDevice,
		setDeviceManagementFilter,
		// isFilterActive,
		rowData,
		setRowData,
		getAllDevices1,
		// filterByDeviceAndModelType,
		hasMore,
		// setPageNumber,
		handleFilterReset,
		selectedDeviceIds,
		setSelectedDeviceIds,
	} = useDeviceManageFilter();

	useEffect(() => {
		setSelectedDeviceIds([]);
		setDeviceManagementFilter({
			isGlobalSearch: true,
			userProfileId: null,
			genres: [],
			cities: "",
			firstName: "",
			lastName: "",
			nickName: "",
			instrument: [],
			isOpenForCollaboration: false,
			acceptGigs: false,
			deviceModelType: "",
			activationDate: null,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClick1 = (event: React.MouseEvent<HTMLElement>, data: any) => {
		setAnchorEl(event.currentTarget);
		setSelectedRow(data);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		handleFilterReset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceManagementFilter]);

	const options = ["Transfer Device", "Unregister Device", "Replace Device"];

	// const handleRequestSort = (
	// 	event: React.MouseEvent<unknown>,
	// 	property: keyof Data
	// ) => {
	// 	const isAsc = orderBy === property && order === "asc";
	// 	setOrder(isAsc ? "desc" : "asc");
	// 	setOrderBy(property);
	// };

	const rowCount = rowData.length;

	// const isHeaderCheckboxChecked =
	// 	selectedDeviceIds.length === rowCount && selectedDeviceIds.length > 0;
	const isHeaderCheckboxChecked =
		selectedDeviceIds.length > 0 && headerCheckBoxClicked;
	const isHeaderCheckboxIndeterminate =
		selectedDeviceIds.length > 0 && selectedDeviceIds.length < rowCount;

	const handleHeaderCheckboxClick = () => {
		if (isHeaderCheckboxChecked) {
			// If header checkbox is already checked, deselect all rows
			setSelectedDeviceIds([]);
			setSelected([]);
			setHeaderCheckBoxClicked((isClicked) => !isClicked);
		} else {
			// If header checkbox is not checked, select all rows
			const allUserIds = rowData
				.filter((row: any) => row.deviceStatus === "Active")
				.map((row: any) => ({ userId: row.userId, deviceId: row.deviceId }));
			setSelectedDeviceIds(allUserIds);
			setSelected(allUserIds);
			setHeaderCheckBoxClicked((isClicked) => !isClicked);
		}

	};

	useEffect(() => {
		if (isHeaderCheckboxChecked) {
			const allUserIds = rowData
				.filter((row: any) => row.deviceStatus === "Active")
				.map((row: any) => ({ userId: row.userId, deviceId: row.deviceId }));
			setSelectedDeviceIds(allUserIds);
			setSelected(allUserIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowData]);

	// const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	if (event.target.checked) {
	// 		const newSelected = rowData.map((n: any) => n.userName);
	// 		setSelected(newSelected);
	// 		return;
	// 	}
	// 	setSelected([]);
	// };

	// const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
	// 	const selectedIndex = selected.indexOf(name);
	// 	let newSelected: readonly string[] = [];

	// 	if (selectedIndex === -1) {
	// 		newSelected = newSelected.concat(selected, name);
	// 	} else if (selectedIndex === 0) {
	// 		newSelected = newSelected.concat(selected.slice(1));
	// 	} else if (selectedIndex === selected.length - 1) {
	// 		newSelected = newSelected.concat(selected.slice(0, -1));
	// 	} else if (selectedIndex > 0) {
	// 		newSelected = newSelected.concat(
	// 			selected.slice(0, selectedIndex),
	// 			selected.slice(selectedIndex + 1)
	// 		);
	// 	}

	// 	setSelected(newSelected);
	// };

	const updatedDeviceStatus = (updatedDevices: any) => {
		if (rowData !== null) {
			const updatedRowData = rowData.map((device: any) => {
				const matchingDevice = updatedDevices.find(
					(updatedDevice: any) => updatedDevice.deviceId === device.deviceId
				);

				if (matchingDevice) {
					return {
						...device,
						deviceStatus:
							matchingDevice.status === "Inactive"
								? "Inactive"
								: matchingDevice.status === "Active"
								? "Active"
								: "",
					};
				}

				return device;
			});

			setRowData(updatedRowData);
		}
	};

	const adminAction1 = (action: string) => {
		setAnchorEl(null);
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-devices/unregister-multiple-devices`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(selectedDeviceIds),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					updatedDeviceStatus(data);
					setSelectedDeviceIds([]);
				})
				.catch((err) => {
				});
		} catch (error) {
		}
	};

	// const handleClick = (event: any, deviceId: any) => {
	// 	const selectedIndex = selectedDeviceIds.indexOf(deviceId);
	// 	let newSelected = [];

	// 	if (selectedIndex === -1) {
	// 		newSelected = [...selectedDeviceIds, deviceId];
	// 	} else {
	// 		newSelected = [
	// 			...selectedDeviceIds.slice(0, selectedIndex),
	// 			...selectedDeviceIds.slice(selectedIndex + 1),
	// 		];
	// 	}

	// 	setSelectedDeviceIds(newSelected);
	// 	setSelected(newSelected);

	// };

	// const handleChangePage = (event: unknown, newPage: number) => {
	// 	setPage(newPage);
	// };

	const handleClick = (
		event: any,
		deviceId: any,
		userId: any,
		deviceStatus: any
	) => {
		const selectedIndex = selectedDeviceIds.findIndex(
			(device: any) => device.deviceId === deviceId
		);
		let newSelectedDevices = [];

		if (selectedIndex === -1 && deviceStatus === "Active") {
			// If device is not already selected, add it to the array
			const newDevice = {
				userId: userId, // Replace with the actual userId
				deviceId: deviceId,
			};
			newSelectedDevices = [...selectedDeviceIds, newDevice];

			// setHeaderCheckBoxClicked(true);
		} else {
			newSelectedDevices = [
				...selectedDeviceIds.slice(0, selectedIndex),
				...selectedDeviceIds.slice(selectedIndex + 1),
			];
		}

		setSelectedDeviceIds(newSelectedDevices);
		setSelected(newSelectedDevices);

	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	// const isSelected = (name: string) => selected.indexOf(name) !== -1;
	const isSelected = (deviceId: string, deviceStatus: string) =>
		selectedDeviceIds.findIndex((item: any) => item.deviceId === deviceId) !==
			-1 && deviceStatus !== "Inactive";

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	// const visibleRows = React.useMemo(
	// 	() =>
	// 		stableSort(rows, getComparator(order, orderBy)).slice(
	// 			page * rowsPerPage,
	// 			page * rowsPerPage + rowsPerPage
	// 		),
	// 	[order, orderBy, page, rowsPerPage]
	// );

	const handleDialogClose = (success: boolean) => {

		handleFilterReset();
		setOpenDialog(false);
	};

	const handleOptionClick = (optionId: any) => {
		setAnchorEl(null);
		setOpenDialog(true);
		setOption(optionId);

		if (optionId === 0) {
			setType("transfer");
			navigate(`/users-management/${selectedRow?.userId}`, {
				state: selectedRow,
			});
		} else if (optionId === 1) {
			setType("unregister");
		} else if (optionId === 2) {
			setType("replace");
		}
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<InfiniteScroll
					dataLength={rowData.length}
					next={getAllDevices1}
					hasMore={hasMore}
					loader={
						<Box className={classes.spinner}>
							<CircularProgress />
						</Box>
					}
					endMessage={
						<p style={{ textAlign: "center" }}>
							<b>Yay! You have seen it all</b>
						</p>
					}
				>
					<EnhancedTableToolbar
						actionAllCheckBox={adminAction1}
						numSelected={selectedDeviceIds.length}
					/>
					<TableContainer>
						<Table
							stickyHeader
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size={dense ? "small" : "medium"}
						>
							<EnhancedTableHead
								numSelected={selectedDeviceIds.length}
								// order={order}
								orderBy={orderBy}
								// onSelectAllClick={handleSelectAllClick}
								headerCheckBoxAction={() =>
									setHeaderCheckBoxClicked((isClicked) => !isClicked)
								}
								isHeadCkeckBoxChecked={isHeaderCheckboxChecked}
								isHeadCheckBoxIndeterminate={isHeaderCheckboxIndeterminate}
								onSelectAllClick={handleHeaderCheckboxClick}
								// onRequestSort={handleRequestSort}
								rowCount={rowData.length}
							/>
								{
									rowData.length === 0 && hasMore && (
										<TableRow>
										<TableCell colSpan={7} align="center">
											<Box>
												<CircularProgress />
											</Box>
										</TableCell>
									</TableRow>
									)
								}
							<TableBody>
								{rowData.length === 0 && !hasMore ? ( // Check if there are no users
									<TableRow>
										<TableCell colSpan={7} align="center" sx={{fontSize: "16px !important"}}>
											No devices present
										</TableCell>
									</TableRow>
								) : (
									rowData.map((row: any, index: any) => {
										const isItemSelected = isSelected(
											row.deviceId,
											row.deviceStatus
										);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.deviceId}
												selected={isItemSelected}
												sx={{
													cursor: "pointer",
												}}
											>
												<TableCell padding="checkbox">
													<Checkbox
														className={classes.checkBoxFill}
														color="primary"
														checked={isItemSelected}
														disabled={row.deviceStatus === "Inactive"}
														onClick={(event) =>
															handleClick(
																event,
																row.deviceId,
																row.userId,
																row.deviceStatus
															)
														}
														inputProps={{
															"aria-labelledby": labelId,
														}}
													/>
												</TableCell>
												<TableCell
													component="th"
													id={labelId}
													scope="row"
													padding="none"
													sx={{fontSize: "16px !important"}}
												>
													{row.deviceId}
												</TableCell>
												<TableCell align="left" sx={{fontSize: "16px !important"}}>{row.deviceStatus}</TableCell>
												<TableCell
													align="left"
													sx={{
														maxWidth: "130px",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														paddingLeft: 1,
														fontSize: "16px !important"
													}}
												>
													{row.userName}
												</TableCell>
												<TableCell align="left" sx={{fontSize: "16px !important"}}>{row.subscription}</TableCell>
												<TableCell
													align={row.lastOnline === "-" ? "center" : "left"}
													sx={{fontSize: "16px !important"}}
												>
													{row.lastOnline}
												</TableCell>
												<TableCell align="left">
													<div
														style={{
															opacity: selectedDeviceIds.length > 0 ? 0.5 : 1,

															cursor:
																selectedDeviceIds.length > 0
																	? "not-allowed"
																	: "pointer",
														}}
													>
														<IconButton
															aria-label="more"
															id="long-button"
															aria-controls={open ? "long-menu" : undefined}
															aria-expanded={open ? "true" : undefined}
															aria-haspopup="true"
															onClick={(e) => handleClick1(e, row)}
															style={{
																pointerEvents:
																	selectedDeviceIds.length > 0
																		? "none"
																		: "auto",
															}}
														>
															<MoreVertIcon />
														</IconButton>
													</div>
												</TableCell>
											</TableRow>
										);
									})
								)}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: (dense ? 33 : 53) * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</InfiniteScroll>
			</Paper>

			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{options.map((option, index) => (
					<MenuItem
						key={option}
						selected={option === "Pyxis"}
						onClick={() => handleOptionClick(index)}
						disabled={selectedRow?.deviceStatus === "Inactive" && option === "Unregister Device"}
					>
						{option}
					</MenuItem>
				))}
			</Menu>

			<DeviceActions
				type={type}
				open={openDialog}
				handleClose={handleDialogClose}
				data={selectedRow}
			/>
		</Box>
	);
}

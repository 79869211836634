import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Card,
	CircularProgress,
	Grid,
	List,
	Menu,
	MenuItem,
	Snackbar,
	Tooltip,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import EventsFilter from "./EventsFilter";
// import EventSearchResult from "./EventSearchResult";
import AllEvents from "./AllEvent";
import NewEvent from "./NewEvent";
import DownArrow from "../../images/arrowdownOrange.svg";
import RightArrow from "../../images/right arrow.svg";
import { useUser } from "../../context/UserContext";
import { useEventsFilter } from "./useEventsFilter";
import EventCardCom from "./EventCardCom";
import RightArrowBlack from "../../images/arrow-right-black.svg";
import HorizontalCard from "../commonComponents/HorizontalCardComp";
import InfiniteScroll from "react-infinite-scroll-component";
import GoogleEvents from "./GoogleEvents";
import SimilarArtists from "../Social/similarArtists";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";
import useAdminRedirect from "../../hooks/useAdminRedirect/useAdminRedirect";
import { useNavigate } from "react-router-dom";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "22px",
			color: "customColor.orange",
		},
		eventMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "22px",
			color: "#fffff",
			borderColor: "#FF702A !importent",
		},
		downArraow: {
			color: "customColor.orange !importent",
		},
		shadowedBox: {
			borderRadius: "15px",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);

const Events = (props: any) => {
	useAdminRedirect();
	// const [activeMenu, setActiveMenu] = useState("allEvents");
	const [activeMenuLable, setActiveMenuLabel] = useState("All Events");
	const [openPopup, setOpenPopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [postNewEventClicked, setPostNewEventClicked] = useState(false);
	const [eventsFilters, setEventFilters] = useState<any>();
	const open = Boolean(anchorEl);
	const token = localStorage.getItem("sessionId");

	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	// const [viewMoreFlag, setViewMoreFlag] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [itemPerPage, setItemPerPage] = useState(8);

	// const [loading, setLoading] = useState(false);
	const classes = useStyles();
	const user = useUser();
	const locationMaxLength = 10;
	// const isNavigatedFromGlobalSearch: boolean = location?.state?.globalSearch;
	const navigate= useNavigate();
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	const {
		eventsList,
		eventsFilter,
		setEventsList,
		handleEventFilterReset,
		resetEventsFilter,
		isFilterActive,
		pageNumber,
		setPageNumber,
        isNavigatedFromGlobalSearch,
		setIsNavigatedFromGlobalSearch,
        loading,
		setLoading,
		totalEvents,
		viewMoreEvents,
		setViewMoreEvents,
		eventActiveMenu,
		setEventActiveMenu,
	} = useEventsFilter();

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});
	let initialItemPerPage = 0;

	// Set the initialItemPerPage based on the condition
		if (windowSize.width >= 768 && windowSize.width <= 1220) {
		initialItemPerPage = 6;
	} else {
		initialItemPerPage = 8;
	}

	const [itemPerPageEvent, setItemPerPageEvent] = useState(initialItemPerPage);
	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if ((!userDetails.firstName || !userDetails.lastName || !userDetails.location) && !isAdmin) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

	useEffect(() => {
		if (
			eventsFilter?.title?.length === 0 &&
			eventsFilter?.startDateTime === null &&
			eventsFilter?.cityCountry?.length === 0 &&
			eventsFilter?.distanceFromCity === 50 &&
			eventsFilter?.genres?.length === 0 &&
			!isNavigatedFromGlobalSearch
		) {
			fetchData();
			// setViewMoreEvents(false);
		} else {
			handleEventFilterReset(
				isNavigatedFromGlobalSearch ? "global-search" : "search",
				isNavigatedFromGlobalSearch ? "EVENT" : null
			);
			setViewMoreEvents(true);
		}

		return () => {
			setIsNavigatedFromGlobalSearch(false);
		}
	}, [eventsFilter, eventActiveMenu]);

	// useEffect(() => {
	// 	if (!isNavigatedFromGlobalSearch) {
	// 		resetEventsFilter();
	// 	}
	// }, []);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [windowSize]);
	const eventsMenu = [
		{
			id: "allEvents",
			label: "All Events",
			navigateMenu: "",
		},
		{
			id: "myPostedEvents",
			label: "My Posted Events",
			navigateMenu: "",
		},
		{
			id: "myLikedEvents",
			label: "My Liked Events",
			navigateMenu: "",
		},
	];
	// const changeMenu = (menu: any) => {
	//  setEventActiveMenu(menu.id);
	//  navigate(menu.navigateMenu);
	//     if(menu.id === 'postNewEvent'){
	//         setOpenPopup(true);
	//     }
	// };
	const handleSuccess = () => {
		setOpenPopup(false);
		fetchData();
		// props.onSuccess();
	};
	const handleOnClose = () => {
		setOpenPopup(false);
		setEventActiveMenu("allEvents");
	};

	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLElement>,
		index: any,
		menuLable: any
	) => {
		setEventActiveMenu(index);
		setActiveMenuLabel(menuLable);
		if (eventActiveMenu === "allEvents") {
			setViewMoreEvents(false);
		}
		// else{
		//  setViewMoreEvents(true)
		// }
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const fetchData = () => {
		setEventsList([]);
		setLoading(true);
		setHasMore(true);
		setPageNumber(1);
		try {
			setHasMore(true);
			// setEventsList([]);
			let myLikedEventsFlag = false;
			let myPostedEventsFlag = false;
			if (eventActiveMenu === "myPostedEvents") {
				myPostedEventsFlag = true;
			}
			if (eventActiveMenu === "myLikedEvents") {
				myLikedEventsFlag = true;
			}

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/get-events-or-gigs?type=EVENT&myLiked=${myLikedEventsFlag}&myPosted=${myPostedEventsFlag}&ops=listing&page=0&size=${itemPerPageEvent}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						title: "",
						cityCountry: "",
						startDateTime: null,
						distanceFromCity: 0,
						genresArray: [],
						instrumentArray: [],
					}),
				}
			)
				.then((res) => {
					if (res.status !== 200) {
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setEventsList([]);
						setLoading(false);
					} else {
						setEventsList(data);
						setPageNumber(1);
						setLoading(false);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const fetchDataForScroll = () => {
		setLoading(true);
		try {
			let myLikedEventsFlag = false;
			let myPostedEventsFlag = false;
			if (eventActiveMenu === "myPostedEvents") {
				myPostedEventsFlag = true;
			}
			if (eventActiveMenu === "myLikedEvents") {
				myLikedEventsFlag = true;
			}

			let searchString = isFilterActive
				? `get-events-or-gigs?type=EVENT&myLiked=&myPosted=&page=${pageNumber}&size=10&ops=${
						isNavigatedFromGlobalSearch ? "global-search" : "search"
				  }`
				: `get-events-or-gigs?type=EVENT&myLiked=${myLikedEventsFlag}&myPosted=${myPostedEventsFlag}&ops=listing&page=${pageNumber}&size=${itemPerPage}`;
			fetch(`${process.env.REACT_APP_BASE_URL}/api/${searchString}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					title: eventsFilter.title,
					cityCountry: eventsFilter.cityCountry,
					startDateTime: eventsFilter.startDateTime,
					distanceFromCity:
						eventsFilter.cityCountry.length > 0
							? eventsFilter.distanceFromCity
							: 0,
					genresArray: eventsFilter.genres,
					instrumentArray: eventsFilter.instrumentArray
						? eventsFilter.instrumentArray
						: [],
				}),
			})
				.then((res) => {

					if (res.status !== 200) {
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						if (pageNumber === 0) {
							setEventsList(data);
							setLoading(false);
						} else {
							setEventsList((prevData: any) => [...prevData, ...data]);
						}
					}

					setPageNumber((prevData: any) => prevData + 1);
					setLoading(false);
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
	// 	if (!isNavigatedFromGlobalSearch) {
	// 		fetchData();
	// 	}
		for(let i=0; i<eventsMenu.length; i++){
			if(eventActiveMenu === eventsMenu[i].id){
				setActiveMenuLabel(eventsMenu[i].label)
			}
		}
	}, [eventActiveMenu]);

	const viewMoreLink = () => {
		setViewMoreEvents(true);
		// navigate("/events/viewMoreEvents");
	};

	const handleCardView = () => {
		setViewMoreEvents(false);
	}


	return (
		<>
		<Grid
				className="center-align homeTab"
				style={{ padding: "10px", position: "relative" }}
				id="home-alert-title-bar"
			>
				{showAlert && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "80px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={6000}
						onClose={() => setShowAlert(false)}
					>
						<Alert
							onClose={() => setShowAlert(false)}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",

									fontWeight: "500",
									color: "#000000",
									display: "flex",

									margin: "auto",
									padding: "4px",
									justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
			</Grid>
			<Grid className="mainPanel">
				<Grid
					container={true}
					className="column mainPanel"
					sx={{ width: "80%" }}
				>
					<Grid
						item={true}
						sx={{ zIndex: 99, width: "100%", backgroundColor: "white" }}
						className="mainPanel"
					>
						<Grid container={true} columns={{ xs: 12 }} className="mainPanel">
							<Grid item={true} xs={7} mt={3}>
								<Tooltip title={user.location}>
									<Typography
										className="home-title-custom-size"
										sx={{
											fontStyle: "normal",
											fontWeight: "500",
											fontSize: "36px !important",
											lineHeight: "normal",
											paddingLeft: "15px",
										}}
									>
										Events near me:{" "}
										{user.location && user.location.length > locationMaxLength
											? user.location.substring(0, locationMaxLength) + "..."
											: user.location}
									</Typography>
								</Tooltip>
							</Grid>

							<Grid
								container={true}
								xs={5}
								mt={3}
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent:'end',
								}}
							>
								<Grid container xs={12} display="flex" alignItems="center" sx={{justifyContent:{md:'end'}}}>
									<Grid item xs={3}>
										{isNavigatedFromGlobalSearch && totalEvents && (
											<Typography variant="body1" sx={{ fontSize: "18px", fontWeight: "500", color: "#7A7985", ml: 2 }}>
												{totalEvents} results found
											</Typography>
										)}
									</Grid>
									<List
										component="nav"
										aria-label="Device settings"
										sx={{ bgcolor: "background.paper" }}
									>
										<Button
											id="eventsMenuButton"
											variant="outlined"
											sx={{
												// width: "187px",
												height: "40px  !important",
												color: "customColor.orange",
												borderRadius: "24px  !important",
												border: "2px solid #FF702A !important",
												"&:hover": {
													borderColor: "#FF702A !important",
												},
												"& .MuiButton-endIcon": {
													mr: 0,
												},
											}}
											className={`${classes.activeMenu} submit-button colorOrange`}
											onClick={handleClickListItem}
											endIcon={<img src={DownArrow} alt="Down Arrow" />}
										>
											<Typography>{activeMenuLable}</Typography>
										</Button>
										<Button
											variant="text"
											sx={{
												// width: "187px",
												height: "40px  !important",
												borderRadius: "24px  !important",
												backgroundColor:
													user.userAccountStatus === "SUSPEND"
														? "rgba(0, 0, 0, 0.12)"
														: "#FF702A !important",
												color: "#fff  !important",
												"&:hover": {
													backgroundColor: "FF702A !important",
													color: "secondary",
												},
												marginLeft: "10px  !important",
											}}
											disabled={user.userAccountStatus === "SUSPEND"}
											className={`${classes.eventMenu} submit-button`}
											onClick={() => setOpenPopup(true)}
											endIcon={<img src={RightArrow} alt="Down Arrow" />}
										>
											<Typography sx={{ fontSize: "1.5rem" }}>
												Post New Event
											</Typography>
										</Button>
									</List>
								</Grid>
								<Menu
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "eventsMenuButton",
										role: "listbox",
									}}
								>
									{eventsMenu.map((menu) => (
										<MenuItem
											key={menu.id}
											// disabled={index === 0}
											selected={menu.id === eventActiveMenu}
											onClick={(event) =>
												handleMenuItemClick(event, menu.id, menu.label)
											}
										>
											{menu.label}
										</MenuItem>
									))}
								</Menu>
							</Grid>
							<Grid item={true} className="rowa mainPanel" sx={{ zIndex: 1 }}>
								<Grid
									container={true}
									columns={{ xs: 12 }}
									style={{ display: "flex" }}
									rowSpacing={{ xs: 1 }}
									paddingTop={3}
									className="mainPanel"
								>
									<Grid xs={3} item className="leftPanelGig" sx={{pt:0}}>
										<EventsFilter
											fetchData={fetchData}
											getEventFilters={setEventFilters}
											handleCardView={handleCardView}
										/>
										<Card sx={{ mt: 3 }}>
											<SimilarArtists />
										</Card>
									</Grid>
									{/* } */}
									<Grid
										item
										xs={9}
										md={9}
										xl={9}
										className="centerPanelwoRightGig"
									>
										{/* {musicFilter.isFilterActive ? <EventSearchResult />
                                        :
                                        <> */}

										{/* <AllEvents
                                                selectedMenu={eventActiveMenu}
                                                filterInput={eventsFilters}
                                                data={eventsList}
                                            /> */}
										<Grid
											item
											xs={12}
											//className={classes.shadowedBox}
											boxShadow = {eventActiveMenu === "allEvents" && !isFilterActive ? "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" : "none"}
											borderRadius = {eventActiveMenu === "allEvents" && !isFilterActive ? "15px" : "none"}
											ml = {eventActiveMenu === "allEvents" && !isFilterActive ? "4px" : "none"}
											mt = {eventActiveMenu === "allEvents" && !isFilterActive ? "8px" : "none"}
											padding="1% 0 1% 2.8%"
											//margin={0}
										>
											<Grid xs={12} container >
												<>
													{viewMoreEvents ||
													isFilterActive ||
													eventActiveMenu === "myPostedEvents" ||
													eventActiveMenu === "myLikedEvents" ||
													eventActiveMenu !== "allEvents" ? (
														<>
															<InfiniteScroll
																dataLength={eventsList && eventsList.length}
																next={fetchDataForScroll}
																hasMore={hasMore}
																loader={
																	loading && (
																		<Box className={classes.spinner}>
                                                                            <CircularProgress />
																		</Box>
																	)
																}
																endMessage={
																	<p style={{ textAlign: "center" }}>
																		<b>Yay! You have seen it all</b>
																	</p>
																}
																style={{ overflow: "none", width:  "100%" }}
															>
																{
																	eventActiveMenu === "myPostedEvents" ||
																	eventActiveMenu === "myLikedEvents" || isFilterActive ? (
																	<>
																	<Grid sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
																		{eventsList && eventsList.length > 0 ? (
																				eventsList?.map((event: any) => (

																				<Grid  key={event.id} item sx={{marginBottom:"24px"}}>
																					<HorizontalCard
																							eventData={event}
																							type={"event"}
																						/>
																				</Grid>

																			))
                                                                            ) : (

                                                                                       !loading && (
                                                                            <Grid container item xs={12} justifyContent={"center"} textAlign={"center"} width={"100%"}>
                                                                                No events available yet
                                                                            </Grid>
																		))}
																		</Grid>
																	</>
																) : (
																	<>
																	<Grid sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
																			{eventsList && eventsList.length > 0 ? (
																				eventsList.map((event: any) => (
																					<Grid  key={event.id} item xs={3} sx={{marginBottom:"12px",display: "flex", flexDirection: "row"}}>
																						<EventCardCom
																							data={event}
																							navigationLink={"/gigs/gigDetails/"}
																							type={"gig"}
																						/>
																					</Grid>
																				))
																			) : (
																				!loading &&
																				<Grid container item display={"flex"} justifyContent="center" textAlign={"center"} width={"100%"}>
																					No Events available
																				</Grid>
																			)}
																		</Grid>
																	</>

																)
																}
															</InfiniteScroll>
														</>
													) : (
														<>
															{loading ? (
																<Grid
																	container
																	xs={12}
																	justifyContent="center"
																	sx={{ p: "16px" }}
																>
																	<CircularProgress />{" "}
																	{/* The loading spinner */}
																	<Grid container item justifyContent="center">
																		Searching ...
																	</Grid>
																</Grid>
															) : (
																<>
																	{eventsList && eventsList.length > 0 ? (
																		eventsList.map((event: any) => (
																			<Grid
																				item
																				key={event.id}
																				md={4}
																				lg={3}
																				sx={{
																					marginBottom: "12px",
																					display: "flex",
																					justifyContent: "left",
																				}}
																			>
																				<EventCardCom
																					data={event}
																					navigationLink={
																						"/events/eventDetails/"
																					}
																					type={"event"}
																				/>
																			</Grid>
																		))
																	) : (
																		<Grid item justifyContent={"center"} textAlign={"center"} width={"100%"}>
																			No events available yet
																		</Grid>
																	)}
																</>
															)}
														</>
													)}
												</>
											</Grid>

											{!viewMoreEvents && eventActiveMenu === "allEvents" && !loading &&  eventsList.length > 7 && (
													<Typography
														variant="body1"
														sx={{
															fontFamily: "Lexend",
															fontSize: " 16px",
															fontWeight: 500,
															lineHeight: "normal",
															letterSpacing: "0em",
															textAlign: "center",
															cursor: "pointer",
														}}
														onClick={() => {
															setViewMoreEvents(true);
														}}
													>
														View more
														<img
															style={{ marginBottom: "-8px" }}
															src={RightArrowBlack}
															alt="View more"
														/>
													</Typography>
												)}
										</Grid>
										{!viewMoreEvents && eventActiveMenu === "allEvents" && (
											<Grid container xs={12}>
												<Grid xs={12} item>
													<Grid
														item
														xs={12}
														ml={1}
														mr={1}
														mt={3}
														className={classes.shadowedBox}
														padding="1% 0 1% 2.8%"
													>
														<Grid container={true} xs={12} paddingBottom={2}>
															<Grid item={true} xs={6}>
																<Typography
																	variant="h5"
																	sx={{ fontSize: "18px !important" }}
																>
																	More Events near you
																</Typography>
															</Grid>
														</Grid>
														<Grid xs={12} container>
															<GoogleEvents />
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										)}
										{/* <AllEvents selectedMenu={eventActiveMenu} filterInput={eventsFilters}/> */}
										{openPopup && (
											<NewEvent
												open={openPopup}
												onSuccess={handleSuccess}
												onPostEventClose={handleOnClose}
												eventType={"newEvent"}
												setAlertMsgMain={setAlertMsg}
												setShowAlertMain={setShowAlert}
												setAlertStatusMain={setAlertStatus}
											/>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default Events;
